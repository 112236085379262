import { Component, OnInit, OnDestroy } from '@angular/core';
import { OperatorUIQuery, OperatorItem } from './filter-custom-operator.model';
import { UIFilterBase } from '../UIFilterBase';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '../../../services/filter.service';
import { FilterElement } from '../FilterElement';

@Component({
  selector: 'core-filter-custom-operator',
  templateUrl: './filter-custom-operator.component.html',
  styleUrls: ['./filter-custom-operator.component.scss'],
  standalone: false
})
export class FilterCustomOperatorComponent extends UIFilterBase<OperatorUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomOperatorKey = 'FilterCustomOperator';

  operatorFilter: FilterElement<OperatorItem>;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomOperatorComponent.FilterCustomOperatorKey);
  }

  ngOnInit(): void {
    this.initialize();
  }

  private initialize() {
    const query = this.filterService.getOperatorQuery();
    this.initializeQuery(query);

    this.operatorFilter = this.query.getOperator();
  }

  protected queryReset(): void {
    this.operatorFilter = this.query.getOperator();
  }

  selectItem(item: FilterElement<OperatorItem>): void {
    // intentionally blank
  }

  operatorChanged(event: { target: HTMLInputElement }): void {
    this.operatorFilter.item.operator = event.target.value;
    if (this.operatorFilter.item.operator && this.operatorFilter.item.operator.trim()) {
      this.operatorFilter.isSelected = true;
    } else {
      this.operatorFilter.isSelected = false;
    }

    this.filterService.updateOperatorQuery(this.query);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateOperatorQuery(this.query);
  }
}
