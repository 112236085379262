import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PairedDeviceSearchComponent } from './paired-device-search/paired-device-search.component';
import { PairedDeviceEditComponent } from './paired-device-edit/paired-device-edit.component';
import { AngularMaterialModule, QuidelCoreModule } from '../../core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';



@NgModule({
  declarations: [PairedDeviceSearchComponent, PairedDeviceEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
    ScrollingModule
  ],
  exports: [
    PairedDeviceSearchComponent,
    PairedDeviceEditComponent
  ]
})
export class PairedDevicesModule { }
