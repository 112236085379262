import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilityListComponent } from './facility-list/facility-list.component';
import { FacilityEditComponent } from './facility-edit/facility-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule, QuidelCoreModule } from '../../core';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [FacilityListComponent, FacilityEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
    ScrollingModule
  ],
  exports: [
    FacilityListComponent,
    FacilityEditComponent
  ]
})
export class FacilityModule { }
