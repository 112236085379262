import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateModule } from '@ngx-translate/core';
import {
  QuidelCoreModule, NavigationGuard, NavigationKeys,
  AssaysNavigationKeys, FacilitiesNavigationKeys, UsersNavigationKeys, OrganizationsNavigationKeys,
  InstrumentsNavigationKeys, ReportNavigationKeys, PairedDevicesNavigationKeys, OrphansNavigationKeys,
  ResultsApiNavigationKeys
} from './core';

import { MsalRedirectComponent } from '@azure/msal-angular';

import { SummaryComponent } from './summary/summary.component';
import { TestResultsComponent } from './test-results/test-results.component';
import { MappingComponent } from './mapping/mapping.component';
import { TestResultsDetailsDialogComponent } from './test-results/details/test-results-details-dialog.component';
import { TestResultsDialogWrapperComponent } from './test-results/test-results-dialog-wrapper/test-results-dialog-wrapper.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ExportFileModalComponent } from './modals/export-file/export-file-modal/export-file-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { routes as AssaysRoutes } from './admin/assays/assays.routing.module';
import { routes as FacilityRoutes } from './admin/facility/facility.routing.module';
import { routes as UserRoutes } from './admin/user/user.routing.module';
import { routes as OrganizationRoutes } from './admin/organization/organization.routing.module';
import { routes as InstrumentsRoutes } from './admin/instruments/instruments.routing.module';
import { routes as PairedDevicesRoutes } from './admin/paired-devices/paired-devices.routing.module';
import { routes as OrphansRoutes } from './admin/orphans/orphans.routing.module';
import { routes as ReportsRoutes } from './reports/reports.routing.module';
import { routes as ResultsApiRoutes } from './admin/results-api/results-api.routing.module';

@NgModule({
  declarations: [
    TestResultsComponent,
    TestResultsDetailsDialogComponent,
    TestResultsDialogWrapperComponent,
    MappingComponent,
    SummaryComponent,
    NavBarComponent,
    ExportFileModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    QuidelCoreModule,
    DragDropModule,
    ScrollingModule,
    RouterModule.forRoot(
      [
        /*
         * Needed for login on page load for PathLocationStrategy.
         * See FAQ for details:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/FAQ.md#pathlocationstrategy
        */
        {
          path: NavigationKeys.Auth, component: MsalRedirectComponent
        },
        {
          // If the redirectTo is changed to point somewhere else, please be sure to update the catch-all route as well
          path: NavigationKeys.Default, redirectTo: NavigationKeys.Summary, pathMatch: 'full',
        },
        {
          path: NavigationKeys.Summary, component: SummaryComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Results, component: TestResultsComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Mapping, component: MappingComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: AssaysNavigationKeys.Module, children: AssaysRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: FacilitiesNavigationKeys.Module, children: FacilityRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: UsersNavigationKeys.Module, children: UserRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: OrganizationsNavigationKeys.Module, children: OrganizationRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: InstrumentsNavigationKeys.Module, children: InstrumentsRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: PairedDevicesNavigationKeys.Module, children: PairedDevicesRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: OrphansNavigationKeys.Module, children: OrphansRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: ReportNavigationKeys.Module, children: ReportsRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: ResultsApiNavigationKeys.Module, children: ResultsApiRoutes,
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Forbidden, component: ForbiddenComponent
        },
        {
          path: '**', redirectTo: NavigationKeys.Summary,
        }
      ])
  ],
  exports: [
    RouterModule,
    TranslateModule,
    SummaryComponent,
    TestResultsComponent,
    TestResultsDetailsDialogComponent,
    TestResultsDialogWrapperComponent,
    MappingComponent,
    NavBarComponent
  ]
})
export class AppRoutingModule { }
