<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        <ng-container *ngIf="!newInstrument">
          {{ translationBaseKey + '.EditOrg' | translate }}
        </ng-container>
        <ng-container *ngIf="newInstrument">
          {{ translationBaseKey + '.AddOrg' | translate }}
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="instrumentForm" [formGroup]="instrumentFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.InstrumentType' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="instrumentTypeId" required="true">
            <mat-option *ngFor="let type of instrumentTypes" [value]="type.instrumentTypeId">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.SerialNumber' | translate }}</mat-label>
          <input matInput formControlName="serialNumber" required="true" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Comments' | translate }}</mat-label>
          <input matInput formControlName="comment" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.RegistrationDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="registrationDate" required="true">
          <mat-datepicker-toggle matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Organization' | translate }}</mat-label>
          <core-virtual-select formControlName="organizationId" required="true" itemSize="42" [dataSource]="orgs"
            valueField="organizationId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Facility' | translate }}</mat-label>
          <core-virtual-select formControlName="facilityId" required="true" itemSize="42" [dataSource]="facilities"
            valueField="facilityId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <div class="facilityInfo">
          <div class="facilityAddress">
            <div>{{facility?.address?.streetAddress1}}</div>
            <div *ngIf="facility?.address?.streetAddress2">{{facility?.address?.streetAddress2}}</div>
            <div *ngIf="facility?.address?.streetAddress3">{{facility?.address?.streetAddress3}}</div>
            <div>{{facility?.address?.city}}, {{facility?.address?.state?.abbreviation}} {{facility?.address?.zipCode}}
            </div>
            <div>{{facility?.address?.county?.name}}</div>
            <div>{{facility?.address?.country?.name}}</div>
          </div>
          <div *ngIf="instrument?.zipCodeOverrides" class="zipCodeRule">
            <div>{{ translationBaseKey + '.ZipCodeOverwrite' | translate:{'zipCodeOverrides':
              instrument.zipCodeOverrides} }}</div>
          </div>
        </div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.WirelessDevice' | translate }}</mat-label>
          <core-virtual-select formControlName="pairedDeviceId" itemSize="42" [dataSource]="devices"
            valueField="pairedDeviceId" displayField="deviceAddress" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.ContactName' | translate }}</mat-label>
          <input matInput formControlName="contactName" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Email' | translate }}</mat-label>
          <input matInput formControlName="contactEmail" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Phone' | translate }}</mat-label>
          <input matInput formControlName="contactPhone" [customErrors]="{ pattern: 'ValidationErrors.Phone' }" />
        </mat-form-field>
      </form>

      <button mat-button [disabled]="instrumentFormGroup.invalid || instrumentFormGroup.disabled" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveInstrument' | translate }}"
        [matTooltipDisabled]="instrumentFormGroup.invalid || instrumentFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelInstrument' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

      <button mat-button *ngIf="!isOrgAdmin && !newInstrument" [disabled]="instrumentFormGroup.disabled"
        (click)="onDelete()" matTooltip="{{ translationBaseKey + '.DeleteInstrument' | translate }}"
        [matTooltipDisabled]="instrumentFormGroup.disabled">
        <mat-icon class="icon-button-override">delete</mat-icon>
        {{ translationBaseKey + '.Delete' | translate }}
      </button>

      <button mat-button *ngIf="!isOrgAdmin" [disabled]="instrumentFormGroup.disabled || newInstrument || dirty"
        class="float-right" (click)="onShowUpdateTestResultsDialog(updateTestResultsTemplate)"
        matTooltip="{{ translationBaseKey + '.UpdateTestResults' | translate }}"
        [matTooltipDisabled]="instrumentFormGroup.disabled || newInstrument || dirty">
        <mat-icon class="icon-button-override">update</mat-icon>
        {{ translationBaseKey + '.UpdateTestResults' | translate }}
      </button>

    </mat-card-content>
  </mat-card>

</div>

<ng-template #updateTestResultsTemplate let-data>
  <h1 mat-dialog-title>{{ translationBaseKey + '.UpdateTestResults' | translate }}</h1>
  <div mat-dialog-content class="update-test-results-content">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" required="true" #resultDate [value]="data.defaultStartDate">
      <mat-datepicker-toggle matSuffix [for]="picker">
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsEndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" required="true" #resultEndDate [value]="data.defaultEndDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker">
      </mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsType' | translate }}</mat-label>
      <mat-select matNativeControl required="true" #dateType>
        <mat-option [value]="0">Storage Date</mat-option>
        <mat-option [value]="1">Run Date</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ translationBaseKey + '.UpdateTestResultsTimezoneNotification' | translate }}</mat-label>
  </div>
  <div mat-dialog-actions>
    <button mat-button
      [mat-dialog-close]="{ startDate: resultDate.value, endDate: resultEndDate.value, type: dateType.value }"
      [disabled]="!resultDate.value || !resultEndDate.value || !IsDateRangeValid(resultDate.value, resultEndDate.value) || dateType.value === undefined">{{
      'ModalConfirmationDialog.YesButton' | translate }}</button>
    <button mat-button mat-dialog-close="false" cdkFocusInitial>
      {{ 'ModalConfirmationDialog.NoButton' | translate }}</button>
  </div>
</ng-template>
