import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MatchedConfirmationModalInput {
  titleResourceKey: string;
  toBeMatchedName: string;
  toBeMatchedValue: string;
}

@Component({
  selector: 'core-matched-confirmation-modal',
  templateUrl: './matched-confirmation-modal.component.html',
  styleUrls: ['./matched-confirmation-modal.component.scss'],
  standalone: false
})
export class MatchedConfirmationModalComponent {  
  public translationBaseKey = 'ModalMatchedConfirmationDialog';

  enteredValue: string;
  yesButtonDisabled = true;

  constructor(
    public dialogRef: MatDialogRef<MatchedConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatchedConfirmationModalInput,
  ) { }

  onYesClick(): void {
    if (this.enteredValue == this.data.toBeMatchedValue) {     
      this.dialogRef.close(true);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  enteredValue_Changed(newEnteredValue: string): void {
    if (this.enteredValue == this.data.toBeMatchedValue) {
      this.yesButtonDisabled = false;
    }
    else {
      this.yesButtonDisabled = true;
    }
  }

}
