import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  PatientsCoInfectionComponent, BaseComponent, QueryService, SearchService, TitleService, FilterService, ReportKeys,
  TestResult_PatientsCoInfectionRow
} from '../../core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'app-report-patient-coinfection',
  templateUrl: './report-patient-coinfection.component.html',
  styleUrls: ['./report-patient-coinfection.component.scss'],
  standalone: false
})
export class ReportPatientCoInfectionComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) public patientsCoInfection: PatientsCoInfectionComponent;

  constructor(private queryService: QueryService,
    private searchService: SearchService,
    private titleService: TitleService,
    private filterService: FilterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toastr: ToastrService,
    private translate: TranslateService) {
    super();

  }


  ngOnInit(): void {

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.onSearch();

    this.titleService.updateTitleTranslateKey('ReportMenu.PatientsCoInfections');
  }

  onSearch(): void {
    if (this.filterService.isReportEnabled(ReportKeys.PatientCoInfections)) {
      this.googleAnalyticsService.logWithLabels('View Report', 'Report', 'View Patient Co-infection Report', 'filters', this.queryService.getCurrentQuery(true));
      this.subscription.add(this.searchService.getReportPatientsCoInfections(this.queryService.getCurrentQuery(true)).subscribe(
        (result: TestResult_PatientsCoInfectionRow[]) => {
          if (!result || result.length === 0) {
            const title = translate(this.translate, 'ReportMenu.NoDataToShow');
            this.toastr.info(undefined, title);
            this.patientsCoInfection.showChart = false;
            return;
          }

          this.patientsCoInfection.testName = this.queryService.getCurrentQuery().testTypes[0];
          this.patientsCoInfection.setData(result);
        }));
    } else {
      const message = translate(this.translate, 'ReportMenu.RequirePatientAssay');
      const title = translate(this.translate, 'ReportMenu.ReportLoadError');
      this.toastr.error(message, title);
    }
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  savePDF(): void {
    this.patientsCoInfection.saveAsPdf();
  }

  saveImage(): void {
    this.patientsCoInfection.saveAsImage();
  }

}
