<div class="instrument-search-container">
  <div>
    <mat-form-field class="search-field">
      <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
      <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
        matTooltip="{{ translationBaseKey + '.Search' | translate }}">
        <mat-icon class="icon-button-override">search</mat-icon>
      </button>
    </mat-form-field>
    <span class="search-notification">{{ translationBaseKey + '.SearchInfo' | translate }}</span>
    <div class="top-right-buttons">
      <button mat-button class="add-new-button" [disabled]="!hasCreatePermission" (click)="createNew()"
        matTooltip="{{ translationBaseKey + '.AddNewInstrument' | translate }}"
        [matTooltipDisabled]="!hasCreatePermission">
        <mat-icon class="icon-button-override">add</mat-icon> {{ translationBaseKey + '.AddNew' | translate }}
      </button>
      <button mat-icon-button class="instrument-export-csv">
        <mat-icon color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
          matTooltip="{{'InstrumentSearch.ExportCSVTooltip' | translate}}"
          (click)="exportFileDialog(ExportFileType.CSV, value)">grid_on</mat-icon>
      </button>
    </div>
  </div>

  <div class="instrument-search">

    <mat-table class="mat-elevation-z8" [dataSource]="instrumentResults?.results" matSort
      (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
      [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="serialnumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.SerialNumber' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument">{{instrument.serialNumber}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="orgname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrgName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument" class="wrap-text">{{instrument.facility?.organization?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.FacilityName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument" class="wrap-text">{{instrument.facility?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="streetaddress1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.StreetAddress1' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let instrument"
          class="wrap-text">{{instrument.facility?.address?.streetAddress1}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.City' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument" class="wrap-text">{{instrument.facility?.address?.city}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.State' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument">{{instrument.facility?.address?.state?.abbreviation}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="county">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.County' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument"
          class="wrap-text">{{instrument.facility?.address?.county?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Country' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let instrument">{{instrument.facility?.address?.country?.code}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zipcode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ZipCode' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let instrument">{{instrument.facility?.address?.zipCode}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="instrument-paginator" [length]="instrumentResults?.totalResults" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>

</div>
