<div class="org-search-container">
  <div>
    <mat-form-field class="search-field">
      <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
      <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
        matTooltip="{{ translationBaseKey + '.Search' | translate }}">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <span class="search-notification">{{ translationBaseKey + '.SearchInfo' | translate }}</span>
    <button mat-button class="add-new-button" [disabled]="!enableAdd" (click)="createNew()"
      matTooltip="{{ translationBaseKey + '.AddOrg' | translate }}" [matTooltipDisabled]="!enableAdd">
      <mat-icon class="icon-button-override">add</mat-icon>{{ translationBaseKey + '.AddNew' | translate }}
    </button>

  </div>

  <div class="org-search">

    <mat-table #table class="mat-elevation-z8" [dataSource]="orgResults?.results" matSort
      (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
      [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="crmId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.CrmId' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.crmId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="contactname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ContactName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.contactName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Email' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Phone' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let org">{{org.phone}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="streetAddress1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.StreetAddress1' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.address?.streetAddress1}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.City' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let org" class="wrap-text">{{org.address?.city}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.State' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let org">{{org.address?.state?.abbreviation}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Country' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let org">{{org.address.country.code}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zipCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ZipCode' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let org">{{org.address.zipCode}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="org-paginator" [length]="orgResults?.totalResults" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>

</div>
