import { Component, OnInit, OnDestroy } from '@angular/core';
import { UIFilterBase } from '../UIFilterBase';
import { DateUIQuery, DatesFilterItemBase, LastDaysFilterItem, FixedDatesFilterItem } from './filter-custom-date.model';
import { FilterService } from '../../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterElement } from '../FilterElement';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import moment_from from 'moment';

@Component({
  selector: 'core-filter-custom-date',
  templateUrl: './filter-custom-date.component.html',
  styleUrls: ['./filter-custom-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  standalone: false
})
export class FilterCustomDateComponent extends UIFilterBase<DateUIQuery> implements OnInit, OnDestroy {
  private static FilterCustomDateKey = 'FilterCustomDate';

  lastDays: FilterElement<LastDaysFilterItem>;
  fixedDates: FilterElement<FixedDatesFilterItem>;

  public unitsFormGroup: UntypedFormGroup;
  public datesFormGroup: UntypedFormGroup;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomDateComponent.FilterCustomDateKey);
  }

  ngOnInit(): void {
    this.intializeValidators();
    this.initialize();
  }

  public hasErrorUnits = (errorName: string): boolean => {
    return this.unitsFormGroup.controls.units.hasError(errorName);
  };

  public hasErrorDates = (controlName: string, errorName: string): boolean => {
    return this.datesFormGroup.controls[controlName].hasError(errorName);
  };

  private intializeValidators() {
    /* eslint-disable @typescript-eslint/unbound-method */
    this.unitsFormGroup = new UntypedFormGroup({
      units: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')])
    });

    this.datesFormGroup = new UntypedFormGroup({
      dateFrom: new UntypedFormControl('', [Validators.required, this.validateDates.bind(this)]),
      dateTo: new UntypedFormControl('', [Validators.required, this.validateDates.bind(this)]),
    });
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  private validateDates(c: UntypedFormControl) {

    let isValid = true;
    if (this && this.datesFormGroup) {
      const dateFrom = this.datesFormGroup.controls.dateFrom;
      const dateTo = this.datesFormGroup.controls.dateTo;
      if (dateFrom && dateTo && dateFrom.value && dateTo.value) {
        const dateFromValue: moment_from.Moment = dateFrom.value as moment_from.Moment;
        const dateToValue: moment_from.Moment = dateTo.value as moment_from.Moment;
        if (!(dateFromValue < dateToValue)) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      return true;
    } else {
      return {
        validateDates: {
          valid: isValid
        }
      };
    }
  }


  private initialize() {

    const query = this.filterService.getDateUIQuery();
    this.initializeQuery(query);

    this.initializeDates();
  }

  private initializeDates() {
    this.lastDays = this.query.getLastDays();
    this.fixedDates = this.query.getFixedDates();

    this.datesFormGroup.setValue(
      {
        dateFrom: this.fixedDates.item.dateFrom,
        dateTo: this.fixedDates.item.dateTo
      }
    );

    this.unitsFormGroup.setValue({
      units: this.lastDays.item.units
    });
  }

  protected queryReset(): void {
    this.initializeDates();
  }

  selectItem(date: FilterElement<DatesFilterItemBase>): void {
    const previousSelection = this.query.getSelected();
    if (previousSelection) {
      if (previousSelection.item.getId() !== date.item.getId()) {
        previousSelection.toggleSelected();
        date.toggleSelected();
        this.filterService.updateDateQuery(this.query);
      }
    }
  }

  startDateChanged(date: MatDatepickerInputEvent<moment_from.Moment>): void {
    this.fixedDates.item.dateFrom = date.value;
    this.filterService.updateDateQuery(this.query);

    this.runDateValidation();
  }

  endDateChanged(date: MatDatepickerInputEvent<moment_from.Moment>): void {
    this.fixedDates.item.dateTo = date.value;
    this.filterService.updateDateQuery(this.query);

    this.runDateValidation();
  }

  runDateValidation(): void {
    this.datesFormGroup.get('dateFrom').updateValueAndValidity();
    this.datesFormGroup.get('dateTo').updateValueAndValidity();
  }

  fixedDaysChanged(event: { target: HTMLInputElement }): void {
    if (this.hasErrorUnits('pattern')) {
      return;
    }
    this.lastDays.item.units = +event.target.value;
    this.filterService.updateDateQuery(this.query);
  }

  timeRangeChanged(): void {
    this.filterService.updateDateQuery(this.query);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateDateQuery(this.query);
  }
}
