import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MetadataService, Organization, Role, User, Facility, DisplayPhoneNumberPipe,
  BaseComponent, InitDataSelectionLists, Features, TitleService, TranslateNotificationKeys,
  ConfirmationModalInput, ConfirmationModalComponent, FormUtility, UserGroup
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { UserEditAccessService } from './user-edit.access.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'myvirena2-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  standalone: false
})
export class UserEditComponent extends BaseComponent implements OnInit, OnDestroy {

  public translationBaseKey = 'UserEdit';
  public isApproved = true;
  public roles: Role[];
  public orgs: Organization[];
  public additionalOrgs: Organization[];
  public user: User = <User>{};
  public userId: string;
  public facilities: Facility[];
  public selectedRole: Role;
  public userFormGroup: UntypedFormGroup;

  public b2cModificationsEnabled = false;

  private paramId = 'id';
  private currentUser: User;

  private groupIdFormControlName = 'groupId';

  // used to prevent nulling out facility id when org id changes on initial load
  private keepFacilityId = false;

  constructor(private metadataService: MetadataService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: TitleService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public accessService: UserEditAccessService
  ) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.userId = params[this.paramId] as string;
      this.loadUser();
      if (this.accessService.isReadOnlyAccess()) {
        this.userFormGroup.disable();
      }
    }));

    this.subscription.add(this.metadataService.getMe().subscribe(user => {
      this.currentUser = user;

      this.loadInitData();
      this.checkWriteFlag();
    }));


    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      this.b2cModificationsEnabled = features.B2CModificationsEnabled;
      if (!features.WriteEnabled || !this.b2cModificationsEnabled) {
        this.userFormGroup.disable();
      }
    }));

  }

  loadInitData(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {

      if (this.accessService.isOrgAdmin()) {
        const currentOrg = initData.Organizations.find(o => o.organizationId === this.currentUser.organizationId);
        this.orgs = [currentOrg];
        this.additionalOrgs = [];
      }
      else {
        this.orgs = initData.Organizations;
        // set the default value
        this.additionalOrgs = this.orgs;
        if (this.user.group?.displayName === UserGroup.NetworkOrganizationAdmin.valueOf()) {
          this.refreshAdditionalOrganizationDropDown();
        }
      }
    }));
  }

  loadUser(): void {
    this.subscription.add(this.metadataService.getUser(this.userId).subscribe({
      next: (user: User) => {

        user.additionalOrganizationIds = [];
        if (user.additionalOrganizations != null && user.additionalOrganizations.length > 0) {
          user.additionalOrganizations.forEach(o => user.additionalOrganizationIds.push(o.organizationId));
        }

        this.user = user;
        this.isApproved = this.user.isApproved;
        this.keepFacilityId = true;
        this.userFormGroup.setValue({
          userName: this.user.userName ? this.user.userName : 'unknown',
          givenName: this.user.givenName,
          surname: this.user.surname,
          email: this.user.email,
          organizationId: this.user.organizationId ? this.user.organizationId : '',
          additionalOrganizationIds: this.user.additionalOrganizationIds ? this.user.additionalOrganizationIds.join(',') : [],
          facilityId: this.user.facilityId ? this.user.facilityId : '',
          groupId: this.user.group ? this.user.group.objectId : '',
          reqOrg: this.user.requestedOrganization ? this.user.requestedOrganization : '',
          reqFac: this.user.requestedFacility ? this.user.requestedFacility : '',
          phone: this.user.phone ? DisplayPhoneNumberPipe.prototype.transform(this.user.phone) : '',
          isApproved: this.user.isApproved ? this.user.isApproved : false,
          notes: this.user.notes ? this.user.notes : ''
        });

        if (this.user.group?.displayName === UserGroup.NetworkOrganizationAdmin.valueOf() && this.orgs) {
          // exclude the main organization from the list of additional organizations
          this.additionalOrgs = this.orgs.filter(o => o.organizationId != this.user.organizationId);
        }

        // Set Org if user is OrgAdmin
        if (this.accessService.isOrgAdmin()) {
          this.userFormGroup.get('organizationId').setValue(this.currentUser.organizationId);
          this.userFormGroup.get('organizationId').disable();
        }
        else {
          this.userFormGroup.get('organizationId').enable();
        }

        this.subscription.add(
          this.metadataService.getRoles().subscribe((roles: Role[]) => {
            this.roles = roles;
            if (this.user.group && this.user.group.objectId) {
              const role = this.roles.find(r => r.objectId === this.user.group.objectId);
              if (role) {
                this.userFormGroup.get(this.groupIdFormControlName)?.enable();
                this.selectedRole = role;
              } else {
                this.userFormGroup.get(this.groupIdFormControlName)?.disable();
                this.roles = [];
                this.roles.push(this.user.group);
                this.selectedRole = this.user.group;
              }
            } else {
              this.userFormGroup.get(this.groupIdFormControlName)?.enable();
            }

            this.updateValidators();
            console.debug(this.roles);
          }));
      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.userId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));

  }

  loadFacilities(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacSelectionListByOrg(val).subscribe((facs: Facility[]) => {
          this.facilities = facs;
        }));
    }
  }

  initForm(): void {
    /* eslint-disable @typescript-eslint/unbound-method */
    this.userFormGroup = this.formBuilder.group({
      userName: [{ value: '', disabled: true }],
      givenName: ['', Validators.required],
      surname: ['', Validators.required],
      email: [{ value: '', disabled: true }],
      organizationId: ['', Validators.required],
      facilityId: ['', Validators.required],
      additionalOrganizationIds: [''],
      groupId: [{ value: '', disabled: true }, Validators.required],
      reqFac: [{ value: '', disabled: true }],
      reqOrg: [{ value: '', disabled: true }],
      phone: [''],
      isApproved: [false],
      notes: ['']
    });
    /* eslint-enable @typescript-eslint/unbound-method */

    this.userFormGroup.markAllAsTouched();

    this.subscription.add(
      this.userFormGroup.get('organizationId').valueChanges.subscribe(val => {
        if (!this.keepFacilityId) {
          this.keepFacilityId = false;
          this.userFormGroup.controls['facilityId'].setValue(null);
        }
        this.loadFacilities(+val);
        if (this.selectedRole?.displayName === UserGroup.NetworkOrganizationAdmin.valueOf()) {
          // remove the main organization from the list of additional organizations
          this.refreshAdditionalOrganizationDropDown();
        }
        this.updateValidators();
      }));

    this.userFormGroup.get(this.groupIdFormControlName).valueChanges.subscribe(roleId => {
      if (this.roles) {
        this.selectedRole = this.roles.find(r => r.objectId == roleId);
        if (this.selectedRole?.displayName === UserGroup.NetworkOrganizationAdmin.valueOf()) {
          this.refreshAdditionalOrganizationDropDown();
        }

        this.updateValidators();
      }
    });
  }

  refreshAdditionalOrganizationIdsControl(): void {
    // trigger the change detection mechanism
    if (this.user.additionalOrganizationIds.length > 0) {
      this.userFormGroup.get('additionalOrganizationIds').setValue(this.user.additionalOrganizationIds.join(','));
    }
    else {
      this.userFormGroup.get('additionalOrganizationIds').setValue(null);
    }
  }

  refreshAdditionalOrganizationDropDown(): void {
    if (this.orgs) {
      const newOrgId = +this.userFormGroup.get('organizationId').value;
      if (newOrgId > 0) {
        // exclude the id of the main organization from the ids of additional organizations
        const idx = this.user.additionalOrganizationIds.indexOf(newOrgId);
        if (idx > -1) {
          this.user.additionalOrganizationIds.splice(idx, 1);
          this.user.additionalOrganizationIds = [... this.user.additionalOrganizationIds];
        }

        // exclude the main organization from the list of additional organizations
        this.additionalOrgs = [...this.orgs.filter(o => o.organizationId != newOrgId)];
      }
      this.refreshAdditionalOrganizationIdsControl();
    }
  }

  updateValidators(): void {
    /* eslint-disable @typescript-eslint/unbound-method */
    if (this.selectedRole) {
      if (this.selectedRole.displayName === UserGroup.NetworkOrganizationAdmin.valueOf()) {
        this.userFormGroup.controls['facilityId'].clearValidators();
        this.userFormGroup.controls['additionalOrganizationIds'].setValidators([Validators.required]);
      }
      else {
        this.userFormGroup.controls['facilityId'].setValidators([Validators.required]);
        this.userFormGroup.controls['additionalOrganizationIds'].clearValidators();
      }

      this.userFormGroup.controls['facilityId'].updateValueAndValidity();
      this.userFormGroup.controls['additionalOrganizationIds'].updateValueAndValidity();
    }
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  updateUser(): void {
    this.user.givenName = this.userFormGroup.get('givenName').value as string;
    this.user.surname = this.userFormGroup.get('surname').value as string;
    this.user.organizationId = +this.userFormGroup.get('organizationId').value;

    if (this.selectedRole && this.selectedRole.displayName === UserGroup.NetworkOrganizationAdmin.valueOf()) {
      this.user.facilityId = null;
    }
    else {
      this.user.facilityId = +this.userFormGroup.get('facilityId').value;
      this.user.additionalOrganizationIds = null;
    }

    this.user.group = {
      objectId: this.userFormGroup.get(this.groupIdFormControlName).value as string,
      displayName: ''
    };
    this.user.phone = FormUtility.emptyStringCheck(this.userFormGroup.get('phone').value as string | null);
    this.user.isApproved = this.userFormGroup.get('isApproved').value as boolean;
    this.user.notes = this.userFormGroup.get('notes').value as string;

    // No need to pass back Facility and Org objects
    this.user.facility = null;
    this.user.organization = null;
    this.user.additionalOrganizations = null;

  }

  public onSave(): void {
    this.updateUser();

    this.subscription.add(
      this.metadataService.saveUser(this.user).subscribe({
        next: () => {
          const title = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`);
          const message = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UserEditSuccessMessage}`);
          this.toastr.success(message, title);
          this.loadUser();
        },
        error: (err) => {
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (err.error && err.error['User Group']) {
            this.toastr.error(err.error['User Group'] as string, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
          } else {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
          }
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        }
      }));
  }

  public onCancel(): void {
    this.location.back();
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'User' })) {
      this.subscription.add(
        this.metadataService.deleteUser(this.user).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
          }
        }));
    }
  }

  public async onReject(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmRejection', parameter: 'User' })) {
      this.updateUser();
      this.subscription.add(
        this.metadataService.rejectUser(this.user).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.RejectSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.RejectFailure}`));
          }
        }));
    }
  }


  public onApprove(): void {
    this.updateUser();
    this.subscription.add(
      this.metadataService.approveUser(this.user).subscribe({
        next: () => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ApproveSuccess}`));
          this.location.back();
        },
        error: (err) => {
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (err.error && err.error['User Group']) {
            this.toastr.error(err.error['User Group'] as string, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ApproveFailure}`));
          }
          else {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ApproveFailure}`));
          }
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        }
      }));
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {

    const modalWidth = 0.25 * window.outerWidth;

    const confDia = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    return await firstValueFrom(confDia.afterClosed());
  }

  public onSaveNotes(): void {
    this.subscription.add(
      this.metadataService.saveUserNotes({ id: this.user.externalObjectId, notes: this.userFormGroup.get('notes').value as string }).subscribe({
        next: () => {
          const title = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`);
          this.toastr.success('', title);
          this.loadUser();
        },
        error: (err) => {
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (err.error && err.error['User Group']) {
            this.toastr.error(err.error['User Group'] as string, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
          } else {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
          }

          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        }
      }));
  }

}
