import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, PairedDevice, Facility, Organization, Instrument,
  MetadataService, User, PairedDeviceType,
  Features, InitDataSelectionLists, TitleService,
  FormUtility,
  TranslateNotificationKeys,
  PairedDeviceSelection, ConfirmationModalComponent, ConfirmationModalInput, PairedDeviceTypeId,
  InstrumentsNavigationKeys, SearchResultsPage
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-paired-device-edit',
  templateUrl: './paired-device-edit.component.html',
  styleUrls: ['./paired-device-edit.component.scss'],
  standalone: false
})
export class PairedDeviceEditComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramId = 'id';
  private paramOrgId = 'orgId';
  private paramFacId = 'facId';
  private isUpdating = false;
  public translationBaseKey = 'PairedDeviceEdit';
  public translationInstrumentTableKey = 'InstrumentTable';
  public newDevice = true;
  public device: PairedDevice;
  public deviceId: number;
  public facId: number;
  public orgId: number;
  public deviceFormGroup: UntypedFormGroup;
  public orgs: Organization[];
  public facilities: Facility[];
  public facility: Facility;
  public isOrgAdmin: boolean;
  public deviceTypes: PairedDeviceType[];
  private confirmationDialogRef: MatDialogRef<ConfirmationModalComponent, boolean>;

  public displayColumns = ['serialNumber'];
  public instruments: Instrument[];

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private location: Location, private titleService: TitleService,
    private toastr: ToastrService, private translate: TranslateService, private dialog: MatDialog) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.deviceId = +params[this.paramId];
      this.orgId = +params[this.paramOrgId];
      this.facId = +params[this.paramFacId];

      this.newDevice = this.deviceId === 0;

      if (!this.newDevice) {
        this.loadDevice();
      } else {
        this.loadNew();
      }

      this.loadInitData();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  loadFacilities(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacSelectionListByOrg(val).subscribe((facs: Facility[]) => {
          this.facilities = facs;

          const facilityControl = this.deviceFormGroup.get('facilityId');

          const desiredFacilityId = facilityControl.value as number;
          const foundFacility = this.facilities.find(f => f.facilityId === +desiredFacilityId);
          if (!foundFacility) {
            this.clearFacilitySelection();
          }
        }));
    }
  }

  private clearFacilitySelection() {
    this.deviceFormGroup.patchValue({
      facilityId: ''
    }, {
      emitEvent: false
    });
  }

  loadInstruments(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getPairedDeviceSelectionListByFacilityId(val).subscribe((devices: PairedDeviceSelection[]) => {
          // this.devices = devices;
        }));
    }
  }


  loadFacility(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacility(val).subscribe((fac: Facility) => {
          this.facility = fac;
        }));
    }
  }

  public loadInitData(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.orgs = initData.Organizations;
    }));

    this.subscription.add(this.metadataService.getPairedDeviceTypes().subscribe((deviceTypes: PairedDeviceType[]) => {
      this.deviceTypes = deviceTypes;
      this.deviceFormGroup.get('macAddress').updateValueAndValidity();
    }));

  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.deviceFormGroup.disable();
      }
    }));

  }

  public loadNew(): void {
    this.isUpdating = true;

    if (this.orgId > 0 && this.facId > 0) {
      this.deviceFormGroup.setValue({
        organizationId: this.orgId,
        deviceAddress: '',
        macAddress: '',
        description: '',
        deviceTypeId: 0,
        facilityId: this.facId,
      });
      this.isUpdating = false;
    }
    else {
      this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

        this.deviceFormGroup.setValue({
          organizationId: me.organizationId,
          deviceAddress: '',
          macAddress: '',
          description: '',
          deviceTypeId: 0,
          facilityId: me.facilityId,
        });
        this.isUpdating = false;
      }));
    }
  }

  public setValueChanges(): void {

    this.subscription.add(
      this.deviceFormGroup.get('organizationId').valueChanges.subscribe(val => {
        this.loadFacilities(+val);
      }));

    this.subscription.add(
      this.deviceFormGroup.get('facilityId').valueChanges.subscribe(val => {
        this.loadFacility(+val);
      }));

    this.subscription.add(
      this.deviceFormGroup.get('deviceTypeId').valueChanges.subscribe(val => {
        if (!this.isUpdating) {
          let newMacAddress = '';
          if (val !== PairedDeviceTypeId.Sofia2) {
            newMacAddress = '00042D';
          }

          this.deviceFormGroup.patchValue({
            macAddress: newMacAddress
          });
        }

        this.deviceFormGroup.get('macAddress').updateValueAndValidity();
      }));


    this.subscription.add(
      this.deviceFormGroup.get('macAddress').valueChanges.subscribe((val: string) => {
        if (!this.isUpdating) {
          const deviceTypeId = this.deviceFormGroup.get('deviceTypeId').value as number;

          let newDeviceAddress = '';
          if (deviceTypeId === PairedDeviceTypeId.Sofia2.valueOf()) {
            const firstBlock = val.substring(0, 7);
            const secondBlock = val.substring(7);
            newDeviceAddress = `00010000-00000000-0${firstBlock}-${secondBlock}`;
          } else {
            const firstBlock = val.substring(0, 6);
            const secondBlock = val.substring(6);
            newDeviceAddress = `00000000-00000000-${firstBlock}FF-FF${secondBlock}`;
          }

          this.deviceFormGroup.patchValue({
            deviceAddress: newDeviceAddress
          });
        }
      }));

  }

  public loadDevice(): void {
    this.isUpdating = true;

    this.subscription.add(this.metadataService.getPairedDevice(this.deviceId).subscribe({
      next: (device: PairedDevice) => {
        this.device = device;

        this.deviceFormGroup.setValue({
          deviceAddress: this.device.deviceAddress ? this.device.deviceAddress : '',
          macAddress: this.device.macAddress ? this.device.macAddress : '',
          description: this.device.description ? this.device.description : '',
          deviceTypeId: this.device.pairedDeviceType_PairedDeviceTypeId ? this.device.pairedDeviceType_PairedDeviceTypeId : 0,
          facilityId: this.device.facility_FacilityId ? this.device.facility_FacilityId : 0,
          organizationId: this.device.facility ? this.device.facility.organization_OrganizationId : 0
        });

        this.isUpdating = false;

        this.deviceFormGroup.get('macAddress').updateValueAndValidity();
      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.deviceId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
        this.isUpdating = false;
      }
    }));

    this.subscription.add(this.metadataService.getInstrumentsByPairedDeviceId(this.deviceId).subscribe((instruments: SearchResultsPage<Instrument>) => {
      this.instruments = instruments.results;
    }));
  }

  validateMacAddress(control: AbstractControl): ValidationErrors | null {
    let isValid = true;

    if (control.parent && control.parent.value) {
      const macAddress = control.value as string;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const deviceTypeId = control.parent.value.deviceTypeId as number;

      isValid = deviceTypeId === PairedDeviceTypeId.Sofia2.valueOf() ?
        macAddress.length === 15 : macAddress.length === 12;
    }

    return isValid ? null : { invalidMacAddress: isValid };
  }

  public updateInstrument(): void {
    if (this.newDevice) {
      this.device = {
        pairedDeviceId: 0,
        deviceAddress: this.deviceFormGroup.get('deviceAddress').value as string,
        macAddress: this.deviceFormGroup.get('macAddress').value as string,
        description: FormUtility.emptyStringCheck(this.deviceFormGroup.get('description').value as string),
        facility_FacilityId: this.deviceFormGroup.get('facilityId').value as number,
        pairedDeviceType_PairedDeviceTypeId: this.deviceFormGroup.get('deviceTypeId').value as number
      };
    } else {
      this.device.deviceAddress = this.deviceFormGroup.get('deviceAddress').value as string;
      this.device.macAddress = this.deviceFormGroup.get('macAddress').value as string;
      this.device.description = FormUtility.emptyStringCheck(this.deviceFormGroup.get('description').value as string);
      this.device.facility_FacilityId = this.deviceFormGroup.get('facilityId').value as number;
      this.device.pairedDeviceType_PairedDeviceTypeId = this.deviceFormGroup.get('deviceTypeId').value as number;
      this.device.facility = null;
      this.device.pairedDeviceType = null;
    }

  }

  initForm(): void {
    this.deviceFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      macAddress: ['', [Validators.required, this.validateMacAddress]],
      description: [''],
      deviceAddress: [{ value: '', disabled: true }],
      facilityId: ['', Validators.required],
      organizationId: ['', Validators.required],
      deviceTypeId: ['', Validators.required]
      /* eslint-enable @typescript-eslint/unbound-method */
    });
    this.setValueChanges();

  }

  public onSave(): void {
    this.updateInstrument();

    let saveObservable: Observable<number>;
    if (this.newDevice) {
      saveObservable = this.metadataService.postPairedDevice(this.device);
    } else {
      saveObservable = this.metadataService.putPairedDevice(this.device);
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: (ret) => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));

          // return to the Facility Edit screen
          if (this.facId > 0) {
            window.history.go(-1);
          }
          else {
            this.newDevice = false;
            this.deviceId = ret;
            this.loadDevice();
          }
        },
        error: (err) => {
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (err.error && err.error.MacAddress && err.error.MacAddress.length > 0) {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.MacAddressInUse}`));
          } else {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
          }
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        }
      }));
  }

  public onCancel(): void {
    window.history.go(-1);
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'Instrument' })) {
      this.subscription.add(
        this.metadataService.deletePairedDevice(this.device).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
          }
        }));
    }
  }

  public onInstrumentRowSelect(instrument: Instrument): void {
    void this.router.navigate([InstrumentsNavigationKeys.EditFullPath, instrument.instrumentId]);
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {
    const modalWidth = 0.16 * window.outerWidth;
    this.confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = await firstValueFrom(this.confirmationDialogRef.afterClosed());
    return result;
  }
}

