import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  PatientsResultTrendsComponent, BaseComponent, QueryService, SearchService,
  TitleService, FilterService, ReportKeys, PatientsResultTrendsDetailComponent, AnalyteDetail, MetadataService, InitDataSelectionLists, TestType,
  TestResult_PatientResultTrendRow, DateGroupType
} from '../../core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'app-report-patient-result-trends',
  templateUrl: './report-patient-result-trends.component.html',
  styleUrls: ['./report-patient-result-trends.component.scss'],
  standalone: false
})
export class ReportPatientResultTrendsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) private patientsResultTrends: PatientsResultTrendsComponent;
  @ViewChild('secondarychart', { static: true }) private patientsResultTrendsDetail: PatientsResultTrendsDetailComponent;

  public selectedValue: DateGroupType;
  public DateGroupType = DateGroupType;
  public analtyes: AnalyteDetail[];
  public testTypes: TestType[];
  public selectedTest: string;
  public testNames: string[];
  public results: TestResult_PatientResultTrendRow[];

  constructor(private queryService: QueryService,
    private searchService: SearchService,
    private titleService: TitleService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private metadataService: MetadataService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private translate: TranslateService) {
    super();

    this.selectedValue = DateGroupType.Month;

  }


  ngOnInit(): void {

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.loadInitData();

    this.titleService.updateTitleTranslateKey('ReportMenu.PatientResultTrends');
  }

  public loadInitData(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.analtyes = initData.Analytes;
      this.testTypes = initData.TestTypes;

      this.onSearch();
    }));
  }

  onChange(changeValue: MatRadioChange): void {
    this.selectedValue = changeValue.value as DateGroupType;
    this.onSearch();
  }

  onTestSelected(changeValue: MatSelectChange): void {
    this.selectedTest = changeValue.value as string;
    this.showSecondaryChart();
  }

  showSecondaryChart(): void {
    if (this.selectedTest && this.selectedTest !== '') {
      const filteredResults = this.results.filter(r => r.testName === this.selectedTest);
      this.patientsResultTrendsDetail.setData(filteredResults);
    }
  }

  onSearch(): void {
    if (this.filterService.isReportEnabled(ReportKeys.PatientResultTrends)) {
      this.googleAnalyticsService.logWithLabels('View Report', 'Report', 'View Patient Result Trends Report', 'filters', this.queryService.getCurrentQuery(true), 'groupType', this.googleAnalyticsService.getDateGroupType(this.selectedValue));
      this.subscription.add(this.searchService.getReportPatientResultTrends(
        {
          query: this.queryService.getCurrentQuery(true),
          dateGroupType: this.selectedValue
        })
        .subscribe(
          (result: TestResult_PatientResultTrendRow[]) => {
            if (!result || result.length === 0) {
              const title = translate(this.translate, 'ReportMenu.NoDataToShow');
              this.toastr.info(undefined, title);
              return;
            }

            this.testNames = [];

            result.forEach(row => {
              if (this.testNames.indexOf(row.testName) < 0) {
                this.testNames.push(row.testName);
              }
            });

            this.testNames.sort((a, b) => {
              const x = a.toLowerCase();
              const y = b.toLowerCase();

              if (x < y) { return -1; }
              if (y < x) { return 1; }
              return 0;
            });

            this.results = result;

            this.patientsResultTrends.setData(result);
          }));
    } else {
      const message = translate(this.translate, 'ReportMenu.RequirePatient');
      const title = translate(this.translate, 'ReportMenu.ReportLoadError');
      this.toastr.error(message, title);
    }
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  savePDF(): void {
    this.patientsResultTrends.saveAsPdf();
  }

  saveImage(): void {
    this.patientsResultTrends.saveAsImage();
  }

}
