import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestResult_PatientsByFacilityResultRow } from '../../../services/search.service.testresult.models';
import { BusyService } from '../../../services/busy.service';

@Component({
  selector: 'core-patients-facility-result',
  templateUrl: './patients-facility-result.component.html',
  styleUrls: ['./patients-facility-result.component.scss'],
  standalone: false
})
export class PatientsFacilityResultComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_PatientsByFacilityResultRow[];

  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: Record<string, unknown>;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<string>;

  constructor(changeDetectorRef: ChangeDetectorRef, translateService: TranslateService, elementRef: ElementRef<HTMLElement>,
    busyService: BusyService) {
    super(changeDetectorRef, translateService, 'PatientsByFacilityResult', busyService, elementRef);
    this.showChart = false;
  }

  ngOnInit(): void {

    this.chartType = 'ColumnChart';
    this.chartOptions = {
      legend: 'right',
      bar: { groupWidth: '75%' },
      isStacked: true,
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1,
        textStyle: {
          fontSize: 10
        }
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 250, top: 40, bottom: 200 }
    };
    this.chartData = [[]];

  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_PatientsByFacilityResultRow[]): void {
    this.beginSetData();
    const columnNames = ['FacilityName'];
    const facilityNames = [];
    const convData: (string | number)[][] = [];
    const series = [];

    data.forEach((row) => {
      let facilityNameNdx = facilityNames.indexOf(row.facilityName);
      if (facilityNameNdx < 0) {
        facilityNames.push(row.facilityName);
        facilityNameNdx = facilityNames.length - 1;
        convData.push([row.facilityName]);
      }

      let columnNameNdx = columnNames.indexOf(row.resultName);
      if (columnNameNdx < 0) {
        columnNames.push(row.resultName);
        columnNameNdx = columnNames.length - 1;

        let columnColor = '';
        switch (row.resultName) {
          case 'Invalid':
            columnColor = '#98999b';
            break;
          case 'Positive':
            columnColor = 'red';
            break;
          case 'Negative':
            columnColor = 'green';
            break;
        }

        if (columnColor.length > 0) {
          series.push({
            color: columnColor
          });
        }
      }

      const dataRow = convData[facilityNameNdx];
      while (dataRow.length < columnNameNdx + 1) {
        dataRow.push(0);
      }
      dataRow[columnNameNdx] = dataRow[columnNameNdx] as number + row.resultCount;
      convData[facilityNameNdx] = dataRow;
    });

    // Fill in holes
    convData.forEach((dataRow) => {
      while (dataRow.length < columnNames.length) {
        dataRow.push(0);
      }
    });

    this.chartColumnNames = columnNames;

    this.chartData = convData;

    this.chartOptions.series = series;

    this.showChart = true;
  }
}
