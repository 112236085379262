<div class="nav-container">
  <div class="nav-links">
    <a routerLink="{{routes.Summary}}" routerLinkActive="selected"><i
        matTooltip="{{'NavBar.SummaryTooltip' | translate}}" class="material-icons">speed</i></a>
    <a routerLink="{{routes.Results}}" routerLinkActive="selected"><i
        matTooltip="{{'NavBar.ResultsTooltip' | translate}}" class="material-icons">table_chart</i></a>
    <a routerLink="{{routes.Mapping}}" routerLinkActive="selected"><i
        matTooltip="{{'NavBar.MappingTooltip' | translate}}" class="material-icons">language</i></a>
  </div>
  <div class="filters" [class.filters-applied]="hasActiveFilters">
    <i matRipple [matRippleDisabled]="true" [matRippleUnbounded]="true" class="material-icons"
      matTooltip="{{'NavBar.SearchTooltip' | translate}}" [class.highlight-search]="filtersChanged"
      (click)="onSearch()">search</i>
    <i class="material-icons flipped" matTooltip="{{'NavBar.ResetFiltersTooltip' | translate}}"
      (click)="resetFilters()">refresh</i>

    <div class="breadcrumb">
      <span class="breadcrumb-title" *ngIf="!hasActiveFilters">{{'NavBar.Title' | translate}}</span>
      <div class="filter-crumb-container">
        <span matTooltip="{{dateTooltip}}">{{dateRange}}
          <span *ngIf="dateTooltip && dateTooltip.length > 0"
            class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
        </span>
        <span [class.hidden]="!assays || assays?.length === 0">/</span>
        <span [class.hidden]="!assays || assays?.length === 0"
          matTooltip="{{assays && assays.length > 1 ? assays.join(', ') : ''}}">{{assays ? assays.length > 1 ?
          '[Multiple Assays]' : assays[0] : '[Assay All]'}}
          <span *ngIf="assays && assays.length > 1"
            class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">{{assays?.length}}</span>
        </span>
        <span [class.hidden]="!results || results?.length === 0">/</span>
        <span [class.hidden]="!results || results?.length === 0"
          matTooltip="{{results && results.length > 1 ? results.join(', ') : ''}}">{{results ? results.length > 1 ?
          '[Multiple Results]' : results[0] : '[Results All]'}}
          <span *ngIf="results && results.length > 1"
            class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">{{results?.length}}</span>
        </span>
        <span [class.hidden]="!organizations || organizations.length === 0">/</span>
        <span [class.hidden]="!organizations || organizations.length === 0"
          matTooltip="{{organizationTooltip}}">{{organizations && organizations.length === 1 ?
          truncate(organizations[0], 20) : '[Multiple Organizations]'}}
          <span
            *ngIf="filters?.orgsFacilities && (filters?.orgsFacilities?.length > 1 || (filters?.orgsFacilities?.length === 1 && filters?.orgsFacilities[0].facilityIds?.length > 0))"
            class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">{{filters?.orgsFacilities?.length}}</span>
        </span>
        <span [class.hidden]="!filters?.geo">/</span>
        <span [class.hidden]="!filters?.geo" matTooltip="{{locationTooltip}}">
          {{filters?.geo ? location : '[Location All]'}}
          <span *ngIf="filters?.geo && totalGeoSubItems > 0"
            class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">{{totalGeoSubItems}}</span>
        </span>
        <span [class.hidden]="!filters?.operator">/</span>
        <span [class.hidden]="!filters?.operator" matTooltip="{{filters?.operator ? 'Operator' : ''}}">
          {{filters?.operator ? filters?.operator : '[Operator All]'}}</span>
        <span [class.hidden]="!filters?.zipCode">/</span>
        <span [class.hidden]="!filters?.zipCode" matTooltip="{{filters?.zipCode ? 'Zip Code' : ''}}">{{filters?.zipCode
          ? filters?.zipCode : '[Zip Code All]'}}</span>
        <span [class.hidden]="!filters?.serialNumber">/</span>
        <span [class.hidden]="!filters?.serialNumber"
          matTooltip="{{filters?.serialNumber ? 'Serial Number' : ''}}">{{filters?.serialNumber ? filters?.serialNumber
          : '[Serial All]'}}</span>
      </div>
    </div>

    <div class="right-side">
      <span class="results" [class.hidden]="resultsCount === 0 || !resultsCount">{{resultsCount?.toLocaleString()}}
        results</span>
      <mat-icon matTooltip="{{'NavBar.ReportListTooltip' | translate}}"
        [matMenuTriggerFor]="reportMenu">insert_chart_outlined</mat-icon>
      <mat-menu #reportMenu>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByRunDateEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByRunDate))"
            disabled="{{!isPatientsByRunDateEnabled}}">
            <span class="report-menu-item">
              {{'ReportMenu.PatientsByRunDate' | translate}}
              <span *ngIf="!isPatientsByRunDateEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByAssayEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByAssay))"
            disabled="{{!isPatientsByAssayEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientsByAssay' | translate}}
              <span *ngIf="!isPatientsByAssayEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientOrg' | translate}}"
          [matTooltipDisabled]='isPatientsByFacilityAssayEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByFacilityAssay))"
            disabled="{{!isPatientsByFacilityAssayEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientsByFacilityAssay' | translate}}
              <span *ngIf="!isPatientsByFacilityAssayEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientOrgAssay' | translate}}"
          [matTooltipDisabled]='isPatientsByFacilityResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByFacilityResult))"
            disabled="{{!isPatientsByFacilityResultEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientsByFacilityResult' | translate}}
              <span *ngIf="!isPatientsByFacilityResultEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByResult))"
            disabled="{{!isPatientsByResultEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientsByResult' | translate}}
              <span *ngIf="!isPatientsByResultEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}"
          [matTooltipDisabled]='isPatientResultTrendsEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientResultTrends))"
            disabled="{{!isPatientResultTrendsEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientResultTrends' | translate}}
              <span *ngIf="!isPatientResultTrendsEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientAssay' | translate}}"
          [matTooltipDisabled]='isPatientsCoInfectionsEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientCoInfections))"
            disabled="{{!isPatientsCoInfectionsEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PatientsCoInfections' | translate}}
              <span *ngIf="!isPatientsCoInfectionsEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}"
          [matTooltipDisabled]='isPercentPositiveResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PercentPositiveResults))"
            disabled="{{!isPercentPositiveResultEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.PercentPositiveResults' | translate}}
              <span *ngIf="!isPercentPositiveResultEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequireQCOrg' | translate}}" [matTooltipDisabled]='isQualityControlReportEnabled'
          *ngIf="isQualityControlReportVisible">
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.QualityControlReport))"
            disabled="{{!isQualityControlReportEnabled}}">
            <span class="report-menu-item">{{'ReportMenu.QualityControlReport' | translate}}
              <span *ngIf="!isQualityControlReportEnabled"
                class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
            </span>
          </button>
        </div>
        <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.TestVolumeByType))"
          disabled="{{!isTestVolumeByTypeReportEnabled}}">
          <span class="report-menu-item"> {{'ReportMenu.TestVolumeByType' | translate}}
            <span *ngIf="!isTestVolumeByTypeReportEnabled"
              class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
          </span>
        </button>
      </mat-menu>

      <mat-icon class="export-pdf" color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
        (click)="exportFileDialog(ExportFileType.PDF)">assignment</mat-icon>
      <mat-icon color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
        matTooltip="{{'NavBar.ExportCSVTooltip' | translate}}"
        (click)="exportFileDialog(ExportFileType.CSV)">grid_on</mat-icon>
    </div>
  </div>
</div>
