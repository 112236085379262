import { Component, OnInit, OnDestroy, ViewContainerRef, QueryList, ViewChildren } from '@angular/core';
import {
  InstrumentResultOrphanSummary, MetadataService, BaseComponent,
  SearchResultsPage, SortDirection, TitleService, OrphansNavigationKeys, TranslateNotificationKeys
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExportFileModalComponent } from './export-file-modal/export-file-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-orphans-search',
  templateUrl: './orphans-search.component.html',
  styleUrls: ['./orphans-search.component.scss'],
  standalone: false
})
export class OrphansSearchComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramSearchText = 'searchText';
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'OrphanSearch';
  value: string;
  rows = [];
  orphans: InstrumentResultOrphanSummary[];
  orphanResults: SearchResultsPage<InstrumentResultOrphanSummary>;
  sortColumn = 'serialNumber';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;

  displayColumns = ['serialNumber', 'deviceAddress', 'zipCode', 'facilityZipCode', 'organizationName', 'resultCount'];
  pageSize = 20;
  pageNumber = 0;
  sortColumns: Record<string, string> = {
    serialnumber: 'InstrumentSerialNumber',
    deviceaddress: 'OriginatingDeviceAddress',
    zipcode: 'ZipCode',
    resultcount: 'ResultCount'
  };

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(private router: Router, private route: ActivatedRoute, private metadataService: MetadataService,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService,
    private dialog: MatDialog) {
    super();
    this.value = '';
  }

  doSearch(): void {
    this.pageNumber = 0;
    this.updateData();
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      searchText: this.value,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    this.subscription.add(
      this.metadataService.searchInstrumentResultOrphans(this.value,
        this.sortColumns[this.sortColumn.toLowerCase()],
        this.sortDirection,
        this.pageSize,
        +this.pageNumber + 1)
        .subscribe({
          next: (orphanResults: SearchResultsPage<InstrumentResultOrphanSummary>) => {
            this.orphanResults = orphanResults;

            if (doScroll) {
              if (isPageUp) {
                this.scrollToTop();
              } else {
                this.scrollToBottom();
              }
            }
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchFailure}`));
          }
        }));

  }
  createNew(): void {
    void this.router.navigate([OrphansNavigationKeys.EditFullPath, 0]);
  }

  onRowSelect(orphan: InstrumentResultOrphanSummary): void {
    void this.router.navigate([
      OrphansNavigationKeys.EditFullPathWithId,
      {
        serialnumber: orphan.instrumentSerialNumber,
        deviceaddress: orphan.originatingDeviceAddress,
        zipcode: orphan.zipCode
      }
    ]);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {

      this.value = params[this.paramSearchText] as string || '';
      this.pageNumber = params[this.paramPageNumber] as number || this.pageNumber;
      this.pageSize = params[this.paramPageSize] as number || this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] as SortDirection || this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';

      if (this.pageNumber && this.pageSize && this.sortColumn && this.sortDirection) {
        this.updateData();
      }
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  exportFileDialog(): void {
    const modalWidth = 0.27 * window.outerWidth;
    this.dialog.open(ExportFileModalComponent, {
      width: `${modalWidth}px`,
      data: { searchText: this.value, resultCount: this.orphanResults?.totalResults }
    });
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }
}
