import { TranslateService } from '@ngx-translate/core';

export const getActiveLanguage = (translateService: TranslateService): string => {
  return translateService.currentLang ? translateService.currentLang : translateService.defaultLang;
};

export const isTranslationLoaded = (translateService: TranslateService): boolean => {
  const langToCheck = translateService.currentLang ? translateService.currentLang : translateService.defaultLang;
   
  const translation = translateService.store.translations[langToCheck] as unknown;
  if (translation) {
    return true;
  } else {
    return false;
  }
};

/**
   * Gets translation text for the current language
   * @param {TranslateService} translateService The translate service used to get the translation
   * @param {string} key The translation key for retrieving the text
   * @param {{ [key: string]: string }} [interpolationParamsDefinition] Object (string, string) defining the key, value pair required for TranslateService interpolation, where the value also needs to be translated first.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated text
   * @returns {string} Translated text
   */
export const translate = (translateService: TranslateService, key: string, interpolationParamsDefinition?: { [key: string]: string }): string => {
  const interpolationParams = buildInterpolationParams(translateService, interpolationParamsDefinition);
  const translatedText = translateWithInterpolateParams(translateService, key, interpolationParams);
  return translatedText;
};

/**
   * Gets translation text for the current language. Used instead of TranslateService.Instant in order to not have to use `as string` every time
   * @param {TranslateService} translateService The translate service used to get the translation
   * @param {string} key The translation key for retrieving the text
   * @param {{ [key: string]: string }} [interpolationParams] Object (string, string) defining the key, value pair required for TranslateService interpolation, where the value should NOT be translated.
   * @returns {string} Translated text
   */
export const translateWithInterpolateParams = (translateService: TranslateService, key: string, interpolationParams?: object): string => {
  const translatedText = translateService.instant(key, interpolationParams) as string;
  return translatedText;
};

/**
   * Build interpolation params for use by the TranslateService from an interpolation params definition
   * @param {TranslateService} translateService The translate service used to get the translation for each interpolation value
   * @param {{ [key: string]: string }} [interpolationParamsDefinition] Object (string, string) defining the key, value pair required for TranslateService interpolation, where the value also needs to be translated first.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated text
   * @returns {{ [key: string]: string }} Interpolation Params for use by TranslateService
   */
export const buildInterpolationParams = (translateService: TranslateService, interpolationParamsDefinition?: { [key: string]: string }): { [key: string]: string } => {
  if (!interpolationParamsDefinition) {
    return {};
  }

  const interpolationParams = {};
  const interpolationParamsKeys = Object.keys(interpolationParamsDefinition);
  interpolationParamsKeys.forEach(key => {
    interpolationParams[key] = translateService.instant(interpolationParamsDefinition[key]) as string;
  });

  return interpolationParams;
};
