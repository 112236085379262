import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestType, InitDataSelectionLists, AnalyteDetail } from '../../../services/metadata.service.models';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from '../../../services/metadata.service';
import { TestResult_PatientResultTrendRow } from '../../../services/search.service.testresult.models';
import { BusyService } from '../../../services/busy.service';

@Component({
  selector: 'core-patients-result-trends',
  templateUrl: './patients-result-trends.component.html',
  styleUrls: ['./patients-result-trends.component.scss'],
  standalone: false
})
export class PatientsResultTrendsComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_PatientResultTrendRow[];
  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: Record<string, unknown>;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<string>;
  testTypes: TestType[];
  analyteDetails: AnalyteDetail[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    translateService: TranslateService,
    elementRef: ElementRef<HTMLElement>,
    busyService: BusyService,
    private metadataService: MetadataService) {
    super(changeDetectorRef, translateService, 'PatientsResultTrends', busyService, elementRef);
    this.showChart = false;
    this.offsetHeight = 125;
  }

  ngOnInit(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.testTypes = initData.TestTypes;
      this.analyteDetails = initData.Analytes;

      if (this.data) {
        this.setData(this.data);
      }
    }));

    this.chartType = 'LineChart';
    this.chartOptions = {
      legend: 'right',
      bar: { groupWidth: '75%' },
      isStacked: true,
      interpolateNulls: true,
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 250, top: 40, bottom: 200 }
    };
    this.chartData = [[]];
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_PatientResultTrendRow[]): void {
    this.beginSetData();
    this.data = data;

    if (!this.analyteDetails) {
      return;
    }

    const columnNames = ['DataGroup'];
    const dateGroups = [];
    const convData: (string | number)[][] = [];
    const series = [];

    data.forEach((row) => {
      let dateGroupNdx = dateGroups.indexOf(row.dateGroup);
      if (dateGroupNdx < 0) {
        dateGroups.push(row.dateGroup);
        dateGroupNdx = dateGroups.length - 1;
        convData.push([row.dateGroup]);
      }

      if (row.analytes && row.analytes.length > 0) {

        const test = this.testTypes.find(t => t.testName === row.testName);

        row.analytes.forEach((analyte) => {
          const analyteName = analyte.analyteName + ' +';

          let columnNameNdx = columnNames.indexOf(analyteName);
          if (columnNameNdx < 0) {
            columnNames.push(analyteName);
            columnNameNdx = columnNames.length - 1;

            const analyteDetail = test ? this.analyteDetails.find(a => a.name === analyte.analyteName && a.testType_TestTypeId === test.testTypeId) : null;

            series.push({
              color: analyteDetail && analyteDetail.positiveColor ? analyteDetail.positiveColor : '#98999b',
              pointSize: 15,
              pointShape: {
                type: analyteDetail && analyteDetail.symbol ? analyteDetail.symbol : '',
                rotation: analyteDetail && analyteDetail.symbolRotation ? analyteDetail.symbolRotation : 0
              }
            });

          }

          const dataRow = convData[dateGroupNdx];
          while (dataRow.length < columnNameNdx - 1) {
            dataRow.push(0);
          }
          dataRow[columnNameNdx] = analyte.positiveCount ? analyte.positiveCount : 0;
          convData[dateGroupNdx] = dataRow;

        });

      }
    });

    // Fill in holes
    convData.forEach((dataRow) => {
      dataRow.forEach((item, i) => {
        if (item == null) {
          dataRow[i] = 0;
        }
      });

      while (dataRow.length < columnNames.length) {
        dataRow.push(0);
      }
    });

    this.chartColumnNames = columnNames;
    this.chartData = convData;
    this.chartOptions.series = series;

    this.showChart = true;
  }
}
