import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  TitleService, BaseComponent, MetadataService,
  PatientSampleTypesResponse, Features, AssaysNavigationKeys, TranslateNotificationKeys
} from '../../../core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-assays-list',
  templateUrl: './assays-list.component.html',
  styleUrls: ['./assays-list.component.scss'],
  standalone: false
})
export class AssaysListComponent extends BaseComponent implements OnInit, OnDestroy {
  public translationBaseKey = 'Assays';

  displayColumns = ['assaysname', 'loincCode', 'assayType'];

  dataSource: PatientSampleTypesResponse[];
  currentDataSource: PatientSampleTypesResponse[];
  pageNumber = 0;
  pageSize = 20;
  enableAdd: boolean;
  value: string;

  constructor(
    private titleService: TitleService,
    private metadataService: MetadataService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);

    this.loadData();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  doSearch(): void {
    // TODO: Implement if search support is needed
  }

  public loadData(): void {
    this.subscription.add(this.metadataService.getPatientSampleTypes().subscribe({
      next: (data) => {

        this.dataSource = data;
        this.currentDataSource = this.dataSource.slice(0, this.pageSize);

        console.debug(this.dataSource);

        this.checkWriteFlag();
      },
      error: () => {
        this.toastr.error(translate(this.translate, `${this.translationBaseKey}.LoadFailure`),
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));
  }

  doPage(pageEvent: PageEvent): void {

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    console.debug(`pageSize:${this.pageSize} pageNumber:${this.pageNumber}`);

    this.currentDataSource = this.dataSource.slice(this.pageNumber * this.pageSize, (this.pageNumber + 1) * this.pageSize);
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      this.enableAdd = features.WriteEnabled;
    }));
  }

  createNew(): void {
    void this.router.navigate([AssaysNavigationKeys.EditFullPath, 0]);
  }

  onRowSelect(assay: PatientSampleTypesResponse): void {
    void this.router.navigate([AssaysNavigationKeys.EditFullPath, assay.testTypeId], { state: { data: assay } });
  }
}
