<div *ngIf="isOpened" class="main-container">

  <div class="container">
    <div class="header">
      <span>{{'FilterCustomDate.Title' | translate}}</span>
      <i class="material-icons clear flipped" (click)="clearFilter()">refresh</i>
    </div>

    <div class="inner-scrollbar-container">
      <ng-container *ngFor="let i of query.items">

        <div *ngIf="!i.isHidden" class="submenu-item filter-option">
          <div (click)="selectItem(i)" class="submenu-item-container">
            <div class="label">
              {{ i.getLabel() | translate }}
            </div>
            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </div>

  <div class="run-date-container" *ngIf="fixedDates && fixedDates.isSelected">

    <form [formGroup]="datesFormGroup">
      <div class="submenu-item ie-flex">
        <mat-form-field class="ie-flex" floatLabel="always">
          <mat-label>{{'FilterCustomDate.StartDate' | translate}}</mat-label>
          <input (dateInput)="startDateChanged($event)" matInput [matDatepicker]="startDate" formControlName="dateFrom"
            id="dateFrom" placeholder="{{'FilterCustomDate.StartDate' | translate}}" noCustomValidation="true">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <div class="error" *ngIf="hasErrorDates('dateFrom', 'required')">
            {{'FilterCustomDate.ValidDateRequired' | translate}}</div>
          <div class="error"
            *ngIf="!hasErrorDates('dateFrom', 'required') && hasErrorDates('dateFrom', 'validateDates')">
            {{'FilterCustomDate.StartDateValidation' | translate}}</div>
        </mat-form-field>
        <!-- <i class="material-icons ie-only ie-margin-top-18">date_range</i> -->
      </div>

      <div class="submenu-item ie-flex">
        <mat-form-field class="ie-flex" floatLabel="always">
          <mat-label>{{'FilterCustomDate.EndDate' | translate}}</mat-label>
          <input (dateInput)="endDateChanged($event)" matInput [matDatepicker]="endDate" formControlName="dateTo"
            id="dateTo" placeholder="{{'FilterCustomDate.EndDate' | translate}}" noCustomValidation="true">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <div class="error" *ngIf="hasErrorDates('dateTo', 'required')">
            {{'FilterCustomDate.ValidDateRequired' | translate}}</div>
          <div class="error" *ngIf="!hasErrorDates('dateTo', 'required') && hasErrorDates('dateTo', 'validateDates')">
            {{'FilterCustomDate.EndDateValidation' | translate}}</div>
        </mat-form-field>
        <!-- <i class="material-icons ie-only ie-margin-top-18">date_range</i> -->
      </div>
    </form>

  </div>

  <div class="fix-result-container" *ngIf="lastDays && lastDays.isSelected">

    <div class="submenu-item">
      <form [formGroup]="unitsFormGroup">
        <mat-form-field floatLabel="always">
          <mat-label>{{'FilterCustomDate.ResultsForLast' | translate}}</mat-label>
          <input formControlName="units" id="units" (focusout)="fixedDaysChanged($event)"
            [(value)]="lastDays.item.units" matInput noCustomValidation="true"
            placeholder="{{'FilterCustomDate.ResultsForLast' | translate}}">
          <div class="error" *ngIf="hasErrorUnits('required')">
            {{'FilterCustomDate.UnitsRequiredValueValidation' | translate}}</div>
          <div class="error" *ngIf="hasErrorUnits('min')">
            {{'FilterCustomDate.UnitsMinimumValueValidation' | translate}}</div>
          <div class="error" *ngIf="hasErrorUnits('pattern')">{{'FilterCustomDate.UnitsNumberValidation' | translate}}
          </div>
        </mat-form-field>
      </form>
    </div>

    <div class="submenu-item ie-flex">
      <mat-form-field class="ie-full-width">
        <mat-select [(value)]="lastDays.item.timeRangeDescription" (focusout)="timeRangeChanged()">
          <mat-option *ngFor="let range of query.timeRangeMap | keyvalue" [value]="range.value">
            {{range.value | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <i class="material-icons ie-only ie-margin-top-18 ie-zero-width">keyboard_arrow_down</i> -->
    </div>

  </div>

</div>
