import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgSearchComponent } from './org-search/org-search.component';
import { OrgEditComponent } from './org-edit/org-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuidelCoreModule, AngularMaterialModule } from '../../core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
  declarations: [OrgSearchComponent, OrgEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
    ScrollingModule
  ],
  exports: [
    OrgSearchComponent,
    OrgEditComponent
  ]
})
export class OrganizationModule { }
