<div class="report-action-container">
  <div class="left-side">
    Select result type:
    <mat-radio-group aria-label="Select an option" (change)="onChange($event)">
      <mat-radio-button value="{{ResultGroupType.Assay}}" checked>Assay</mat-radio-button>
      <mat-radio-button value="{{ResultGroupType.Analyte}}">Analyte</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="right-side">
    <button mat-flat-button [matMenuTriggerFor]="saveAsMenu">
      <mat-icon class="icon-button-override">save</mat-icon>
      Save As
    </button>
    <mat-menu #saveAsMenu>
      <button mat-menu-item (click)="saveImage()">
        <span>Image</span>
      </button>
      <button mat-menu-item (click)="savePDF()">
        <span>PDF</span>
      </button>
    </mat-menu>
  </div>
</div>
<core-patients-result #chart></core-patients-result>
