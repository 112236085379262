import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterElement } from '../FilterElement';
import { UIFilterBase } from '../UIFilterBase';
import { FilterService } from '../../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { GeoUIQuery, CountryItem, StateItem, CountyItem } from './filter-custom-geo.model';

@Component({
  selector: 'core-filter-custom-geo',
  templateUrl: './filter-custom-geo.component.html',
  styleUrls: ['./filter-custom-geo.component.scss'],
  standalone: false
})
export class FilterCustomGeoComponent extends UIFilterBase<GeoUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomGeoKey = 'FilterCustomGeo';

  private timerId: NodeJS.Timeout;
  private delayTresholdForNewCountiesLoadingMiliseconds = 300;
  private delayedCountiesLoadForCountry: FilterElement<CountryItem>;

  countries: FilterElement<CountryItem>[];
  selectedCountry: FilterElement<CountryItem>;

  availableStates: FilterElement<StateItem>[];
  selectedStates: FilterElement<StateItem>[];

  availableCounties: Map<string, FilterElement<CountyItem>[]>;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomGeoComponent.FilterCustomGeoKey);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  protected initialize(): void {
    const query = this.filterService.getGeoQuery();

    this.initializeQuery(query);

    this.subscription.add(this.query.rootItemsLoaded$.subscribe(orgs => {
      this.setCountries();
    }));
    this.setCountries();

    void this.updateHeaders();
  }

  protected queryReset(): void {
    void this.updateHeaders();
  }

  async selectCountry(country: FilterElement<CountryItem>): Promise<void> {
    const previousCountry = this.query.getSelectedCountry();
    if (previousCountry) {
      previousCountry.toggleSelected();
      void this.deselectStates(previousCountry);

      if (previousCountry.item.getId() === country.item.getId()) {
        await this.updateStatesVisibilty(previousCountry);
        await this.updateHeaders();
        this.filterService.updateGeoQuery(this.query);
        return;
      }
    }

    country.toggleSelected();
    await this.updateStatesVisibilty(country);

    const currentCountry = this.query.getSelectedCountry();
    if (previousCountry && currentCountry) {
      await this.updateStatesVisibilty(previousCountry);
    }

    await this.refreshAfterSelection();
  }

  selectState(state: FilterElement<StateItem>): void {
    state.toggleSelected();
    if (!state.isSelected) {
      this.deselectCounties(state);
    }

    this.updateCachedCountiesVisibility(state);

    this.setupDelayedCountiesLoad();
  }

  private async delayedCountiesLoad(country: FilterElement<CountryItem>) {
    if ((this.delayedCountiesLoadForCountry && country && this.delayedCountiesLoadForCountry.item.getId() !== country.item.getId())
      || !country) {
      if (!country) {
        console.debug('no current country selected');
      } else {
        console.debug('different country');
      }
      console.debug('cancelled delayed counties loading');
      return;
    }

    const selectedStates = await this.query.getSelectedStates();
    if (selectedStates) {
      await this.updateCountiesVisibility(selectedStates);
    }
    await this.refreshAfterSelection();

    console.debug('timer executed');
  }

  private stopDelayedCountiesLoad() {
    clearTimeout(this.timerId);
    this.timerId = undefined;
    console.debug('timer cleared');
  }

  private setupDelayedCountiesLoad() {
    this.delayedCountiesLoadForCountry = this.selectedCountry;
    if (this.timerId) {
      this.stopDelayedCountiesLoad();
    }

    console.debug('starting new timer ...');
    this.timerId = setTimeout(() => {
      void this.delayedCountiesLoad(this.selectedCountry);
    }, this.delayTresholdForNewCountiesLoadingMiliseconds);
  }

  selectCounty(county: FilterElement<CountyItem>): void {
    county.toggleSelected();

    this.filterService.updateGeoQuery(this.query);
  }

  async updateCountiesVisibility(states: FilterElement<StateItem>[]): Promise<void> {
    const statesCounties = await this.query.getCounties(states);
    if (statesCounties) {
      statesCounties.forEach(f => {
        f.isHidden = !f.isSelected;
      });
    }
  }


  updateCachedCountiesVisibility(state: FilterElement<StateItem>): void {
    const stateCounties = this.query.getCachedCounties(state);
    if (stateCounties) {
      stateCounties.forEach(f => {
        f.isHidden = !state.isSelected;
      });
    }
  }

  async updateStatesVisibilty(country: FilterElement<CountryItem>): Promise<void> {
    const countryStates = await this.query.getStates(country);
    if (countryStates) {
      countryStates.forEach(f => {
        f.isHidden = !country.isSelected;
      });

      void this.updateCountiesVisibility(countryStates);
    }
  }

  private deselectCounties(state: FilterElement<StateItem>) {
    const stateCounties = this.query.getCachedCounties(state);
    if (stateCounties) {
      stateCounties.forEach(f => {
        f.isSelected = false;
      });
    }
  }

  private async deselectStates(country: FilterElement<CountryItem>) {
    const countryStates = await this.query.getStates(country);
    if (countryStates) {
      countryStates.forEach(s => {
        s.isSelected = false;
        this.deselectCounties(s);
      });
    }
  }

  private async refreshAfterSelection() {
    this.filterService.updateGeoQuery(this.query);
    await this.updateHeaders();
  }

  private async updateHeaders() {
    this.selectedCountry = this.query.getSelectedCountry();

    if (this.selectedCountry) {
      this.availableStates = await this.query.getStates(this.selectedCountry);
      this.selectedStates = await this.query.getSelectedStates();
      if (this.selectedStates) {
        const map = new Map<string, FilterElement<CountyItem>[]>();

        const promises: Promise<{ state: string, counties: FilterElement<CountyItem>[] }>[] = [];

        this.selectedStates.sort((s1, s2) => s1.getLabel().localeCompare(s2.getLabel())).forEach(s => {
           
          promises.push(new Promise<{ state: string, counties: FilterElement<CountyItem>[] }>(async (res) => {
            const counties = await this.query.getCounties([s]);
            res({ state: s.getLabel(), counties });
          }));
        });

        const results = await Promise.all(promises);

        results.forEach(r => {
          map.set(r.state, r.counties);
        });

        this.selectedStates.forEach(s => this.updateCachedCountiesVisibility(s));
        this.availableCounties = map;
      } else {
        this.availableCounties = new Map<string, FilterElement<CountyItem>[]>();
      }
    } else {
      this.selectedStates = undefined;
      this.availableStates = undefined;
      this.availableCounties = undefined;
    }
  }

  clearCountryFilter(): void {
    this.query.reset();
    this.filterService.updateGeoQuery(this.query);
    void this.updateHeaders();
  }

  async clearStateFilter(): Promise<void> {
    const country = this.query.getSelectedCountry();

    if (country) {
      await this.deselectStates(country);
      this.filterService.updateGeoQuery(this.query);
      void this.updateHeaders();
    }
  }

  async clearCountyFilter(): Promise<void> {
    const states = await this.query.getSelectedStates();

    if (states) {
      const promises: Promise<void>[] = [];
      states.forEach(s => {
        promises.push(new Promise(res => {
          this.deselectCounties(s);
          res();
        }));
      });

      await Promise.all(promises);
      this.filterService.updateGeoQuery(this.query);
    }
  }

  private setCountries() {
    if (!this.query.rootItems) {
      return;
    }

    this.countries = this.query.rootItems;
  }
}
