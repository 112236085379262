import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './utils/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, HttpHandler, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { providePrimeNG } from 'primeng/config';
import Nora from '@primeng/themes/nora';

import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { MapComponent } from './components/map/map.component';
import { LegendComponent } from './components/legend/legend.component';

import { FilterCoreComponent } from './components/filters/filter-core.component';
import { FilterCustomAssaysComponent } from './components/filters/filter-custom-assays/filter-custom-assays.component';
import { FilterCustomResultTypesComponent } from './components/filters/result-types-custom-filter/result-types-custom-filter.component';
import { FilterCustomOrgsFacilitiesComponent } from './components/filters/filter-custom-orgs-facilities/filter-custom-orgs-facilities.component';
import { FilterCustomGeoComponent } from './components/filters/filter-custom-geo/filter-custom-geo.component';
import { FilterCustomDateComponent } from './components/filters/filter-custom-date/filter-custom-date.component';
import { FilterCustomOperatorComponent } from './components/filters/filter-custom-operator/filter-custom-operator.component';
import { FilterCustomZipcodeComponent } from './components/filters/filter-custom-zipcode/filter-custom-zipcode.component';
import { FilterCustomSerialNumberComponent } from './components/filters/filter-custom-serial-number/filter-custom-serial-number.component';
import { FilterCustomFavoritesComponent } from './components/filters/filter-custom-favorites/filter-custom-favorites.component';
import { VirtualSelectComponent } from './components/virtual-select/virtual-select.component';
import { AboutDialogComponent } from './components/about/about.dialog';

import { FormControlErrorContainerDirective } from './directives/form-errors/form-control-error-container.directive';
import { FormControlErrorComponent } from './directives/form-errors/form-control-error.component';
import { FormControlErrorsDirective } from './directives/form-errors/form-control-errors.directive';
import { FormSubmitDirective } from './directives/form-errors/form-submit.directive';

import { DisplayPhoneNumberPipe } from './pipes/phone.pipe';
import { TruncateNumberPipe } from './pipes/truncate-number.pipe';
import { HttpCachingClient, httpCachingClientCreator } from './utils/http-caching-client';

import { MsalService } from '@azure/msal-angular';
import { AddEditModalComponent } from './components/filters/filter-custom-favorites/modals/add-edit-modal/add-edit-modal/add-edit-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatchedConfirmationModalComponent } from './components/matched-confirmation-modal/matched-confirmation-modal.component';
import { FiltersSummaryComponent } from './components/filters-summary/filters-summary.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';


@NgModule({
  declarations: [
    HeaderBarComponent,
    MapComponent,
    LegendComponent,
    FilterCoreComponent,
    FilterCustomAssaysComponent,
    FilterCustomResultTypesComponent,
    FilterCustomOrgsFacilitiesComponent,
    FilterCustomGeoComponent,
    FilterCustomDateComponent,
    FilterCustomOperatorComponent,
    FilterCustomZipcodeComponent,
    FilterCustomSerialNumberComponent,
    FilterCustomFavoritesComponent,
    DisplayPhoneNumberPipe,
    TruncateNumberPipe,
    FormControlErrorComponent,
    FormControlErrorContainerDirective,
    FormControlErrorsDirective,
    FormSubmitDirective,
    ConfirmationModalComponent,
    MatchedConfirmationModalComponent,
    AddEditModalComponent,
    VirtualSelectComponent,
    AboutDialogComponent,
    FiltersSummaryComponent
  ],
  exports: [
    HeaderBarComponent,
    MapComponent,
    LegendComponent,
    TranslateModule,
    FilterCoreComponent,
    DisplayPhoneNumberPipe,
    TruncateNumberPipe,
    AngularMaterialModule,
    FormControlErrorComponent,
    FormControlErrorContainerDirective,
    FormControlErrorsDirective,
    FormSubmitDirective,
    VirtualSelectComponent,
    DragDropModule
  ],
  imports: [
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    ScrollingModule,
    TranslateModule,
    DragDropModule
  ],
  providers: [
    GoogleAnalyticsService,
    MsalService,
    {
      provide: HttpCachingClient,
      useFactory: httpCachingClientCreator,
      deps: [HttpHandler]
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
         
        preset: Nora,
        options: {
          darkModeSelector: false
        }

      }
    })
  ]
})
export class QuidelCoreModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
