import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, QueryService, SearchService, TitleService, TestResult_QualityControlRow,
  FilterService, ReportKeys, DateConstants
} from '../../core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExportFileType, ExportFileModalComponent } from '../../modals/export-file/export-file-modal/export-file-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'app-report-quality-control',
  templateUrl: './report-quality-control.component.html',
  styleUrls: ['./report-quality-control.component.scss'],
  standalone: false
})
export class ReportQualityControlComponent extends BaseComponent implements OnInit, OnDestroy {

  runDateFormat = DateConstants.YearMonthDayWithoutTimeFormat;
  enableExportButtons = false;
  results: TestResult_QualityControlRow[];
  displayColumns: string[];
  public translationBaseKey = 'QualityControl';

  constructor(private queryService: QueryService,
    private searchService: SearchService,
    private titleService: TitleService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialog: MatDialog) {
    super();
  }


  ngOnInit(): void {

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.onSearch();

    this.titleService.updateTitleTranslateKey('ReportMenu.QualityControlReport');

    this.displayColumns = ['date', 'operator', 'serialNumber', 'instrumentType', 'testName', 'kitLotNumber', 'cassetteNumber', 'controlType', 'result'];
  }

  onSearch(): void {

    if (this.filterService.isReportEnabled(ReportKeys.QualityControlReport)) {
      this.googleAnalyticsService.logWithLabels('View Report', 'Report', 'View Quality Control Report', 'filters', this.queryService.getCurrentQuery(true));
      this.subscription.add(this.searchService.getReportQualityControl(this.queryService.getCurrentQuery(true)).subscribe(
        (result: TestResult_QualityControlRow[]) => {
          if (!result || result.length === 0) {
            const title = translate(this.translate, 'ReportMenu.NoDataToShow');
            this.toastr.info(undefined, title);
            return;
          }

          let facilityName = '';

          result.forEach((row) => {
            if (row.facilityName !== facilityName) {
              row.isGroupBy = true;
              facilityName = row.facilityName;
            }
          });

          this.searchService.searchResultsCompletedSubject.next(result.length);

          this.results = result;
          this.enableExportButtons = true;
        }));
    } else {
      const message = translate(this.translate, 'ReportMenu.RequireQCOrg');
      const title = translate(this.translate, 'ReportMenu.ReportLoadError');
      this.toastr.error(message, title);
      this.enableExportButtons = false;
    }
  }

  isGroup(_index: number, item: TestResult_QualityControlRow): boolean {
    return item.isGroupBy ? item.isGroupBy : false;
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  exportFileDialog(): void {
    if (!this.enableExportButtons) {
      return;
    }

    const modalWidth = 0.27 * window.outerWidth;
    const fileType: ExportFileType = ExportFileType.QC;
    const isTestResult = false;
    const value = '';

    this.dialog.open(ExportFileModalComponent, {
      width: `${modalWidth}px`,
      data: { fileType, value, isTestResult }
    });
  }
}
