<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        {{ translationBaseKey + '.AssignOrphan' | translate }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="orphanForm" [formGroup]="orphanFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.SerialNumber' | translate }}</mat-label>
          <input matInput formControlName="serialNumber" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline" (click)="onPairedDevice()">
          <mat-label>{{ translationBaseKey + '.DeviceAddress' | translate }}</mat-label>
          <input matInput formControlName="deviceAddress" />
        </mat-form-field>
        <div class="div-checkbox" *ngIf="showPairedDeviceOverride">
          <mat-checkbox formControlName="pairedDeviceOverride">
            <mat-label>{{ translationBaseKey + '.CreatePairedDeviceOverride' | translate }}</mat-label>
          </mat-checkbox>
        </div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.ZipCode' | translate }}</mat-label>
          <input matInput formControlName="zipCode" />
        </mat-form-field>
        <div class="example-full-width flex-justify-content-end" appearance="outline">
          <button mat-button (click)="createNewOrganization()">
            <mat-icon class="icon-button-override">add</mat-icon> {{ translationBaseKey + '.AddNewOrganization' |
            translate }}
          </button>
        </div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Organization' | translate }}</mat-label>
          <core-virtual-select formControlName="organizationId" required="true" itemSize="42" [dataSource]="orgs"
            valueField="organizationId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <div class="example-full-width flex-justify-content-end" appearance="outline">
          <button mat-button [disabled]="!orgId" (click)="createNewFacility()">
            <mat-icon class="icon-button-override">add</mat-icon> {{ translationBaseKey + '.AddNewFacility' | translate
            }}
          </button>
        </div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Facility' | translate }}</mat-label>
          <core-virtual-select formControlName="facilityId" required="true" itemSize="42" [dataSource]="facilities"
            valueField="facilityId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <div class="facilityInfo">
          <div>{{facility?.address?.streetAddress1}}</div>
          <div *ngIf="facility?.address?.streetAddress2">{{facility?.address?.streetAddress2}}</div>
          <div *ngIf="facility?.address?.streetAddress3">{{facility?.address?.streetAddress3}}</div>
          <div>{{facility?.address?.city}}, {{facility?.address?.state?.abbreviation}} {{facility?.address?.zipCode}}
          </div>
          <div>{{facility?.address?.county?.name}}</div>
          <div>{{facility?.address?.country?.name}}</div>
        </div>
        <div class="div-checkbox">
          <mat-checkbox formControlName="createZipOverride">
            <mat-label>{{ translationBaseKey + '.CreateZipOverride' | translate }}</mat-label>
          </mat-checkbox>
        </div>
      </form>
      <div class="section">
        <div class="section-cell-large">
          <div>{{ translationBaseKey + '.OrphanReason' | translate }}:</div>
          <div>{{ orphanReason }}</div>
        </div>
      </div>
      <button mat-button [disabled]="orphanFormGroup.invalid || orphanFormGroup.disabled" (click)="onAssign()"
        matTooltip="{{ translationBaseKey + '.AssignOrphanBtn' | translate }}"
        [matTooltipDisabled]="orphanFormGroup.invalid || orphanFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Assign' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelOrphan' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

      <button mat-button (click)="onDelete()" matTooltip="{{ translationBaseKey + '.DeleteOrphan' | translate }}">
        <mat-icon class="icon-button-override">delete</mat-icon>
        {{ translationBaseKey + '.Delete' | translate }}
      </button>

      <button mat-button (click)="onDownloadArchive()"
        matTooltip="{{ translationBaseKey + '.DownloadResults' | translate }}">
        <mat-icon class="icon-button-override">download</mat-icon>
        {{ translationBaseKey + '.Download' | translate }}
      </button>

      <button mat-button (click)="onViewDetails()" matTooltip="{{ translationBaseKey + '.ViewResults' | translate }}">
        <mat-icon class="icon-button-override">view_list</mat-icon>
        {{ translationBaseKey + '.ViewDetails' | translate }}
      </button>

      <div *ngIf="isZipCodeOverrideMatched" class="autoAssignMessage">
        {{ translationBaseKey + '.AutoAssignMessage' | translate }}
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="this.idbRecord">

    <mat-card-header>
      <mat-card-title>
        {{ translationBaseKey + '.IDBInfo' | translate }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <div class="section-header">
        {{ translationBaseKey + '.InstrumentInfo' | translate }}:
      </div>
      <div class="section">
        <div class="section-cell-large">
          <div>{{ translationBaseKey + '.SKU' | translate }}:</div>
          <div>{{ idbRecord?.instrumentItemNumber }}</div>

          <div>{{ translationBaseKey + '.SerialNumber' | translate }}:</div>
          <div>{{ idbRecord?.serialNumber }}</div>

          <div>{{ translationBaseKey + '.InstrumentProductClass' | translate }}:</div>
          <div>{{ idbRecord?.instrumentProductClass }}</div>

          <div>{{ translationBaseKey + '.InstrumentDescription' | translate }}:</div>
          <div>{{ idbRecord?.instrumentDescription }}</div>
        </div>
      </div>

      <div class="section-header">
        {{ translationBaseKey + '.WirelessInfo' | translate }}:
      </div>
      <div class="section">
        <div class="section-cell-large">
          <div>{{ translationBaseKey + '.IMEI' | translate }}:</div>
          <div>{{ idbRecord?.imei }}</div>

          <div>{{ translationBaseKey + '.ICCID' | translate }}:</div>
          <div>{{ idbRecord?.iccId }}</div>

          <div>{{ translationBaseKey + '.DevId' | translate }}:</div>
          <div>{{ idbRecord?.devId }}</div>

          <div>{{ translationBaseKey + '.WirelessMAC' | translate }}:</div>
          <div>{{ idbRecord?.wmId }}</div>

          <div>{{ translationBaseKey + '.EthernetMAC' | translate }}:</div>
          <div>{{ idbRecord?.emId }}</div>
        </div>
      </div>

      <div class="section-header">
        {{ translationBaseKey + '.ShippingInfo' | translate }}:
      </div>
      <div class="section">
        <div class="section-cell-large">
          <div>{{ translationBaseKey + '.ShippingDate' | translate }}:</div>
          <div>{{ idbRecord?.shippingDate }}</div>

          <div>{{ translationBaseKey + '.AddressCode' | translate }}:</div>
          <div>{{ idbRecord?.addressCode }}</div>

          <div>{{ translationBaseKey + '.Salutation' | translate }}:</div>
          <div>{{ idbRecord?.shippingSalutation }}</div>

          <div>{{ translationBaseKey + '.StreetNumber' | translate }}:</div>
          <div>{{ idbRecord?.shippingStreetNumber }}</div>

          <div>{{ translationBaseKey + '.StreetName' | translate }}:</div>
          <div>{{ idbRecord?.shippingStreetName }}</div>

          <div>{{ translationBaseKey + '.Building' | translate }}:</div>
          <div>{{ idbRecord?.shippingBuilding }}</div>

          <div>{{ translationBaseKey + '.Unit' | translate }}:</div>
          <div>{{ idbRecord?.shippingUnit }}</div>

          <div>{{ translationBaseKey + '.City' | translate }}:</div>
          <div>{{ idbRecord?.shippingCity }}</div>

          <div>{{ translationBaseKey + '.State' | translate }}:</div>
          <div>{{ idbRecord?.shippingState }}</div>

          <div>{{ translationBaseKey + '.ZipCode' | translate }}:</div>
          <div>{{ idbRecord?.shippingZipCode }}</div>

          <div>{{ translationBaseKey + '.Country' | translate }}:</div>
          <div>{{ idbRecord?.shippingCountry }}</div>
        </div>
      </div>

      <div class="section-header">
        {{ translationBaseKey + '.SalesOrderInfo' | translate }}:
      </div>
      <div class="section">
        <div class="section-cell-large">
          <div>{{ translationBaseKey + '.SalesOrderNumber' | translate }}:</div>
          <div>{{ idbRecord?.slsOrd }}</div>

          <div>{{ translationBaseKey + '.CrmId' | translate }}:</div>
          <div>
            <div *ngIf="idbRecord?.crmId">
              <a target="_blank"
                href="https://quidel.lightning.force.com/lightning/cmp/c__GetAccountByAccountNumber?c__accNumber={{ idbRecord?.crmId }}">{{
                idbRecord?.crmId }}</a>
              or
              <a target="_blank"
                href="https://quidel.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str={{ idbRecord?.crmId }}">Search</a>
            </div>
          </div>

          <div>{{ translationBaseKey + '.ContractId' | translate }}:</div>
          <div>
            <div *ngIf="idbRecord?.contId">
              <a target="_blank"
                href="https://quidel.lightning.force.com/lightning/cmp/c__GetContractByContractNumber?c__conNumber={{ idbRecord?.contId }}">{{
                idbRecord?.contId }}</a>
              or
              <a target="_blank"
                href="https://quidel.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str={{ idbRecord?.contId }}">Search</a>
            </div>
          </div>

          <div>{{ translationBaseKey + '.SalesPosition' | translate }}:</div>
          <div>{{ idbRecord?.slsPos }}</div>

          <div>{{ translationBaseKey + '.VirenaOptIn' | translate }}:</div>
          <div>{{ idbRecord?.optIn === 1 ? 'True' : 'False' }}</div>

          <div>{{ translationBaseKey + '.VirenaActivationStatus' | translate }}:</div>
          <div>{{ idbRecord?.actSts === 1 ? 'True' : 'False' }}</div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

</div>
