// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { UserSearchComponent } from './user-search/user-search.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserUnapprovedComponent } from './user-unapproved/user-unapproved.component';
import { NavigationGuard, UsersNavigationKeys } from '../../core';

export const routes: Routes = [
  {
    path: UsersNavigationKeys.Search, component: UserSearchComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: UsersNavigationKeys.EditWithIdArg, component: UserEditComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: UsersNavigationKeys.Unapproved, component: UserUnapprovedComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class UsersRoutingModule { }
