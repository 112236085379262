<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        <ng-container *ngIf="!newAssay">
          {{ translationBaseKey + '.EditAssay' | translate }}
        </ng-container>
        <ng-container *ngIf="newAssay">
          {{ translationBaseKey + '.AddAssay' | translate }}
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="assayForm" [formGroup]="assayFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Name' | translate }}</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Type' | translate }}</mat-label>
          <input matInput formControlName="type" required [matAutocomplete]="typeAuto" />
          <mat-autocomplete autoActiveFirstOption #typeAuto="matAutocomplete">
            <mat-option *ngFor="let type of filteredTypes | async" [value]="type.code">
              {{type.code}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Code' | translate }}</mat-label>
          <input matInput formControlName="code" required [matAutocomplete]="codeAuto" />
          <mat-autocomplete autoActiveFirstOption #codeAuto="matAutocomplete">
            <mat-option *ngFor="let code of filteredCodes | async" [value]="code.code">
              {{code.code}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="div-checkbox">
          <mat-checkbox formControlName="export">
            <mat-label>{{ translationBaseKey + '.Export' | translate }}</mat-label>
          </mat-checkbox>
        </div>
      </form>

      <div class="analyte-table">
        <button mat-button class="add-new-button" (click)="addAnalyte()">
          <mat-icon class="icon-button-override">add</mat-icon>{{ translationBaseKey + '.AddAnalyte' | translate }}
        </button>

        <mat-table class="mat-elevation-z8" [dataSource]="analytes" matSort>

          <ng-container matColumnDef="analyteName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationAnalyteTableKey + '.Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let analyte"><input type="text" [(ngModel)]="analyte.analyteName"
                required /></mat-cell>
          </ng-container>

          <ng-container matColumnDef="analytePositive">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationAnalyteTableKey + '.Positive' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let analyte"><input [(ngModel)]="analyte.positiveSnomed" type="text" /></mat-cell>
          </ng-container>

          <ng-container matColumnDef="analyteNegative">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationAnalyteTableKey + '.Negative' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let analyte"><input [(ngModel)]="analyte.negativeSnomed" type="text" /></mat-cell>
          </ng-container>

          <ng-container matColumnDef="analyteInvalid">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationAnalyteTableKey + '.Invalid' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let analyte"><input [(ngModel)]="analyte.invalidSnomed" type="text" /></mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let analyte"><i class="material-icons" (click)="deleteAnalyte(analyte)">delete</i></mat-cell>
                    </ng-container> -->

          <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
          </mat-row>

        </mat-table>
      </div>
      <div id="disclaimer">{{ translationBaseKey + '.CodeDisclaimer' | translate }}</div>

      <p *ngIf="analyteError" class="error">
        {{ (analyteError === AnalyteValidation.InvalidCount ? translationBaseKey + '.InvalidAnalyteCount' :
        translationBaseKey + '.AnalyteInvalid') | translate }}
      </p>
      <p *ngIf="!newAssay" class="warning"><strong>{{ translationBaseKey + '.Warning' | translate }}</strong></p>

      <button mat-button [disabled]="!assayFormGroup.valid" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveAssay' | translate }}" [matTooltipDisabled]="!assayFormGroup.valid">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelAssay' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

    </mat-card-content>
  </mat-card>

</div>
