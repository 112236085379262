import { Injectable } from '@angular/core';
import { RoleAccessService, UserGroup } from '../../../core';

@Injectable({
  providedIn: 'root'
})

export class UserEditAccessService {

  constructor(private roleAccessService: RoleAccessService) { } 

  public isReadOnlyAccess(): boolean {
    return this.roleAccessService.getRole() === UserGroup.SalesAdmin;
  }

  public isOrgAdmin(): boolean {
    return this.roleAccessService.getRole() === UserGroup.OrgAdmin;
  }
}