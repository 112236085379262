import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestResult_TestVolumeByTypeRow } from '../../../services/search.service.testresult.models';
import { SampleType, InitDataSelectionLists } from '../../../services/metadata.service.models';
import { MetadataService } from '../../../services/metadata.service';
import { BusyService } from '../../../services/busy.service';


@Component({
  selector: 'core-test-volume-type',
  templateUrl: './test-volume-type.component.html',
  styleUrls: ['./test-volume-type.component.scss'],
  standalone: false
})
export class TestVolumeTypeComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_TestVolumeByTypeRow[];

  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: object;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<unknown>;
  sampleTypes: SampleType[];

  constructor(changeDetectorRef: ChangeDetectorRef, translateService: TranslateService, elementRef: ElementRef<HTMLElement>,
    busyService: BusyService,
    private metadataService: MetadataService) {
    super(changeDetectorRef, translateService, 'TestVolumeByType', busyService, elementRef);

    this.showChart = false;
  }

  ngOnInit(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.sampleTypes = initData.SampleTypes;

      if (this.data) {
        this.setData(this.data);
      }
    }));

    this.chartType = 'ColumnChart';
    this.chartOptions = {
      legend: { position: 'none' },
      bar: { groupWidth: '75%' },
      isStacked: true,
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1,
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 150, top: 40, bottom: 200 }
    };
    this.chartColumnNames = ['ColumnName', this.getText('TestVolume'), { role: 'style' }];
    this.chartData = [[]];

  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_TestVolumeByTypeRow[]): void {
    this.beginSetData();
    const convData: (string | number)[][] = [];

    data.forEach((row) => {
      const typeCode = this.sampleTypes.find(s => s.code === row.testType);
      convData.push([typeCode ? typeCode.name : row.testType, row.testCount, typeCode ? typeCode.color : '#98999b']);
    });

    this.chartData = convData;
    this.showChart = true;
  }
}
