import { Injectable } from '@angular/core';
import { FilterService } from './filter.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { TestResultsQuery, OrgFacilities, Geo, UIQueryDates } from './query.service.model';

import moment_from from 'moment';
const moment = moment_from; // https://github.com/jvandemo/generator-angular2-library/issues/221

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  private defaultQuery: TestResultsQuery;
  private currentQuery: TestResultsQuery;

  private filtersAppliedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filtersApplied: Observable<boolean> = this.filtersAppliedSubject.asObservable();

  private searchRequestedSubject = new Subject<void>();
  public searchRequested = this.searchRequestedSubject.asObservable();

  constructor(private filterService: FilterService) {
    console.debug('QueryService');

    this.defaultQuery = {
      dates: this.getDatesQuery(),
      storageDates: null
    };

    this.currentQuery = this.defaultQuery;
  }

  public search(): void {
    this.searchRequestedSubject.next();
  }

  public getCurrentQuery(updateFromFilters: boolean = true): TestResultsQuery {

    if (!updateFromFilters) {
      return this.currentQuery;
    }

    const newQuery: TestResultsQuery = {
      dates: this.getDatesQuery(),
      storageDates: null
    };

    // Only assigning properties that are not undefined to keep the query small
    const testTypes = this.getTestTypesQuery();
    if (testTypes) {
      newQuery.testTypes = testTypes;
    }

    const sampleTypes = this.getSampleTypesQuery();
    if (sampleTypes) {
      newQuery.sampleTypes = sampleTypes;
    }

    const orgsFacilities = this.getOrgsFacilitiesQuery();
    if (orgsFacilities) {
      newQuery.orgsFacilities = orgsFacilities;
    }

    const geo = this.getGeoQuery();
    if (geo) {
      newQuery.geo = geo;
    }

    const operator = this.getOperatorQuery();
    if (operator) {
      newQuery.operator = operator;
    }

    const zipCode = this.getZipCodeQuery();
    if (zipCode) {
      newQuery.zipCode = zipCode;
    }

    const serialNumber = this.getSerialNumberQuery();
    if (serialNumber) {
      newQuery.serialNumber = serialNumber;
    }

    this.currentQuery = newQuery;
    this.filtersAppliedSubject.next(this.determineIfFiltersAreApplied());
    return this.currentQuery;
  }

  private getDatesQuery(): UIQueryDates {
    const selected = this.filterService.getDateUIQuery().getSelected();
    return selected.item.getDates();
  }

  private getTestTypesQuery(): string[] | undefined {
    // TestTypes on the backend are equivalent to the UI's Assays
    const selectedAssays = this.filterService.getAssaysUIQuery().getSelectedItems();

    if (selectedAssays && selectedAssays.length > 0) {
      return selectedAssays.map(a => a.getLabel());
    }

    return undefined;
  }

  private getSampleTypesQuery(): string[] | undefined {
    // SampleTypes on the backend are equivalent to the UI's Result Types
    const selectedTypes = this.filterService.getResultTypesQuery().getSelectedItems();

    if (selectedTypes && selectedTypes.length > 0) {
      return selectedTypes.map(a => a.sampleType.code);
    }

    return undefined;
  }

  private getOperatorQuery(): string | undefined {
    const selectedOperator = this.filterService.getOperatorQuery().getOperator();

    if (selectedOperator && selectedOperator.item
      && selectedOperator.item.operator && selectedOperator.item.operator.length > 0) {
      return selectedOperator.item.operator;
    }

    return undefined;
  }

  private getZipCodeQuery(): string | undefined {
    const selectedZipCode = this.filterService.getZipCodeQuery().getZipCode();

    if (selectedZipCode && selectedZipCode.item
      && selectedZipCode.item.zipCode && selectedZipCode.item.zipCode.length > 0) {
      return selectedZipCode.item.zipCode;
    }

    return undefined;
  }

  private getSerialNumberQuery(): string | undefined {
    const selectedSerialNumber = this.filterService.getSerialNumberQuery().getSerialNumber();

    if (selectedSerialNumber && selectedSerialNumber.item
      && selectedSerialNumber.item.serialNumber && selectedSerialNumber.item.serialNumber.length > 0) {
      return selectedSerialNumber.item.serialNumber;
    }

    return undefined;
  }

  private getOrgsFacilitiesQuery(): OrgFacilities[] | undefined {
    const orgsFacilities = this.filterService.getOrgsFacilitiesQuery();
    const selectedOrgs = orgsFacilities.getSelectedOrganizations();

    if (selectedOrgs) {
      const orgFacilitiesQuery: OrgFacilities[] = [];
      selectedOrgs.forEach(o => {
        orgFacilitiesQuery.push({ organizationId: o.item?.getId(), facilityIds: orgsFacilities.getSelectedCachedFacilities(o)?.map(f => f.item?.getId()) });
      });

      return orgFacilitiesQuery;
    }

    return undefined;
  }

  private getGeoQuery(): Geo | undefined {
    const geo = this.filterService.getGeoQuery();
    const selectedCountry = geo.getSelectedCountry();

    if (selectedCountry) {
      const geoQuery: Geo = {
        countryId: selectedCountry.item.country.countryId
      };

      const selectedStates = geo.getSelectedCachedStates(selectedCountry);

      if (selectedStates && selectedStates.length > 0) {
        const stateIds = selectedStates.map(i => i.item.state.stateId);
        geoQuery.stateIds = stateIds;
      }

      let selectedCountyIds: number[] = [];
      selectedStates.forEach(s => {
        const counties = geo.getCachedCounties(s)
          .filter(f => f.isSelected)
          .map(c => c.item.getId())
          ;
        selectedCountyIds = [...selectedCountyIds, ...counties];
      });

      if (selectedCountyIds && selectedCountyIds.length > 0) {
        geoQuery.countyIds = selectedCountyIds;
      }

      return geoQuery;
    }

    return undefined;
  }

  private determineIfFiltersAreApplied(): boolean {

    const currentStartDate = moment(this.currentQuery.dates.startDate);
    const defaultStartDate = moment(this.defaultQuery.dates.startDate);
    if (!currentStartDate.isSame(defaultStartDate)) {
      return true;
    }

    const currentEndDate = moment(this.currentQuery.dates.endDate);
    const defaultEndDate = moment(this.defaultQuery.dates.endDate);
    if (!currentEndDate.isSame(defaultEndDate)) {
      return true;
    }

    if (this.currentQuery.storageDates) {
      const currentStorageStartDate = moment(this.currentQuery.storageDates.startDate);
      const defaultStorageStartDate = moment(this.defaultQuery.storageDates.startDate);
      if (!currentStorageStartDate.isSame(defaultStorageStartDate)) {
        return true;
      }

      const currentStorageEndDate = moment(this.currentQuery.storageDates.endDate);
      const defaultStorageEndDate = moment(this.defaultQuery.storageDates.endDate);
      if (!currentStorageEndDate.isSame(defaultStorageEndDate)) {
        return true;
      }
    }

    if (this.currentQuery.testTypes !== this.defaultQuery.testTypes) {
      return true;
    }

    if (this.currentQuery.sampleTypes !== this.defaultQuery.sampleTypes) {
      return true;
    }

    if (this.currentQuery.orgsFacilities && this.currentQuery.orgsFacilities.length > 0) {
      return true;
    }

    if (this.currentQuery.geo !== this.defaultQuery.geo) {
      return true;
    }

    if (this.currentQuery.operator !== this.defaultQuery.operator) {
      return true;
    }

    if (this.currentQuery.zipCode !== this.defaultQuery.zipCode) {
      return true;
    }

    if (this.currentQuery.serialNumber !== this.defaultQuery.serialNumber) {
      return true;
    }

    return false;
  }
}

