import { Component, OnInit, OnDestroy, ViewContainerRef, QueryList, ViewChildren } from '@angular/core';
import {
  Organization, MetadataService, BaseComponent, Features, User, UserGroup,
  SearchResultsPage, SortDirection, TitleService, OrganizationsNavigationKeys, TranslateNotificationKeys
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-org-search',
  templateUrl: './org-search.component.html',
  styleUrls: ['./org-search.component.scss'],
  standalone: false
})
export class OrgSearchComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramSearchText = 'searchText';
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'OrgSearch';
  value: string;
  rows = [];
  orgs: Organization[];
  enableAdd: boolean;
  orgResults: SearchResultsPage<Organization>;
  sortColumn = 'name';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;

  displayColumns = ['name', 'crmId', 'contactname', 'email', 'phone', 'streetAddress1', 'city', 'state', 'country', 'zipCode'];
  pageSize = 20;
  pageNumber = 0;

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(private router: Router, private route: ActivatedRoute, private metadataService: MetadataService,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService) {
    super();
    this.value = '';

    // OrgAdmin Check
    this.redirectOrgAdmin();
  }

  redirectOrgAdmin(): void {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      if (me.group.displayName === UserGroup.OrgAdmin.valueOf()) {
        void this.router.navigate([OrganizationsNavigationKeys.EditFullPath, me.organizationId]);
      }
    }));
  }

  doSearch(): void {
    this.pageNumber = 0;
    this.updateData();
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      searchText: this.value,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    this.subscription.add(
      this.metadataService.searchOrganizations(this.value,
        this.sortColumn,
        this.sortDirection,
        this.pageSize,
        +this.pageNumber + 1)
        .subscribe({
          next: (orgResults: SearchResultsPage<Organization>) => {
            this.orgResults = orgResults;

            if (doScroll) {
              if (isPageUp) {
                this.scrollToTop();
              } else {
                this.scrollToBottom();
              }
            }
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchFailure}`));
          }
        }));

  }

  createNew(): void {
    void this.router.navigate([OrganizationsNavigationKeys.EditFullPath, 0]);
  }

  onRowSelect(org: Organization): void {
    void this.router.navigate([OrganizationsNavigationKeys.EditFullPath, org.organizationId]);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {

      this.value = params[this.paramSearchText] as string || '';
      this.pageNumber = params[this.paramPageNumber] as number || this.pageNumber;
      this.pageSize = params[this.paramPageSize] as number || this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] as SortDirection || this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';

      if (this.pageNumber && this.pageSize && this.sortColumn && this.sortDirection) {
        this.updateData();
      }
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      this.enableAdd = features.WriteEnabled;
    }));

  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

}
