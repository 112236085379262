import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateNotificationKeys, MetadataService, BaseComponent } from '../../../../core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-export-file-modal',
  templateUrl: './export-file-modal.component.html',
  styleUrls: ['./export-file-modal.component.scss'],
  standalone: false
})
export class ExportFileModalComponent extends BaseComponent {

  public searchText: string;
  public resultCount: number;

  constructor(
    public dialogRef: MatDialogRef<ExportFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { searchText: string; resultCount: number; },
    private toastr: ToastrService,
    private translate: TranslateService,
    private metadataService: MetadataService
  ) {
    super();

    this.searchText = data ? data.searchText : '';
    this.resultCount = data ? data.resultCount : -1;

    console.debug(this.searchText);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onExport(): void {
    console.debug('Exporting Orphan Report ...');

    this.subscription.add(
      this.metadataService.exportOrphansReport(this.searchText).subscribe({
        next: () => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportSuccess}`));
          this.dialogRef.close();
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportFailure}`));
          this.dialogRef.close();
        }
      }));

  }
}
