<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        <ng-container *ngIf="!newResultsApi">
          {{ translationBaseKey + '.Edit' | translate }}
        </ng-container>
        <ng-container *ngIf="newResultsApi">
          {{ translationBaseKey + '.Add' | translate }}
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="resultsApiForm" [formGroup]="resultsApiFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Name' | translate }}</mat-label>
          <input matInput formControlName="name" readonly />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Comment' | translate }}</mat-label>
          <input matInput formControlName="comment" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Token' | translate }}</mat-label>
          <input matInput formControlName="token" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.ContainerExpiration' | translate }}</mat-label>
          <input matInput type="number" min="1" max="500" step="1" formControlName="containerExpiration" readonly />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Organization' | translate }}</mat-label>
          <core-virtual-select formControlName="organizationId" required="true" itemSize="42" [dataSource]="orgs"
            valueField="organizationId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Facility' | translate }}</mat-label>
          <core-virtual-select formControlName="facilityId" required="true" itemSize="42" [dataSource]="facilities"
            valueField="facilityId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <div class="example-full-width allowPHI">
          <mat-checkbox formControlName="allowPHI">
            <mat-label>{{ translationBaseKey + '.AllowPHI' | translate }}</mat-label>
          </mat-checkbox>
        </div>
        <div class="example-full-width">
          <mat-checkbox formControlName="myVirenaEnabled">
            <mat-label>{{ translationBaseKey + '.MyVirenaEnabled' | translate }}</mat-label>
          </mat-checkbox>
        </div>
        <div class="facilityInfo">
          <div class="facilityAddress">
            <div>{{facility?.address?.streetAddress1}}</div>
            <div *ngIf="facility?.address?.streetAddress2">{{facility?.address?.streetAddress2}}</div>
            <div *ngIf="facility?.address?.streetAddress3">{{facility?.address?.streetAddress3}}</div>
            <div>{{facility?.address?.city}}, {{facility?.address?.state?.abbreviation}} {{facility?.address?.zipCode}}
            </div>
            <div>{{facility?.address?.county?.name}}</div>
            <div>{{facility?.address?.country?.name}}</div>
          </div>
        </div>
      </form>

      <div class="instrument-table" *ngIf="instrumentTypes">
        <div class="instrument-types">
          <mat-label>{{ translationBaseKey + '.InstrumentTypes' | translate }}</mat-label>
        </div>
        <mat-table class="mat-elevation-z8" [dataSource]="instrumentTypes" matSort>
          <ng-container matColumnDef="checked">
            <mat-header-cell *matHeaderCellDef>
              Selected
            </mat-header-cell>
            <mat-cell *matCellDef="let instrumentType">
              <mat-checkbox disabled
                [checked]="isInstrumentTypeSelected(instrumentType.instrumentTypeId)"></mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentTypeTableKey + '.Name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let instrumentType">{{instrumentType.name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentTypeTableKey + '.Code' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let instrumentType">{{instrumentType.code}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="serialNumberPrefix">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentTypeTableKey + '.SerialNumberPrefix' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let instrumentType">{{instrumentType.serialNumberPrefix}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentTypeTableKey + '.Description' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let instrumentType">{{instrumentType.description}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
        </mat-table>
      </div>

      <button mat-button [disabled]="resultsApiFormGroup.invalid || resultsApiFormGroup.disabled" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveApi' | translate }}"
        [matTooltipDisabled]="resultsApiFormGroup.invalid || resultsApiFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelApi' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

    </mat-card-content>
  </mat-card>
</div>
