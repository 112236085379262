import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrphansSearchComponent } from './orphans-search/orphans-search.component';
import { OrphansAssignComponent } from './orphans-assign/orphans-assign.component';
import { OrphansResultListComponent } from './orphans-result-list/orphans-result-list.component';
import { OrphansResultDetailDialogComponent } from './orphans-result-list/details/orphans-result-detail-dialog.component';
import { ExportFileModalComponent } from './orphans-search/export-file-modal/export-file-modal.component';
import { AngularMaterialModule, QuidelCoreModule } from '../../core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [OrphansSearchComponent, OrphansAssignComponent, OrphansResultListComponent, OrphansResultDetailDialogComponent, ExportFileModalComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
    ScrollingModule
  ],
  exports: [OrphansSearchComponent, OrphansAssignComponent, OrphansResultListComponent, OrphansResultDetailDialogComponent, ExportFileModalComponent]
})
export class OrphansModule { }
