import { Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef, ViewContainerRef, NgZone, EmbeddedViewRef, EventEmitter, Output } from '@angular/core';
import {
  BaseComponent, MetadataService, Facility, Organization, Country, County, State, InitDataSelectionLists, FacilityDescription, User, Features,
  TitleService,
  TranslateNotificationKeys,
  ConfirmationModalComponent,
  FilterService,
  HttpCachingClient,
  AddressSuggestion,
  AddressRequest,
  Address, FormUtility,
  ConfirmationModalInput,
  UpdateInstrumentTestResultsType,
  UpdateTestResultsRequest,
  UpdateTestResultsRequestType,
  UserGroup,
  OrphansNavigationKeys,
  SearchResultsPage,
  SortDirection,
  InstrumentsNavigationKeys,
  PairedDevicesNavigationKeys,
  InstrumentPairedDevice,
  AddressSuggestionRequest
} from '../../../core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { createPopper, Instance } from '@popperjs/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-facility-edit',
  templateUrl: './facility-edit.component.html',
  styleUrls: ['./facility-edit.component.scss'],
  standalone: false
})
export class FacilityEditComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() closed = new EventEmitter();

  private paramId = 'id';
  private paramOrgId = 'orgId';
  private paramSerialNumber = 'serialnumber';
  private paramDeviceAddress = 'deviceaddress';
  private paramZipCode = 'zipcode';
  private testResultsDeletedEnabled: boolean;
  private deleteTestResults: boolean;
  public translationBaseKey = 'FacilityEdit';
  public translationInstrumentPairedDeviceTableKey = 'InstrumentPairedDeviceTable';
  public newFacility = true;
  public facility: Facility;
  public facId: number;
  public orgId: number;
  public facFormGroup: UntypedFormGroup;
  public orgs: Organization[];
  public countries: Country[];
  public states: State[];
  public counties: County[];
  public facDescriptions: FacilityDescription[];
  public smartyStreets = false;
  public addressSuggestions: AddressSuggestion[] = [];
  public addressVerified = false;
  public isOrgAdmin: boolean;
  public isOrgReadOnly = false;
  public isForOrphan = false;
  public orphanSerialNumber: string;
  public orphanDeviceAddress: string;
  public orphanZipCode: string;
  enableAdd: boolean;
  public instrumentsPairedDevices: SearchResultsPage<InstrumentPairedDevice>;

  @ViewChild('addressField', { read: ElementRef }) addressField: ElementRef<HTMLInputElement>;
  @ViewChild('addressSuggestions', { read: TemplateRef }) suggestionTemplate: TemplateRef<unknown>;

  private view: EmbeddedViewRef<unknown>;
  private popperRef: Instance;

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  pageSize = 20;
  pageNumber = 0;
  sortColumn = 'insSerialNumber';
  sortDirection: SortDirection = SortDirection.Descending;
  sortMatDirection: string;
  displayColumns = ['insSerialNumber', 'pdDeviceAddress'];
  sortColumns: Record<string, string> = {
    insSerialNumber: 'Instrument.SerialNumber',
    pdDeviceAddress: 'PairedDevice.DeviceAddress'
  };

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private location: Location, private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService,
    private dialog: MatDialog, private filterService: FilterService, private cachingService: HttpCachingClient,
    private vcr: ViewContainerRef,
    private zone: NgZone) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.facId = +params[this.paramId];

      this.newFacility = this.facId < 1;

      this.checkIsOrgAdmin();
      this.loadInitData();
      this.loadFacilityDescriptions();
      this.checkWriteFlag();

      if (!this.newFacility) {
        this.loadFacility();
      } else {
        if (params[this.paramOrgId]) {
          this.orgId = +params[this.paramOrgId];
          this.isOrgReadOnly = true;
        }

        if (params[this.paramSerialNumber] && params[this.paramDeviceAddress] && params[this.paramZipCode]) {
          this.isForOrphan = true;
          this.orphanSerialNumber = params[this.paramSerialNumber] as string;
          this.orphanDeviceAddress = params[this.paramDeviceAddress] as string;
          this.orphanZipCode = params[this.paramZipCode] as string;
        }

        this.loadNew();
      }
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  private loadInitData() {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.countries = initData.Countries;
      this.orgs = initData.Organizations;

      if (this.newFacility && this.countries.length > 0) {
        this.facFormGroup.get('countryId').setValue(this.countries[0].countryId);
        this.toggleSmartyStreets(this.countries[0].countryId);
      } else if (this.facility && this.facility.address) {
        this.toggleSmartyStreets(this.facility.address.country_CountryId);
      }
    }));
  }

  private checkIsOrgAdmin() {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      this.isOrgAdmin = me.group.displayName === UserGroup.OrgAdmin.valueOf();
    }));
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.facFormGroup.disable();
      }

      this.testResultsDeletedEnabled = features.TestResultsDeleteEnabled;
      this.enableAdd = features.WriteEnabled;
    }));

  }

  public loadFacilityDescriptions(): void {
    this.subscription.add(this.metadataService.getFacilityDescriptions().subscribe((facDes: FacilityDescription[]) => {
      this.facDescriptions = facDes;
    }));

  }

  public loadNewAddressFromOrg(orgId: number): void {
    if (this.facility == null || orgId !== this.facility.organization_OrganizationId) {

      this.subscription.add(this.metadataService.getOrganization(orgId).subscribe((org: Organization) => {

        this.facFormGroup.patchValue({
          countryId: org.address ? org.address.country_CountryId : '',
          address1: org.address && org.address.streetAddress1 ? org.address.streetAddress1 : '',
          address2: org.address && org.address.streetAddress2 ? org.address.streetAddress2 : '',
          address3: org.address && org.address.streetAddress3 ? org.address.streetAddress3 : '',
          city: org.address.city ? org.address.city : '',
          stateId: org.address ? org.address.state_StateId : '',
          countyId: org.address && org.address.county_CountyId ? org.address.county_CountyId : '',
          zipCode: org.address ? org.address.zipCode : '',
          contactName: org.contactName,
          workPhone: org.phone,
          email: org.email,
          mobilePhone: org.mobilePhone,
          crmId: org.crmId ? org.crmId : ''
        });
      }));
    }

  }

  public loadNew(): void {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

      this.facFormGroup.setValue({
        name: '',
        descriptionId: 1,
        organizationId: '',
        contactName: '',
        workPhone: '',
        mobilePhone: '',
        email: '',
        countryId: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        stateId: '',
        countyId: '',
        zipCode: '',
        crmId: ''
      });
      this.setValueChanges();

      if (this.orgId) {
        this.facFormGroup.patchValue({
          organizationId: this.orgId ? this.orgId : ''
        });

        this.facFormGroup.get('organizationId').disable();
      }
    }));

  }

  public populateOrgAddress(): void {
    this.loadNewAddressFromOrg(this.orgId);
  }

  public setValueChanges(): void {

    this.subscription.add(
      this.facFormGroup.get('countryId').valueChanges.subscribe(val => {
        this.toggleSmartyStreets(+val);
        this.loadStates(+val);
      }));

    this.subscription.add(
      this.facFormGroup.get('stateId').valueChanges.subscribe(val => {
        this.loadCounties(+val);
      }));

    this.subscription.add(
      this.facFormGroup.get('organizationId').valueChanges.subscribe(val => {
        this.orgId = +val;
      }));

    this.subscription.add(
      this.facFormGroup.get('address1').valueChanges.subscribe(val => {
        this.addressVerified = false;

        if (this.smartyStreets) {
          const req: AddressSuggestionRequest = { streetAddress: val as string };

          this.subscription.add(this.metadataService.getAddressSuggestions(req).subscribe((suggestions: AddressSuggestion[]) => {
            this.addressSuggestions = suggestions;
            this.showAddressSuggestions();
          }));
        }
      }));
  }

  public loadFacility(): void {
    this.subscription.add(this.metadataService.getFacility(this.facId).subscribe({
      next: (facility: Facility) => {
        this.facility = facility;
        this.facFormGroup.patchValue({
          name: this.facility.name,
          descriptionId: this.facility.facilityDescription_FacilityDescriptionId,
          organizationId: this.facility.organization_OrganizationId,
          contactName: this.facility.contactName ? this.facility.contactName : '',
          workPhone: this.facility.contactWorkPhone ? this.facility.contactWorkPhone : '',
          mobilePhone: this.facility.contactMobilePhone ? this.facility.contactMobilePhone : '',
          email: this.facility.contactEmail ? this.facility.contactEmail : '',
          countryId: this.facility.address ? this.facility.address.country_CountryId : '',
          address2: this.facility.address && this.facility.address.streetAddress2 ? this.facility.address.streetAddress2 : '',
          address3: this.facility.address && this.facility.address.streetAddress3 ? this.facility.address.streetAddress3 : '',
          city: this.facility.address && this.facility.address.city ? this.facility.address.city : '',
          stateId: this.facility.address ? this.facility.address.state_StateId : '',
          countyId: this.facility.address ? this.facility.address.county_CountyId : '',
          zipCode: this.facility.address ? this.facility.address.zipCode : '',
          crmId: this.facility.crmId ? this.facility.crmId : ''
        });

        this.facFormGroup.patchValue({
          address1: this.facility.address && this.facility.address.streetAddress1 ? this.facility.address.streetAddress1 : '',
        }, { emitEvent: false });
      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.facId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));

    this.updateData();
    this.setValueChanges();
  }

  public updateFacility(): void {
    if (this.newFacility) {
      this.facility = {
        facilityId: 0,
        name: this.facFormGroup.get('name').value as string,
        facilityDescription_FacilityDescriptionId: this.facFormGroup.get('descriptionId').value as number,
        organization_OrganizationId: this.facFormGroup.get('organizationId').value as number,
        contactName: this.facFormGroup.get('contactName').value as string,
        contactWorkPhone: this.facFormGroup.get('workPhone').value as string,
        contactMobilePhone: this.facFormGroup.get('mobilePhone').value as string,
        contactEmail: this.facFormGroup.get('email').value as string,
        crmId: FormUtility.emptyStringCheck(this.facFormGroup.get('crmId').value as string),
        lastModifiedBy: 'System',
        lastModifiedDate: new Date(),
        address: {
          addressId: 0,
          streetAddress1: this.facFormGroup.get('address1').value as string,
          streetAddress2: FormUtility.emptyStringCheck(this.facFormGroup.get('address2').value as string),
          streetAddress3: FormUtility.emptyStringCheck(this.facFormGroup.get('address3').value as string),
          city: this.facFormGroup.get('city').value as string,
          zipCode: this.facFormGroup.get('zipCode').value as string,
          state_StateId: this.facFormGroup.get('stateId').value as number,
          county_CountyId: this.facFormGroup.get('countyId').value as number,
          country_CountryId: this.facFormGroup.get('countryId').value as number,
          lastModifiedBy: 'System'
        }
      };
    } else {
      this.facility.name = this.facFormGroup.get('name').value as string;
      this.facility.facilityDescription_FacilityDescriptionId = this.facFormGroup.get('descriptionId').value as number;
      this.facility.organization_OrganizationId = this.facFormGroup.get('organizationId').value as number;
      this.facility.contactName = this.facFormGroup.get('contactName').value as string;
      this.facility.contactWorkPhone = this.facFormGroup.get('workPhone').value as string;
      this.facility.contactMobilePhone = this.facFormGroup.get('mobilePhone').value as string;
      this.facility.contactEmail = this.facFormGroup.get('email').value as string;
      this.facility.crmId = FormUtility.emptyStringCheck(this.facFormGroup.get('crmId').value as string);
      this.facility.lastModifiedBy = 'System';
      this.facility.organization = null;
      this.facility.address.streetAddress1 = this.facFormGroup.get('address1').value as string;
      this.facility.address.streetAddress2 = FormUtility.emptyStringCheck(this.facFormGroup.get('address2').value as string);
      this.facility.address.streetAddress3 = FormUtility.emptyStringCheck(this.facFormGroup.get('address3').value as string);
      this.facility.address.city = this.facFormGroup.get('city').value as string;
      this.facility.address.zipCode = this.facFormGroup.get('zipCode').value as string;
      this.facility.address.country_CountryId = this.facFormGroup.get('countryId').value as number;
      this.facility.address.county_CountyId = this.facFormGroup.get('countyId').value as number;
      this.facility.address.state_StateId = this.facFormGroup.get('stateId').value as number;

      this.facility.address.lastModifiedBy = 'System';
    }

    this.facility.address.country = this.countries.find(c => c.countryId === this.facility.address.country_CountryId);
    if (this.facility.address.state_StateId != null || this.facility.address.state_StateId > 0) {
      this.facility.address.state = this.states.find(s => s.stateId === this.facility.address.state_StateId);
    }

  }

  loadStates(val: number): void {
    if (!isNaN(val) && val > 0) {

      this.subscription.add(
        this.metadataService.getStates(val).subscribe((states: State[]) => {
          this.states = states;

          const stateControl = this.facFormGroup.get('stateId');

          const desiredStateId = stateControl.value as number;
          const foundState = this.states.find(s => s.stateId === +desiredStateId);
          if (!foundState) {
            this.clearStateSelection();
          }

          if (this.states == null || this.states.length === 0) {
            stateControl.reset({ value: null, disabled: true });
          } else if (!this.facFormGroup.disabled) {
            stateControl.enable();
          }
        }));
    }
  }

  loadCounties(val: number): void {
    const countyControl = this.facFormGroup.get('countyId');

    if (!isNaN(val) && val > 0) {
      const ids = [val];

      this.subscription.add(
        this.metadataService.getCountySelectionListByStates(ids).subscribe((counties: County[][]) => {
          this.counties = counties[val];

          const desiredCountyId = countyControl.value as number;
          const foundCounty = this.counties.find(c => c.countyId === +desiredCountyId);
          if (!foundCounty) {
            this.clearCountySelection();
          }

          if (this.counties == null || this.counties.length === 0) {
            countyControl.reset({ value: null, disabled: true });
          } else if (!this.facFormGroup.disabled) {
            countyControl.enable();
          }
        }));
    } else {
      countyControl.reset({ value: null, disabled: true });
      this.counties = [];
    }
  }

  private clearStateSelection() {
    this.facFormGroup.patchValue({
      stateId: ''
    }, {
      emitEvent: false
    });
    this.clearCountySelection();
  }

  private clearCountySelection() {
    this.facFormGroup.patchValue({
      countyId: ''
    }, {
      emitEvent: false
    });
  }

  initForm(): void {
    this.facFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      name: ['', Validators.required],
      descriptionId: [1, Validators.required],
      organizationId: ['', Validators.required],
      contactName: ['', Validators.required],
      workPhone: ['', [Validators.required]],
      mobilePhone: [''],
      email: ['', [Validators.required, Validators.email]],
      countryId: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      city: ['', Validators.required],
      stateId: ['', Validators.required],
      countyId: ['', Validators.required],
      zipCode: ['', Validators.required],
      crmId: ['']
      /* eslint-enable @typescript-eslint/unbound-method */
    });

  }

  public async onSave(): Promise<void> {
    this.updateFacility();

    if (this.smartyStreets && !this.addressVerified) {
      const valid = await this.validateAddress();

      if (!valid) {
        return;
      }
    }

    let saveObservable: Observable<number>;
    if (this.newFacility) {
      saveObservable = this.metadataService.postFacility(this.facility);
    } else {
      saveObservable = this.metadataService.putFacility(this.facility);
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: (ret) => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));
          this.newFacility = false;
          this.facId = ret;
          this.loadFacility();

          this.filterService.setFacilitiesDirty(true);
          this.cachingService.invalidate();

          if (this.orgId) {

            if (this.isForOrphan) {
              void this.router.navigate(
                [
                  OrphansNavigationKeys.EditFullPathWithId,
                  {
                    serialnumber: this.orphanSerialNumber,
                    deviceaddress: this.orphanDeviceAddress,
                    zipcode: this.orphanZipCode,
                    organizationid: this.orgId,
                    facilityid: this.facId
                  }
                ],
                { replaceUrl: true });
            } else {
              this.location.back();
            }
          }
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
        }
      }));
  }

  public onCancel(): void {
    this.location.back();
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {

    const modalWidth = 0.25 * window.outerWidth;

    const confDia = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    return await firstValueFrom(confDia.afterClosed());
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'Facility' })) {
      const result = await this.showConfirmationDialog({ titleResourceKey: this.testResultsDeletedEnabled ? 'ConfirmDeleteTestResults' : 'ConfirmDeleteTestResultsUnavailable' });

      if (this.testResultsDeletedEnabled) {
        this.deleteTestResults = result;
      } else {
        this.deleteTestResults = false;
      }

      this.subscription.add(
        this.metadataService.deleteFacility(this.facility, this.deleteTestResults).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
          }
        }));
    }
  }

  private showAddressSuggestions() {
    if (this.isOpen) {
      return;
    }

    this.view = this.vcr.createEmbeddedView(this.suggestionTemplate, this);
    const dropdown = this.view.rootNodes[0] as HTMLDivElement;

    document.body.appendChild(dropdown);
    dropdown.style.width = `${this.addressField.nativeElement.offsetWidth}px`;

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(this.addressField.nativeElement, dropdown);
    });

    this.handleClickOutside();
  }

  public onAddressSuggestionChosen(suggestion: AddressSuggestion): void {

    this.facFormGroup.get('address1').setValue(suggestion.street, { emitEvent: false });
    this.facFormGroup.get('city').setValue(suggestion.city);

    this.facFormGroup.patchValue({
      stateId: suggestion.state_StateId
    });

    this.destroyPopperResources();
  }

  public async validateAddress(): Promise<boolean> {

    return new Promise<boolean>((res) => {
      const county = this.counties.find(c => c.countyId === this.facFormGroup.get('countyId').value);
      const country = this.countries.find(c => c.countryId === this.facFormGroup.get('countryId').value);
      const state = this.states.find(s => s.stateId === this.facFormGroup.get('stateId').value);

      const request: AddressRequest = {
        streetAddress1: this.facFormGroup.get('address1').value as string,
        city: this.facFormGroup.get('city').value as string,
        zipCode: this.facFormGroup.get('zipCode').value as string,
        countyName: county ? county.name : null,
        countryCode: country ? country.code : null,
        stateCode: state ? state.abbreviation : null
      };

      this.subscription.add(this.metadataService.validateAddress(request).subscribe(async (results: Address[]) => {
        this.addressVerified = results.length === 1;

        if (this.addressVerified) {
          const address = results[0];

          this.facFormGroup.get('city').setValue(address.city);
          this.facFormGroup.get('zipCode').setValue(address.zipCode);

          if (address.county_CountyId) {
            this.facFormGroup.patchValue({
              countyId: results[0].county_CountyId
            });
          }

          if (address.state_StateId) {
            this.facFormGroup.patchValue({
              stateId: address.state_StateId
            });
          }
        } else {
          await this.confirmAddressIsValid();
        }

        res(this.addressVerified);
      }));
    });
  }

  private async confirmAddressIsValid(): Promise<boolean> {
    const modalWidth = 0.25 * window.outerWidth;
    const message = 'ConfirmAddressValid';

    const confDia = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: { titleResourceKey: message }
    });

    const result = await firstValueFrom(confDia.afterClosed());

    if (result) {
      this.addressVerified = true;
    }

    return result;
  }

  destroyPopperResources(): void {
    this.closed.emit();

    if (this.popperRef) {
      this.popperRef.destroy();
    }

    if (this.view) {
      this.view.destroy();
    }

    this.view = null;
    this.popperRef = null;
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const origin = this.popperRef.state.elements.reference as HTMLElement;
          const popper = this.popperRef.state.elements.popper;
          const contains = origin.contains(target as HTMLElement) || popper.contains(target as HTMLElement);
          return contains === false;
        }),
        takeUntil(this.closed)
      )
      .subscribe(() => {
        this.destroyPopperResources();
      });
  }

  private toggleSmartyStreets(countryId: number) {
    if (!this.countries) {
      return;
    }

    const country = this.countries.find(c => c.countryId === countryId);
    this.smartyStreets = country && country.code === 'US';
  }

  public async onShowUpdateTestResultsDialog(template: TemplateRef<unknown>): Promise<void> {
    const modalWidth = 0.25 * window.outerWidth;
    const dialogRef = this.dialog.open<unknown, unknown, { startDate: string, endDate: string, type: UpdateInstrumentTestResultsType } | 'false'>(template, {
      width: `${modalWidth}px`,
      data: { defaultStartDate: new Date(2010, 0, 1), defaultEndDate: new Date() }
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const type: UpdateInstrumentTestResultsType = result.type;

      const startMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.startDate, 'MM-DD-YYYY') :
        moment(result.startDate, 'MM-DD-YYYY');
      const endMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.endDate, 'MM-DD-YYYY').endOf('day') :
        moment(result.endDate, 'MM-DD-YYYY').add(1, 'day').add(-1, 'second');

      const continueUpdate = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmUpdateTestResults', parameter: 'facility' });

      if (continueUpdate) {
        const request: UpdateTestResultsRequest = {
          id: this.facId,
          startTime: startMoment.toDate(),
          endTime: endMoment.toDate(),
          updateType: type,
          updateRequestType: UpdateTestResultsRequestType.Facility
        };
        this.subscription.add(this.metadataService.UpdateInstrumentResultsOrgFac(request).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsSucceeded}`));
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsFailed}`));
          }
        }));
      }
    }
  }

  public IsDateRangeValid(startDate: string, endDate: string): boolean {
    return new Date(startDate) <= new Date(endDate);
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData();
  }

  updateData(): void {

    this.subscription.add(
      this.metadataService.getInstrumentsPairedDevicesByFacilityId(
        this.facId, this.pageNumber + 1, this.pageSize, this.sortColumns[this.sortColumn], this.sortDirection)
        .subscribe((inss: SearchResultsPage<InstrumentPairedDevice>) => {
          this.instrumentsPairedDevices = inss;
        }));

    this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';
  }


  public onInstrumentSelect(instrumentId: number): void {
    void this.router.navigate([InstrumentsNavigationKeys.EditFullPath, instrumentId]);
  }

  public onRouterSelect(pairedDeviceId: number): void {
    void this.router.navigate([PairedDevicesNavigationKeys.EditFullPath, pairedDeviceId]);
  }

  createNewInstrument(): void {
    void this.router.navigate([InstrumentsNavigationKeys.EditFullPath, 0, this.orgId, this.facId]);
  }

  createNewPairedDevice(): void {
    void this.router.navigate([PairedDevicesNavigationKeys.EditFullPath, 0, this.orgId, this.facId]);
  }
}
