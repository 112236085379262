import { Component, OnInit, OnDestroy, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import {
  MetadataService, User, SortDirection, SearchResultsPage,
  TitleService, UsersNavigationKeys, BaseComponent, TranslateNotificationKeys, DateConstants
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  standalone: false
})
export class UserSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  runDateFormat = DateConstants.YearMonthDayWithoutTimeFormat;
  private paramSearchText = 'searchText';
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'UserSearch';
  value: string;
  displayColumns = ['displayname', 'username', 'organizationname', 'facilityname', 'createddate'];
  users: User[];

  userResults: SearchResultsPage<User>;
  sortColumn = 'userName';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;

  pageSize = 20;
  pageNumber = 0;
  sortColumns: Record<string, string> = {
    username: 'UserName',
    displayname: 'DisplayName',
    organizationname: 'Organization.Name',
    facilityname: 'Facility.Name',
    createddate: 'CreatedDate'
  };
  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(private router: Router, private route: ActivatedRoute, private metadataService: MetadataService,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService) {
    super();
    this.value = '';
  }

  onRowSelect(user: User): void {
    void this.router.navigate([UsersNavigationKeys.EditFullPath, user.externalObjectId]);
  }

  doSearch(): void {
    this.pageNumber = 0;
    this.updateData();
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      searchText: this.value,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    this.subscription.add(
      this.metadataService.searchUsers(this.value, this.sortColumns[this.sortColumn.toLowerCase()], this.sortDirection, this.pageSize, +this.pageNumber + 1)
        .subscribe({
          next: (userResults: SearchResultsPage<User>) => {
            this.userResults = userResults;

            if (doScroll) {
              if (isPageUp) {
                this.scrollToTop();
              } else {
                this.scrollToBottom();
              }
            }
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchFailure}`));
          }
        }));

  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {

      this.value = params[this.paramSearchText] as string || '';
      this.pageNumber = params[this.paramPageNumber] as number || this.pageNumber;
      this.pageSize = params[this.paramPageSize] as number || this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] as SortDirection || this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';

      if (this.pageNumber && this.pageSize && this.sortColumn && this.sortDirection) {
        this.updateData();
      }
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }
}
