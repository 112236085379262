import { Component, OnInit, OnDestroy, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import {
  BaseComponent, MetadataService, Features,
  SortDirection, TitleService, ResultsApiNavigationKeys, TranslateNotificationKeys,
  ResultsApiIdentifier, InstrumentType
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-results-api-list',
  templateUrl: './results-api-list.component.html',
  styleUrls: ['./results-api-list.component.scss'],
  standalone: false
})
export class ResultsApiListComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'ResultsApiList';
  public displayColumns = ['name', 'organizationname', 'facilityname', 'myVirenaEnabled', 'instrumentTypes', 'comment'];
  resultsApiIdentifiers: ResultsApiIdentifier[];
  sortedResultsApiIdentifiers: ResultsApiIdentifier[];
  enableAdd: boolean;
  sortColumn = 'name';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;
  isOrgAdmin: boolean;

  pageSize = 20;
  pageNumber = 0;
  sortColumns = {
    name: 'ResultsApiIdentifier.Name',
    facilityname: 'Facility.Name',
    orgname: 'Organization.Name',
    myVirenaEnabled: 'ResultsApiIdentifier.MyVirenaEnabled',
    comment: 'ResultsApiIdentifier.Comment'
  };

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  public instrumentTypes: InstrumentType[];

  constructor(private router: Router, private route: ActivatedRoute, private metadataService: MetadataService,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService) {
    super();
    this.isOrgAdmin = false;
  }

  loadData(): void {
    this.subscription.add(this.metadataService.getResultsApiIdentifiers().subscribe({
      next: (resultsApiIdentifiers: ResultsApiIdentifier[]) => {
        this.resultsApiIdentifiers = resultsApiIdentifiers;
        this.updateData();
      },
      error: () => {
        this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    const isAsc = this.sortDirection === SortDirection.Ascending;

    const sliceStart = this.pageNumber * this.pageSize;

    this.sortedResultsApiIdentifiers = this.resultsApiIdentifiers.sort((a, b) => {
      switch (this.sortColumn) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'organizationname': return this.compare(a.facility.organization.name.toLowerCase(), b.facility.organization.name.toLowerCase(), isAsc);
        case 'facilityname': return this.compare(a.facility.name.toLowerCase(), b.facility.name.toLowerCase(), isAsc);
        case 'myVirenaEnabled': return this.compare(a.myVirenaEnabled ? a.myVirenaEnabled.toString() : 'false', b.myVirenaEnabled ? b.myVirenaEnabled.toString() : 'false', isAsc);
        case 'comment': return this.compare(a.comment ? a.comment.toLowerCase() : '', b.comment ? b.comment.toLowerCase() : '', isAsc);
        default: return 0;
      }
    }).slice(sliceStart, sliceStart + this.pageSize);

    if (doScroll) {
      if (isPageUp) {
        this.scrollToTop();
      } else {
        this.scrollToBottom();
      }
    }

  }

  onRowSelect(resultsApiIdentifier: ResultsApiIdentifier): void {
    void this.router.navigate([ResultsApiNavigationKeys.EditFullPath, resultsApiIdentifier.resultsApiIdentifierId]);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {
      this.pageNumber = params[this.paramPageNumber] ? Number(params[this.paramPageNumber]) : this.pageNumber;
      this.pageSize = params[this.paramPageSize] ? Number(params[this.paramPageSize]) : this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] ? Number(params[this.paramSortDirection]) : this.sortDirection;
      this.sortMatDirection = + this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';
      this.loadData();

      this.loadInitData();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  private loadInitData() {
    this.subscription.add(this.metadataService.getInstrumentTypeList().subscribe((instrumentTypes: InstrumentType[]) => {
      this.instrumentTypes = instrumentTypes;
    }));
  }

  public getSelectedInstrumentTypeCodes(instrumentTypeIds?: number[]): string {
    const instrumentTypeCodes: string[] = [];
    if (instrumentTypeIds && this.instrumentTypes) {
      instrumentTypeIds.forEach(id => {
        const instrumentType = this.instrumentTypes.find(({ instrumentTypeId }) => id === instrumentTypeId);
        if (instrumentType) {
          instrumentTypeCodes.push(instrumentType.code);
        }
      });
    }

    return instrumentTypeCodes.join(', ');
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      this.enableAdd = features.WriteEnabled;
    }));
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
