import { Injectable } from '@angular/core';
import { RoleAccessService, UserGroup } from '../../core';

@Injectable({
  providedIn: 'root'
})

export class InstrumentAccessService {

  constructor(private roleAccessService: RoleAccessService) { }

  public hasAccessToDetails(): boolean {
    const role = this.roleAccessService.getRole();
    if (role === UserGroup.SystemAdmin) {
      return true;
    }
    else {
      return false;
    }
  }
}
