import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  TestVolumeTypeComponent, BaseComponent, QueryService, SearchService, TitleService, FilterService, ReportKeys,
  TestResult_TestVolumeByTypeRow
} from '../../core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'app-report-test-volume-type',
  templateUrl: './report-test-volume-type.component.html',
  styleUrls: ['./report-test-volume-type.component.scss'],
  standalone: false
})
export class ReportTestVolumeTypeComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) private testTypeByVolume: TestVolumeTypeComponent;

  constructor(private queryService: QueryService,
    private searchService: SearchService,
    private titleService: TitleService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private googleAnalyticsService : GoogleAnalyticsService,
    private translate: TranslateService) {
    super();

  }

  ngOnInit(): void {

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.onSearch();

    this.titleService.updateTitleTranslateKey('ReportMenu.TestVolumeByType');
  }

  onSearch(): void {
    if (this.filterService.isReportEnabled(ReportKeys.TestVolumeByType)) {
      this.googleAnalyticsService.logWithLabels('View Report', 'Report', 'View Test Volumes By Type Report','filters', this.queryService.getCurrentQuery(true));
      this.subscription.add(this.searchService.getReportTestVolumeByType(this.queryService.getCurrentQuery(true)).subscribe(
        (result: TestResult_TestVolumeByTypeRow[]) => {
          if (!result || result.length === 0) {
            const title = translate(this.translate, 'ReportMenu.NoDataToShow');
            this.toastr.info(undefined, title);
            return;
          }

          this.testTypeByVolume.setData(result);
        }));
    } else {
      const message = translate(this.translate, 'ReportMenu.RequirePatient');
      const title = translate(this.translate, 'ReportMenu.ReportLoadError');
      this.toastr.error(message, title);
    }
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  savePDF(): void {
    this.testTypeByVolume.saveAsPdf();
  }

  saveImage(): void {
    this.testTypeByVolume.saveAsImage();
  }

}
