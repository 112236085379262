import { Injectable } from '@angular/core';
import { HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import {
  InitDataSelectionLists, User, Facility, CountiesSelection, State,
  Features, Role, Organization, FacilityDescription, OrganizationDistributor, Instrument, PatientSampleTypesResponse,
  AssayType, LoincCode, PatientSampleType, SnomedCode, UserFavorite, PairedDeviceSelection, AddressSuggestion,
  AddressRequest, Address, UserFavoriteSchedule, FilterState, PairedDevice, PairedDeviceType,
  InstrumentResultOrphanSummary, InstrumentResultOrphan, UpdateTestResultsRequest,
  IdbRecord, ResultsApiIdentifier, InsertApplicationIdentifierRequest, UpdateApplicationIdentifierRequest, InstrumentType, SaveNotesRequest, FacilitiesSelection, InstrumentPairedDevice, AddressSuggestionRequest
} from './metadata.service.models';
import { SearchResultsPage, SortDirection } from './common.service.models';
import { Observable, throwError, of } from 'rxjs';
import { HttpCachingClient } from '../utils/http-caching-client';
import { SettingsKeys } from '../utils/settings.keys';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  public static preventBusyIndicatorHeaders = new HttpHeaders({ preventBusyIndicator: 'true' });

  private apiUrl: string;
  private settingsV1BaseUrl = 'api/v1.0/Settings';
  private facilitiesV1BaseUrl = 'api/v1.0/Facilities';
  private userV1BaseUrl = 'api/v1.0/Users';
  private organizationV1BaseUrl = 'api/v1.0/Organizations';
  private applicationV1BaseUrl = 'api/v1.0/Application';
  private locationV1BaseUrl = 'api/v1.0/Locations';
  private instrumentV1BaseUrl = 'api/v1.0/Instruments';
  private userFavoritesV1BaseUrl = 'api/v1.0/UserFavorites';
  private resultsApiV1BaseUrl = 'api/v1.0/ResultsApi';

  // Application
  private getSelectionListInitDataUrl = this.applicationV1BaseUrl + '/getSelectionListInitializationData';
  private getFacSelectionListByOrgUrl = this.applicationV1BaseUrl + '/getFacilitiesSelectionListByOrganizationId';
  private getFacSelectionListByMultipleOrgsUrl = this.applicationV1BaseUrl + '/getFacilitiesSelectionListByMultipleOrganizationIds';
  private getStateSelectionListByCountryUrl = this.applicationV1BaseUrl + '/getStatesSelectionListByCountryId';
  private getCountySelectionListByStatesUrl = this.applicationV1BaseUrl + '/getCountiesSelectionListByStateIds';
  private getPairedDeviceSelectionListUrl = this.applicationV1BaseUrl + '/getPairedDeviceSelectionList';
  private getCurrentUserUrl = this.applicationV1BaseUrl + '/me';
  private getSearchInstrumentSerialNumbersUrl = this.applicationV1BaseUrl + '/SearchInstrumentSerialNumbers';
  private getInstrumentTypes = this.instrumentV1BaseUrl + '/GetInstrumentTypes';

  // Settings
  private getFeaturesUrl = this.settingsV1BaseUrl + '/features';
  private getSettingsUrl = this.settingsV1BaseUrl + '/settings';

  // Users
  private getUserUrl = this.userV1BaseUrl + '/getByUserId';
  private getUserUnapprovedUrl = this.userV1BaseUrl + '/getUnapproved';
  private getRolesUrl = this.userV1BaseUrl + '/getRoles';
  private searchUserUrl = this.userV1BaseUrl + '/search';
  private deleteUserUrl = this.userV1BaseUrl + '/delete';
  private saveUserUrl = this.userV1BaseUrl + '/put';
  private approveUserUrl = this.userV1BaseUrl + '/approve';
  private rejectUserUrl = this.userV1BaseUrl + '/reject';
  private sendRegistrationEmailUrl = this.userV1BaseUrl + '/sendRegistrationEmail';
  private saveUserNotesUrl = this.userV1BaseUrl + '/saveNotes';

  // Organizations
  private getOrganizationsUrl = this.organizationV1BaseUrl + '/get';
  private searchOrganizationsUrl = this.organizationV1BaseUrl + '/search';
  private getOrganizationUrl = this.organizationV1BaseUrl + '/getByOrganizationId';
  private putOrganizationUrl = this.organizationV1BaseUrl + '/put';
  private postOrganizationUrl = this.organizationV1BaseUrl + '/post';
  private deleteOrganizationUrl = this.organizationV1BaseUrl + '/delete';
  private getOrganizationDistributorsUrl = this.organizationV1BaseUrl + '/getOrganizationDistributors';

  // Facilities
  private getFacilitiesByOrgIdUrl = this.facilitiesV1BaseUrl + '/GetByOrganizationId';
  private searchFacilitiesUrl = this.facilitiesV1BaseUrl + '/search';
  private getFacilityUrl = this.facilitiesV1BaseUrl + '/getByFacilityId';
  private getFacilityDescriptionUrl = this.facilitiesV1BaseUrl + '/getFacilityDescriptions';
  private putFacilityUrl = this.facilitiesV1BaseUrl + '/put';
  private postFacilityUrl = this.facilitiesV1BaseUrl + '/post';
  private deleteFacilityUrl = this.facilitiesV1BaseUrl + '/delete';

  // Instruments
  private getInstrumentUrl = this.instrumentV1BaseUrl + '/getInstrumentById';
  private putInstrumentUrl = this.instrumentV1BaseUrl + '/updateInstrument';
  private postInstrumentUrl = this.instrumentV1BaseUrl + '/insertInstrument';
  private deleteInstrumentUrl = this.instrumentV1BaseUrl + '/deleteInstrument';
  private deleteInstrumentResultOrphansBySummaryUrl = this.instrumentV1BaseUrl + '/deleteInstrumentResultOrphansBySummary';
  private searchInstrumentsUrl = this.instrumentV1BaseUrl + '/searchInstruments';
  private getPatientSamplesTypesUrl = this.instrumentV1BaseUrl + '/getPatientSampleTypes';
  private getLoincCodesUrl = this.instrumentV1BaseUrl + '/getLoincCodes';
  private getAssayTypesUrl = this.instrumentV1BaseUrl + '/getAssayTypes';
  private postInsertTestTypeUrl = this.instrumentV1BaseUrl + '/insertTestType';
  private putUpdateTestTypeUrl = this.instrumentV1BaseUrl + '/updateTestType';
  private getSnomedCodesUrl = this.instrumentV1BaseUrl + '/getSnomedCodes';
  private getInstrumentsByPairedDeviceIdUrl = this.instrumentV1BaseUrl + '/getInstrumentsByPairedDeviceId';
  private updateInstrumentResultsOrgFacUrl = this.instrumentV1BaseUrl + '/updateInstrumentResultsOrgFac';

  // Orphans
  private searchInstrumentResultOrphanSummariesUrl = this.instrumentV1BaseUrl + '/searchInstrumentResultOrphanSummaries';
  private getInstrumentResultOrphanBySummaryUrl = this.instrumentV1BaseUrl + '/getInstrumentResultOrphanBySummary';
  private assignInstrumentResultOrphansUrl = this.instrumentV1BaseUrl + '/assignInstrumentResultOrphans';
  private getInstrumentResultOrphanBlobArchiveBySummaryUrl = this.instrumentV1BaseUrl + '/getInstrumentResultOrphanBlobArchiveBySummary';
  private getInstrumentResultOrphansPageBySummaryUrl = this.instrumentV1BaseUrl + '/getInstrumentResultOrphansPageBySummary';
  private getInstrumentResultOrphanBlobByIdUrl = this.instrumentV1BaseUrl + '/getInstrumentResultOrphanBlobById';

  // IDB Records
  private getIdbRecordBySerialNumberUrl = this.instrumentV1BaseUrl + '/getIDBRecordBySerialNumber';

  // Paired Devices
  private searchPairedDevicesUrl = this.instrumentV1BaseUrl + '/searchPairedDevices';
  private getPairedDeviceUrl = this.instrumentV1BaseUrl + '/getPairedDeviceById';
  private getPairedDeviceTypesUrl = this.instrumentV1BaseUrl + '/getPairedDeviceTypes';
  private putPairedDeviceUrl = this.instrumentV1BaseUrl + '/updatePairedDevice';
  private postPairedDeviceUrl = this.instrumentV1BaseUrl + '/insertPairedDevice';
  private deletePairedDeviceUrl = this.instrumentV1BaseUrl + '/deletePairedDevice';

  // Instruments and Paired Devices
  private getInstrumentsPairedDevicesByFacilityIdUrl = this.instrumentV1BaseUrl + '/GetInstrumentsPairedDevicesByFacilityId';

  // Location
  private getStatesUrl = this.locationV1BaseUrl + '/getStates';
  private getAddressSuggestionUrl = this.locationV1BaseUrl + '/getAddressCompletion';
  private validateAddressUrl = this.locationV1BaseUrl + '/getAddress';

  // Favorites
  private postInsertUserFavortiteUrl = this.userFavoritesV1BaseUrl + '/insertUserFavorite';
  private getFavoriteForCurrentUserUrl = this.userFavoritesV1BaseUrl + '/getForCurrentUser';
  private getUserFavoriteUrl = this.userFavoritesV1BaseUrl + '/getUserFavorite';
  private updateUserFavoriteUrl = this.userFavoritesV1BaseUrl + '/updateUserFavorite';
  private updateUserFavoriteScheduleUrl = this.userFavoritesV1BaseUrl + '/upsertUserFavoriteSchedule';
  private deleteUserFavoriteUrl = this.userFavoritesV1BaseUrl + '/deleteUserFavorite';
  private getIsUserFavoriteEditableByUserUrl = this.userFavoritesV1BaseUrl + '/getIsUserFavoriteEditableByUser';
  // update controller endpoints in user favorites
  private exportReportUrl = this.userFavoritesV1BaseUrl + '/exportReport';
  private exportQCReportUrl = this.userFavoritesV1BaseUrl + '/exportQCReport';
  private exportOrphansReportUrl = this.userFavoritesV1BaseUrl + '/exportOrphansReport';

  // Results API Identifiers
  private getResultsApiIdentifierUrl = this.resultsApiV1BaseUrl + '/getResultsApiIdentifier';
  private getResultsApiIdentifiersUrl = this.resultsApiV1BaseUrl + '/getResultsApiIdentifiers';
  private putResultsApiIdentifierUrl = this.resultsApiV1BaseUrl + '/insertResultsApiIdentifier';
  private updateResultsApiIdentifierUrl = this.resultsApiV1BaseUrl + '/updateResultsApiIdentifier';
  private deleteResultsApiIdentifierUrl = this.resultsApiV1BaseUrl + '/deleteResultsApiIdentifier';

  constructor(private httpClient: HttpCachingClient, configurationService: ConfigurationService) {

    const config = configurationService.getConfiguration();

    this.apiUrl = config.metadataServiceEndpoint;
    if (!this.apiUrl.endsWith('/')) {
      this.apiUrl = `${this.apiUrl}/`;
    }
  }

  private getFullUrl(subPath: string) {
    return this.apiUrl + subPath;
  }

  // Application

  public getMe(): Observable<User> {
    return this.httpClient.getWithCache<User>(this.getFullUrl(this.getCurrentUserUrl));
  }

  public fetchInitDataSelectionLists(): Observable<InitDataSelectionLists> {
    return this.httpClient.getWithCache<InitDataSelectionLists>(this.getFullUrl(this.getSelectionListInitDataUrl));
  }

  public getFacSelectionListByOrg(id: number): Observable<Facility[]> {
    return this.httpClient.getWithCache<Facility[]>(this.getFullUrl(this.getFacSelectionListByOrgUrl),
      {
        params: new HttpParams().set('organizationId', id.toString())
      });
  }

  public getInstrumentTypeList(): Observable<InstrumentType[]> {
    return this.httpClient.getWithCache<InstrumentType[]>(this.getFullUrl(this.getInstrumentTypes));
  }

  public getStateSelectionListByCountry(id: number): Observable<State[]> {
    return this.httpClient.getWithCache<State[]>(this.getFullUrl(this.getStateSelectionListByCountryUrl),
      {
        params: new HttpParams().set('countryId', id.toString())
      });
  }

  public getCountySelectionListByStates(ids: number[]): Observable<CountiesSelection> {
    return this.httpClient.postWithCache<CountiesSelection>(this.getFullUrl(this.getCountySelectionListByStatesUrl), ids);
  }

  public getFacilitySelectionListByMultipleOrgs(ids: number[]): Observable<FacilitiesSelection> {
    return this.httpClient.postWithCache<FacilitiesSelection>(this.getFullUrl(this.getFacSelectionListByMultipleOrgsUrl), ids);
  }

  public getPairedDeviceSelectionListByFacilityId(facilityId: number): Observable<PairedDeviceSelection[]> {
    return this.httpClient.getWithCache<PairedDeviceSelection[]>(this.getFullUrl(this.getPairedDeviceSelectionListUrl),
      {
        params: new HttpParams().set('facilityId', facilityId.toString())
      });

  }

  public getPairedDeviceSelectionListByOrganizationId(orgId: number): Observable<PairedDeviceSelection[]> {
    return this.httpClient.getWithCache<PairedDeviceSelection[]>(this.getFullUrl(this.getPairedDeviceSelectionListUrl),
      {
        params: new HttpParams().set('organizationId', orgId.toString())
      });

  }

  public getSearchInstrumentSerialNumbers(searchText: string): Observable<string[]> {
    return this.httpClient.get<string[]>(this.getFullUrl(this.getSearchInstrumentSerialNumbersUrl),
      {
        params: new HttpParams().set('searchText', searchText),
        headers: MetadataService.preventBusyIndicatorHeaders
      });
  }

  // Settings
  public getFeatures(): Observable<Features> {
    return this.httpClient.get<Features>(this.getFullUrl(this.getFeaturesUrl));
  }

  public getSettings(): Observable<unknown> {
    return this.httpClient
      .get(this.getFullUrl(this.getSettingsUrl))
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 403) {
          return of({
            [SettingsKeys.isUnauthorized]: true
          });
        }

        throwError(() => err);
        return of(undefined);
      }));
  }

  // Users
  public getUser(id: string): Observable<User> {
    return this.httpClient.get<User>(this.getFullUrl(this.getUserUrl),
      {
        params: new HttpParams().set('id', id)
      });
  }

  public getUnapprovedUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.getFullUrl(this.getUserUnapprovedUrl));
  }

  public searchUsers(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<User>> {
    return this.httpClient.get<SearchResultsPage<User>>(this.getFullUrl(this.searchUserUrl),
      {
        params: new HttpParams().set('searchText', query)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public deleteUser(user: User): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteUserUrl),
      {
        params: new HttpParams().set('id', user.externalObjectId)
      });
  }

  public saveUser(user: User): Observable<unknown> {
    return this.httpClient.put(this.getFullUrl(this.saveUserUrl), user);
  }

  public approveUser(user: User): Observable<unknown> {
    return this.httpClient.put(this.getFullUrl(this.approveUserUrl), user);
  }

  public rejectUser(user: User): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.rejectUserUrl),
      {
        params: new HttpParams().set('id', user.externalObjectId)
      });
  }

  public saveUserNotes(request: SaveNotesRequest): Observable<unknown> {
    return this.httpClient.post(this.getFullUrl(this.saveUserNotesUrl), request);
  }


  public sendRegistrationEmail(id: string): Observable<unknown> {
    return this.httpClient.get(this.getFullUrl(this.sendRegistrationEmailUrl),
      {
        params: new HttpParams().set('id', id)
      });
  }

  /**
   * Returns ALL roles that exists in the system.
   *
   * **It is NOT current user's role BUT ALL possbile roles. User could have only ONE role assigned - to check it call getMe() or getUser(userId) method.**
   */
  public getRoles(): Observable<Role[]> {
    return this.httpClient.getWithCache<Role[]>(this.getFullUrl(this.getRolesUrl));
  }

  // Organizations
  public getOrganizations(): Observable<Organization[]> {
    return this.httpClient.get<Organization[]>(this.getFullUrl(this.getOrganizationsUrl));
  }

  public searchOrganizations(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Organization>> {
    return this.httpClient.get<SearchResultsPage<Organization>>(this.getFullUrl(this.searchOrganizationsUrl),
      {
        params: new HttpParams().set('searchText', query)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public getOrganizationDistributors(): Observable<OrganizationDistributor[]> {
    return this.httpClient.getWithCache<OrganizationDistributor[]>(this.getFullUrl(this.getOrganizationDistributorsUrl));
  }

  public getOrganization(id: number): Observable<Organization> {
    return this.httpClient.get<Organization>(this.getFullUrl(this.getOrganizationUrl),
      {
        params: new HttpParams().set('id', id.toString())
      });
  }


  public deleteOrganization(org: Organization, deleteTestResults: boolean): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteOrganizationUrl),
      {
        params: new HttpParams()
          .set('id', org.organizationId.toString())
          .set('deleteTestResults', deleteTestResults.toString())
      });
  }

  public postOrganization(org: Organization): Observable<number> {
    return this.httpClient.post<number>(this.getFullUrl(this.postOrganizationUrl), org);
  }

  public putOrganization(org: Organization): Observable<number> {
    return this.httpClient.put<number>(this.getFullUrl(this.putOrganizationUrl), org);
  }

  // Facilities
  public getFacilitiesByOrgId(id: number, pageNumber: number = 1, pageSize: number = 50,
    sortColumn: string = null, sortDirection: SortDirection = SortDirection.Ascending): Observable<SearchResultsPage<Facility>> {
    return this.httpClient.get<SearchResultsPage<Facility>>(this.getFullUrl(this.getFacilitiesByOrgIdUrl),
      {
        params: new HttpParams()
          .set('organizationId', id.toString())
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn ? sortColumn : '')
          .set('sortDirection', sortDirection.toString())
      });
  }

  public searchFacilities(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Facility>> {
    return this.httpClient.get<SearchResultsPage<Facility>>(this.getFullUrl(this.searchFacilitiesUrl),
      {
        params: new HttpParams().set('searchText', query)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public getFacility(id: number): Observable<Facility> {
    return this.httpClient.get<Facility>(this.getFullUrl(this.getFacilityUrl),
      {
        params: new HttpParams().set('facilityId', id.toString())
      });
  }

  public getFacilityDescriptions(): Observable<FacilityDescription[]> {
    return this.httpClient.get<FacilityDescription[]>(this.getFullUrl(this.getFacilityDescriptionUrl));
  }

  public deleteFacility(facility: Facility, deleteTestResults: boolean): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteFacilityUrl),
      {
        params: new HttpParams()
          .set('id', facility.facilityId.toString())
          .set('deleteTestResults', deleteTestResults.toString())
      });
  }

  public postFacility(facility: Facility): Observable<number> {
    return this.httpClient.post<number>(this.getFullUrl(this.postFacilityUrl), facility);
  }

  public putFacility(facility: Facility): Observable<number> {
    return this.httpClient.put<number>(this.getFullUrl(this.putFacilityUrl), facility);
  }

  // Instruments

  public getInstrument(id: number): Observable<Instrument> {
    return this.httpClient.get<Instrument>(this.getFullUrl(this.getInstrumentUrl),
      {
        params: new HttpParams().set('instrumentId', id.toString())
      });
  }

  public deleteInstrument(instrument: Instrument, deleteTestResults: boolean): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteInstrumentUrl),
      {
        params: new HttpParams()
          .set('id', instrument.instrumentId.toString())
          .set('deleteTestResults', deleteTestResults.toString())
      });
  }

  public deleteInstrumentResultOrphansBySummary(serialNumber: string, deviceAddress: string, zipCode: string): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteInstrumentResultOrphansBySummaryUrl),
      {
        params: new HttpParams()
          .set('serialNumber', serialNumber)
          .set('deviceAddress', deviceAddress)
          .set('zipCode', zipCode)
      });
  }

  public postInstrument(instrument: Instrument): Observable<number> {
    return this.httpClient.post<number>(this.getFullUrl(this.postInstrumentUrl), instrument);
  }

  public putInstrument(instrument: Instrument): Observable<number> {
    return this.httpClient.put<number>(this.getFullUrl(this.putInstrumentUrl), instrument);
  }

  public searchInstruments(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Instrument>> {
    return this.httpClient.get<SearchResultsPage<Instrument>>(this.getFullUrl(this.searchInstrumentsUrl),
      {
        params: new HttpParams().set('searchText', query)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public getResultsApiIdentifier(id: string): Observable<ResultsApiIdentifier> {
    return this.httpClient.get<ResultsApiIdentifier>(this.getFullUrl(this.getResultsApiIdentifierUrl),
      {
        params: new HttpParams().set('resultsApiId', id)
      });
  }

  public getResultsApiIdentifiers(): Observable<ResultsApiIdentifier[]> {
    return this.httpClient.get<ResultsApiIdentifier[]>(this.getFullUrl(this.getResultsApiIdentifiersUrl),
      {
      });
  }

  public postResultsApiIdentifier(request: InsertApplicationIdentifierRequest): Observable<ResultsApiIdentifier> {
    return this.httpClient.post<ResultsApiIdentifier>(this.getFullUrl(this.putResultsApiIdentifierUrl), request);
  }

  public updateResultsApiIdentifier(request: UpdateApplicationIdentifierRequest): Observable<ResultsApiIdentifier> {
    return this.httpClient.put<ResultsApiIdentifier>(this.getFullUrl(this.updateResultsApiIdentifierUrl), request);
  }

  public deleteResultsApiIdentifier(resultsApiIdentifier: ResultsApiIdentifier): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deleteResultsApiIdentifierUrl),
      {
        params: new HttpParams()
          .set('resultsApiId', resultsApiIdentifier.resultsApiIdentifierId)
      });
  }

  public getPatientSampleTypes(): Observable<PatientSampleTypesResponse[]> {
    return this.httpClient.get<PatientSampleTypesResponse[]>(this.getFullUrl(this.getPatientSamplesTypesUrl),
      {
      });
  }

  public getLoincCodes(): Observable<LoincCode[]> {
    return this.httpClient.get<LoincCode[]>(this.getFullUrl(this.getLoincCodesUrl),
      {
      });
  }

  public getAssayTypes(): Observable<AssayType[]> {
    return this.httpClient.get<AssayType[]>(this.getFullUrl(this.getAssayTypesUrl),
      {
      });
  }

  public postInsertTestType(assay: PatientSampleType): Observable<unknown> {
    return this.httpClient.post(this.getFullUrl(this.postInsertTestTypeUrl), assay);
  }

  public putUpdateTestType(assay: PatientSampleType): Observable<unknown> {
    return this.httpClient.put(this.getFullUrl(this.putUpdateTestTypeUrl), assay);
  }

  public getSnomedCodes(): Observable<SnomedCode[]> {
    return this.httpClient.get<SnomedCode[]>(this.getFullUrl(this.getSnomedCodesUrl),
      {
      });
  }

  public getInstrumentsByPairedDeviceId(id: number): Observable<SearchResultsPage<Instrument>> {
    return this.httpClient.get<SearchResultsPage<Instrument>>(this.getFullUrl(this.getInstrumentsByPairedDeviceIdUrl),
      {
        params: new HttpParams().set('pairedDeviceId', id.toString())
      });
  }

  public UpdateInstrumentResultsOrgFac(request: UpdateTestResultsRequest): Observable<unknown> {
    return this.httpClient.post(this.getFullUrl(this.updateInstrumentResultsOrgFacUrl), request);
  }

  // Orphans
  public searchInstrumentResultOrphans(query: string, sortColumn: string, sortDirection: SortDirection,
    pageSize: number, pageNumber: number): Observable<SearchResultsPage<InstrumentResultOrphanSummary>> {

    return this.httpClient.post<SearchResultsPage<InstrumentResultOrphanSummary>>(this.getFullUrl(this.searchInstrumentResultOrphanSummariesUrl),
      { searchText: query, pageNumber: pageNumber, pageSize: pageSize, sortColumn: sortColumn, sortDirection: sortDirection });
  }

  public getInstrumentResultOrphansPageBySummary(serialNumber: string, deviceAddress: string, zipCode: string, sortColumn: string, sortDirection: SortDirection,
    pageSize: number, pageNumber: number): Observable<SearchResultsPage<InstrumentResultOrphan>> {
    return this.httpClient.get<SearchResultsPage<InstrumentResultOrphan>>(this.getFullUrl(this.getInstrumentResultOrphansPageBySummaryUrl),
      {
        params: new HttpParams()
          .set('serialNumber', serialNumber)
          .set('deviceAddress', deviceAddress)
          .set('zipCode', zipCode)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public getInstrumentResultOrphanBlobById(instrumentResultOrphanId: number): Observable<string> {
    return this.httpClient.get<string>(this.getFullUrl(this.getInstrumentResultOrphanBlobByIdUrl),
      {
        params: new HttpParams().set('instrumentResultOrphanId', instrumentResultOrphanId.toString())
      });
  }

  public getInstrumentResultOrphanBySummary(serialNumber: string, deviceAddress: string, zipCode: string): Observable<InstrumentResultOrphan> {
    return this.httpClient.post<InstrumentResultOrphan>(this.getFullUrl(this.getInstrumentResultOrphanBySummaryUrl),
      { serialNumber, deviceAddress, zipCode });
  }

  public assignInstrumentResultOrphans(instrumentResultOrphanId: number, facilityId: number, createZipOverride: boolean, pairedDeviceOverride: boolean): Observable<number> {
    return this.httpClient.get<number>(this.getFullUrl(this.assignInstrumentResultOrphansUrl),
      {
        params: new HttpParams()
          .set('instrumentResultOrphanId', instrumentResultOrphanId ? instrumentResultOrphanId.toString() : '')
          .set('facilityId', facilityId ? facilityId.toString() : '')
          .set('createZipOverride', createZipOverride ? createZipOverride.toString() : 'false')
          .set('pairedDeviceOverride', pairedDeviceOverride ? pairedDeviceOverride.toString() : 'false')
      });
  }

  public getInstrumentResultOrphanBlobArchiveBySummary(serialNumber: string, deviceAddress: string, zipCode: string): Observable<Blob> {
    return this.httpClient.get(this.getFullUrl(this.getInstrumentResultOrphanBlobArchiveBySummaryUrl),
      {
        params: new HttpParams().set('serialNumber', serialNumber)
          .set('deviceAddress', deviceAddress)
          .set('zipCode', zipCode),
        responseType: 'blob'
      });
  }

  // IDB Records:

  public getIdbRecordBySerialNumber(serialNumber: string): Observable<IdbRecord> {
    return this.httpClient.get<IdbRecord>(this.getFullUrl(this.getIdbRecordBySerialNumberUrl),
      {
        params: new HttpParams().set('serialNumber', serialNumber)
      });
  }

  // Paired Devices

  public searchPairedDevices(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<PairedDevice>> {
    return this.httpClient.get<SearchResultsPage<PairedDevice>>(this.getFullUrl(this.searchPairedDevicesUrl),
      {
        params: new HttpParams().set('searchText', query)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn)
          .set('sortDirection', sortDirection.toString())
      });
  }

  public getPairedDevice(id: number): Observable<PairedDevice> {
    return this.httpClient.get<PairedDevice>(this.getFullUrl(this.getPairedDeviceUrl),
      {
        params: new HttpParams().set('pairedDeviceId', id.toString())
      });
  }

  public getPairedDeviceTypes(): Observable<PairedDeviceType[]> {
    return this.httpClient.get<PairedDeviceType[]>(this.getFullUrl(this.getPairedDeviceTypesUrl));
  }

  public deletePairedDevice(pairedDevice: PairedDevice): Observable<unknown> {
    return this.httpClient.delete(this.getFullUrl(this.deletePairedDeviceUrl),
      {
        params: new HttpParams()
          .set('id', pairedDevice.pairedDeviceId.toString())
      });
  }

  public postPairedDevice(pairedDevice: PairedDevice): Observable<number> {
    return this.httpClient.post<number>(this.getFullUrl(this.postPairedDeviceUrl), pairedDevice);
  }

  public putPairedDevice(pairedDevice: PairedDevice): Observable<number> {
    return this.httpClient.put<number>(this.getFullUrl(this.putPairedDeviceUrl), pairedDevice);
  }

  // Instruments and Paired Devices
  public getInstrumentsPairedDevicesByFacilityId(id: number, pageNumber: number = 1, pageSize: number = 50,
    sortColumn: string = null, sortDirection: SortDirection = SortDirection.Descending): Observable<SearchResultsPage<InstrumentPairedDevice>> {
    return this.httpClient.get<SearchResultsPage<InstrumentPairedDevice>>(this.getFullUrl(this.getInstrumentsPairedDevicesByFacilityIdUrl),
      {
        params: new HttpParams()
          .set('facilityId', id.toString())
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('sortColumn', sortColumn ? sortColumn : '')
          .set('sortDirection', sortDirection.toString())
      });
  }

  // Locations
  public getStates(id: number): Observable<State[]> {
    return this.httpClient.getWithCache<State[]>(this.getFullUrl(this.getStatesUrl),
      {
        params: new HttpParams().set('countryId', id.toString())
      });
  }

  public getAddressSuggestions(address: AddressSuggestionRequest): Observable<AddressSuggestion[]> {
    return this.httpClient.post<AddressSuggestion[]>(this.getFullUrl(this.getAddressSuggestionUrl), address, {
      headers: MetadataService.preventBusyIndicatorHeaders
    });
  }

  public validateAddress(address: AddressRequest): Observable<Address[]> {
    return this.httpClient.post<Address[]>(this.getFullUrl(this.validateAddressUrl), address);
  }

  // Favorites
  public postInsertUserFavorite(userFavorite: UserFavorite): Observable<number> {
    return this.httpClient.post<number>(this.getFullUrl(this.postInsertUserFavortiteUrl), userFavorite);
  }

  public getFavoriteForCurrentUser(): Observable<UserFavorite[]> {
    return this.httpClient.get<UserFavorite[]>(this.getFullUrl(this.getFavoriteForCurrentUserUrl));
  }

  public getUserFavorite(userFavoriteId: number): Observable<UserFavorite> {
    return this.httpClient.get<UserFavorite>(this.getFullUrl(this.getUserFavoriteUrl),
      {
        params: new HttpParams().set('userFavoriteId', userFavoriteId.toString())
      });
  }

  public updateUserFavorite(userFavorite: UserFavorite): Observable<UserFavorite> {
    return this.httpClient.put<UserFavorite>(this.getFullUrl(this.updateUserFavoriteUrl), userFavorite);
  }

  public updateUserFavoriteSchedule(userFavoriteSchedule: UserFavoriteSchedule): Observable<UserFavoriteSchedule> {
    return this.httpClient.put<UserFavoriteSchedule>(this.getFullUrl(this.updateUserFavoriteScheduleUrl), userFavoriteSchedule);
  }

  public deleteUserFavorite(userFavoriteId: number): Observable<number> {
    return this.httpClient.delete<number>(this.getFullUrl(this.deleteUserFavoriteUrl),
      {
        params: new HttpParams().set('userFavoriteId', userFavoriteId.toString())
      });
  }

  public exportReport(filterState: FilterState, urlEndpoint: string, searchText: string, assayId: number): Observable<unknown> {
    return this.httpClient.post(this.getFullUrl(this.exportReportUrl), { filterState, urlEndpoint, searchText, assayId });
  }

  public exportQCReport(filterState: FilterState): Observable<unknown> {
    return this.httpClient.post(this.getFullUrl(this.exportQCReportUrl), filterState);
  }

  public exportOrphansReport(searchText: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.getFullUrl(this.exportOrphansReportUrl),
      {
        params: new HttpParams().set('searchText', searchText)
      });
  }

}
