<div class="report-action-container">
  <div class="left-side">
  </div>
  <div class="right-side">
    <button mat-flat-button (click)="exportFileDialog()">
      <mat-icon class="icon-button-override">save</mat-icon>
      {{ translationBaseKey + '.Export' | translate }}
    </button>
  </div>
</div>

<div class="report-qc-table">

  <mat-table class="mat-elevation-z8" [dataSource]="results" multiTemplateDataRows>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.Date' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.testCompletionDate | date : runDateFormat : 'UTC'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="operator">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.Operator' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.operatorId}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="serialNumber">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.SerialNumber' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.serialNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="instrumentType">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.InstrumentType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.instrumentType}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="testName">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.TestName' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.testName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="kitLotNumber">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.KitLotNumber' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.kitLotNumber }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="cassetteNumber">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.CassetteNumber' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.cassetteNumber }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="controlType">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.ControlType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.controlType }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="result">
      <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.Result' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.result }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="facilityName">
      <mat-cell *matCellDef="let groupHeader" class="groupHeader">{{groupHeader.facilityName}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

    <mat-row *matRowDef="let row; columns: ['facilityName']; when: isGroup"></mat-row>
    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>

  </mat-table>
</div>
