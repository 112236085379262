// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, PairedDevicesNavigationKeys } from '../../core';
import { PairedDeviceEditComponent } from './paired-device-edit/paired-device-edit.component';
import { PairedDeviceSearchComponent } from './paired-device-search/paired-device-search.component';

export const routes: Routes = [
  {
    path: PairedDevicesNavigationKeys.Search, component: PairedDeviceSearchComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: PairedDevicesNavigationKeys.NewFromFacilityEdit, component: PairedDeviceEditComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: PairedDevicesNavigationKeys.EditWithIdArg, component: PairedDeviceEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class PairedDevicesRoutingModule { }
