<div mat-dialog-content>
  <div class="section-header">
    {{ 'OrphanResultDetails.CardResultDetailTitle' | translate }}
  </div>
  <div class="result-id">
    {{ 'OrphanResultDetails.OrphanResultId' | translate }}: {{ orphan?.instrumentResultOrphanId }}
  </div>

  <div class="section">
    <div class="section-cell-large">

      <div>{{ 'OrphanResultDetails.ResultOrigin' | translate }}:</div>
      <div>{{ orphan?.resultOrigin }}</div>

      <div>{{ 'OrphanResultDetails.StorageDate' | translate }}:</div>
      <div>{{ orphan?.storageDateUtc | date: storageDateFormat }}</div>

      <div>{{ 'OrphanResultDetails.InstrumentSerialNumber' | translate }}:</div>
      <div>{{ orphan?.instrumentSerialNumber }}</div>

      <div>{{ 'OrphanResultDetails.InstrumentTypeCode' | translate }}:</div>
      <div>{{ orphan?.instrumentTypeCode }}</div>

      <div>{{ 'OrphanResultDetails.OriginatingDeviceAddress' | translate }}:</div>
      <div>{{ orphan?.originatingDeviceAddress }}</div>

      <div>{{ 'OrphanResultDetails.ZipCode' | translate }}:</div>
      <div>{{ orphan?.zipCode }}</div>

      <div>{{ 'OrphanResultDetails.ErrorCode' | translate }}:</div>
      <div>{{ orphan?.errorCode }}</div>

      <div>{{ 'OrphanResultDetails.OrphanReason' | translate }}:</div>
      <div>{{ orphan?.orphanReason }}</div>

      <div>{{ 'OrphanResultDetails.ErrorMessage' | translate }}:</div>
      <div>{{ orphan?.errorMessage }}</div>

    </div>

    <textarea rows="25">{{ json }}</textarea>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDoneClick()">
    <mat-icon class="icon-button-override">cancel</mat-icon>
    {{ 'OrphanResultDetails.Close' | translate }}
  </button>
  <button mat-button (click)="onDownloadJson()">
    <mat-icon class="icon-button-override">download</mat-icon>
    {{ 'OrphanResultDetails.Download' | translate }}
  </button>
</div>
