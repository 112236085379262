// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, AssaysNavigationKeys } from '../../core';
import { AssaysListComponent } from './assays-list/assays-list.component';
import { AssaysEditComponent } from './assays-edit/assays-edit.component';

export const routes: Routes = [
  {
    path: AssaysNavigationKeys.List, component: AssaysListComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: AssaysNavigationKeys.EditWithIdArg, component: AssaysEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class AssaysRoutingModule { }
