import { Component, OnInit, OnDestroy, ViewContainerRef, QueryList, ViewChildren } from '@angular/core';
import {
  InstrumentResultOrphan, MetadataService, BaseComponent,
  SearchResultsPage, SortDirection, TitleService, TranslateNotificationKeys, DateConstants
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { OrphansResultDetailDialogComponent } from './details/orphans-result-detail-dialog.component';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-orphans-result-list',
  templateUrl: './orphans-result-list.component.html',
  styleUrls: ['./orphans-result-list.component.scss'],
  standalone: false
})
export class OrphansResultListComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramSerialNumber = 'serialnumber';
  private paramDeviceAddress = 'deviceaddress';
  private paramZipCode = 'zipcode';
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'OrphanResultList';
  serialNumber: string;
  deviceAddress: string;
  zipCode: string;
  rows = [];
  orphans: InstrumentResultOrphan[];
  orphanResults: SearchResultsPage<InstrumentResultOrphan>;
  sortColumn = 'instrumentResultOrphanId';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;
  storageDateFormat = DateConstants.YearMonthDayWithTimeFormat;

  displayColumns = ['instrumentResultOrphanId', 'storageDateUtc', 'serialNumber', 'errorCode', 'orphanReason'];
  pageSize = 20;
  pageNumber = 0;
  sortColumns: Record<string, string> = {
    instrumentresultorphanid: 'InstrumentResultOrphanId',
    storagedateutc: 'StorageDateUtc',
    serialnumber: 'InstrumentSerialNumber',
    errorcode: 'ErrorCode',
    orphanreason: 'OrphanReason'
  };

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(private router: Router, private route: ActivatedRoute, private metadataService: MetadataService,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService, private dialog: MatDialog) {
    super();
    this.serialNumber = '';
    this.deviceAddress = '';
    this.zipCode = '';
  }

  doSearch(): void {
    this.pageNumber = 0;
    this.updateData();
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      serialnumber: this.serialNumber,
      deviceaddress: this.deviceAddress,
      zipcode: this.zipCode,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    this.subscription.add(
      this.metadataService.getInstrumentResultOrphansPageBySummary(this.serialNumber,
        this.deviceAddress,
        this.zipCode,
        this.sortColumns[this.sortColumn.toLowerCase()],
        this.sortDirection,
        this.pageSize,
        +this.pageNumber + 1)
        .subscribe({
          next: (orphanResults: SearchResultsPage<InstrumentResultOrphan>) => {
            this.orphanResults = orphanResults;

            if (doScroll) {
              if (isPageUp) {
                this.scrollToTop();
              } else {
                this.scrollToBottom();
              }
            }
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
          }
        }));

  }

  onRowSelect(orphan: InstrumentResultOrphan): void {
    this.subscription.add(this.metadataService.getInstrumentResultOrphanBlobById(orphan.instrumentResultOrphanId).subscribe({
      next: data => {
        this.openDialog(orphan, data);
      },
      error: () => {
        this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.FailedToDownloadOrphanDetailsMessage}`));
      }
    }));
  }

  openDialog(orphan: InstrumentResultOrphan, json: string): void {

    const modalWidth = 0.86 * window.outerWidth;
    const dialogRef = this.dialog.open(OrphansResultDetailDialogComponent, {
      width: `${modalWidth}px`,
      data: { orphan, json }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {

      this.serialNumber = params[this.paramSerialNumber] ? params[this.paramSerialNumber] as string : '';
      this.deviceAddress = params[this.paramDeviceAddress] ? params[this.paramDeviceAddress] as string : '';
      this.zipCode = params[this.paramZipCode] ? params[this.paramZipCode] as string : '';
      this.pageNumber = params[this.paramPageNumber] ? params[this.paramPageNumber] as number : this.pageNumber;
      this.pageSize = params[this.paramPageSize] ? params[this.paramPageSize] as number : this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] ? params[this.paramSortColumn] as string : this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] ? params[this.paramSortDirection] as SortDirection : this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';

      this.updateData();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }
}
