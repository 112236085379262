<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        <ng-container *ngIf="isApproved">
          {{ translationBaseKey + '.EditUser' | translate }}
        </ng-container>
        <ng-container *ngIf="!isApproved">
          {{ translationBaseKey + '.ApproveUser' | translate }}
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="userForm" [formGroup]="userFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Username' | translate }}</mat-label>
          <input matInput formControlName="userName" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.FirstName' | translate }}</mat-label>
          <input matInput formControlName="givenName" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.LastName' | translate }}</mat-label>
          <input matInput formControlName="surname" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Email' | translate }}</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Phone' | translate }}</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isApproved">
          <mat-label>{{ translationBaseKey + '.ReqOrg' | translate }}</mat-label>
          <input matInput formControlName="reqOrg" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Org' | translate }}</mat-label>
          <core-virtual-select formControlName="organizationId" itemSize="42" [dataSource]="orgs"
            valueField="organizationId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isApproved">
          <mat-label>{{ translationBaseKey + '.ReqFac' | translate }}</mat-label>
          <input matInput formControlName="reqFac" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline"
          *ngIf="selectedRole == null || selectedRole.displayName!='Network Organization Admin'">
          <mat-label>{{ translationBaseKey + '.Fac' | translate }}</mat-label>
          <core-virtual-select formControlName="facilityId" itemSize="42" [dataSource]="facilities"
            valueField="facilityId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline" floatLabel="always"
          *ngIf="selectedRole?.displayName=='Network Organization Admin'">
          <mat-label>{{ translationBaseKey + '.AdditionalOrgs' | translate }}</mat-label>
          <ng-select [(ngModel)]="user.additionalOrganizationIds" [ngModelOptions]="{standalone: true}"
            [multiple]="true" [virtualScroll]="true" placeholder="Select Organizations" style="line-height: 1.5;"
            [items]="additionalOrgs" bindLabel="name" bindValue="organizationId" [hideSelected]="true"
            (change)="refreshAdditionalOrganizationIdsControl()">
          </ng-select>
          <input matInput formControlName="additionalOrganizationIds" hidden="true" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Role' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="groupId">
            <mat-option *ngFor="let role of roles" [value]="role.objectId">
              {{role.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isApproved">
          <mat-label>{{ translationBaseKey + '.Notes' | translate }}</mat-label>
          <textarea matInput formControlName="notes" maxlength="250" rows=3></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="isApproved" *ngIf="isApproved">{{ translationBaseKey + '.IsApproved' | translate
          }}
        </mat-checkbox>
      </form>

      <p *ngIf="!b2cModificationsEnabled" class="warning">
        <strong>{{ translationBaseKey + '.B2CModificationsDisabled' | translate }}</strong>
      </p>

      <ng-container *ngIf="isApproved">

        <button mat-button [disabled]="userFormGroup.invalid || userFormGroup.disabled" (click)="onSave()"
          matTooltip="{{ translationBaseKey + '.SaveUser' | translate }}"
          [matTooltipDisabled]="userFormGroup.invalid || userFormGroup.disabled">
          <mat-icon class="icon-button-override">save</mat-icon>
          {{ translationBaseKey + '.Save' | translate }}
        </button>

        <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelUser' | translate }}">
          <mat-icon class="icon-button-override">cancel</mat-icon>
          {{ translationBaseKey + '.Cancel' | translate }}
        </button>

        <button mat-button [disabled]="userFormGroup.disabled" (click)="onDelete()"
          matTooltip="{{ translationBaseKey + '.DeleteUser' | translate }}"
          [matTooltipDisabled]="userFormGroup.disabled">
          <mat-icon class="icon-button-override">delete</mat-icon>
          {{ translationBaseKey + '.Delete' | translate }}
        </button>

      </ng-container>

      <ng-container *ngIf="!isApproved">

        <button mat-button [disabled]="userFormGroup.invalid || userFormGroup.disabled" (click)="onApprove()"
          matTooltip="{{ translationBaseKey + '.ApproveUserBtn' | translate }}"
          [matTooltipDisabled]="userFormGroup.invalid || userFormGroup.disabled">
          <mat-icon class="icon-button-override">save</mat-icon>
          {{ translationBaseKey + '.Approve' | translate }}
        </button>

        <button mat-button [disabled]="userFormGroup.disabled" (click)="onReject()"
          matTooltip="{{ translationBaseKey + '.RejectUser' | translate }}"
          [matTooltipDisabled]="userFormGroup.disabled">
          <mat-icon class="icon-button-override">delete</mat-icon>
          {{ translationBaseKey + '.Reject' | translate }}
        </button>

        <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelUser' | translate }}">
          <mat-icon class="icon-button-override">cancel</mat-icon>
          {{ translationBaseKey + '.Cancel' | translate }}
        </button>

        <button mat-button (click)="onSaveNotes()" matTooltip="{{ translationBaseKey + '.SaveUserNotes' | translate }}"
          [matTooltipDisabled]="userFormGroup.disabled">
          <mat-icon class="icon-button-override">edit_note</mat-icon>
          {{ translationBaseKey + '.SaveNotes' | translate }}
        </button>


      </ng-container>
    </mat-card-content>
  </mat-card>


</div>
