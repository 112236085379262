import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  IMarker, MarkerType, FilterService, QueryService, SearchService,
  BaseComponent, TitleService, ThemeService, TranslateNotificationKeys, TestResultsQuery
} from '../core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { translate, translateWithInterpolateParams } from '../core/utils/translateServiceHelper';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss'],
  standalone: false
})
export class MappingComponent extends BaseComponent implements OnInit, OnDestroy {


  constructor(
    private queryService: QueryService,
    private filterService: FilterService,
    private searchService: SearchService,
    private titleService: TitleService,
    private themeService: ThemeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
  }

  markers: IMarker[] = [];

  ngOnInit(): void {
    this.themeService.enableMapTheme();
    this.titleService.updateTitleTranslateKey('Mapping.Title');

    this.loadMapData();

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.loadMapData();
    }));
  }

  private loadMapData() {
    // Cloning the current query to allow for local modifications
    const currentQuery = { ...this.queryService.getCurrentQuery() } as TestResultsQuery;

    this.googleAnalyticsService.logWithLabels('Search', 'Map', 'Search', 'filters', currentQuery);
    // Ensuring that search only occurs for 1 assay (test type)
    if (!currentQuery.testTypes || currentQuery.testTypes.length === 0 || currentQuery.testTypes.length > 1) {
      let assayCount: number;
      let assayChosen: string;
      const assayQuery = this.filterService.getAssaysUIQuery();
      if (!currentQuery.testTypes || currentQuery.testTypes.length === 0) {
        if (!assayQuery.items) {
          // Assays filter hasn't been initialized yet, so need wait to load map data until after
          const assaysInitializationSubscription = assayQuery.itemsLoaded$.subscribe(() => {
            assaysInitializationSubscription.unsubscribe();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.loadMapData.bind(this)();
          });
          this.subscription.add(assaysInitializationSubscription);
          return;
        }
        assayCount = assayQuery.items.length;
        assayChosen = assayQuery.items[0].getLabel();
      } else if (currentQuery.testTypes.length > 1) {
        assayCount = currentQuery.testTypes.length;
        assayChosen = assayQuery.items[0].getLabel();
      }

      const toastTitle = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.MapLimitOneAssayTitle}`);
      const toastContent = translateWithInterpolateParams(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.MapLimitOneAssayDescription}`,
        { assayCount, assayChosen });
      this.toastr.info(toastContent, toastTitle);

      currentQuery.testTypes = [assayChosen];
    }

    // Ensuring that even if there are values for the following filters, they will NOT affect the map results
    delete currentQuery.operator;
    delete currentQuery.zipCode;
    delete currentQuery.serialNumber;

    this.subscription.add(this.searchService.getMapQuery(currentQuery).subscribe((dataByTest) => {
      this.markers.length = 0;
      if (!dataByTest || Object.keys(dataByTest).length === 0) {
        this.searchService.searchResultsCompletedSubject.next(0);
      } else {
        const data = dataByTest[Object.keys(dataByTest)[0]];

        this.searchService.searchResultsCompletedSubject.next(data.totalTests);
        if (data.markers) {
          data.markers.forEach(m => {
            if (m) {

              const countyMarker: IMarker = {
                lat: m.latitude ? m.latitude : 0,
                lng: m.longitude ? m.longitude : 0,
                descriptions: [`<b>${m.county}</b>: ${m.numberTests} tests with ${m.percentPositive ? m.percentPositive : 0}% (${m.numberPositives ? m.numberPositives : 0}) positive`],
                type: MarkerType.County,
                testsPercentage: m.percentPositive,
                testsNumber: m.numberTests
              };
              if (m.myFacilityResult) {
                countyMarker.descriptions.push(`<b>My Facilities</b>: ${m.myFacilityResult}`);
              }
              this.markers.push(countyMarker);
            }
          });
        }

        if (data.facilityMarkers) {
          data.facilityMarkers.forEach(m => {
            if (m) {

              if (m.facilityResults && m.facilityResults.length > 0) {
                m.facilityResults.forEach(r => {
                  if (r) {
                    const facilityMarker: IMarker = {
                      lat: r.latitude ? r.latitude : 0,
                      lng: r.longitude ? r.longitude : 0,
                      descriptions: r.facilityDescriptions,
                      type: MarkerType.Facility,
                    };
                    this.markers.push(facilityMarker);
                  }
                });
              }
            }
          });
        }
      }

      // This is here to have the map component retrigger on the property changing,
      //  rather than having it watch to see if the array changes
      this.markers = this.markers.slice();
    }));
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

}
