import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestResult_PatientsByAssayRow } from '../../../services/search.service.testresult.models';
import { InitDataSelectionLists, TestType } from '../../../services/metadata.service.models';
import { MetadataService } from '../../../services/metadata.service';
import { BusyService } from '../../../services/busy.service';

@Component({
  selector: 'core-patients-assay',
  templateUrl: './patients-assay.component.html',
  styleUrls: ['./patients-assay.component.scss'],
  standalone: false
})
export class PatientsAssayComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_PatientsByAssayRow[];

  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: Record<string, unknown>;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<unknown>;

  testTypes: TestType[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    translateService: TranslateService,
    elementRef: ElementRef<HTMLElement>,
    busyService: BusyService,
    private metadataService: MetadataService) {
    super(changeDetectorRef, translateService, 'PatientsByAssay', busyService, elementRef);

    this.showChart = false;
  }

  ngOnInit(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.testTypes = initData.TestTypes;

      if (this.data) {
        this.setData(this.data);
      }
    }));

    this.chartType = 'ColumnChart';
    this.chartOptions = {
      legend: 'none',
      bar: { groupWidth: '75%' },
      isStacked: true,
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1,
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 250, top: 40, bottom: 200 }
    };
    this.chartColumnNames = ['ColumnName', this.getText('PatientCount'), { role: 'style' }];
    this.chartData = [[]];
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_PatientsByAssayRow[]): void {
    this.beginSetData();
    this.data = data;

    if (!this.testTypes) {
      return;
    }

    const convData: (string | number)[][] = [];
    data.forEach((row) => {
      const testType = this.testTypes.find(t => t.testName === row.testName);
      convData.push([row.testName, row.testCount, testType ? testType.color : '#98999b']);
    });

    this.chartData = convData;

    this.showChart = true;
  }
}
