<div class="assay-container">
  <div>
    <mat-form-field class="search-field">
      <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
      <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
        matTooltip="{{ translationBaseKey + '.Search' | translate }}">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <div class="top-right-buttons">
      <button mat-icon-button class="refresh-button" (click)="loadData()"
        matTooltip="{{ translationBaseKey + '.Refresh' | translate }}">
        <mat-icon>refresh</mat-icon>
      </button>

      <button mat-button class="add-new-button" [disabled]="!enableAdd" (click)="createNew()"
        matTooltip="{{ translationBaseKey + '.AddNewAssay' | translate }}" [matTooltipDisabled]="!enableAdd">
        <mat-icon class="icon-button-override">add</mat-icon>{{ translationBaseKey + '.AddNew' | translate }}
      </button>
    </div>

  </div>

  <div class="assay-list">

    <mat-table class="mat-elevation-z8" [dataSource]="currentDataSource">

      <ng-container matColumnDef="assaysname">
        <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.AssaysNameColumn' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let assays">{{assays.testName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="loincCode">
        <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.LIONCCodeColumn' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let assays">{{assays.loincCode.code}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="assayType">
        <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.AssayTypeColumn' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let assays">{{assays.assayType.code}}</mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>

      <mat-row class="assay-row" *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)">
      </mat-row>

    </mat-table>
  </div>

  <mat-paginator class="paginator" [length]="dataSource?.length" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>
</div>
