import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QuidelCoreModule, AngularMaterialModule, ReportsCoreModule } from '../core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ReportPatientAssayComponent } from './report-patient-assay/report-patient-assay.component';
import { ReportPatientFacilityResultComponent } from './report-patient-facility-result/report-patient-facility-result.component';
import { ReportPatientFacilityAssayComponent } from './report-patient-facility-assay/report-patient-facility-assay.component';
import { ReportPatientRunDateComponent } from './report-patient-run-date/report-patient-run-date.component';
import { ReportPatientResultComponent } from './report-patient-result/report-patient-result.component';
import { ReportTestVolumeTypeComponent } from './report-test-volume-type/report-test-volume-type.component';
import { ReportPercentPositiveRunDateComponent } from './report-percent-positive-run-date/report-percent-positive-run-date.component';
import { ReportPatientResultTrendsComponent } from './report-patient-result-trends/report-patient-result-trends.component';
import { ReportQualityControlComponent } from './report-quality-control/report-quality-control.component';
import { ReportPatientCoInfectionComponent } from './report-patient-coinfection/report-patient-coinfection.component';

@NgModule({
  declarations: [ReportPatientRunDateComponent,
    ReportPatientFacilityAssayComponent,
    ReportPatientFacilityResultComponent,
    ReportPatientAssayComponent,
    ReportPatientResultComponent,
    ReportTestVolumeTypeComponent,
    ReportPercentPositiveRunDateComponent,
    ReportPatientResultTrendsComponent,
    ReportQualityControlComponent,
    ReportPatientCoInfectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    QuidelCoreModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ReportsCoreModule
  ],
  exports: [
    ReportPatientRunDateComponent,
    ReportPatientFacilityAssayComponent,
    ReportPatientFacilityResultComponent,
    ReportPatientAssayComponent,
    ReportPatientResultComponent,
    ReportTestVolumeTypeComponent,
    ReportPercentPositiveRunDateComponent,
    ReportPatientResultTrendsComponent,
    ReportQualityControlComponent,
    ReportPatientCoInfectionComponent
  ]
})
export class ReportsModule { }
