<div class="orphan-search-container">
  <div class="justify-space-between">
    <div>
      <mat-form-field class="search-field">
        <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
        <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
          matTooltip="{{ translationBaseKey + '.Search' | translate }}">
          <mat-icon class="icon-button-override">search</mat-icon>
        </button>
      </mat-form-field>
      <span class="search-notification">{{ translationBaseKey + '.SearchInfo' | translate }}</span>
    </div>
    <div>
      <mat-icon class="export-button" (click)="exportFileDialog()"
        matTooltip="{{ translationBaseKey + '.ExportOrphans' | translate }}">grid_on</mat-icon>
    </div>
  </div>

  <div class="orphan-search">

    <mat-table #table class="mat-elevation-z8" [dataSource]="orphanResults?.results" matSort
      (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
      [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="serialNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.SerialNumber' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">{{orphan.instrumentSerialNumber}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="deviceAddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.DeviceAddress' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">{{orphan.originatingDeviceAddress}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zipCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ZipCode' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">{{orphan.zipCode}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityZipCode">
        <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.FacilityZipCode' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">{{orphan.facilityZipCode}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="organizationName">
        <mat-header-cell *matHeaderCellDef>{{ translationBaseKey + '.OrganizationName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">{{orphan.organizationName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="resultCount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ResultCount' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let orphan">
          {{orphan.resultCount}}
          <div *ngIf="orphan.zipCodeOverrides === orphan.zipCode"
            matTooltip="{{ translationBaseKey + '.ZipCodeOverride' | translate }}">
            <mat-icon class="icon-button-override">sync</mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="orphan-paginator" [length]="orphanResults?.totalResults" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>

</div>
