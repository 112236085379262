import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestResult_PatientsByRunDateRow } from '../../../services/search.service.testresult.models';
import { MetadataService } from '../../../services/metadata.service';
import { TestType, InitDataSelectionLists } from '../../../services/metadata.service.models';
import { BusyService } from '../../../services/busy.service';

@Component({
  selector: 'core-patients-run-date',
  templateUrl: './patients-run-date.component.html',
  styleUrls: ['./patients-run-date.component.scss'],
  standalone: false
})
export class PatientsRunDateComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_PatientsByRunDateRow[];
  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: Record<string, unknown>;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<unknown>;

  testTypes: TestType[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    translateService: TranslateService,
    elementRef: ElementRef<HTMLElement>,
    busyService: BusyService,
    private metadataService: MetadataService) {
    super(changeDetectorRef, translateService, 'PatientsByRunDate', busyService, elementRef);
    this.showChart = false;
  }

  ngOnInit(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.testTypes = initData.TestTypes;

      if (this.data) {
        this.setData(this.data);
      }
    }));

    this.chartType = 'ColumnChart';
    this.chartOptions = {
      legend: 'right',
      bar: { groupWidth: '75%' },
      isStacked: true,
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1,
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 250, top: 40, bottom: 200 }
    };
    this.chartData = [[]];
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_PatientsByRunDateRow[]): void {
    this.beginSetData();
    this.data = data;

    if (!this.testTypes) {
      return;
    }

    const columnNames = ['DataGroup'];
    const dateGroups = [];
    const convData: (string | number)[][] = [];
    const series = [];

    data.forEach((row) => {
      let dateGroupNdx = dateGroups.indexOf(row.dateGroup);
      if (dateGroupNdx < 0) {
        dateGroups.push(row.dateGroup);
        dateGroupNdx = dateGroups.length - 1;
        convData.push([row.dateGroup]);
      }

      let columnNameNdx = columnNames.indexOf(row.testName);
      if (columnNameNdx < 0) {
        columnNames.push(row.testName);
        columnNameNdx = columnNames.length - 1;

        const test = this.testTypes.find(t => t.testName === row.testName);
        series.push({ color: test ? test.color : '#98999b' });
      }

      const dataRow = convData[dateGroupNdx];
      while (dataRow.length < columnNameNdx - 1) {
        dataRow.push(0);
      }
      dataRow[columnNameNdx] = row.testCount;
      convData[dateGroupNdx] = dataRow;
    });

    // Fill in holes
    convData.forEach((dataRow) => {
      while (dataRow.length < columnNames.length) {
        dataRow.push(0);
      }
    });

    this.chartOptions.series = series;
    this.chartColumnNames = columnNames;
    this.chartData = convData;

    this.showChart = true;
  }
}
