<div #gridContainer>

  <mat-card appearance="outlined">

    <mat-card-header class="justify-space-between">
      <mat-card-title>
        <ng-container *ngIf="!newOrg">
          {{ translationBaseKey + '.EditOrg' | translate }}
        </ng-container>
        <ng-container *ngIf="newOrg">
          {{ translationBaseKey + '.AddOrg' | translate }}
        </ng-container>
      </mat-card-title>

      <button *ngIf="isSysAdmin" mat-button [disabled]="!enableAddFacility || newOrg" (click)="createNewFacility()"
        matTooltip="{{ translationBaseKey + '.AddNewFacility' | translate }}" [matTooltipDisabled]="!enableAddFacility">
        <mat-icon class="icon-button-override">add</mat-icon>{{ translationBaseKey + '.AddNewFacility' | translate }}
      </button>
    </mat-card-header>

    <mat-card-content>

      <form class="orgForm" [formGroup]="orgFormGroup" class="form-grid">

        <div class="general-section">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Name' | translate }}</mat-label>
            <input matInput formControlName="name" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Description' | translate }}</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.ContactName' | translate }}</mat-label>
            <input matInput formControlName="contactName" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.WorkPhone' | translate }}</mat-label>
            <input matInput formControlName="phone" required="true"
              [customErrors]="{ pattern: 'ValidationErrors.Phone' }" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.MobilePhone' | translate }}</mat-label>
            <input matInput formControlName="mobilePhone" [customErrors]="{ pattern: 'ValidationErrors.Phone' }" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Email' | translate }}</mat-label>
            <input matInput formControlName="email" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.CrmId' | translate }}</mat-label>
            <input matInput formControlName="crmId" />
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Distributor' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="distributorId" placeholder="Select a Distributor">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let distributor of distributors" [value]="distributor.organizationDistributorId">
                {{distributor.distributor}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="div-checkbox">
            <mat-checkbox formControlName="shareNational">
              <mat-label>{{ translationBaseKey + '.ShareNational' | translate }}</mat-label>
            </mat-checkbox>
          </div>

          <div class="div-checkbox">
            <mat-checkbox formControlName="shareState">
              <mat-label>{{ translationBaseKey + '.ShareState' | translate }}</mat-label>
            </mat-checkbox>
          </div>

          <div class="div-checkbox" *ngIf="canEditOrgBooleanSettings">
            <mat-checkbox formControlName="rejectPatient">
              <mat-label>{{ translationBaseKey + '.RejectPatient' | translate }}</mat-label>
            </mat-checkbox>
          </div>

          <div class="div-checkbox" *ngIf="canEditOrgBooleanSettings">
            <mat-checkbox formControlName="rejectStudyCodes">
              <mat-label>{{ translationBaseKey + '.RejectStudyCodes' | translate }}</mat-label>
            </mat-checkbox>
          </div>

          <div class="div-checkbox" *ngIf="canEditOrgBooleanSettings">
            <mat-checkbox formControlName="rejectOrderNumbers">
              <mat-label>{{ translationBaseKey + '.RejectOrderNumbers' | translate }}</mat-label>
            </mat-checkbox>
          </div>

          <div class="div-checkbox" *ngIf="canEditOrgBooleanSettings">
            <mat-checkbox formControlName="nationalMapSub">
              <mat-label>{{ translationBaseKey + '.NationalMapSub' | translate }}</mat-label>
            </mat-checkbox>
          </div>
        </div>

        <div class="address-section">
          <button mat-button [disabled]="!idbRecord" (click)="populateAddressFromIdb()" class="populate-address-button">
            <mat-icon class="icon-button-override">smart_button</mat-icon>{{ translationBaseKey +
            '.PopulateAddressFromIdb' | translate }}
          </button>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Country' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="countryId" required="true">
              <mat-option *ngFor="let country of countries" [value]="country.countryId">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address1' | translate }}</mat-label>
            <input matInput formControlName="address1" required="true" #addressField autocomplete="new-password">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address2' | translate }}</mat-label>
            <input matInput formControlName="address2">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address3' | translate }}</mat-label>
            <input matInput formControlName="address3">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.City' | translate }}</mat-label>
            <input matInput formControlName="city" required="true">
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.State' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="stateId" required="true">
              <mat-option *ngFor="let state of states" [value]="state.stateId">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.ZipCode' | translate }}</mat-label>
            <input matInput formControlName="zipCode" required="true">
            <button class="address-button" [class.verified]="addressVerified" *ngIf="smartyStreets" type="button"
              (click)="validateAddress()" matTooltip="{{ 'Verification.Address' | translate}}">
              <i class="material-icons">check</i>
            </button>
          </mat-form-field>
        </div>

      </form>

      <div class="fac-table" *ngIf="!newOrg">

        <mat-table class="mat-elevation-z8" [dataSource]="facilities?.results" matSort (matSortChange)="doSort($event)"
          matSortDisableClear="true" [matSortActive]="sortColumn" [matSortDirection]="sortMatDirection">

          <ng-container matColumnDef="facName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationFacilityTableKey + '.Name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let fac">{{fac.name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="facCountry">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationFacilityTableKey + '.Country' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let fac">{{fac.address?.country?.name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="facState">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationFacilityTableKey + '.State' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let fac">{{fac.address?.state?.name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="facCity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationFacilityTableKey + '.City' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let fac">{{fac.address?.city}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

        </mat-table>
      </div>
      <mat-paginator class="facility-paginator" [length]="facilities?.totalResults" [pageSize]="pageSize"
        [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
      </mat-paginator>

      <button mat-button [disabled]="orgFormGroup.invalid || orgFormGroup.disabled" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveOrg' | translate }}"
        [matTooltipDisabled]="orgFormGroup.invalid || orgFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelOrg' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

      <button mat-button *ngIf="!isOrgAdmin && !newOrg" [disabled]="orgFormGroup.disabled" (click)="onDelete()"
        matTooltip="{{ translationBaseKey + '.DeleteOrg' | translate }}" [matTooltipDisabled]="orgFormGroup.disabled">
        <mat-icon class="icon-button-override">delete</mat-icon>
        {{ translationBaseKey + '.Delete' | translate }}
      </button>

      <button mat-button *ngIf="!isOrgAdmin" [disabled]="orgFormGroup.disabled || newOrg" class="float-right"
        (click)="onShowUpdateTestResultsDialog(updateTestResultsTemplate)"
        matTooltip="{{ translationBaseKey + '.UpdateTestResults' | translate }}"
        [matTooltipDisabled]="orgFormGroup.disabled || newOrg">
        <mat-icon class="icon-button-override">update</mat-icon>
        {{ translationBaseKey + '.UpdateTestResults' | translate }}
      </button>
    </mat-card-content>
  </mat-card>

</div>

<ng-template #addressSuggestions let-addressSuggestions="addressSuggestions">
  <div class="suggestion-container">
    <cdk-virtual-scroll-viewport itemSize="48" class="address-virtual-list" [style.height.px]="256">
      <mat-option *cdkVirtualFor="let obj of addressSuggestions" [value]="obj" (click)="onAddressSuggestionChosen(obj)">
        {{obj.text}}
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>

<ng-template #updateTestResultsTemplate let-data>
  <h1 mat-dialog-title>{{ translationBaseKey + '.UpdateTestResults' | translate }}</h1>
  <div mat-dialog-content class="update-test-results-content">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" required="true" #resultDate [value]="data.defaultStartDate">
      <mat-datepicker-toggle matSuffix [for]="picker">
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsEndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" required="true" #resultEndDate [value]="data.defaultEndDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker">
      </mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsType' | translate }}</mat-label>
      <mat-select matNativeControl required="true" #dateType>
        <mat-option [value]="0">Storage Date</mat-option>
        <mat-option [value]="1">Run Date</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ translationBaseKey + '.UpdateTestResultsTimezoneNotification' | translate }}</mat-label>
  </div>
  <div mat-dialog-actions>
    <button mat-button
      [mat-dialog-close]="{ startDate: resultDate.value, endDate: resultEndDate.value, type: dateType.value }"
      [disabled]="!resultDate.value ||!resultEndDate.value || !IsDateRangeValid(resultDate.value, resultEndDate.value) || dateType.value === undefined">{{
      'ModalConfirmationDialog.YesButton' | translate }}</button>
    <button mat-button mat-dialog-close="false" cdkFocusInitial>{{ 'ModalConfirmationDialog.NoButton' | translate
      }}</button>
  </div>
</ng-template>
