<div #gridContainer class="crm-grid-container">

  <mat-card appearance="outlined">

    <mat-card-header>
      <mat-card-title>
        <ng-container *ngIf="!newDevice">
          {{ translationBaseKey + '.EditDevice' | translate }}
        </ng-container>
        <ng-container *ngIf="newDevice">
          {{ translationBaseKey + '.AddDevice' | translate }}
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <form class="deviceForm" [formGroup]="deviceFormGroup">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.DeviceType' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="deviceTypeId" required="true">
            <mat-option *ngFor="let type of deviceTypes" [value]="type.pairedDeviceTypeId">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.MacId' | translate }}</mat-label>
          <input matInput formControlName="macAddress" required="true" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.DeviceId' | translate }}</mat-label>
          <input matInput formControlName="deviceAddress" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Description' | translate }}</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Organization' | translate }}</mat-label>
          <core-virtual-select formControlName="organizationId" required="true" itemSize="42" [dataSource]="orgs"
            valueField="organizationId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{ translationBaseKey + '.Facility' | translate }}</mat-label>
          <core-virtual-select formControlName="facilityId" required="true" itemSize="42" [dataSource]="facilities"
            valueField="facilityId" displayField="name" showSearch="true">
          </core-virtual-select>
        </mat-form-field>
        <div class="facilityInfo">
          <div>{{facility?.address?.streetAddress1}}</div>
          <div *ngIf="facility?.address?.streetAddress2">{{facility?.address?.streetAddress2}}</div>
          <div *ngIf="facility?.address?.streetAddress3">{{facility?.address?.streetAddress3}}</div>
          <div>{{facility?.address?.city}}, {{facility?.address?.state?.abbreviation}} {{facility?.address?.zipCode}}
          </div>
          <div>{{facility?.address?.county?.name}}</div>
          <div>{{facility?.address?.country?.name}}</div>
        </div>
      </form>

      <div class="instrument-table" *ngIf="instruments">

        <mat-table class="mat-elevation-z8" [dataSource]="instruments" matSort>

          <ng-container matColumnDef="serialNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentTableKey + '.SerialNumber' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let instrument">{{instrument.serialNumber}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
            (click)="onInstrumentRowSelect(row)"></mat-row>

        </mat-table>
      </div>

      <button mat-button [disabled]="deviceFormGroup.invalid || deviceFormGroup.disabled" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveDevice' | translate }}"
        [matTooltipDisabled]="deviceFormGroup.invalid || deviceFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelDevice' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

      <button mat-button *ngIf="!newDevice" [disabled]="deviceFormGroup.disabled" (click)="onDelete()"
        matTooltip="{{ translationBaseKey + '.DeleteDevice' | translate }}"
        [matTooltipDisabled]="deviceFormGroup.disabled">
        <mat-icon class="icon-button-override">delete</mat-icon>
        {{ translationBaseKey + '.Delete' | translate }}
      </button>


    </mat-card-content>
  </mat-card>

</div>
