import { IGetLabel, FilterElement, IGetId, IEquatable, UIQueryHierarchyFilterBase } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';
import { InitDataSelectionLists, Organization, Facility, FilterStateOrgFac } from '../../../services/metadata.service.models';
import { firstValueFrom } from 'rxjs';

export class OrgItem implements IGetLabel, IGetId, IEquatable<OrgItem> {

  private org: Organization;

  constructor(org: Organization) {
    this.org = org;
  }

  getLabel(): string {
    return this.org.name;
  }

  getId(): number {
    return this.org.organizationId;
  }

  equals(other: OrgItem): boolean {
    if (!other) {
      return false;
    }

    return this.org.organizationId === other.org.organizationId;
  }
}

export class FacItem implements IGetLabel, IGetId, IEquatable<FacItem> {

  private facility: Facility;

  constructor(facility: Facility) {
    this.facility = facility;
  }

  getLabel(): string {
    return this.facility.name;
  }

  getId(): number {
    return this.facility.facilityId;
  }

  equals(other: FacItem): boolean {
    if (!other) {
      return false;
    }

    return this.facility.facilityId === other.facility.facilityId;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrgsFacilitiesUIQuery extends UIQueryHierarchyFilterBase<OrgItem, FacItem> implements OnDestroy {

  private defaultFacilitiesMap: Map<number, FilterElement<FacItem>[]> = new Map<number, FilterElement<FacItem>[]>();

  constructor(private metadataService: MetadataService) {
    super();

    this.initialize();
  }

  protected initialize(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((d: InitDataSelectionLists) => {

      if (d.Organizations) {
        const selectedIds = this.rootItems ? this.rootItems.filter(i => i.isSelected).map(e => e.item.getId()) : [];

        const items: FilterElement<OrgItem>[] = [];
        d.Organizations.forEach(o => {
          const element = FilterElement.Build(new OrgItem(o));
          element.isSelected = selectedIds && selectedIds.length > 0 ? selectedIds.includes(element.item.getId()) : false;
          items.push(element);
        });

        this.rootItems = items;
      }

      this.rootItemsLoaded.next(this.rootItems);
    }));

  }

  public refreshData(): void {
    setTimeout(() => this.initialize());
  }

  public isDirty(): boolean {
    const selectedOrganizations = this.getSelectedOrganizations();

    if (selectedOrganizations && selectedOrganizations.length > 0) {
      return true;
    }

    return false;
  }

  public reset(): void {
    this.resetInternal();
    this.childItemsMap.forEach(facilitiesFilterElements => facilitiesFilterElements.forEach(f => f.resetSelection()));
  }

  public async getFacilities(orgs: FilterElement<OrgItem>[]): Promise<FilterElement<FacItem>[]> {

    const cachedFacilities: FilterElement<FacItem>[] = [];
    const missingOrgFacilityIds: number[] = [];
    orgs.forEach(o => {
      const facilities = this.childItemsMap.get(o.item.getId());
      if (facilities) {
        Array.prototype.push.apply(cachedFacilities, facilities);
      } else {
        missingOrgFacilityIds.push(o.item.getId());
      }
    });

    if (missingOrgFacilityIds.length > 0) {
      const facilitiesSelection = await firstValueFrom(this.metadataService.getFacilitySelectionListByMultipleOrgs(missingOrgFacilityIds));

      console.debug(facilitiesSelection);

      if (facilitiesSelection) {
        missingOrgFacilityIds.forEach(orgId => {
          const facilities = facilitiesSelection[orgId];
          const facilitiesFilter = facilities.map(f => FilterElement.Build(new FacItem(f)));
          this.childItemsMap.set(orgId, facilitiesFilter);
          Array.prototype.push.apply(cachedFacilities, facilitiesFilter);
        });
      }
    }

    return Promise.resolve(cachedFacilities);
  }

  public async getFacilitiesByOrg(org: FilterElement<OrgItem>): Promise<FilterElement<FacItem>[]> {

    const facilitiesEntries = this.childItemsMap.get(org.item.getId());
    if (!facilitiesEntries) {
      const facilities = await firstValueFrom(this.metadataService.getFacSelectionListByOrg(org.item.getId()));

      const facsFilter: FilterElement<FacItem>[] = [];
      if (facilities) {
        facilities.forEach(f => {
          const fac: FilterElement<FacItem> = FilterElement.Build(new FacItem(f));
          facsFilter.push(fac);
        });
      }

      this.childItemsMap.set(org.item.getId(), facsFilter);
    }

    return Promise.resolve(this.childItemsMap.get(org.item.getId()));
  }

  public getSelectedOrganizations(): FilterElement<OrgItem>[] {
    return this.getSelectedRootItems();
  }

  public getCachedFacilities(org: FilterElement<OrgItem>): FilterElement<FacItem>[] {
    return this.childItemsMap.get(org.item.getId());
  }

  public getSelectedCachedFacilities(org: FilterElement<OrgItem>): FilterElement<FacItem>[] {
    const cachedFacilities = this.getCachedFacilities(org);
    if (cachedFacilities) {
      return cachedFacilities.filter(e => e.isSelected);
    }

    return undefined;
  }

  public async getSelectedFacilities(): Promise<FilterElement<FacItem>[]> {
    const selectedOrgs = this.getSelectedOrganizations();
    if (!selectedOrgs) {
      return undefined;
    }

    const facilities: FilterElement<FacItem>[] = await this.getFacilities(selectedOrgs);
    const selectedFacilities = facilities.filter(c => c.isSelected);
    if (selectedFacilities.length === 0) {
      return undefined;
    }

    return selectedFacilities;
  }

  setDefaultState(orgsFacs: FilterStateOrgFac[]): void {
    if (orgsFacs) {
      orgsFacs.forEach(async o => {
        const boundFunction = this.getFacilitiesByOrg.bind(this) as (root: FilterElement<OrgItem>) => Promise<FilterElement<FacItem>[]>;
        await this.setDefaultStateInternal(o.orgId, boundFunction, o.facilitiesIds);
      });
    }
  }

  public serialize(): FilterStateOrgFac[] {
    const selectedOrganizations = this.getSelectedOrganizations();
    if (!selectedOrganizations || selectedOrganizations.length === 0) {
      return undefined;
    }

    const orgFacs: FilterStateOrgFac[] = [];

    selectedOrganizations.forEach(org => {
      const selectedFacs = this.getSelectedCachedFacilities(org);
      if (selectedFacs) {
        orgFacs.push({ orgId: org.item.getId(), facilitiesIds: selectedFacs.map(e => e.item.getId()) });
      }
      else {
        orgFacs.push({ orgId: org.item.getId(), facilitiesIds: null });
      }
    });

    return orgFacs;
  }
}
