import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  template: '<p class="help is-danger" [class.hide]="hide">{{textInternal}}</p>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormControlErrorComponent {
  textInternal: string;
  hide = true;

  @Input() set text(value: string) {
    if (value !== this.textInternal) {
      this.textInternal = value;
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) { }
}
