import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule, QuidelCoreModule } from '../../core';
import { TranslateModule } from '@ngx-translate/core';
import { AssaysListComponent } from './assays-list/assays-list.component';
import { AssaysEditComponent } from './assays-edit/assays-edit.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [AssaysListComponent, AssaysEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
  ],
  exports: [
    AssaysListComponent,
    AssaysEditComponent
  ]
})
export class AssaysModule { }
