<div class="user-search-container">
  <div class="search-container">
    <mat-form-field class="search-field">
      <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
      <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
        matTooltip="{{ translationBaseKey + '.Search' | translate }}">
        <mat-icon class="icon-button-override">search</mat-icon>
      </button>
    </mat-form-field>
    <span class="search-notification">{{ translationBaseKey + '.SearchInfo' | translate }}</span>
  </div>
  <div class="user-search-list">
    <mat-table class="mat-elevation-z8" [dataSource]="userResults?.results" matSort (matSortChange)="doSort($event)"
      matSortDisableClear="true" [matSortActive]="sortColumn" [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="displayname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.displayName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.UserName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.userName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="organizationname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrgName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.organization?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.FacName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.facility?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createddate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.DateCreated' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.b2cCreatedDate | date : runDateFormat : 'UTC' }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="user-search-paginator" [length]="userResults?.totalResults" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>
</div>
