<div class="device-search-container">
  <div>
    <mat-form-field class="search-field">
      <input matInput type="text" placeholder="Search" [(ngModel)]="value" (keyup.enter)="doSearch()">
      <button matSuffix mat-icon-button aria-label="Search" (click)="doSearch()"
        matTooltip="{{ translationBaseKey + '.Search' | translate }}">
        <mat-icon class="icon-button-override">search</mat-icon>
      </button>
    </mat-form-field>
    <span class="search-notification">{{ translationBaseKey + '.SearchInfo' | translate }}</span>
    <div class="top-right-buttons">
      <button mat-button class="add-new-button" [disabled]="!hasCreatePermission" (click)="createNew()"
        matTooltip="{{ translationBaseKey + '.AddNewPairedDevice' | translate }}"
        [matTooltipDisabled]="!hasCreatePermission">
        <mat-icon class="icon-button-override">add</mat-icon>{{ translationBaseKey + '.AddNew' | translate }}
      </button>
      <button mat-icon-button class="device-export-csv">
        <mat-icon color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
          matTooltip="{{'PairedDeviceSearch.ExportCSVTooltip' | translate}}"
          (click)="exportFileDialog(ExportFileType.CSV, value)">grid_on</mat-icon>
      </button>
    </div>
  </div>

  <div class="device-search">

    <mat-table #table class="mat-elevation-z8" [dataSource]="deviceResults?.results" matSort
      (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
      [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="orgname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrgName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let device">{{device.facility?.organization?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.FacilityName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let device">{{device.facility?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="deviceaddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.DeviceAddress' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let device">{{device.deviceAddress}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="macaddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.MacAddress' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let device">{{device.macAddress}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="device-paginator" [length]="deviceResults?.totalResults" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>

</div>
