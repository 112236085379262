import { Directive, Self, OnInit, Inject, Host, Optional, ComponentRef, ViewContainerRef, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FORM_ERRORS } from './form-errors-models';
import { Observable, EMPTY, merge } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { FormControlErrorComponent } from './form-control-error.component';
import { FormControlErrorContainerDirective } from './form-control-error-container.directive';
import { TranslatePipe } from '@ngx-translate/core';

@UntilDestroy()
@Directive({
  selector: '[formControl], [formControlName]',
  providers: [TranslatePipe],
  standalone: false
})
export class FormControlErrorsDirective implements OnInit {
  ref: ComponentRef<FormControlErrorComponent>;
  container: ViewContainerRef;
  errorContainer: FormControlErrorContainerDirective;
  submit$: Observable<Event>;
  @Input() customErrors: Record<string, string> = {};
  @Input() noCustomValidation: boolean;

  constructor(
    vcr: ViewContainerRef,
    @Optional() controlErrorContainer: FormControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors: Record<string, (error: string) => string>,
    @Optional() @Host() private form: FormSubmitDirective,
    @Self() private controlDir: NgControl,
    private translatePipe: TranslatePipe) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.errorContainer = controlErrorContainer;
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
  }

  ngOnInit(): void {
    merge(
      this.submit$,
      this.control.valueChanges
    )
      .pipe(untilDestroyed(this)).subscribe((v) => {
        const controlErrors = this.control.errors as Record<string, string>;

        if (controlErrors && !this.noCustomValidation) {
          try {
            const firstKey = Object.keys(controlErrors)[0];
            const getError = this.errors[firstKey];
            const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);

            this.setError(this.translatePipe.transform(text) as string);
          } catch {
            // intentionally ignoring error
          }
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  get control(): AbstractControl {
    return this.controlDir.control;
  }

  setError(text: string): void {
    if (this.noCustomValidation) {
      return;
    }

    const container = this.errorContainer ? this.errorContainer.vcr : this.container;

    if (!this.ref) {
      this.ref = container.createComponent(FormControlErrorComponent);
    }

    this.ref.instance.text = text;
  }
}
