import { FavoriteItem } from '../../../filter-custom-favorites.model';
import { UserFavoriteSchedule } from '../../../../../../services/metadata.service.models';

export enum FavoriteModalTypes {
    Add = 'Add',
    Edit = 'Edit',
    Readonly = 'Readonly'
}

export interface FavoriteModalData {
    mode: FavoriteModalTypes;
    favorite?: FavoriteItem;
    schedule?: UserFavoriteSchedule;
}

export enum EmailSubscriptionScheduleTypes {
    None = 'None',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}


export const ScheduleTypeKey = 'FavortiesAddEditDialog';

export const EmailSubscriptionScheduleTypeDescriptions = new Map<string, string>([
  [EmailSubscriptionScheduleTypes.None, ScheduleTypeKey + '.ScheduleTypeNone'],
  [EmailSubscriptionScheduleTypes.Daily, ScheduleTypeKey + '.ScheduleTypeDaily'],
  [EmailSubscriptionScheduleTypes.Weekly, ScheduleTypeKey + '.ScheduleTypeWeekly'],
  [EmailSubscriptionScheduleTypes.Monthly, ScheduleTypeKey + '.ScheduleTypeMonthly'],
]);

export class EmailSubscription {
  id: number;
  email: string;
  hovered: boolean;
}
