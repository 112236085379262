// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, OrganizationsNavigationKeys } from '../../core';
import { OrgSearchComponent } from './org-search/org-search.component';
import { OrgEditComponent } from './org-edit/org-edit.component';

export const routes: Routes = [
  {
    path: OrganizationsNavigationKeys.Search, component: OrgSearchComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: OrganizationsNavigationKeys.EditWithIdArg, component: OrgEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class OrganizationRoutingModule { }
