<div mat-dialog-content>
  <div class="section-header">
    {{ 'TestResultDetails.CardResultDetailTitle' | translate }}
  </div>
  <div class="loinc-code">
    <span matTooltip="{{ 'TestResultDetails.CodeDisclaimer' | translate }}">
      {{ 'TestResultDetails.LOINCCodeField' | translate }}: {{ test?.testType?.loincCode?.code }}
      <span class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
    </span>
  </div>

  <mat-table class="mat-elevation-z8 analytes-table" [dataSource]="tableDataSource">

    <ng-container matColumnDef="analyte">
      <mat-header-cell *matHeaderCellDef>{{ 'TestResultDetails.AnalyteNameColumn' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let r">{{r.analyte?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="result">
      <mat-header-cell *matHeaderCellDef>{{'TestResultDetails.ResultColumn' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let r">{{r.result}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="snomedCode">
      <mat-header-cell *matHeaderCellDef>
        <span matTooltip="{{ 'TestResultDetails.CodeDisclaimer' | translate }}">
          {{ 'TestResultDetails.SNOMEDCodeColumn' | translate }}
          <span class="mat-badge-small mat-badge-content mat-badge-active manual-mat-badge-small-override">i</span>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let r">{{r.snomedCode?.code}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="signalToCutOff" *ngIf="accessService.hasAccess(FieldAccess.SignalToCutOff)">
      <mat-header-cell *matHeaderCellDef>{{ 'TestResultDetails.SignalToCutOffColumn' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let r">{{r.signalToCutoff}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayColumns">
    </mat-row>

  </mat-table>

  <div class="section-header">{{ 'TestResultDetails.TestDetailCardTitle' | translate }}</div>
  <div class="section">
    <div class="section-cell-small">
      <div *ngIf="accessService.hasAccess(FieldAccess.PatientId)">
        {{ 'TestResultDetails.PatientIdField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.PatientId)">{{ testInformation?.patient?.patientId }}</div>

      <div>{{ 'TestResultDetails.PatientAgeField' | translate }}:</div>
      <div>
        <span *ngIf="!testInformation?.patient?.ageLessThan && !testInformation?.patient?.ageGreaterThan">{{
          testInformation?.patient?.age }}</span>
        <span *ngIf="testInformation?.patient?.ageLessThan">&lt;{{ testInformation?.patient?.ageLessThan }}</span>
        <span *ngIf="testInformation?.patient?.ageGreaterThan">&gt;{{ testInformation?.patient?.ageGreaterThan }}</span>
      </div>

      <div>{{ 'TestResultDetails.PatientStatusField' | translate }}:</div>
      <div>{{ testInformation?.patient?.status }}</div>

      <div>{{ 'TestResultDetails.StudyCodeA' | translate }}:</div>
      <div>{{ studyCodeA }}</div>

      <div>{{ 'TestResultDetails.StudyCodeB' | translate }}:</div>
      <div>{{ studyCodeB }}</div>

      <div>{{ 'TestResultDetails.StudyCodeC' | translate }}:</div>
      <div>{{ studyCodeC }}</div>

      <div>{{ 'TestResultDetails.StudyCodeD' | translate }}:</div>
      <div>{{ studyCodeD }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.KitLotNumber)">
        {{ 'TestResultDetails.KitLotNumberField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.KitLotNumber)">{{ testInformation?.kitLotNumber }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.OrderNumber)">{{ 'TestResultDetails.OrderNumber' | translate }}:
      </div>
      <div *ngIf="accessService.hasAccess(FieldAccess.OrderNumber)">{{ testInformation?.orderNumber }}</div>
    </div>

    <div class="section-cell-large">
      <div>{{ 'TestResultDetails.CassetteLotNumberField' | translate }}:</div>
      <div>{{ testInformation?.cassetteLotNumber }}</div>
      <div>{{ 'TestResultDetails.CassetteSerialNumberField' | translate }}:</div>
      <div>{{ testInformation?.cassetteNumber }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.TestFlag)">{{ 'TestResultDetails.TestFlagField' | translate }}:
      </div>
      <div *ngIf="accessService.hasAccess(FieldAccess.TestFlag)">{{ testInformation?.testFlag }}</div>

      <div>{{ 'TestResultDetails.AssayTypeField' | translate }}:</div>
      <div>{{ test?.testType?.assayType?.code }}</div>

      <div>{{ 'TestResultDetails.RunDateField' | translate }}:</div>
      <div>{{ testInformation?.testCompletionDateUtc || '' | date: runDateFormat : 'UTC' }}</div>

      <div>{{ 'TestResultDetails.TransmissionDateField' | translate }}:</div>
      <div>{{ data.transmissionDateUtc | date: runDateFormat : 'UTC' }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.StorageDateAndTime)">
        {{ 'TestResultDetails.StorageDateAndTimeField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.StorageDateAndTime)">{{ data.storageDateUtc | date:
        storageDateFormat }}</div>
    </div>
  </div>

  <div
    *ngIf="accessService.hasAccess(FieldAccess.CellInformation) && (data?.cellInformation?.plmCommandResponse || data?.cellInformation?.towerIDCommandResponse)"
    class="section-header">{{ 'TestResultDetails.CellInformationDetailCardTitle' | translate }}</div>

  <div
    *ngIf="accessService.hasAccess(FieldAccess.CellInformation) && (data?.cellInformation?.plmCommandResponse || data?.cellInformation?.towerIDCommandResponse)">
    <div class="section-cell-small">
      <div>{{ 'TestResultDetails.PLMResponse' | translate }}:</div>
      <div>{{ data?.cellInformation?.plmCommandResponse }}</div>

      <div>{{ 'TestResultDetails.TowerIDResponse' | translate }}:</div>
      <div>{{ data?.cellInformation?.towerIDCommandResponse }}</div>
    </div>
  </div>

  <div class="section-header">{{ 'TestResultDetails.InstrumentDetailCardTitle' | translate }}</div>

  <div class="section">
    <div class="section-cell-medium">
      <div>{{ 'TestResultDetails.InstrumentTypeField' | translate }}:</div>
      <div>{{ data?.instrument?.type?.name }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.SerialNumber)">
        {{ 'TestResultDetails.SerialNumberField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.SerialNumber)">{{ data?.instrument?.serialNumber }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.RegistrationDate)">
        {{ 'TestResultDetails.RegistrationDateField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.RegistrationDate)">{{ data?.instrument?.registrationDate | date:
        storageDateFormat }}</div>

      <div>{{ 'TestResultDetails.OrganizationField' | translate }}:</div>
      <div>{{ data?.instrument?.facility?.organization?.name }}</div>

      <div>{{ 'TestResultDetails.CommentField' | translate }}:</div>
      <div>{{ data?.instrument?.comment }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.PairedDeviceType)">
        {{ 'TestResultDetails.PairedDeviceTypeField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.PairedDeviceType)">{{ data?.instrument?.pairedDevice?.type }}
      </div>

      <div *ngIf="accessService.hasAccess(FieldAccess.PairedDeviceId)">
        {{ 'TestResultDetails.PairedDeviceIDField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.PairedDeviceId)">
        {{ data?.instrument?.pairedDevice?.deviceAddress }}</div>
    </div>

    <div class="section-cell-medium">
      <div *ngIf="accessService.hasAccess(FieldAccess.FirmwareRevision)">
        {{ 'TestResultDetails.FirmwareRevisionField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.FirmwareRevision)">{{ data?.instrument?.firmwareRevision }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.ContactName)">
        {{ 'TestResultDetails.ContactNameField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.ContactName)">{{ data?.instrument?.contact?.name }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.ContactEmail)">
        {{ 'TestResultDetails.ContactEmailField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.ContactEmail)">{{ data?.instrument?.contact?.email }}</div>

      <div *ngIf="accessService.hasAccess(FieldAccess.ContactPhoneNumber)">
        {{ 'TestResultDetails.ContactPhoneNumberField' | translate }}:</div>
      <div *ngIf="accessService.hasAccess(FieldAccess.ContactPhoneNumber)">{{ data?.instrument?.contact?.phone }}</div>
    </div>
  </div>

  <div class="section-header">{{ 'TestResultDetails.FacilityDetailCardField' | translate }}</div>
  <div class="section-cell-small">
    <div>{{ 'TestResultDetails.FacilityNameField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.name }}</div>

    <div>{{ 'TestResultDetails.CountryField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.country?.name }}</div>

    <div>{{ 'TestResultDetails.StateField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.state?.name }}</div>

    <div>{{ 'TestResultDetails.CityField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.city }}</div>

    <div>{{ 'TestResultDetails.AddressField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.streetAddress1 }}</div>

    <div>
      <!--Empty on purpose to take up the "key" cell-->
    </div>
    <div>{{ data?.instrument?.facility?.address?.streetAddress2 }}</div>

    <div>
      <!--Empty on purpose to take up the "key" cell-->
    </div>
    <div>{{ data?.instrument?.facility?.address?.streetAddress3 }}</div>

    <div>{{ 'TestResultDetails.ZipCodeField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.zipCode }}</div>

    <div>{{ 'TestResultDetails.CountyField' | translate }}:</div>
    <div>{{ data?.instrument?.facility?.address?.county?.name }}</div>

    <div *ngIf="accessService.hasAccess(FieldAccess.OrganizationDistributor)">{{
      'TestResultDetails.OrganizationDistributorField' | translate }}:</div>
    <div *ngIf="accessService.hasAccess(FieldAccess.OrganizationDistributor)">{{
      data?.instrument?.facility?.organization?.distributor }}</div>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDoneClick()">Done</button>
</div>
