import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSearchComponent } from './user-search/user-search.component';
import { UserUnapprovedComponent } from './user-unapproved/user-unapproved.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuidelCoreModule, AngularMaterialModule } from '../../core';
import { UserEditComponent } from './user-edit/user-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [UserSearchComponent, UserUnapprovedComponent, UserEditComponent],
  imports: [
    CommonModule,
    TranslateModule,
    QuidelCoreModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule ,
    NgSelectModule
  ],
  exports: [
    UserSearchComponent,
    UserUnapprovedComponent,
    UserEditComponent
  ]
})
export class UserModule { }
