<div class="unapproved-users-container">
  <div>
    <button mat-icon-button class="refresh-button" (click)="loadData()"
      matTooltip="{{ translationBaseKey + '.Refresh' | translate }}">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div class="unapproved-users-list">
    <mat-table class="mat-elevation-z8" [dataSource]="sortedUsers" matSort matSortDisableClear="true"
      (matSortChange)="doSort($event)" [matSortActive]="sortColumn" [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="displayname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.displayName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.UserName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.userName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="organizationname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrgRequested' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.requestedOrganization}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.FacRequested' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.requestedFacility}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createddate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.DateCreated' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.b2cCreatedDate | date : runDateFormat: 'UTC'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Notes' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let user" matTooltip="{{user.notes}}">{{user.notes}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>

  <mat-paginator class="user-search-paginator" [length]="users?.length" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>
</div>
