import { Component, OnInit, OnDestroy } from '@angular/core';
import { SerialNumberUIQuery, SerialNumberItem } from './filter-custom-serial-number.model';
import { FilterElement } from '../FilterElement';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '../../../services/filter.service';
import { UIFilterBase } from '../UIFilterBase';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'core-filter-custom-serial-number',
  templateUrl: './filter-custom-serial-number.component.html',
  styleUrls: ['./filter-custom-serial-number.component.scss'],
  standalone: false
})
export class FilterCustomSerialNumberComponent extends UIFilterBase<SerialNumberUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomSerialNumberKey = 'FilterCustomSerialNumber';
  private defaultDebounceTimeMiliseconds = 500;
  private searchingStartsWhenAtLeastCharacters = 3;

  serialNumberFilter: FilterElement<SerialNumberItem>;

  filteredOptions: Observable<string[]>;

  public serialNumberFormGroup: UntypedFormGroup;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomSerialNumberComponent.FilterCustomSerialNumberKey);
  }

  ngOnInit(): void {
    this.intializeValidators();
    this.initialize();
  }

  private initialize() {
    const query = this.filterService.getSerialNumberQuery();
    this.initializeQuery(query);

    this.updateSerialNumber();
  }

  private updateSerialNumber() {
    this.serialNumberFilter = this.query.getSerialNumber();
    this.serialNumberFormGroup.setValue({
      serialNumber: this.serialNumberFilter.item.serialNumber
    });
  }

  protected queryReset(): void {
    this.updateSerialNumber();
  }

  private intializeValidators() {
    this.serialNumberFormGroup = new UntypedFormGroup({
      serialNumber: new UntypedFormControl('', [Validators.maxLength(50)])
    });


    this.filteredOptions = this.serialNumberFormGroup.valueChanges
      .pipe(
        debounceTime(this.defaultDebounceTimeMiliseconds),
        filter((query: { serialNumber: string }) => query.serialNumber.length >= this.searchingStartsWhenAtLeastCharacters),
        mergeMap((value: { serialNumber: string }) => this.query.searchMatchingSerialNumber(value.serialNumber))
      );
  }

  public hasError = (errorName: string): boolean => {
    return this.serialNumberFormGroup.controls.serialNumber.hasError(errorName);
  };

  serialNumberChanged(event: { target: HTMLInputElement }): void {
    this.serialNumberFilter.item.serialNumber = event.target.value;
    if (this.serialNumberFilter.item.serialNumber && this.serialNumberFilter.item.serialNumber.trim()) {
      this.serialNumberFilter.isSelected = true;
    } else {
      this.serialNumberFilter.isSelected = false;
    }

    this.filterService.updateSerialNumberQuery(this.query);

  }

  selectItem(item: FilterElement<SerialNumberItem>): void {
    // intentionally blank
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateSerialNumberQuery(this.query);
  }

}
