import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, Facility, Organization,
  MetadataService, User,
  Features, InitDataSelectionLists, TitleService, FormUtility,
  TranslateNotificationKeys,
  PairedDeviceSelection, ResultsApiIdentifier, InstrumentType,
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-results-api-edit',
  templateUrl: './results-api-edit.component.html',
  styleUrls: ['./results-api-edit.component.scss'],
  standalone: false
})
export class ResultsApiEditComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramId = 'id';
  private isUpdating = false;
  public translationBaseKey = 'ResultsApiEdit';
  public translationInstrumentTypeTableKey = 'InstrumentTypesTable';
  public newResultsApi = true;
  public resultsApiId: string;
  public resultsApiFormGroup: UntypedFormGroup;
  public resultsApiIdentifier: ResultsApiIdentifier;
  public orgs: Organization[];
  public facilities: Facility[];
  public facility: Facility;
  public devices: PairedDeviceSelection[];
  public isOrgAdmin: boolean;
  public dirty = false;

  public displayColumns = ['checked', 'name', 'code', 'serialNumberPrefix', 'description'];
  public instrumentTypes: InstrumentType[];
  public selectedInstrumentTypes: InstrumentType[] = [];

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private location: Location, private titleService: TitleService,
    private toastr: ToastrService, private translate: TranslateService, private dialog: MatDialog) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.resultsApiId = params[this.paramId] as string;

      this.newResultsApi = !this.resultsApiId || this.resultsApiId === '0';

      this.loadInitData();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  isInstrumentTypeSelected(id: number): boolean {
    if (this.selectedInstrumentTypes.length === 0) {
      return false;
    }

    const result = this.selectedInstrumentTypes.find(({ instrumentTypeId }) => instrumentTypeId === id);
    if (result) {
      return true;
    }

    return false;
  }

  loadFacilities(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacSelectionListByOrg(val).subscribe((facs: Facility[]) => {
          this.facilities = facs;

          const facilityControl = this.resultsApiFormGroup.get('facilityId');

          const desiredFacilityId = facilityControl.value as number;
          const foundFacility = this.facilities.find(f => f.facilityId === +desiredFacilityId);
          if (!foundFacility) {
            this.clearFacilitySelection();
          }
        }));
    }
  }

  private clearFacilitySelection() {
    this.resultsApiFormGroup.patchValue({
      facilityId: ''
    }, {
      emitEvent: false
    });
    this.clearPairedDeviceSelection();
  }

  private clearPairedDeviceSelection() {
    this.resultsApiFormGroup.patchValue({
      pairedDeviceId: ''
    }, {
      emitEvent: false
    });
  }

  loadFacility(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacility(val).subscribe((fac: Facility) => {
          this.facility = fac;
        }));
    }
  }

  private loadInitData() {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.orgs = initData.Organizations;
      this.instrumentTypes = initData.InstrumentTypes;

      if (!this.newResultsApi) {
        this.loadResultsApiIdentifier();
      } else {
        this.loadNew();
      }

    }));
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.resultsApiFormGroup.disable();
      }
    }));

  }

  public loadNew(): void {
    this.isUpdating = true;
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

      this.resultsApiFormGroup.setValue({
        name: '',
        organizationId: null,
        comment: '',
        facilityId: null,
        myVirenaEnabled: false,
        token: '',
        containerExpiration: '',
        allowPHI: false
      });

      this.resultsApiFormGroup.get('token').disable({ onlySelf: true });

      this.isUpdating = false;
    }));

  }

  public setValueChanges(): void {

    this.subscription.add(
      this.resultsApiFormGroup.get('organizationId').valueChanges.subscribe(val => {
        this.dirty = true;
        this.loadFacilities(+val);
      }));

    this.subscription.add(
      this.resultsApiFormGroup.get('facilityId').valueChanges.subscribe(val => {
        this.dirty = true;
        this.loadFacility(+val);
      }));
  }

  public loadResultsApiIdentifier(): void {
    this.isUpdating = true;

    this.subscription.add(this.metadataService.getResultsApiIdentifier(this.resultsApiId).subscribe({
      next: (resultsApiIdentifier: ResultsApiIdentifier) => {
        this.resultsApiIdentifier = resultsApiIdentifier;

        this.resultsApiFormGroup.setValue({
          name: this.resultsApiIdentifier.name ? this.resultsApiIdentifier.name : '',
          comment: this.resultsApiIdentifier.comment ? this.resultsApiIdentifier.comment : '',
          organizationId: this.resultsApiIdentifier.facility?.organization?.organizationId ? this.resultsApiIdentifier.facility.organization.organizationId : '',
          myVirenaEnabled: this.resultsApiIdentifier.myVirenaEnabled ? this.resultsApiIdentifier.myVirenaEnabled : false,
          facilityId: this.resultsApiIdentifier.facility?.facilityId ? this.resultsApiIdentifier.facility.facilityId : '',
          token: this.resultsApiIdentifier.token ? this.resultsApiIdentifier.token : '',
          containerExpiration: this.resultsApiIdentifier.containerExpiration ? this.resultsApiIdentifier.containerExpiration : '',
          allowPHI: this.resultsApiIdentifier.allowPHI ? this.resultsApiIdentifier.allowPHI : false
        });

        this.resultsApiFormGroup.get('name').disable({ onlySelf: true });
        this.resultsApiFormGroup.get('token').disable({ onlySelf: true });
        this.resultsApiFormGroup.get('containerExpiration').disable({ onlySelf: true });
        this.resultsApiFormGroup.get('myVirenaEnabled').disable({ onlySelf: true });
        this.resultsApiFormGroup.get('allowPHI').disable({ onlySelf: true });

        this.selectedInstrumentTypes = [];
        this.instrumentTypes.forEach((instrumentType, index) => {
          const result = this.resultsApiIdentifier.instrumentTypeIds.find((id) => id === instrumentType.instrumentTypeId);
          if (result) {
            this.selectedInstrumentTypes.push(instrumentType);
          }
        });

        this.isUpdating = false;
        this.dirty = false;
      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.resultsApiId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
        this.isUpdating = false;
      }
    }));
  }

  initForm(): void {
    this.resultsApiFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      name: [''],
      comment: [''],
      organizationId: ['', Validators.required],
      myVirenaEnabled: [{ value: false, disabled: true }],
      facilityId: ['', Validators.required],
      token: [''],
      containerExpiration: [''],
      allowPHI: [{ value: false, disabled: true }]
      /* eslint-enable @typescript-eslint/unbound-method */
    });
    this.setValueChanges();

  }

  public onSave(): void {
    let saveObservable: Observable<ResultsApiIdentifier>;

    const containerExpiration = this.resultsApiFormGroup.get('containerExpiration').value as number;

    if (this.newResultsApi) {
      saveObservable = this.metadataService.postResultsApiIdentifier({
        name: this.resultsApiFormGroup.get('name').value as string,
        comment: FormUtility.emptyStringCheck(this.resultsApiFormGroup.get('comment').value as string),
        myVirenaEnabled: this.resultsApiFormGroup.get('myVirenaEnabled').value as boolean,
        facilityId: this.resultsApiFormGroup.get('facilityId').value as number,
        instrumentTypeIds: this.getSelectedInstrumentTypeIds(),
        containerExpiration: containerExpiration,
        allowPHI: this.resultsApiFormGroup.get('allowPHI').value as boolean
      });
    } else {
      saveObservable = this.metadataService.updateResultsApiIdentifier({
        name: this.resultsApiFormGroup.get('name').value as string,
        comment: FormUtility.emptyStringCheck(this.resultsApiFormGroup.get('comment').value as string),
        myVirenaEnabled: this.resultsApiFormGroup.get('myVirenaEnabled').value as boolean,
        facilityId: this.resultsApiFormGroup.get('facilityId').value as number,
        resultsApiId: this.resultsApiId,
        instrumentTypeIds: this.getSelectedInstrumentTypeIds(),
        containerExpiration: containerExpiration,
        allowPHI: this.resultsApiFormGroup.get('allowPHI').value as boolean
      });
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: ret => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));
          this.newResultsApi = false;
          this.resultsApiId = ret.resultsApiIdentifierId;
          this.loadResultsApiIdentifier();
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
        }
      }));
  }

  private getSelectedInstrumentTypeIds() {
    const instrumentTypeIds: number[] = [];
    this.selectedInstrumentTypes.forEach((instrumentType) => {
      instrumentTypeIds.push(instrumentType.instrumentTypeId);
    });

    return instrumentTypeIds;
  }

  public onCancel(): void {
    window.history.go(this.isOrgAdmin ? -2 : -1);
  }

}
