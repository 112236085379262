import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import {
  BaseComponent, Instrument, Facility, Organization,
  MetadataService, User, UserGroup,
  Features, InitDataSelectionLists, InstrumentType, InstrumentTypeId, TitleService,
  FormUtility,
  TranslateNotificationKeys,
  PairedDeviceSelection, ConfirmationModalComponent, ConfirmationModalInput,
  MatchedConfirmationModalComponent, MatchedConfirmationModalInput,
  UpdateInstrumentTestResultsType,
  UpdateTestResultsRequest,
  UpdateTestResultsRequestType,
  SearchService
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.scss'],
  standalone: false
})
export class InstrumentEditComponent extends BaseComponent implements OnInit, OnDestroy {

  private paramId = 'id';
  private paramOrgId = 'orgId';
  private paramFacId = 'facId';
  private isUpdating = false;
  public translationBaseKey = 'InstrumentEdit';
  public newInstrument = true;
  public instrument: Instrument;
  public instrumentId: number;
  public instrumentFormGroup: UntypedFormGroup;
  public orgId: number;
  public orgs: Organization[];
  public facilities: Facility[];
  public facility: Facility;
  public facId: number;
  public devices: PairedDeviceSelection[];
  public isOrgAdmin: boolean;
  public instrumentTypes: InstrumentType[];
  public dirty = false;
  private confirmationDialogRef: MatDialogRef<ConfirmationModalComponent, boolean>;
  private testResultsDeletedEnabled: boolean;
  private deleteTestResults: boolean;

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private location: Location, private titleService: TitleService,
    private toastr: ToastrService, private translate: TranslateService, private dialog: MatDialog, private searchService: SearchService) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.instrumentId = +params[this.paramId];
      this.orgId = +params[this.paramOrgId];
      this.facId = +params[this.paramFacId];

      this.newInstrument = this.instrumentId === 0;

      this.checkIsOrgAdmin();
      this.loadInitData();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  loadFacilities(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacSelectionListByOrg(val).subscribe((facs: Facility[]) => {
          this.facilities = facs;

          const facilityControl = this.instrumentFormGroup.get('facilityId');

          const desiredFacilityId = facilityControl.value as number;
          const foundFacility = this.facilities.find(f => f.facilityId === +desiredFacilityId);
          if (!foundFacility) {
            this.clearFacilitySelection();
          }
        }));
    }
  }

  loadPairedDevices(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getPairedDeviceSelectionListByFacilityId(val).subscribe((devices: PairedDeviceSelection[]) => {
          this.devices = devices;
          this.devices.unshift({ pairedDeviceId: null, deviceAddress: translate(this.translate, `${this.translationBaseKey}.NoWirelessDevice`) });

          const pairedDeviceControl = this.instrumentFormGroup.get('pairedDeviceId');

          const desiredPairedDeviceId = pairedDeviceControl.value as number;
          const foundPairedDevice = this.devices.find(d => d.pairedDeviceId === +desiredPairedDeviceId);
          if (!foundPairedDevice) {
            this.clearPairedDeviceSelection();
          }
        }));
    }
  }

  private clearFacilitySelection() {
    this.instrumentFormGroup.patchValue({
      facilityId: ''
    }, {
      emitEvent: false
    });
    this.clearPairedDeviceSelection();
  }

  private clearPairedDeviceSelection() {
    this.instrumentFormGroup.patchValue({
      pairedDeviceId: ''
    }, {
      emitEvent: false
    });
  }

  loadFacility(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacility(val).subscribe((fac: Facility) => {
          this.facility = fac;
        }));
    }
  }

  private loadInitData() {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.orgs = initData.Organizations;
      this.instrumentTypes = initData.InstrumentTypes;

      // Load instrument after getting init data so validators will fire correctly on load
      if (!this.newInstrument) {
        this.loadInstrument();
      } else {
        this.loadNew();
      }
    }));
  }

  private checkIsOrgAdmin() {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      this.isOrgAdmin = me.group.displayName === UserGroup.OrgAdmin.valueOf();

      if (this.isOrgAdmin) {
        this.instrumentFormGroup.get('instrumentTypeId').disable({ onlySelf: true });
        this.instrumentFormGroup.get('serialNumber').disable({ onlySelf: true });
        this.instrumentFormGroup.get('registrationDate').disable({ onlySelf: true });
        this.instrumentFormGroup.get('organizationId').disable({ onlySelf: true });
      }
    }));
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.instrumentFormGroup.disable();
      }
      this.testResultsDeletedEnabled = features.TestResultsDeleteEnabled;
    }));

  }

  public loadNew(): void {
    this.isUpdating = true;

    if (this.orgId > 0 && this.facId > 0) {
      this.instrumentFormGroup.setValue({
        organizationId: this.orgId,
        registrationDate: new Date(),
        serialNumber: '2',
        comment: '',
        active: false,
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        instrumentTypeId: InstrumentTypeId.Sofia2,
        facilityId: this.facId,
        pairedDeviceId: ''
      });
      this.isUpdating = false;
    }
    else {
      this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

        this.instrumentFormGroup.setValue({
          organizationId: me.organizationId,
          registrationDate: new Date(),
          serialNumber: '2',
          comment: '',
          active: false,
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          instrumentTypeId: InstrumentTypeId.Sofia2,
          facilityId: me.facilityId,
          pairedDeviceId: ''
        });
        this.isUpdating = false;
      }));
    }
  }

  public setValueChanges(): void {

    this.subscription.add(
      this.instrumentFormGroup.get('organizationId').valueChanges.subscribe(val => {
        this.dirty = true;
        this.loadFacilities(+val);
      }));

    this.subscription.add(
      this.instrumentFormGroup.get('facilityId').valueChanges.subscribe(val => {
        this.dirty = true;
        this.loadFacility(+val);
        this.loadPairedDevices(+val);
      }));

    this.subscription.add(
      this.instrumentFormGroup.get('instrumentTypeId').valueChanges.subscribe(val => {
        if (!this.isUpdating) {
          let num = '';

          switch (val) {
            case InstrumentTypeId.Sofia: // Sofia
              num = '0';
              break;
            case InstrumentTypeId.Solana: // Solana
              num = '1';
              break;
            case InstrumentTypeId.Savana: // Savana
              num = '';
              break;
            case InstrumentTypeId.Sofia2: // Sofia 2
              num = '2';
              break;
            default: { // QuickVue and SofiaQ
              const instrumentType = this.instrumentTypes.find(i => i.instrumentTypeId === val);
              num = instrumentType ? instrumentType.serialNumberPrefix : '';
              break;
            }
          }
          this.instrumentFormGroup.patchValue({
            serialNumber: num ? num : ''
          });
        }
      }));

  }

  public loadInstrument(): void {
    this.isUpdating = true;

    this.subscription.add(this.metadataService.getInstrument(this.instrumentId).subscribe({
      next: (instrument: Instrument) => {
        this.instrument = instrument;

        this.instrumentFormGroup.setValue({
          registrationDate: this.instrument.registrationDate ? this.instrument.registrationDate : '' as unknown,
          serialNumber: this.instrument.serialNumber ? this.instrument.serialNumber : '',
          comment: this.instrument.comment ? this.instrument.comment : '',
          active: this.instrument.active ? this.instrument.active : false,
          contactName: this.instrument.contactName ? this.instrument.contactName : '',
          contactEmail: this.instrument.contactEmail ? this.instrument.contactEmail : '',
          contactPhone: this.instrument.contactPhone ? this.instrument.contactPhone : '',
          instrumentTypeId: this.instrument.instrumentType_InstrumentTypeId ? this.instrument.instrumentType_InstrumentTypeId : 0,
          facilityId: this.instrument.facility_FacilityId ? this.instrument.facility_FacilityId : 0,
          organizationId: this.instrument.facility ? this.instrument.facility.organization_OrganizationId : 0,
          pairedDeviceId: this.instrument.pairedDevice_PairedDeviceId ? this.instrument.pairedDevice_PairedDeviceId : 0
        });

        this.instrumentFormGroup.get('serialNumber').disable({ onlySelf: true });
        this.instrumentFormGroup.get('registrationDate').disable({ onlySelf: true });
        this.instrumentFormGroup.get('instrumentTypeId').disable({ onlySelf: true });

        this.isUpdating = false;
        this.dirty = false;
      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.instrumentId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
        this.isUpdating = false;
      }
    }));
  }

  public updateInstrument(): void {
    if (this.newInstrument) {
      this.instrument = {
        instrumentId: 0,
        registrationDate: this.instrumentFormGroup.get('registrationDate').value,
        serialNumber: this.instrumentFormGroup.get('serialNumber').value as string,
        comment: FormUtility.emptyStringCheck(this.instrumentFormGroup.get('comment').value as string),
        active: this.instrumentFormGroup.get('active').value as boolean,
        contactName: FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactName').value as string),
        contactEmail: FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactEmail').value as string),
        contactPhone: FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactPhone').value as string),
        instrumentType_InstrumentTypeId: this.instrumentFormGroup.get('instrumentTypeId').value as number,
        facility_FacilityId: this.instrumentFormGroup.get('facilityId').value as number,
        pairedDevice_PairedDeviceId: this.instrumentFormGroup.get('pairedDeviceId').value as number
      };
    } else {
      this.instrument.registrationDate = this.instrumentFormGroup.get('registrationDate').value;
      this.instrument.serialNumber = this.instrumentFormGroup.get('serialNumber').value as string;
      this.instrument.comment = FormUtility.emptyStringCheck(this.instrumentFormGroup.get('comment').value as string);
      this.instrument.contactName = FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactName').value as string);
      this.instrument.contactEmail = FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactEmail').value as string);
      this.instrument.contactPhone = FormUtility.emptyStringCheck(this.instrumentFormGroup.get('contactPhone').value as string);
      this.instrument.instrumentType_InstrumentTypeId = this.instrumentFormGroup.get('instrumentTypeId').value as number;
      this.instrument.facility_FacilityId = this.instrumentFormGroup.get('facilityId').value as number;
      this.instrument.pairedDevice_PairedDeviceId = this.instrumentFormGroup.get('pairedDeviceId').value as number;
      this.instrument.facility = null;
      this.instrument.instrumentType = null;
      this.instrument.pairedDevice = null;
      this.instrument.active = true;
    }

  }

  initForm(): void {
    this.instrumentFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      active: [false],
      registrationDate: ['', Validators.required],
      serialNumber: ['', [Validators.required, (control: AbstractControl) => this.validateSerialNumber(control, this.instrumentTypes)]],
      contactName: [''],
      contactPhone: [''],
      contactEmail: ['', Validators.email],
      comment: [''],
      facilityId: ['', Validators.required],
      organizationId: ['', Validators.required],
      pairedDeviceId: [''],
      instrumentTypeId: ['', Validators.required]
      /* eslint-enable @typescript-eslint/unbound-method */
    });
    this.setValueChanges();

  }

  validateSerialNumber(control: AbstractControl, instrumentTypes: Array<InstrumentType>): ValidationErrors {

    let isValid = true;

    if (control.parent && control.parent.value && instrumentTypes) {

      const serialNumber = control.value as string;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const instrumentTypeId = control.parent.value.instrumentTypeId as number;
      const instrumentType = instrumentTypes.find(i => i.instrumentTypeId === instrumentTypeId);

      if (instrumentType && instrumentType.serialNumberPrefix) { // QuickVue and SofiaQ
        isValid = serialNumber.substring(0, instrumentType.serialNumberPrefix.length).toUpperCase() === instrumentType.serialNumberPrefix.toUpperCase()
          && serialNumber.length > instrumentType.serialNumberPrefix.length;
      } else {
        isValid = serialNumber.length === 8;

        if (isValid) {
          switch (instrumentTypeId) {
            case InstrumentTypeId.Sofia.valueOf(): // Sofia
              isValid = serialNumber.substring(0, 1) === '0';
              break;
            case InstrumentTypeId.Solana.valueOf(): // Solana
              isValid = serialNumber.substring(0, 1) === '1';
              break;
            case InstrumentTypeId.Savana.valueOf(): // Savana
              break;
            case InstrumentTypeId.Sofia2.valueOf(): // Sofia 2
              isValid = serialNumber.substring(0, 1) === '2';
              break;
          }
        }
      }
    }
    return isValid ? null : { invalidSerialNumber: isValid };
  }

  public onSave(): void {
    this.updateInstrument();

    let saveObservable: Observable<number>;
    if (this.newInstrument) {
      saveObservable = this.metadataService.postInstrument(this.instrument);
    } else {
      saveObservable = this.metadataService.putInstrument(this.instrument);
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: (ret) => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));

          // return to the Facility Edit screen
          if (this.facId > 0) {
            window.history.go(-1);
          }
          else {
            this.newInstrument = false;
            this.instrumentId = ret;
            this.loadInstrument();
          }
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
        }
      }));
  }

  public onCancel(): void {
    window.history.go(this.isOrgAdmin ? -2 : -1);
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'Instrument' })) {
      if (this.testResultsDeletedEnabled) {
        this.subscription.add(this.searchService.getTestResultsCount(this.instrument.serialNumber).subscribe(async count => {
          if (count > 0) {
            this.deleteTestResults = await this.showMatchedConfirmationDialog({ titleResourceKey: 'ConfirmDeleteTestResults', toBeMatchedName: 'Number of Test Results', toBeMatchedValue: count.toString() });
          }
          else {
            // do not confirm if there is no test result.
            this.deleteTestResults = false;
          }

          this.subscription.add(
            this.metadataService.deleteInstrument(this.instrument, this.deleteTestResults).subscribe({
              next: () => {
                this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
                this.location.back();
              },
              error: () => {
                this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
              }
            }));
        }));
      } else {
        if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDeleteTestResultsUnavailable' })) {
          this.subscription.add(
            this.metadataService.deleteInstrument(this.instrument, false).subscribe({
              next: () => {
                this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
                this.location.back();
              },
              error: () => {
                this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
              }
            }));
        }
      }
    }
  }

  private async showMatchedConfirmationDialog(inputData: MatchedConfirmationModalInput): Promise<boolean> {
    const modalWidth = 0.16 * window.outerWidth;
    this.confirmationDialogRef = this.dialog.open(MatchedConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = await firstValueFrom(this.confirmationDialogRef.afterClosed());
    return result;
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {
    const modalWidth = 0.16 * window.outerWidth;
    this.confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = await firstValueFrom(this.confirmationDialogRef.afterClosed());
    return result;
  }

  public async onShowUpdateTestResultsDialog(template: TemplateRef<unknown>): Promise<void> {
    const modalWidth = 0.25 * window.outerWidth;
    const dialogRef = this.dialog.open<unknown, unknown, { startDate: string, endDate: string, type: UpdateInstrumentTestResultsType } | 'false'>(template, {
      width: `${modalWidth}px`,
      data: { defaultStartDate: new Date(2010, 0, 1), defaultEndDate: new Date() }
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const type: UpdateInstrumentTestResultsType = result.type;

      const startMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.startDate, 'MM-DD-YYYY') :
        moment(result.startDate, 'MM-DD-YYYY');
      const endMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.endDate, 'MM-DD-YYYY').endOf('day') :
        moment(result.endDate, 'MM-DD-YYYY').add(1, 'day').add(-1, 'second');

      const continueUpdate = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmUpdateTestResults', parameter: 'instrument' });

      if (continueUpdate) {
        const request: UpdateTestResultsRequest = {
          id: this.instrumentId,
          startTime: startMoment.toDate(),
          endTime: endMoment.toDate(),
          updateType: type,
          updateRequestType: UpdateTestResultsRequestType.Instrument
        };

        this.subscription.add(this.metadataService.UpdateInstrumentResultsOrgFac(request).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsSucceeded}`));
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsFailed}`));
          }
        }));
      }
    }
  }

  public IsDateRangeValid(startDate: string, endDate: string): boolean {
    return new Date(startDate) <= new Date(endDate);
  }
}
