<div #gridContainer>

  <mat-card appearance="outlined">

    <mat-card-header class="justify-space-between">
      <mat-card-title>
        <ng-container *ngIf="!newFacility">
          {{ translationBaseKey + '.EditFacility' | translate }}
        </ng-container>
        <ng-container *ngIf="newFacility">
          {{ translationBaseKey + '.AddFacility' | translate }}
        </ng-container>
      </mat-card-title>

      <button mat-button [disabled]="!this.orgId" (click)="populateOrgAddress()">
        <mat-icon class="icon-button-override">smart_button</mat-icon>
        {{ translationBaseKey + '.PopulateAddressFromOrg' | translate }}
      </button>
    </mat-card-header>

    <mat-card-content>

      <form class="facilityForm" [formGroup]="facFormGroup" class="form-grid">
        <div class="general-section">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Organization' | translate }}</mat-label>
            <core-virtual-select formControlName="organizationId" required="true" itemSize="42" [dataSource]="orgs"
              valueField="organizationId" displayField="name" showSearch="true">
            </core-virtual-select>
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Name' | translate }}</mat-label>
            <input matInput formControlName="name" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isOrgAdmin">
            <mat-label>{{ translationBaseKey + '.Description' | translate }}</mat-label>
            <mat-select formControlName="descriptionId" required="true">
              <mat-option *ngFor="let desc of facDescriptions" [value]="desc.facilityDescriptionId">
                {{desc.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.ContactName' | translate }}</mat-label>
            <input matInput formControlName="contactName" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.WorkPhone' | translate }}</mat-label>
            <input matInput formControlName="workPhone" required="true"
              [customErrors]="{ pattern: 'ValidationErrors.Phone' }" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.MobilePhone' | translate }}</mat-label>
            <input matInput formControlName="mobilePhone" [customErrors]="{ pattern: 'ValidationErrors.Phone' }" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Email' | translate }}</mat-label>
            <input matInput formControlName="email" required="true" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isOrgAdmin">
            <mat-label>{{ translationBaseKey + '.CRMId' | translate }}</mat-label>
            <input matInput formControlName="crmId" />
          </mat-form-field>
        </div>

        <div class="address-section">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Country' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="countryId" required="true">
              <mat-option>Select Country</mat-option>
              <mat-option *ngFor="let country of countries" [value]="country.countryId">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address1' | translate }}</mat-label>
            <input matInput formControlName="address1" required="true" #addressField autocomplete="new-password">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address2' | translate }}</mat-label>
            <input matInput formControlName="address2">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.Address3' | translate }}</mat-label>
            <input matInput formControlName="address3">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.City' | translate }}</mat-label>
            <input matInput formControlName="city" required="true">
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.State' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="stateId" required="true">
              <mat-option>Select State</mat-option>
              <mat-option *ngFor="let state of states" [value]="state.stateId">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ translationBaseKey + '.ZipCode' | translate }}</mat-label>
            <input matInput formControlName="zipCode" required="true">
            <button class="address-button" [class.verified]="addressVerified" *ngIf="smartyStreets" type="button"
              (click)="validateAddress()" matTooltip="{{ 'Verification.Address' | translate}}">
              <i class="material-icons">check</i>
            </button>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" floatLabel="always">
            <mat-label>{{ translationBaseKey + '.County' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="countyId" required="true">
              <mat-option>Select County</mat-option>
              <mat-option *ngFor="let county of counties" [value]="county.countyId">
                {{county.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </form>

      <div class="fac-table" *ngIf="!newFacility">
        <mat-table class="mat-elevation-z8" [dataSource]="instrumentsPairedDevices?.results" matSort
          (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
          [matSortDirection]="sortMatDirection">
          <ng-container matColumnDef="insSerialNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentPairedDeviceTableKey + '.SerialNumber' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let ins" (click)="onInstrumentSelect(ins.instrumentId)">{{ins.serialNumber}}
              <mat-icon matBadgeColor="warn"
                matTooltip="This instrument belongs to {{ins.instrument_FacilityName}} facility."
                *ngIf="ins.instrument_FacilityId >0 && ins.instrument_FacilityId !== facId">
                report_problem
              </mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pdDeviceAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translationBaseKey + '.' + translationInstrumentPairedDeviceTableKey + '.WirelessDevice' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let ins" (click)="onRouterSelect(ins.pairedDeviceId)">
              {{ins.deviceAddress}}
              <mat-icon matBadgeColor="warn"
                matTooltip="This wireless device belongs to {{ins.pairedDevice_FacilityName}} facility."
                *ngIf="ins.pairedDevice_FacilityId >0 && ins.pairedDevice_FacilityId !== facId">
                report_problem
              </mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
        </mat-table>
      </div>
      <mat-paginator class="instrument-paginator" [length]="instrumentsPairedDevices?.totalResults"
        [pageSize]="pageSize" [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)"
        showFirstLastButtons="true">
      </mat-paginator>

      <button mat-button [disabled]="facFormGroup.invalid || facFormGroup.disabled" (click)="onSave()"
        matTooltip="{{ translationBaseKey + '.SaveFac' | translate }}"
        [matTooltipDisabled]="facFormGroup.invalid || facFormGroup.disabled">
        <mat-icon class="icon-button-override">save</mat-icon>
        {{ translationBaseKey + '.Save' | translate }}
      </button>

      <button mat-button (click)="onCancel()" matTooltip="{{ translationBaseKey + '.CancelFac' | translate }}">
        <mat-icon class="icon-button-override">cancel</mat-icon>
        {{ translationBaseKey + '.Cancel' | translate }}
      </button>

      <button mat-button *ngIf="!facFormGroup.disabled && !newFacility" (click)="onDelete()"
        matTooltip="{{ translationBaseKey + '.DeleteFac' | translate }}"
        [matTooltipDisabled]="facFormGroup.disabled || newFacility">
        <mat-icon class="icon-button-override">delete</mat-icon>
        {{ translationBaseKey + '.Delete' | translate }}
      </button>

      <button mat-button class="add-new-button" *ngIf="enableAdd && !isOrgAdmin" (click)="createNewInstrument()"
        matTooltip="{{ translationBaseKey + '.AddNewInstrument' | translate }}"
        [matTooltipDisabled]="!enableAdd || isOrgAdmin">
        <mat-icon class="icon-button-override">add</mat-icon> {{ translationBaseKey + '.AddNewIns' | translate }}
      </button>

      <button mat-button class="add-new-button" *ngIf="enableAdd && !isOrgAdmin" (click)="createNewPairedDevice()"
        matTooltip="{{ translationBaseKey + '.AddNewPairedDevice' | translate }}"
        [matTooltipDisabled]="!enableAdd || isOrgAdmin">
        <mat-icon class="icon-button-override">add</mat-icon> {{ translationBaseKey + '.AddNewPD' | translate }}
      </button>

      <button mat-button *ngIf="!isOrgAdmin" [disabled]="facFormGroup.disabled || newFacility" class="float-right"
        (click)="onShowUpdateTestResultsDialog(updateTestResultsTemplate)"
        matTooltip="{{ translationBaseKey + '.UpdateTestResults' | translate }}"
        [matTooltipDisabled]="facFormGroup.disabled || newFacility">
        <mat-icon class="icon-button-override">update</mat-icon>
        {{ translationBaseKey + '.UpdateTestResults' | translate }}
      </button>
    </mat-card-content>
  </mat-card>

</div>

<ng-template #addressSuggestions let-addressSuggestions="addressSuggestions">
  <div class="suggestion-container">
    <cdk-virtual-scroll-viewport itemSize="48" class="address-virtual-list" [style.height.px]="256">
      <mat-option *cdkVirtualFor="let obj of addressSuggestions" [value]="obj" (click)="onAddressSuggestionChosen(obj)">
        {{obj.text}}
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>

<ng-template #updateTestResultsTemplate let-data>
  <h1 mat-dialog-title>{{ translationBaseKey + '.UpdateTestResults' | translate }}</h1>
  <div mat-dialog-content class="update-test-results-content">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" required="true" #resultDate [value]="data.defaultStartDate">
      <mat-datepicker-toggle matSuffix [for]="picker">
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsEndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" required="true" #resultEndDate [value]="data.defaultEndDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker">
      </mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>{{ translationBaseKey + '.UpdateTestResultsType' | translate }}</mat-label>
      <mat-select matNativeControl required="true" #dateType>
        <mat-option [value]="0">Storage Date</mat-option>
        <mat-option [value]="1">Run Date</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ translationBaseKey + '.UpdateTestResultsTimezoneNotification' | translate }}</mat-label>
  </div>
  <div mat-dialog-actions>
    <button mat-button
      [mat-dialog-close]="{ startDate: resultDate.value, endDate: resultEndDate.value,  type: dateType.value }"
      [disabled]="!resultDate.value ||!resultEndDate.value || !IsDateRangeValid(resultDate.value, resultEndDate.value) || dateType.value === undefined">{{
      'ModalConfirmationDialog.YesButton' | translate }}</button>
    <button mat-button mat-dialog-close="false" cdkFocusInitial>{{ 'ModalConfirmationDialog.NoButton' | translate
      }}</button>
  </div>
</ng-template>
