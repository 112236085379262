import { Component, OnInit, OnDestroy } from '@angular/core';
import { ZipCodeUIQuery, ZipCodeItem } from './filter-custom-zipcode.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '../../../services/filter.service';
import { UIFilterBase } from '../UIFilterBase';
import { FilterElement } from '../FilterElement';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'core-filter-custom-zipcode',
  templateUrl: './filter-custom-zipcode.component.html',
  styleUrls: ['./filter-custom-zipcode.component.scss'],
  standalone: false
})
export class FilterCustomZipcodeComponent extends UIFilterBase<ZipCodeUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomZipCodeKey = 'FilterCustomZipCode';

  zipCodeFilter: FilterElement<ZipCodeItem>;

  public zipCodeFormGroup: UntypedFormGroup;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomZipcodeComponent.FilterCustomZipCodeKey);
  }

  ngOnInit(): void {
    this.intializeValidators();
    this.initialize();
  }

  private initialize() {
    const query = this.filterService.getZipCodeQuery();
    this.initializeQuery(query);
    this.updateZipCode();
  }

  protected queryReset(): void {
    this.updateZipCode();
  }

  private updateZipCode() {
    this.zipCodeFilter = this.query.getZipCode();

    this.zipCodeFormGroup.setValue({
      zipCode: this.zipCodeFilter.item.zipCode
    });
  }

  private intializeValidators() {
    this.zipCodeFormGroup = new UntypedFormGroup({
      zipCode: new UntypedFormControl('', [Validators.maxLength(20)])
    });
  }

  public hasError = (errorName: string): boolean => {
    return this.zipCodeFormGroup.controls.zipCode.hasError(errorName);
  };

  selectItem(item: FilterElement<ZipCodeItem>): void {
    // intentionally blank
  }

  zipCodeChanged(event: { target: HTMLInputElement }): void {
    this.zipCodeFilter.item.zipCode = event.target.value;
    if (this.zipCodeFilter.item.zipCode && this.zipCodeFilter.item.zipCode.trim()) {
      this.zipCodeFilter.isSelected = true;
    } else {
      this.zipCodeFilter.isSelected = false;
    }

    this.filterService.updateZipCodeQuery(this.query);
  }


  ngOnDestroyInternal(): void {
    // Required by base component
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateZipCodeQuery(this.query);
  }

}
