<div class="results-api-container">
  <div>

    <div class="top-right-buttons">
      <button mat-icon-button class="refresh-button" (click)="loadData()"
        matTooltip="{{ translationBaseKey + '.Refresh' | translate }}">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>

  </div>

  <div class="results-api-list">

    <mat-table class="mat-elevation-z8" [dataSource]="sortedResultsApiIdentifiers" matSort
      (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
      [matSortDirection]="sortMatDirection">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resultsApi">{{resultsApi.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="organizationname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrgName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resultsApi" class="wrap-text">{{resultsApi.facility?.organization?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.FacilityName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resultsApi" class="wrap-text">{{resultsApi.facility?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="myVirenaEnabled">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.MyVirenaEnabled' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let resultsApi">
          <mat-checkbox disabled=true [(ngModel)]='resultsApi.myVirenaEnabled'></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="instrumentTypes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.InstrumentTypes' | translate
          }}</mat-header-cell>
        <mat-cell
          *matCellDef="let resultsApi">{{this.getSelectedInstrumentTypeCodes(resultsApi?.instrumentTypeIds)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.Comment' | translate
          }}</mat-header-cell>
        <mat-cell *matCellDef="let resultsApi">{{resultsApi.comment}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>

      <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

    </mat-table>
  </div>
  <mat-paginator class="results-api-paginator" [length]="resultsApiIdentifiers?.length" [pageSize]="pageSize"
    [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
  </mat-paginator>

</div>
