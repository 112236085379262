<h1 mat-dialog-title>
  {{ title | translate}} {{ 'FavortiesAddEditDialog.FavoriteTitle' | translate }}
</h1>


<div mat-dialog-content>

  <div class="section">

    <form class="favoritesForm" [formGroup]="favoritesFormGroup">

      <mat-form-field class="field-full-width" appearance="outline">
        <mat-label>{{ 'FavortiesAddEditDialog.Name' | translate }}</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <div class="section">
        <mat-checkbox formControlName="export">
          <mat-label>{{ 'FavortiesAddEditDialog.ShareWOrganization' | translate }}</mat-label>
        </mat-checkbox>
      </div>



      <div class="section">

        <div class="section-header">{{ 'FavortiesAddEditDialog.SummarySelectedFilters' | translate }}</div>

        <div class="section">
          <core-filters-summary></core-filters-summary>
        </div>

      </div>

      <div class="section" *ngIf="showEmailSubscription">
        <div class="section-header">{{ 'FavortiesAddEditDialog.EmailsSubscriptionHeader' | translate }}</div>

        <mat-form-field>
          <mat-label>{{ 'FavortiesAddEditDialog.ScheduleLabel' | translate }}</mat-label>
          <mat-select [(value)]="selectedEmailScheduleDescription">
            <mat-option *ngFor="let schedule of emailSubscriptionScheduleTypesMap | keyvalue" [value]="schedule.value">
              {{schedule.value | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="section" *ngIf="showEmailSubscription">
        <div class="section-header">{{ 'FavortiesAddEditDialog.ReportsLabel' | translate }}</div>

        <section class="" style="display: flex; flex-wrap: wrap;">
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedCsvReport" [ngModelOptions]="{standalone: true}"
            [disabled]="!isCsvEnabled">
            {{ 'FavortiesAddEditDialog.CsvReport' | translate }} </mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedCsvByStorageDateReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isCsvByStorageDateEnabled">
            {{ 'FavortiesAddEditDialog.CsvReportByStorageDate' | translate }} </mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientsByRunDateReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientsByRunDateEnabled">{{
            'FavortiesAddEditDialog.PatientsByRunDateReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientsByAssayReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientsByAssayEnabled">{{
            'FavortiesAddEditDialog.PatientsByAssayReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientsByFacilityAssayReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientsByFacilityAssayEnabled">{{
            'FavortiesAddEditDialog.PatientsByFacilityAssayReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientsByFacilityResultReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientsByFacilityResultEnabled">{{
            'FavortiesAddEditDialog.PatientsByFacilityResultReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientsByResultReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientsByResultEnabled">{{
            'FavortiesAddEditDialog.PatientsByResultReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientResultTrendsReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientResultTrendsEnabled">{{
            'FavortiesAddEditDialog.PatientResultTrendsReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPercentPositiveResultsReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPercentPositiveResultEnabled">{{
            'FavortiesAddEditDialog.PercentPositiveResultsReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedPatientCoInfectionsReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isPatientCoInfectionsEnabled">{{
            'FavortiesAddEditDialog.PatientCoInfectionsReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedQualityControlReportReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isQualityControlReportEnabled">{{
            'FavortiesAddEditDialog.QualityControlReport' | translate }}</mat-checkbox>
          <mat-checkbox class="checkbox-margin" [(ngModel)]="isCheckedTestVolumeByTypeReport"
            [ngModelOptions]="{standalone: true}" [disabled]="!isTestVolumeByTypeEnabled">{{
            'FavortiesAddEditDialog.TestVolumeByTypeReport' | translate }}</mat-checkbox>
        </section>
        <mat-error *ngIf="hasErrorEmail('isAtLeastOneReportSelected')">{{
          'FavortiesAddEditDialog.AddAtLeastOneReportValidatorMessage' | translate }}</mat-error>

      </div>

      <div class="section" *ngIf="showEmailSubscription">
        <div class="section-header">{{ 'FavortiesAddEditDialog.EmailsHeader' | translate }}</div>

        <div>

          <mat-table class="mat-elevation-z8" [dataSource]="emailsSubscriptionList">

            <ng-container matColumnDef="emailColumn">
              <mat-header-cell *matHeaderCellDef>
                {{ 'FavortiesAddEditDialog.EmailHeader' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let item">{{item.email}}</mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{'highlight': selectedRowIndex == row.id, 'highlight-hover': row.hovered}"
              (click)="selectEmailForEdit(row)" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </mat-row>

          </mat-table>
          <mat-error *ngIf="hasErrorEmail('isAtLeastOneEmailPresent')">{{
            'FavortiesAddEditDialog.AddAtLeastOneEmailValidatorMessage' | translate }}</mat-error>

        </div>


        <div class="add-edit-email-section">
          <mat-form-field class="field-full-width" appearance="outline">
            <mat-label>{{ 'FavortiesAddEditDialog.EmailHeader' | translate }}</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>

          <button mat-button (click)="addUpdateEmail()">{{ addUpdateEmailTitle | translate }}</button>
          <button mat-button (click)="removeEmail()" [disabled]='!selectedEmail'>
            {{ removeEmailTitle | translate }}</button>
        </div>

      </div>

    </form>

  </div>



</div>

<div mat-dialog-actions>
  <button mat-button (click)="onAddEditClick()"
    [disabled]="favoritesFormGroup?.invalid || (!isEmailsSubscriptionListDirty && isReadOnlyMode)" cdkFocusInitial>{{
    confirmationTitle | translate }}</button>
  <button mat-button (click)="onCancelClick()">{{ cancelCloseTitle | translate }}</button>
</div>
