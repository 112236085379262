import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Configuration, ApiHealthResult, ApiHealthResults } from './configuration.service.models';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsKeys } from '../utils/settings.keys';

@Injectable()
export class ConfigurationService {
  private static readonly notInitializedError = new Error('ConfigurationService must be initialized first.');

  private configuration: Configuration;
  private initialized = false;

  constructor(private httpClient: HttpClient) { }

  public async initializeConfiguration(environment: { version: string, production: boolean }, writeLogMessages: boolean = true): Promise<void> {

    if (this.initialized) {
      console.debug('ConfigurationService already intitialized. Skipping initializeConfiguration.initializeConfiguration execution.');
      return;
    }

    const configuration = await firstValueFrom(this.httpClient.get('/assets/configuration.json')) as Configuration;

    const appInsightsConfigKey = configuration.appInsightsKey;
    const msalConfig = configuration.msalConfig;

    this.configuration = {
      version: environment.version,
      production: environment.production,
      metadataServiceEndpoint: configuration.metadataServiceEndpoint,
      testResultsServiceEndpoint: configuration.testResultsServiceEndpoint,
      searchServiceEndpoint: configuration.searchServiceEndpoint,
      appInsightsKey: appInsightsConfigKey,
      msalConfig: {
        tenant: msalConfig.tenant,
        clientId: msalConfig.clientId,
        signInPolicy: msalConfig.signInPolicy,
        resetPwdPolicy: msalConfig.resetPwdPolicy,
        redirectUri: msalConfig.redirectUri,
        b2cScopes: msalConfig.b2cScopes,
        authority: `https://${msalConfig.tenant}.b2clogin.com/tfp/${msalConfig.tenant}.onmicrosoft.com/${msalConfig.signInPolicy}`,
        resetAuthority: `https://${msalConfig.tenant}.b2clogin.com/tfp/${msalConfig.tenant}.onmicrosoft.com/${msalConfig.resetPwdPolicy}`
      },
      isLoaded: false,
      GoogleAnalyticsTrackingID: configuration.GoogleAnalyticsTrackingID,
      GoogleMapApiKey: configuration.GoogleMapApiKey
    };

    if (writeLogMessages) {
      console.log(`Application Version: ${this.configuration.version}`);
    }

    const healthCheckResult = await this.apiHealthChecks(writeLogMessages);

    if (healthCheckResult) {
      if (healthCheckResult.metadata) {
        this.configuration.metadataApiVersion = healthCheckResult.metadata.version;
        if (writeLogMessages) {
          console.log(`Metadata API Version: ${this.configuration.metadataApiVersion}`);
        }
      }

      if (healthCheckResult.testResults) {
        this.configuration.testResultsApiVersion = healthCheckResult.testResults.version;
        if (writeLogMessages) {
          console.log(`TestResults API Version: ${this.configuration.testResultsApiVersion}`);
        }
      }

      if (healthCheckResult.search) {
        this.configuration.searchApiVersion = healthCheckResult.search.version;
        if (writeLogMessages) {
          console.log(`Search API Version: ${this.configuration.searchApiVersion}`);
        }
      }

      this.initialized = true;
    }
  }

  private async apiHealthChecks(writeLogMessages: boolean): Promise<ApiHealthResults> {
    const metadataHealth = await firstValueFrom(this.apiHealthCheck(this.configuration.metadataServiceEndpoint, writeLogMessages));
    const testResultsHealth = await firstValueFrom(this.apiHealthCheck(this.configuration.testResultsServiceEndpoint, writeLogMessages));
    const searchHealth = await firstValueFrom(this.apiHealthCheck(this.configuration.searchServiceEndpoint, writeLogMessages));

    const results = {
      metadata: metadataHealth,
      testResults: testResultsHealth,
      search: searchHealth
    } as ApiHealthResults;

    return results;
  }

  private apiHealthCheck(endpoint: string, writeLogMessages: boolean): Observable<ApiHealthResult> {
    const apiEndpointParts = endpoint.split('/api');
    return this.httpClient.get<ApiHealthResult>(`${apiEndpointParts[0]}health`)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (writeLogMessages) {
          console.error(`Unable to connect to API: ${err.message}`);
        }
        return of<ApiHealthResult>(undefined);
      }));
  }

  public getConfiguration(): Configuration {
    if (!this.initialized) {
      throw ConfigurationService.notInitializedError;
    }

    return this.configuration;
  }

  public setFeatures(features: Record<string, boolean>): void {
    if (!this.initialized) {
      throw ConfigurationService.notInitializedError;
    }

    const keys = Object.keys(features);
    const featuresMap = new Map<string, boolean>();
    keys.forEach(k => {
      featuresMap.set(k, features[k]);
    });
    this.configuration.features = featuresMap;
  }

  public setSettings(settings: Record<string, string>): void {
    if (!this.initialized) {
      throw ConfigurationService.notInitializedError;
    }
    this.configuration.isUnauthorized = false;

    const keys = Object.keys(settings);
    const settingsMap = new Map<string, string>();
    keys.forEach(k => {
      settingsMap.set(k, settings[k]);

      if (k === SettingsKeys.isUnauthorized) {
        this.configuration.isUnauthorized = true;
      }
    });
    this.configuration.settings = settingsMap;
  }
}
