import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentSearchComponent } from './instrument-search/instrument-search.component';
import { InstrumentEditComponent } from './instrument-edit/instrument-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuidelCoreModule, AngularMaterialModule } from '../../core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';



@NgModule({
  declarations: [InstrumentSearchComponent, InstrumentEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
  ],
  exports: [
    InstrumentSearchComponent,
    InstrumentEditComponent
  ]
})
export class InstrumentsModule { }
