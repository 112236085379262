import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { QuidelCoreModule, ActivityInterceptor, LoaderInterceptor, HttpLoaderFactory } from './core';
import { AppRoutingModule } from './app-routing.module';

import { MsalModule, MsalInterceptor, MsalService, MsalRedirectComponent } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { UserBlockingCookiesComponent } from './user-blocking-cookies/user-blocking-cookies.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { UserModule } from './admin/user/user.module';
import { AssaysModule } from './admin/assays/assays.module';
import { FacilityModule } from './admin/facility/facility.module';
import { OrganizationModule } from './admin/organization/organization.module';
import { InstrumentsModule } from './admin/instruments/instruments.module';
import { PairedDevicesModule } from './admin/paired-devices/paired-devices.module';
import { OrphansModule } from './admin/orphans/orphans.module';
import { ReportsModule } from './reports/reports.module';
import { ResultsApiModule } from './admin/results-api/results-api.module';

@NgModule({
  declarations: [
    AppComponent,
    PendingApprovalComponent,
    UserBlockingCookiesComponent,
    ForbiddenComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent], imports: [CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MsalModule,
    QuidelCoreModule,
    AssaysModule,
    FacilityModule,
    UserModule,
    OrganizationModule,
    InstrumentsModule,
    PairedDevicesModule,
    OrphansModule,
    ReportsModule,
    ResultsApiModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center'
    })],
  providers: [
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: ActivityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }


