import { Component, OnInit, OnDestroy, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import {
  MetadataService, Facility, BaseComponent, Features,
  SortDirection, SearchResultsPage, TitleService, FacilitiesNavigationKeys, TranslateNotificationKeys,
  RoleAccessService,
  UserGroup
} from '../../../core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ExportFileModalComponent, ExportFileType } from '../../../modals/export-file/export-file-modal/export-file-modal.component';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-facility-list',
  templateUrl: './facility-list.component.html',
  styleUrls: ['./facility-list.component.scss'],
  standalone: false
})
export class FacilityListComponent extends BaseComponent implements OnInit, OnDestroy {
  private paramSearchText = 'searchText';
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'FacilityList';

  value: string;
  rows = [];
  facilities: Facility[];
  enableAdd: boolean;

  facilityResults: SearchResultsPage<Facility>;
  sortColumn = 'facilityName';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;
  userGroup: UserGroup;

  pageSize = 20;
  pageNumber = 0;

  displayColumns = ['facilityname', 'organization', 'streetaddress1', 'city', 'state', 'county', 'country', 'zipcode', 'crmid'];
  sortColumns: Record<string, string> = {
    facilityname: 'Facility.Name',
    organization: 'Organization.Name',
    streetaddress1: 'Facility.StreetAddress1',
    city: 'Facility.City',
    state: 'Facility.State.Abbreviation',
    county: 'Facility.County.Name',
    country: 'Facility.Country.Name',
    zipcode: 'Facility.ZipCode',
    crmid: 'Facility.CrmId'
  };

  ExportFileType = ExportFileType;
  exportButtonsState: string;
  enabledExportButtons: boolean;

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private metadataService: MetadataService,
    private titleService: TitleService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private roleAccessService: RoleAccessService,
    private dialog: MatDialog,
  ) {
    super();
    this.value = '';
    this.enabledExportButtons = false;
    this.exportButtonsState = 'not-allowed';
  }

  doSearch(): void {
    this.pageNumber = 0;
    this.updateData();
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {


    const searchParams: Params = {
      searchText: this.value,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    this.subscription.add(
      this.metadataService.searchFacilities(this.value,
        this.sortColumns[this.sortColumn.toLowerCase()],
        this.sortDirection,
        this.pageSize,
        +this.pageNumber + 1)
        .subscribe({
          next: (facilityResults: SearchResultsPage<Facility>) => {
            this.facilityResults = facilityResults;

            if (doScroll) {
              if (isPageUp) {
                this.scrollToTop();
              } else {
                this.scrollToBottom();
              }
            }

            this.enabledExportButtons = this.facilityResults !== null &&
              this.roleAccessService.getRole() !== UserGroup.TechSupportAdmin &&
              this.roleAccessService.getRole() !== UserGroup.SalesAdmin;
            if (this.enabledExportButtons) {
              this.exportButtonsState = 'pointer';
            } else {
              this.exportButtonsState = 'not-allowed';
            }
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchFailure}`));
          }
        }));

  }

  createNew(): void {
    void this.router.navigate([FacilitiesNavigationKeys.EditFullPath, 0]);
  }

  onRowSelect(facility: Facility): void {
    void this.router.navigate([FacilitiesNavigationKeys.EditFullPath, facility.facilityId]);
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      this.enableAdd = features.WriteEnabled;
    }));

  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {

      this.value = params[this.paramSearchText] as string || '';
      this.pageNumber = params[this.paramPageNumber] as number || this.pageNumber;
      this.pageSize = params[this.paramPageSize] as number || this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] as SortDirection || this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';


      if (this.pageNumber && this.pageSize && this.sortColumn && this.sortDirection) {
        this.updateData();
      }

      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  exportFileDialog(fileType: ExportFileType, value: string): void {
    if (!this.enabledExportButtons) {
      return;
    }

    const isTestResultExport = false;
    const modalWidth = 0.27 * window.outerWidth;
    this.dialog.open(ExportFileModalComponent, {
      width: `${modalWidth}px`,
      data: { fileType, value, isTestResultExport }
    });
  }
}
