import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateConstants, InstrumentResultOrphan } from '../../../../core';

@Component({
  selector: 'myvirena2-orphans-result-detail-dialog',
  templateUrl: './orphans-result-detail-dialog.component.html',
  styleUrls: ['./orphans-result-detail-dialog.component.scss'],
  standalone: false
})
export class OrphansResultDetailDialogComponent {

  orphan: InstrumentResultOrphan;
  json: string;
  storageDateFormat = DateConstants.YearMonthDayWithTimeFormat;

  constructor(
    public dialogRef: MatDialogRef<OrphansResultDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orphan: InstrumentResultOrphan; json: string; }
  ) {
    this.orphan = data.orphan ? data.orphan : null;
    let jsonObj: unknown;
    try {
      jsonObj = JSON.parse(data.json);
    } catch {
      jsonObj = null;
    }
    this.json = jsonObj ? JSON.stringify(jsonObj, null, 2) : null;
  }

  onDownloadJson(): void {
    const blob = new Blob([this.json], { type: 'text/json' });

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = `${this.orphan.instrumentResultOrphanId}.json`;
    a.href = URL.createObjectURL(blob);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  onDoneClick(): void {
    this.dialogRef.close();
  }

}
