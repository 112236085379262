import { Component } from '@angular/core';

@Component({
  selector: 'app-test-results-dialog-wrapper',
  templateUrl: './test-results-dialog-wrapper.component.html',
  styleUrl: './test-results-dialog-wrapper.component.scss',
  standalone: false
})
export class TestResultsDialogWrapperComponent {

}
