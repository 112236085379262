// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, ResultsApiNavigationKeys } from '../../core';
import { ResultsApiListComponent } from './results-api-list/results-api-list.component';
import { ResultsApiEditComponent } from './results-api-edit/results-api-edit.component';

export const routes: Routes = [
  {
    path: ResultsApiNavigationKeys.List, component: ResultsApiListComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ResultsApiNavigationKeys.EditWithIdArg, component: ResultsApiEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class ResultsApiRoutingModule { }
