import { enableProdMode, Injector, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigurationService } from './app/core';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

if (environment.production) {
  enableProdMode();
}

export function loggerCallback(logLevel: LogLevel, message: string): void {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(`msal: ${message}`);
      return;
    case LogLevel.Info:
      console.debug(`msal: ${message}`);
      return;
    case LogLevel.Verbose:
      console.debug(`msal: ${message}`);
      return;
    case LogLevel.Warning:
      console.warn(`msal: ${message}`);
      return;
  }
}

const injector = Injector.create({
  providers: [
    { provide: HttpClient, deps: [HttpHandler] },
    { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    { provide: ConfigurationService, deps: [HttpClient] }]
});

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
console.debug('Is IE:', isIE);

const configurationService = injector.get(ConfigurationService as Type<ConfigurationService>);
void configurationService.initializeConfiguration(environment, true).then(() => {

  const currentConfig = configurationService.getConfiguration();
  const msalConfigData = currentConfig.msalConfig;

  const metadataServiceEndpointUrl = new URL(currentConfig.metadataServiceEndpoint);
  const testResultsServiceEndpointUrl = new URL(currentConfig.testResultsServiceEndpoint);
  const searchServiceEndpointUrl = new URL(currentConfig.searchServiceEndpoint);
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(metadataServiceEndpointUrl.origin, msalConfigData.b2cScopes);
  if(!protectedResourceMap.has(testResultsServiceEndpointUrl.origin)){
    protectedResourceMap.set(testResultsServiceEndpointUrl.origin, msalConfigData.b2cScopes);
  }

  if(!protectedResourceMap.has(searchServiceEndpointUrl.origin)){
    protectedResourceMap.set(searchServiceEndpointUrl.origin, msalConfigData.b2cScopes);
  }

  const authRouteSuffix = msalConfigData.redirectUri.endsWith('/')
    ? 'auth'
    : '/auth';

  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: msalConfigData.clientId,
      authority: msalConfigData.authority,
      knownAuthorities: [`${msalConfigData.tenant}.b2clogin.com`],
      redirectUri: `${msalConfigData.redirectUri}${authRouteSuffix}`,
      postLogoutRedirectUri: msalConfigData.redirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      storeAuthStateInCookie: isIE // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v1-docs/ie-support.md
    },
    system: {
      loggerOptions: {
        loggerCallback,
        piiLoggingEnabled: true
      },
      loadFrameTimeout: 90000
    }
  });

  const interceptorConfig: MsalInterceptorConfiguration = {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };

  const guardConfig: MsalGuardConfiguration = {
    interactionType: InteractionType.Redirect
  };

  platformBrowserDynamic([
    { provide: ConfigurationService, useValue: configurationService },
    { provide: MSAL_INSTANCE, useValue: msalInstance },
    { provide: MSAL_INTERCEPTOR_CONFIG, useValue: interceptorConfig },
    { provide: MSAL_GUARD_CONFIG, useValue: guardConfig }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
);

