import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, Facility, Organization,
  MetadataService, Features, InitDataSelectionLists, TitleService,
  TranslateNotificationKeys,
  ConfirmationModalComponent, ConfirmationModalInput,
  FacilitiesNavigationKeys, OrganizationsNavigationKeys,
  InstrumentResultOrphan, OrphansNavigationKeys, IdbRecord, UrlService
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { translate } from 'src/app/core/utils/translateServiceHelper';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'myvirena2-orphans-assign',
  templateUrl: './orphans-assign.component.html',
  styleUrls: ['./orphans-assign.component.scss'],
  standalone: false
})
export class OrphansAssignComponent extends BaseComponent implements OnInit, OnDestroy {

  private serialNumberId = 'serialnumber';
  private instrumentTypeCodeId = 'instrumentTypeCode';
  private deviceAddressId = 'deviceaddress';
  private zipCodeId = 'zipcode';
  private facilityIdParam = 'facilityid';
  private orgIdParam = 'organizationid';
  private serialNumber: string;
  private instrumentTypeCode: string;
  private deviceAddress: string;
  private zipCode: string;
  private isUpdating = false;
  public isZipCodeOverrideMatched = false;
  public orphanReason: string;

  private instrumentId: number;
  private pairedDeviceId: number;

  public translationBaseKey = 'OrphanAssign';
  public orphanResult: InstrumentResultOrphan;
  public orphanFormGroup: UntypedFormGroup;
  public idbRecord: IdbRecord;
  public idbFormGroup: UntypedFormGroup;
  public orgs: Organization[];
  public facilities: Facility[];
  public facility: Facility;
  public orgId: number;
  public facilityId: number;
  public currentUrl: string = null;
  public previousUrl: string = null;
  private confirmationDialogRef: MatDialogRef<ConfirmationModalComponent, boolean>;

  public showPairedDeviceOverride = false;

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private location: Location, private titleService: TitleService,
    private toastr: ToastrService, private translate: TranslateService, private dialog: MatDialog,
    private urlService: UrlService) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.urlService.previousUrl$.subscribe(previousUrl => {
      this.previousUrl = previousUrl;
    }));

    this.subscription.add(this.urlService.currentUrl$.subscribe(currentUrl => {
      this.currentUrl = currentUrl;
    }));

    this.subscription.add(this.router.events.pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.previousUrl && this.currentUrl) {
        if (this.previousUrl.substring(0, this.currentUrl.length) === this.currentUrl) {
          window.history.go(-1);
        }
      }
    }));

    this.subscription.add(this.route.params.subscribe(params => {
      this.serialNumber = params[this.serialNumberId] as string;
      this.instrumentTypeCode = params[this.instrumentTypeCodeId] as string;
      this.deviceAddress = params[this.deviceAddressId] as string;
      this.zipCode = params[this.zipCodeId] as string;

      if (params[this.facilityIdParam]) {
        this.facilityId = +params[this.facilityIdParam];
      }

      if (params[this.orgIdParam]) {
        this.orgId = +params[this.orgIdParam];
      }

      this.loadOrphan();
      this.loadIdbRecord();
      this.loadInitData();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  loadFacilities(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacSelectionListByOrg(val).subscribe((facs: Facility[]) => {
          this.facilities = facs;
        }));
    }
  }

  loadFacility(val: number): void {
    if (!isNaN(val) && val > 0) {
      this.subscription.add(
        this.metadataService.getFacility(val).subscribe((fac: Facility) => {
          this.facility = fac;
        }));
    }
  }

  createNewOrganization(): void {
    void this.router.navigate([
      OrganizationsNavigationKeys.EditFullPath, 0,
      {
        serialnumber: this.serialNumber,
        deviceaddress: this.deviceAddress,
        zipcode: this.zipCode
      }
    ]);
  }

  createNewFacility(): void {
    void this.router.navigate([
      FacilitiesNavigationKeys.EditFullPath, 0, this.orgId,
      {
        serialnumber: this.serialNumber,
        deviceaddress: this.deviceAddress,
        zipcode: this.zipCode
      }
    ]);
  }

  public loadInitData(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.orgs = initData.Organizations;
    }));
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.orphanFormGroup.disable();
      }
    }));

  }

  public setValueChanges(): void {

    this.subscription.add(
      this.orphanFormGroup.get('organizationId').valueChanges.subscribe(val => {
        this.loadFacilities(+val);
        this.orgId = +val;
      }));

    this.subscription.add(
      this.orphanFormGroup.get('facilityId').valueChanges.subscribe(val => {
        this.loadFacility(+val);
      }));
  }

  public loadIdbRecord(): void {
    this.isUpdating = true;

    this.subscription.add(this.metadataService.getIdbRecordBySerialNumber(this.serialNumber)
      .subscribe({
        next: (idbRecordResult: IdbRecord) => {
          this.idbRecord = idbRecordResult;

          this.isUpdating = false;
        },
        error: () => {
          const message = `${translate(this.translate, `${this.translationBaseKey}.LoadIdbFailure`)} ${this.serialNumber}`;
          this.toastr.info(message);
          this.isUpdating = false;
        }
      }));
  }

  public loadOrphan(): void {
    this.isUpdating = true;

    this.subscription.add(this.metadataService.getInstrumentResultOrphanBySummary(this.serialNumber, this.deviceAddress, this.zipCode)
      .subscribe({
        next: (orphanResult: InstrumentResultOrphan) => {
          this.orphanResult = orphanResult;

          const facility = this.orphanResult.instrument && this.orphanResult.instrument.facility ?
            this.orphanResult.instrument.facility :
            this.orphanResult.pairedDevice && this.orphanResult.pairedDevice.facility ?
              this.orphanResult.pairedDevice.facility : null;

          console.debug(this.orphanResult);

          this.orphanFormGroup.setValue({
            deviceAddress: this.orphanResult.originatingDeviceAddress ? this.orphanResult.originatingDeviceAddress : '',
            serialNumber: this.orphanResult.instrumentSerialNumber ? this.orphanResult.instrumentSerialNumber : '',
            zipCode: this.orphanResult.zipCode ? this.orphanResult.zipCode : '',
            instrumentId: this.orphanResult.instrument ? this.orphanResult.instrument.instrumentId : 0,
            pairedDeviceId: this.orphanResult.pairedDevice ? this.orphanResult.pairedDevice.pairedDeviceId : 0,
            facilityId: this.facilityId ? this.facilityId : facility ? facility.facilityId : 0,
            organizationId: this.orgId ? this.orgId : facility ? facility.organization_OrganizationId : 0,
            createZipOverride: false,
            pairedDeviceOverride: this.orphanResult.instrument && this.orphanResult.instrument.pairedDeviceOverride ? this.orphanResult.instrument.pairedDeviceOverride : false
          });

          this.instrumentId = this.orphanResult.instrument ? this.orphanResult.instrument.instrumentId : 0;
          this.pairedDeviceId = this.orphanResult.pairedDevice ? this.orphanResult.pairedDevice.pairedDeviceId : 0;
          this.instrumentTypeCodeId = this.orphanResult.instrumentTypeCode;
          this.serialNumber = this.orphanResult.instrumentSerialNumber;
          this.isZipCodeOverrideMatched = this.orphanResult.instrument ? this.orphanResult.instrument.zipCodeOverrides === this.orphanResult.zipCode : false;
          this.orphanReason = this.orphanResult.orphanReason;

          this.showPairedDeviceOverride = this.orphanResult.originatingDeviceAddress?.toLowerCase().includes('unknown') ? true : false;

          this.isUpdating = false;
        },
        error: () => {
          const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.serialNumber} - ${this.deviceAddress} - ${this.zipCodeId}`;
          this.toastr.error(message,
            translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
          this.isUpdating = false;
        }
      }));
  }

  initForm(): void {
    this.orphanFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      serialNumber: [{ value: '', disabled: true }],
      deviceAddress: [{ value: '', disabled: true }],
      zipCode: [{ value: '', disabled: true }],
      instrumentId: [''],
      pairedDeviceId: [''],
      facilityId: ['', Validators.required],
      organizationId: ['', Validators.required],
      createZipOverride: [false],
      pairedDeviceOverride: [false]
      /* eslint-enable @typescript-eslint/unbound-method */
    });
    this.setValueChanges();

  }

  onPairedDevice(): void {
    // intentionally blank
  }

  public onAssign(): void {

    this.subscription.add(this.metadataService.assignInstrumentResultOrphans(this.orphanResult.instrumentResultOrphanId,
      this.facility.facilityId,
      this.orphanFormGroup.get('createZipOverride').value as boolean, this.orphanFormGroup.get('pairedDeviceOverride').value as boolean)
      .subscribe({
        next: () => {
          this.toastr.success(undefined, translate(this.translate, `${this.translationBaseKey}.${TranslateNotificationKeys.SaveSuccess}`));
          this.onCancel();
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
        }
      }));
  }

  public onCancel(): void {
    if (this.orgId && this.facilityId) {
      window.history.go(-2);
    } else {
      window.history.go(-1);
    }
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'Instrument Result Orphan' })) {
      this.subscription.add(
        this.metadataService.deleteInstrumentResultOrphansBySummary(this.serialNumber, this.deviceAddress, this.zipCode).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${this.translationBaseKey}.${TranslateNotificationKeys.DeleteSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
          }
        }));
    }
  }

  public onDownloadArchive(): void {
    this.subscription.add(
      this.metadataService.getInstrumentResultOrphanBlobArchiveBySummary(this.serialNumber, this.deviceAddress, this.zipCode).subscribe({
        next: (ret) => {
          console.debug(ret);

          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = this.serialNumber + '.zip';
          a.href = URL.createObjectURL(ret);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        },
        error: () => {
          const message = `${translate(this.translate, `${this.translationBaseKey}.DownloadFailure`)}`;
          this.toastr.error(message);
        }
      }));
  }

  onViewDetails(): void {
    void this.router.navigate([
      OrphansNavigationKeys.ResultsListFullPathWithId,
      {
        serialnumber: this.serialNumber,
        deviceaddress: this.deviceAddress,
        zipcode: this.zipCode
      }
    ]);
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {
    const modalWidth = 0.16 * window.outerWidth;
    this.confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = await firstValueFrom(this.confirmationDialogRef.afterClosed());
    return result;
  }
}


