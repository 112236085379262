// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, InstrumentsNavigationKeys } from '../../core';
import { InstrumentSearchComponent } from './instrument-search/instrument-search.component';
import { InstrumentEditComponent } from './instrument-edit/instrument-edit.component';

export const routes: Routes = [
  {
    path: InstrumentsNavigationKeys.Search, component: InstrumentSearchComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: InstrumentsNavigationKeys.NewFromFacilityEdit, component: InstrumentEditComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: InstrumentsNavigationKeys.EditWithIdArg, component: InstrumentEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class InstrumentsRoutingModule { }
