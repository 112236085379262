// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, ReportKeys } from '../core';
import { ReportPatientRunDateComponent } from './report-patient-run-date/report-patient-run-date.component';
import { ReportPatientAssayComponent } from './report-patient-assay/report-patient-assay.component';
import { ReportPatientFacilityAssayComponent } from './report-patient-facility-assay/report-patient-facility-assay.component';
import { ReportPatientFacilityResultComponent } from './report-patient-facility-result/report-patient-facility-result.component';
import { ReportPatientResultComponent } from './report-patient-result/report-patient-result.component';
import { ReportTestVolumeTypeComponent } from './report-test-volume-type/report-test-volume-type.component';
import { ReportPercentPositiveRunDateComponent } from './report-percent-positive-run-date/report-percent-positive-run-date.component';
import { ReportPatientResultTrendsComponent } from './report-patient-result-trends/report-patient-result-trends.component';
import { ReportQualityControlComponent } from './report-quality-control/report-quality-control.component';
import { ReportPatientCoInfectionComponent } from './report-patient-coinfection/report-patient-coinfection.component';

export const routes: Routes = [
  {
    path: ReportKeys.PatientsByRunDate, component: ReportPatientRunDateComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientsByAssay, component: ReportPatientAssayComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientsByFacilityAssay, component: ReportPatientFacilityAssayComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientsByFacilityResult, component: ReportPatientFacilityResultComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientsByResult, component: ReportPatientResultComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.TestVolumeByType, component: ReportTestVolumeTypeComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PercentPositiveResults, component: ReportPercentPositiveRunDateComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientResultTrends, component: ReportPatientResultTrendsComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.QualityControlReport, component: ReportQualityControlComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: ReportKeys.PatientCoInfections, component: ReportPatientCoInfectionComponent,
    canActivate: [NavigationGuard]
  }
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class ReportsRoutingModule { }
