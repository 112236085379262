import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ConfigurationService } from '../../services/configuration.service';
import {
  NavigationKeys, OrganizationsNavigationKeys, FacilitiesNavigationKeys, PairedDevicesNavigationKeys,
  InstrumentsNavigationKeys, UsersNavigationKeys, AssaysNavigationKeys, OrphansNavigationKeys, ResultsApiNavigationKeys
} from '../../utils/navigation.keys';
import { RoleAccessService } from '../../services/role-access.service';
import { MetadataService } from '../../services/metadata.service';
import { BaseComponent } from '../base/base.component';
import { TitleService } from '../../services/title.service';
import { QueryService } from '../../services/query.service';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent, AboutDialogData } from '../about/about.dialog';

@Component({
  selector: 'core-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  standalone: false
})
export class HeaderBarComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() toggleSidenav = new EventEmitter<void>();

  public showAdminControls: boolean;
  public userDisplayName: string;

  public title: string;
  public filtersApplied: boolean;

  public navigationKeys = NavigationKeys;

  public orgSearchFullPath = OrganizationsNavigationKeys.SearchFullPath;
  public facSearchFullPath = FacilitiesNavigationKeys.SearchFullPath;
  public devicesSearchFullPath = PairedDevicesNavigationKeys.SearchFullPath;
  public instrumentsSearchFullPath = InstrumentsNavigationKeys.SearchFullPath;
  public usersSearchFullPath = UsersNavigationKeys.SearchFullPath;
  public usersUnapprovedFullPath = UsersNavigationKeys.UnapprovedFullPath;
  public assaysListFullPath = AssaysNavigationKeys.ListFullPath;
  public orphansListFullPath = OrphansNavigationKeys.SearchFullPath;
  public resultsApiListFullPath = ResultsApiNavigationKeys.ListFullPath;

  public hasOrgSearchAccess: boolean;
  public hasOrgFacilitySearchAccess: boolean;
  public hasOrgAccess: boolean;
  public hasRouterAccess: boolean;
  public hasInstrumentSearchAccess: boolean;
  public hasUserSearchAccess: boolean;
  public hasUserUnapprovedAccess: boolean;
  public hasAssaysAccess: boolean;
  public hasOrphanAccess: boolean;
  public hasResultsApiAccess: boolean;

  private applicationVersion: string;
  private isProduction: boolean;
  private isAuthorized: boolean;

  constructor(
    private authService: MsalService,
    private metadataService: MetadataService,
    private configurationService: ConfigurationService,
    private accessService: RoleAccessService,
    private titleService: TitleService,
    private queryService: QueryService,
    private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    const config = this.configurationService.getConfiguration();
    this.isAuthorized = !config.isUnauthorized;
    this.applicationVersion = config.version;
    this.isProduction = config.production;
    this.initializeUserDisplayName();
    this.subscription.add(this.metadataService.getMe().subscribe(user => {
      this.userDisplayName = user ? user.displayName : undefined;
    }));

    this.subscription.add(this.titleService.titleTranslateKeyChanges.subscribe((newTitle: string) => {
      this.title = newTitle;
    }));

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      this.filtersApplied = filtersApplied;
    }));

    void this.checkForAccess();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  public logout(): void {
    this.authService.logout();
  }

  public showAbout(): void {
    const dialogData: AboutDialogData = {
      appVersion: this.applicationVersion,
      showLicensesLink: this.isProduction
    };

    this.dialog.open(AboutDialogComponent, {
      data: dialogData
    });
  }

  private async checkForAccess() {
    const results = await Promise.all([
      this.hasAccess(this.orgSearchFullPath),
      this.hasAccess(this.facSearchFullPath),
      this.hasAccess(this.devicesSearchFullPath),
      this.hasAccess(this.instrumentsSearchFullPath),
      this.hasAccess(this.usersSearchFullPath),
      this.hasAccess(this.usersUnapprovedFullPath),
      this.hasAccess(this.assaysListFullPath),
      this.hasAccess(this.orphansListFullPath),
      this.hasAccess(this.resultsApiListFullPath)
    ]);

    this.hasOrgSearchAccess = results[0];
    this.hasOrgFacilitySearchAccess = results[1];
    this.hasRouterAccess = results[2];
    this.hasInstrumentSearchAccess = results[3];
    this.hasUserSearchAccess = results[4];
    this.hasUserUnapprovedAccess = results[5];
    this.hasAssaysAccess = results[6];
    this.hasOrphanAccess = results[7];
    this.hasResultsApiAccess = results[8];

    this.showAdminControls = this.isAuthorized
      && results.some(bool => bool);
  }

  // WARNING: do NOT refactor it by removing properties and using direct method calls in the template.
  // It does NOT work and it's causing infinite loop at browser level when trying to initialize component.
  // Details:  https://github.com/angular/angular/issues/6696 and https://github.com/angular/angular/issues/24133
  private hasAccess(navigationKey: string): Promise<boolean> {
    return this.accessService.checkAccess(navigationKey);
  }

  private initializeUserDisplayName() {
    const msalUser = this.authService.instance.getActiveAccount();
    if (!msalUser || !msalUser.idTokenClaims) {
      return;
    }

    const typedIdToken = msalUser.idTokenClaims as { given_name: string, family_name: string };
    this.userDisplayName = `${typedIdToken.given_name} ${typedIdToken.family_name}`;
  }
}
