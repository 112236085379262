import { Component, OnInit, OnDestroy, EventEmitter, Output, ViewChild, TemplateRef, ElementRef, EmbeddedViewRef, ViewContainerRef, NgZone } from '@angular/core';
import {
  BaseComponent, Organization, OrganizationDistributor, Country, State, MetadataService, InitDataSelectionLists,
  User, Features, Facility, UserGroup, TitleService, FacilitiesNavigationKeys, OrphansNavigationKeys, SearchResultsPage,
  TranslateNotificationKeys, ConfirmationModalComponent, FilterService, HttpCachingClient,
  AddressSuggestion,
  AddressRequest,
  Address, FormUtility,
  ConfirmationModalInput,
  UpdateInstrumentTestResultsType,
  UpdateTestResultsRequest,
  UpdateTestResultsRequestType,
  IdbRecord, SortDirection, AddressSuggestionRequest
} from '../../../core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { createPopper, Instance } from '@popperjs/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-org-edit',
  templateUrl: './org-edit.component.html',
  styleUrls: ['./org-edit.component.scss'],
  standalone: false
})
export class OrgEditComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() closed = new EventEmitter();

  private paramId = 'id';
  private paramSerialNumber = 'serialnumber';
  private paramDeviceAddress = 'deviceaddress';
  private paramZipCode = 'zipcode';
  private testResultsDeletedEnabled: boolean;
  private deleteTestResults: boolean;

  private shareNationalFormControlName = 'shareNational';
  private shareStateFormControlName = 'shareState';

  public translationBaseKey = 'OrgEdit';
  public translationFacilityTableKey = 'FacilityTable';
  public newOrg = true;
  public org: Organization;
  public orgId: number;
  public orgFormGroup: UntypedFormGroup;
  public distributors: OrganizationDistributor[];
  public countries: Country[];
  public states: State[];
  public facilities: SearchResultsPage<Facility>;
  public isOrgAdmin: boolean;
  public isSysAdmin: boolean;
  public isForOrphan = false;
  public orphanSerialNumber: string;
  public orphanDeviceAddress: string;
  public orphanZipCode: string;
  public smartyStreets = false;
  public addressSuggestions: AddressSuggestion[] = [];
  public addressVerified = false;
  public idbRecord: IdbRecord;
  public canEditOrgBooleanSettings = false;

  enableAddFacility: boolean;

  @ViewChild('addressField', { read: ElementRef }) addressField: ElementRef<HTMLInputElement>;
  @ViewChild('addressSuggestions', { read: TemplateRef }) suggestionTemplate: TemplateRef<unknown>;

  private view: EmbeddedViewRef<unknown>;
  private popperRef: Instance;

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  pageSize = 20;
  pageNumber = 0;
  sortColumn = 'facName';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;
  displayColumns = ['facName', 'facCountry', 'facState', 'facCity'];
  sortColumns: Record<string, string> = {
    facName: 'Facility.Name',
    facCity: 'Facility.City',
    facState: 'Facility.State.Abbreviation',
    facCountry: 'Facility.Country.Name',
  };

  constructor(private router: Router, private metadataService: MetadataService, private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute, private location: Location, private titleService: TitleService,
    private toastr: ToastrService, private translate: TranslateService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private cachingClient: HttpCachingClient,
    private vcr: ViewContainerRef,
    private zone: NgZone) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.orgId = +params[this.paramId];

      if (params[this.paramSerialNumber] && params[this.paramDeviceAddress] && params[this.paramZipCode]) {
        this.isForOrphan = true;
        this.orphanSerialNumber = params[this.paramSerialNumber] as string;
        this.orphanDeviceAddress = params[this.paramDeviceAddress] as string;
        this.orphanZipCode = params[this.paramZipCode] as string;

        this.loadIdbRecord();
      }

      this.newOrg = this.orgId === 0;

      if (!this.newOrg) {
        this.loadOrg();
      } else {
        this.loadNew();
      }

      this.checkIsOrgAdmin();
      this.checkIsSysAdmin();
      this.checkCanEditOrgBooleanSettings();
      this.loadInitData();
      this.loadDistributors();
      this.checkWriteFlag();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  public loadInitData(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.countries = initData.Countries;

      if (this.newOrg && this.countries.length > 0) {
        this.orgFormGroup.get('countryId').setValue(this.countries[0].countryId);
        this.toggleSmartyStreets(this.countries[0].countryId);
      } else if (this.org && this.org.address) {
        this.toggleSmartyStreets(this.org.address.country_CountryId);
      }
    }));
  }

  public checkIsOrgAdmin(): void {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      this.isOrgAdmin = me.group.displayName === UserGroup.OrgAdmin.valueOf();
    }));
  }

  private checkIsSysAdmin() {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      this.isSysAdmin = me.group.displayName === UserGroup.SystemAdmin.valueOf();
    }));
  }

  private checkCanEditOrgBooleanSettings() {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {
      if (me.group.displayName === UserGroup.SystemAdmin.valueOf() || me.group.displayName === UserGroup.TechSupportAdmin.valueOf()) {
        this.canEditOrgBooleanSettings = true;
        this.orgFormGroup.get(this.shareNationalFormControlName)?.enable();
        this.orgFormGroup.get(this.shareStateFormControlName)?.enable();
      }
    }));
  }

  checkWriteFlag(): void {
    this.subscription.add(this.metadataService.getFeatures().subscribe((features: Features) => {
      if (!features.WriteEnabled) {
        this.orgFormGroup.disable();
      }

      this.testResultsDeletedEnabled = features.TestResultsDeleteEnabled;
      this.enableAddFacility = features.WriteEnabled;
    }));
  }

  createNewFacility(): void {
    void this.router.navigate([FacilitiesNavigationKeys.EditFullPath, 0, this.orgId]);
  }

  public loadDistributors(): void {
    this.subscription.add(this.metadataService.getOrganizationDistributors().subscribe((distributors: OrganizationDistributor[]) => {
      this.distributors = distributors;
    }));

  }

  public loadIdbRecord(): void {
    this.subscription.add(this.metadataService.getIdbRecordBySerialNumber(this.orphanSerialNumber)
      .subscribe({
        next: (idbRecordResult: IdbRecord) => {
          this.idbRecord = idbRecordResult;

        },
        error: () => {
          const message = `${translate(this.translate, `${this.translationBaseKey}.LoadIdbFailure`)} ${this.orphanSerialNumber}`;
          this.toastr.error(message,
            translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
        }
      }));
  }

  public loadNew(): void {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

      this.orgFormGroup.setValue({
        name: '',
        description: '',
        contactName: '',
        phone: '',
        mobilePhone: '',
        email: '',
        countryId: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        stateId: '',
        zipCode: '',
        distributorId: null,
        shareNational: true,
        shareState: true,
        rejectPatient: true,
        rejectStudyCodes: true,
        rejectOrderNumbers: true,
        nationalMapSub: true,
        crmId: ''
      });
      this.setValueChanges();
    }));

  }

  public setValueChanges(): void {

    this.subscription.add(
      this.orgFormGroup.get('countryId').valueChanges.subscribe(val => {
        this.toggleSmartyStreets(+val);
        this.loadStates(+val);
      }));

    this.subscription.add(
      this.orgFormGroup.get('address1').valueChanges.subscribe(val => {
        this.addressVerified = false;

        if (this.smartyStreets) {
          const req: AddressSuggestionRequest = { streetAddress: val as string };

          this.subscription.add(this.metadataService.getAddressSuggestions(req).subscribe((suggestions: AddressSuggestion[]) => {
            this.addressSuggestions = suggestions;
            this.showAddressSuggestions();
          }));
        }
      }));
  }

  public loadOrg(): void {
    this.subscription.add(this.metadataService.getOrganization(this.orgId).subscribe({
      next: (org: Organization) => {
        this.org = org;
        this.orgFormGroup.patchValue({
          name: this.org.name,
          description: this.org.description ? this.org.description : '',
          contactName: this.org.contactName ? this.org.contactName : '',
          phone: this.org.phone ? this.org.phone : '',
          mobilePhone: this.org.mobilePhone ? this.org.mobilePhone : '',
          email: this.org.email ? this.org.email : '',
          countryId: this.org.address ? this.org.address.country_CountryId : '',
          address2: this.org.address && this.org.address.streetAddress2 ? this.org.address.streetAddress2 : '',
          address3: this.org.address && this.org.address.streetAddress3 ? this.org.address.streetAddress3 : '',
          city: this.org.address && this.org.address.city ? this.org.address.city : '',
          stateId: this.org.address ? this.org.address.state_StateId : '',
          zipCode: this.org.address ? this.org.address.zipCode : '',
          rejectPatient: this.org.rejectPatientId ? this.org.rejectPatientId : false,
          rejectStudyCodes: this.org.rejectStudyCodes ? this.org.rejectStudyCodes : false,
          nationalMapSub: this.org.nationalMapSubscription ? this.org.nationalMapSubscription : false,
          rejectOrderNumbers: this.org.rejectOrderNumbers ? this.org.rejectOrderNumbers : false,
          distributorId: this.org.organizationDistributor_OrganizationDistributorId ? this.org.organizationDistributor_OrganizationDistributorId : null,
          shareNational: this.org.shareNational ? this.org.shareNational : false,
          shareState: this.org.shareState ? this.org.shareState : false,
          crmId: this.org.crmId ? this.org.crmId : ''
        });

        this.orgFormGroup.patchValue({
          address1: this.org.address && this.org.address.streetAddress1 ? this.org.address.streetAddress1 : ''
        }, { emitEvent: false });

      },
      error: () => {
        const message = `${translate(this.translate, `${this.translationBaseKey}.LoadFailure`)} ${this.orgId}`;
        this.toastr.error(message,
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));

    this.updateData();

    this.setValueChanges();
  }

  public updateOrg(): void {
    if (this.newOrg) {
      this.org = {
        organizationId: 0,
        name: this.orgFormGroup.get('name').value as string,
        description: FormUtility.emptyStringCheck(this.orgFormGroup.get('description').value as string),
        contactName: this.orgFormGroup.get('contactName').value as string,
        phone: this.orgFormGroup.get('phone').value as string,
        mobilePhone: this.orgFormGroup.get('mobilePhone').value as string,
        email: this.orgFormGroup.get('email').value as string,
        rejectPatientId: this.orgFormGroup.get('rejectPatient').value as boolean,
        rejectStudyCodes: this.orgFormGroup.get('rejectStudyCodes').value as boolean,
        nationalMapSubscription: this.orgFormGroup.get('nationalMapSub').value as boolean,
        rejectOrderNumbers: this.orgFormGroup.get('rejectOrderNumbers').value as boolean,
        shareNational: this.orgFormGroup.get(this.shareNationalFormControlName).value as boolean,
        shareState: this.orgFormGroup.get(this.shareStateFormControlName).value as boolean,
        organizationDistributor_OrganizationDistributorId: this.orgFormGroup.get('distributorId').value as number,
        lastModifiedBy: 'System',
        lastModifiedDate: new Date(),
        crmId: this.orgFormGroup.get('crmId').value as string,
        address: {
          addressId: 0,
          streetAddress1: this.orgFormGroup.get('address1').value as string,
          streetAddress2: FormUtility.emptyStringCheck(this.orgFormGroup.get('address2').value as string),
          streetAddress3: FormUtility.emptyStringCheck(this.orgFormGroup.get('address3').value as string),
          city: this.orgFormGroup.get('city').value as string,
          zipCode: this.orgFormGroup.get('zipCode').value as string,
          state_StateId: this.orgFormGroup.get('stateId').value as number,
          country_CountryId: this.orgFormGroup.get('countryId').value as number,
          lastModifiedBy: 'System'
        }
      };
    } else {
      this.org.name = this.orgFormGroup.get('name').value as string;
      this.org.description = FormUtility.emptyStringCheck(this.orgFormGroup.get('description').value as string);
      this.org.contactName = this.orgFormGroup.get('contactName').value as string;
      this.org.phone = this.orgFormGroup.get('phone').value as string;
      this.org.mobilePhone = this.orgFormGroup.get('mobilePhone').value as string;
      this.org.email = this.orgFormGroup.get('email').value as string;
      this.org.rejectPatientId = this.orgFormGroup.get('rejectPatient').value as boolean;
      this.org.rejectStudyCodes = this.orgFormGroup.get('rejectStudyCodes').value as boolean;
      this.org.nationalMapSubscription = this.orgFormGroup.get('nationalMapSub').value as boolean;
      this.org.rejectOrderNumbers = this.orgFormGroup.get('rejectOrderNumbers').value as boolean;
      this.org.crmId = this.orgFormGroup.get('crmId').value as string;
      this.org.organizationDistributor_OrganizationDistributorId = this.orgFormGroup.get('distributorId').value as number;
      this.org.lastModifiedBy = 'System';
      this.org.address.streetAddress1 = this.orgFormGroup.get('address1').value as string;
      this.org.address.streetAddress2 = FormUtility.emptyStringCheck(this.orgFormGroup.get('address2').value as string);
      this.org.address.streetAddress3 = FormUtility.emptyStringCheck(this.orgFormGroup.get('address3').value as string);
      this.org.address.city = this.orgFormGroup.get('city').value as string;
      this.org.address.zipCode = this.orgFormGroup.get('zipCode').value as string;
      this.org.address.country_CountryId = this.orgFormGroup.get('countryId').value as number;
      this.org.address.state_StateId = this.orgFormGroup.get('stateId').value as number;
      this.org.shareNational = this.orgFormGroup.get(this.shareNationalFormControlName).value as boolean;
      this.org.shareState = this.orgFormGroup.get(this.shareStateFormControlName).value as boolean;
      this.org.address.lastModifiedBy = 'System';
    }

    this.org.address.country = this.countries.find(c => c.countryId === this.org.address.country_CountryId);
    if (this.org.address.state_StateId != null || this.org.address.state_StateId > 0) {
      this.org.address.state = this.states.find(s => s.stateId === +this.org.address.state_StateId);
    }
  }

  populateAddressFromIdb(): void {
    if (this.idbRecord) {
      const shippingCountry = this.countries.find(c => c.name.toLowerCase() === this.idbRecord.shippingCountry.toLowerCase());
      const shippingState = this.states.find(s => s.abbreviation.toLowerCase() === this.idbRecord.shippingState.toLowerCase());

      this.orgFormGroup.patchValue({
        countryId: shippingCountry ? shippingCountry.countryId : '',
        address1: this.idbRecord.shippingStreetNumber && this.idbRecord.shippingStreetName ? `${this.idbRecord.shippingStreetNumber} ${this.idbRecord.shippingStreetName}` : '',
        city: this.idbRecord.shippingCity ? this.idbRecord.shippingCity : '',
        stateId: shippingState ? shippingState.stateId : '',
        zipCode: this.idbRecord.shippingZipCode ? this.idbRecord.shippingZipCode : '',
      });
    }
  }

  loadStates(val: number): void {
    if (!isNaN(val) && val > 0) {

      this.subscription.add(
        this.metadataService.getStates(val).subscribe((states: State[]) => {
          this.states = states;

          const stateControl = this.orgFormGroup.get('stateId');
          const desiredStateId = stateControl.value as number;
          const foundState = this.states.find(s => s.stateId === +desiredStateId);
          if (!foundState) {
            this.clearStateSelection();
          }

          if (this.states == null || this.states.length === 0) {
            // stateControl.disable();
            stateControl.reset({ value: null, disabled: true });
            //          stateControl.setValue(null);
          } else if (!this.orgFormGroup.disabled) {
            stateControl.enable();
          }
        }));
    }
  }

  private clearStateSelection() {
    this.orgFormGroup.patchValue({
      stateId: ''
    }, {
      emitEvent: false
    });
  }

  initForm(): void {
    this.orgFormGroup = this.formBuilder.group({
      /* eslint-disable @typescript-eslint/unbound-method */
      name: ['', Validators.required],
      description: [''],
      contactName: ['', Validators.required],
      phone: ['', [Validators.required]],
      mobilePhone: [''],
      email: ['', [Validators.required, Validators.email]],
      crmId: [''],
      countryId: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      city: ['', Validators.required],
      stateId: ['', Validators.required],
      zipCode: ['', Validators.required],
      distributorId: [null],
      shareNational: [{ value: true, disabled: true }],
      shareState: [{ value: true, disabled: true }],
      rejectPatient: [true],
      rejectStudyCodes: [true],
      rejectOrderNumbers: [true],
      nationalMapSub: [true]
      /* eslint-enable @typescript-eslint/unbound-method */
    });

  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData();
  }

  updateData(): void {

    this.subscription.add(
      this.metadataService.getFacilitiesByOrgId(
        this.orgId, this.pageNumber + 1, this.pageSize, this.sortColumns[this.sortColumn], this.sortDirection)
        .subscribe((facs: SearchResultsPage<Facility>) => {
          this.facilities = facs;
        }));

    this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';
  }

  public async onSave(): Promise<void> {
    this.updateOrg();

    if (this.smartyStreets && !this.addressVerified) {
      const valid = await this.validateAddress();

      if (!valid) {
        return;
      }
    }

    let saveObservable: Observable<number>;
    if (this.newOrg) {
      saveObservable = this.metadataService.postOrganization(this.org);
    } else {
      saveObservable = this.metadataService.putOrganization(this.org);
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: (ret) => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));
          this.newOrg = false;
          this.orgId = ret;
          this.loadOrg();

          this.filterService.setOrgsDirty(true);
          this.cachingClient.invalidate();

          if (this.isForOrphan) {
            void this.router.navigate(
              [
                OrphansNavigationKeys.EditFullPathWithId,
                {
                  serialnumber: this.orphanSerialNumber,
                  deviceaddress: this.orphanDeviceAddress,
                  zipcode: this.orphanZipCode,
                  organizationid: this.orgId
                },
              ],
              { replaceUrl: true });
          }
        },
        error: () => {
          this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
        }
      }));
  }

  public onCancel(): void {
    window.history.go(this.isOrgAdmin ? -2 : -1);
  }

  private async showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {

    const modalWidth = 0.25 * window.outerWidth;

    const confDia = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    return await firstValueFrom(confDia.afterClosed());
  }

  public async onDelete(): Promise<void> {
    if (await this.showConfirmationDialog({ titleResourceKey: 'ConfirmDelete', parameter: 'Organization' })) {
      const result = await this.showConfirmationDialog({ titleResourceKey: this.testResultsDeletedEnabled ? 'ConfirmDeleteTestResults' : 'ConfirmDeleteTestResultsUnavailable' });

      if (this.testResultsDeletedEnabled) {
        this.deleteTestResults = result;
      } else {
        this.deleteTestResults = false;
      }

      this.subscription.add(
        this.metadataService.deleteOrganization(this.org, this.deleteTestResults).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteSuccess}`));
            this.location.back();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.DeleteFailure}`));
          }
        }));

    }
  }

  public onRowSelect(fac: Facility): void {
    void this.router.navigate([FacilitiesNavigationKeys.EditFullPath, fac.facilityId]);
  }

  private showAddressSuggestions() {
    if (this.isOpen) {
      return;
    }

    this.view = this.vcr.createEmbeddedView(this.suggestionTemplate, this);
    const dropdown = this.view.rootNodes[0] as HTMLDivElement;

    document.body.appendChild(dropdown);
    dropdown.style.width = `${this.addressField.nativeElement.offsetWidth}px`;

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(this.addressField.nativeElement, dropdown);
    });

    this.handleClickOutside();
  }

  public onAddressSuggestionChosen(suggestion: AddressSuggestion): void {

    this.orgFormGroup.get('address1').setValue(suggestion.street, { emitEvent: false });
    this.orgFormGroup.get('city').setValue(suggestion.city);

    this.orgFormGroup.patchValue({
      stateId: suggestion.state_StateId
    });

    this.destroyPopperResources();
  }

  public async validateAddress(): Promise<boolean> {
    return new Promise<boolean>(res => {
      const country = this.countries.find(c => c.countryId === this.orgFormGroup.get('countryId').value);
      const state = this.states.find(s => s.stateId === this.orgFormGroup.get('stateId').value);

      const request: AddressRequest = {
        streetAddress1: this.orgFormGroup.get('address1').value as string,
        city: this.orgFormGroup.get('city').value as string,
        zipCode: this.orgFormGroup.get('zipCode').value as string,
        countryCode: country ? country.code : null,
        stateCode: state ? state.abbreviation : null
      };

      this.subscription.add(this.metadataService.validateAddress(request).subscribe(async (results: Address[]) => {
        this.addressVerified = results.length === 1;

        if (this.addressVerified) {
          const address = results[0];

          this.orgFormGroup.get('city').setValue(address.city);
          this.orgFormGroup.get('zipCode').setValue(address.zipCode);

          if (address.state_StateId) {
            this.orgFormGroup.patchValue({
              stateId: address.state_StateId
            });
          }
        } else {
          await this.confirmAddressIsValid();
        }

        res(this.addressVerified);
      }));
    });
  }

  private async confirmAddressIsValid(): Promise<boolean> {
    const modalWidth = 0.25 * window.outerWidth;
    const message = 'ConfirmAddressValid';

    const confDia = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: { titleResourceKey: message }
    });

    const result = await firstValueFrom(confDia.afterClosed());

    if (result) {
      this.addressVerified = true;
    }

    return result;
  }

  destroyPopperResources(): void {
    this.closed.emit();

    if (this.popperRef) {
      this.popperRef.destroy();
    }

    if (this.view) {
      this.view.destroy();
    }

    this.view = null;
    this.popperRef = null;
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const origin = this.popperRef.state.elements.reference as HTMLElement;
          const popper = this.popperRef.state.elements.popper;
          const contains = origin.contains(target as HTMLElement) || popper.contains(target as HTMLElement);
          return contains === false;
        }),
        takeUntil(this.closed)
      )
      .subscribe(() => {
        this.destroyPopperResources();
      });
  }

  private toggleSmartyStreets(countryId: number) {
    if (!this.countries) {
      return;
    }

    const country = this.countries.find(c => c.countryId === countryId);
    this.smartyStreets = country && country.code === 'US';
  }

  public async onShowUpdateTestResultsDialog(template: TemplateRef<unknown>): Promise<void> {
    const modalWidth = 0.25 * window.outerWidth;
    const dialogRef = this.dialog.open<unknown, unknown, { startDate: string, endDate: string, type: UpdateInstrumentTestResultsType } | 'false'>(template, {
      width: `${modalWidth}px`,
      data: { defaultStartDate: new Date(2010, 0, 1), defaultEndDate: new Date() }
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const type: UpdateInstrumentTestResultsType = result.type;

      const startMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.startDate, 'MM-DD-YYYY') :
        moment(result.startDate, 'MM-DD-YYYY');
      const endMoment = type === UpdateInstrumentTestResultsType.RunDate ?
        moment.utc(result.endDate, 'MM-DD-YYYY').endOf('day') :
        moment(result.endDate, 'MM-DD-YYYY').add(1, 'day').add(-1, 'second');

      const continueUpdate = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmUpdateTestResults', parameter: 'organization' });

      if (continueUpdate) {
        const request: UpdateTestResultsRequest = {
          id: this.orgId,
          startTime: startMoment.toDate(),
          endTime: endMoment.toDate(),
          updateType: type,
          updateRequestType: UpdateTestResultsRequestType.Organization
        };
        this.subscription.add(this.metadataService.UpdateInstrumentResultsOrgFac(request).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsSucceeded}`));
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.UpdateInstrumentTestResultsFailed}`));
          }
        }));
      }
    }
  }

  public IsDateRangeValid(startDate: string, endDate: string): boolean {
    return new Date(startDate) <= new Date(endDate);
  }
}
