import { Injectable } from '@angular/core';
import {
  NavigationKeys, OrganizationsNavigationKeys, FacilitiesNavigationKeys, PairedDevicesNavigationKeys,
  InstrumentsNavigationKeys, UsersNavigationKeys, OrphansNavigationKeys
} from '../utils/navigation.keys';
import { User } from './metadata.service.models';
import { MetadataService } from './metadata.service';
import { UserGroup } from '../utils/user-group.enum';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {

  private roleRoutesMap = new Map<string, string[]>();
  private userRole: UserGroup;
  private user: User;

  constructor(private metadataService: MetadataService) {

    console.debug('RoleAccessService: ctor');

    // TODO: consider changing to Map<route, UserGroup> for faster lookup when
    // loading configuration from external data source will be implemented.
    // For permission maintainability and readability leaved as is.
    this.roleRoutesMap.set(UserGroup.TechSupportAdmin, [
      NavigationKeys.Summary,
      NavigationKeys.Results,
      NavigationKeys.Mapping,
      NavigationKeys.Reports,
      NavigationKeys.ReportsWithWildcard,
      OrganizationsNavigationKeys.Module,
      OrganizationsNavigationKeys.EditFullPath,
      OrganizationsNavigationKeys.EditFullPathWithId,
      OrganizationsNavigationKeys.SearchFullPath,
      FacilitiesNavigationKeys.Module,
      FacilitiesNavigationKeys.SearchFullPath,
      FacilitiesNavigationKeys.EditFullPath,
      FacilitiesNavigationKeys.EditFullPathWithId,
      PairedDevicesNavigationKeys.Module,
      PairedDevicesNavigationKeys.SearchFullPath,
      InstrumentsNavigationKeys.Module,
      InstrumentsNavigationKeys.SearchFullPath,
      UsersNavigationKeys.Module,
      UsersNavigationKeys.SearchFullPath,
      UsersNavigationKeys.EditFullPath,
      UsersNavigationKeys.EditFullPathWithId,
      UsersNavigationKeys.UnapprovedFullPath,
      OrphansNavigationKeys.Module,
      OrphansNavigationKeys.SearchFullPath,
      OrphansNavigationKeys.ResultsListFullPath,
    ])
      .set(UserGroup.SalesAdmin, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
        UsersNavigationKeys.Module,
        UsersNavigationKeys.SearchFullPath,
        UsersNavigationKeys.EditFullPath,
        UsersNavigationKeys.EditFullPathWithId,
        UsersNavigationKeys.UnapprovedFullPath,
        OrphansNavigationKeys.Module,
        OrphansNavigationKeys.SearchFullPath,
        OrphansNavigationKeys.ResultsListFullPath,
      ])
      .set(UserGroup.OrgAdmin, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
        OrganizationsNavigationKeys.Module,
        OrganizationsNavigationKeys.EditFullPath,
        OrganizationsNavigationKeys.EditFullPathWithId,
        OrganizationsNavigationKeys.SearchFullPath,
        FacilitiesNavigationKeys.Module,
        FacilitiesNavigationKeys.SearchFullPath,
        FacilitiesNavigationKeys.EditFullPath,
        FacilitiesNavigationKeys.EditFullPathWithId,
        UsersNavigationKeys.Module,
        UsersNavigationKeys.SearchFullPath,
        UsersNavigationKeys.EditFullPath,
        UsersNavigationKeys.EditFullPathWithId,
        UsersNavigationKeys.UnapprovedFullPath,
      ])
      .set(UserGroup.NetworkOrganizationAdmin, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ])
      .set(UserGroup.Org, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ])
      .set(UserGroup.Global, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ])
      .set(UserGroup.Country, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ])
      .set(UserGroup.State, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ])
      .set(UserGroup.County, [
        NavigationKeys.Summary,
        NavigationKeys.Results,
        NavigationKeys.Mapping,
        NavigationKeys.Reports,
        NavigationKeys.ReportsWithWildcard,
      ]);
  }

  public async checkAccess(routePath: string): Promise<boolean> {

    this.user = await firstValueFrom(this.metadataService.getMe());

    if (!this.user) {
      return Promise.resolve(false);
    }

    const currentUser = await firstValueFrom(this.metadataService.getMe());
    if (currentUser.externalObjectId !== this.user.externalObjectId) {
      this.user = currentUser;
      this.userRole = undefined;
    }

    if (!this.userRole && this.user) {
      console.debug(this.user.group.displayName);
      this.userRole = this.user.group.displayName as UserGroup;
      console.debug(`user role:${this.userRole}`);
    }

    const isAcessGranted = this.hasRouteAccess(routePath);
    // console.debug(`Path: ${routePath} hasPermission: ${isAcessGranted}`);

    return Promise.resolve(isAcessGranted);
  }

  public getRole(): UserGroup {
    return this.userRole;
  }

  private hasRouteAccess(routePath: string): boolean {

    const hasAccess = this.checkSpecialPermission();
    if (hasAccess) { return true; }

    const routePathParts = routePath.split('/');
    if (this.roleRoutesMap && this.userRole) {
      const navigationRoutes = this.roleRoutesMap.get(this.userRole);
      if (navigationRoutes === undefined) {
        console.debug(`No defined routes for role:${this.userRole}`);
      } else {
        for (const route of navigationRoutes) {
          if (route.endsWith(NavigationKeys.Wildcard)) {
            let allRoutePartsValid = true;
            const routeParts = route.split('/');
            for (let index = 0; index < routeParts.length; index++) {
              const routePart = routeParts[index];
              const routePathPart = routePathParts[index];
              if (routePart !== NavigationKeys.Wildcard && routePart !== routePathPart) {
                allRoutePartsValid = false;
                break;
              }
            }
            if (allRoutePartsValid) {
              return true;
            }
          } else if (routePath === route) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private checkSpecialPermission(): boolean {

    // console.debug(`checkSpecialPermission(): ${this.msalUser} ${this.userRole}`);

    if (this.userRole) {
      const hasSpecialPermission = this.userRole === UserGroup.SystemAdmin;
      // console.debug(`checkSpecialPermission:${hasSpecialPermission}`);

      return hasSpecialPermission;
    }

    return false;
  }

  public isQuidelEmployee(): boolean {
    switch (this.getRole()) {
      case UserGroup.SystemAdmin:
      case UserGroup.SalesAdmin:
      case UserGroup.TechSupportAdmin:
      case UserGroup.Global:
        return true;
    }
    return false;
  }
}
