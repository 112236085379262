import {
  Component, OnInit, ViewChild,
  ComponentRef, ViewContainerRef,
  Type, EmbeddedViewRef, NgZone, ViewRef, ApplicationRef,
  createComponent,
  EnvironmentInjector
} from '@angular/core';
import { UIFilterBase } from './UIFilterBase';
import { FilterCustomAssaysComponent } from './filter-custom-assays/filter-custom-assays.component';
import { FilterCustomResultTypesComponent } from './result-types-custom-filter/result-types-custom-filter.component';
import { FilterCustomOrgsFacilitiesComponent } from './filter-custom-orgs-facilities/filter-custom-orgs-facilities.component';
import { FilterCustomGeoComponent } from './filter-custom-geo/filter-custom-geo.component';
import { UIQueryFilterBase } from './FilterElement';
import { FilterCustomDateComponent } from './filter-custom-date/filter-custom-date.component';
import { FilterCustomOperatorComponent } from './filter-custom-operator/filter-custom-operator.component';
import { FilterCustomZipcodeComponent } from './filter-custom-zipcode/filter-custom-zipcode.component';
import { FilterCustomSerialNumberComponent } from './filter-custom-serial-number/filter-custom-serial-number.component';
import { QueryService } from '../../services/query.service';
import { Router, NavigationEnd } from '@angular/router';
import { NavigationKeys } from '../../utils/navigation.keys';
import { FilterCustomFavoritesComponent } from './filter-custom-favorites/filter-custom-favorites.component';
import { createPopper, Instance, State } from '@popperjs/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FiltersSummaryComponent } from '../filters-summary/filters-summary.component';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'core-filter',
  templateUrl: './filter-core.component.html',
  styleUrls: ['./filter-core.component.scss'],
  standalone: false
})
export class FilterCoreComponent extends BaseComponent implements OnInit {
  @ViewChild('filtersContainer', { static: true, read: ViewContainerRef }) filtersContainerElement: ViewContainerRef;

  filters: UIFilterBase<UIQueryFilterBase>[] = [];
  filterTypes: Type<unknown>[] = [];

  private filtersComponentRef: ComponentRef<unknown>[] = [];

  hasActiveFilters = false;

  private view: ViewRef;
  private popperRef: Instance;

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  constructor(
    private queryService: QueryService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private appRef: ApplicationRef,
    private vcr: ViewContainerRef,
    private environmentInjector: EnvironmentInjector,
    private zone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    this.AddFilterComponents();

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      setTimeout(() => this.hasActiveFilters = filtersApplied);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.closeOtherOpenedFilters(null);
      this.close();
    }));

    this.routeEvent(this.router);
    this.UpdateExtendedFilterComponents();
  }

  ngOnDestroyInternal(): void {
    if (this.filtersComponentRef) {
      this.filtersComponentRef.forEach(f => {
        if (f) {
          f.changeDetectorRef.detach();
          f.destroy();
        }
      });
    }

    this.destroyResources();
  }

  routeEvent(router: Router): void {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.UpdateExtendedFilterComponents();
      }
    });
  }

  public showAppliedFiltersStatus(): void {
    this.bottomSheet.open(FiltersSummaryComponent);
  }

  private AddFilterComponents() {
    this.insertComponent(FilterCustomFavoritesComponent);
    this.insertComponent(FilterCustomDateComponent);
    this.insertComponent(FilterCustomAssaysComponent);
    this.insertComponent(FilterCustomResultTypesComponent);
    this.insertComponent(FilterCustomOrgsFacilitiesComponent);
    this.insertComponent(FilterCustomGeoComponent);
  }

  private UpdateExtendedFilterComponents() {
    const showExtendedFilters = this.router.url.includes(NavigationKeys.Summary) || this.router.url.includes(NavigationKeys.Results);

    if (showExtendedFilters) {
      if (!this.hasComponent(FilterCustomOperatorComponent)) {
        this.insertComponent(FilterCustomOperatorComponent);
      }
      if (!this.hasComponent(FilterCustomZipcodeComponent)) {
        this.insertComponent(FilterCustomZipcodeComponent);
      }
      if (!this.hasComponent(FilterCustomSerialNumberComponent)) {
        this.insertComponent(FilterCustomSerialNumberComponent);
      }
    } else {
      this.removeComponent(FilterCustomOperatorComponent);
      this.removeComponent(FilterCustomSerialNumberComponent);
      this.removeComponent(FilterCustomZipcodeComponent);
    }
  }

  tryToggle(filter: UIFilterBase<UIQueryFilterBase>, element: HTMLElement): void {
    this.closeOtherOpenedFilters(filter);
    this.close();

    filter.toggle();

    if (filter.isOpened) {
      const index = this.filters.indexOf(filter);

      if (index !== -1) {
        this.open(this.filterTypes[index], element);
      }
    }
  }

  isFilterActive(filter: UIFilterBase<UIQueryFilterBase>): boolean {
    if (!filter || !filter.hasAtLeastOneFilterSelected()) {
      return false;
    }

    // TODO: Need to compare against default Query using QueryService
    return true;
  }

  private closeOtherOpenedFilters(excluded: UIFilterBase<UIQueryFilterBase>) {
    this.filters.forEach(f => {
      if (!(f === excluded)) {
        f.isOpened = false;
      }
    });
  }

  private insertComponent<T>(componentType: Type<T>) {
    const componentRef: ComponentRef<T> = this.createComponent(this.environmentInjector, this.filtersContainerElement, componentType);
    this.filtersComponentRef.push(componentRef);

    componentRef.changeDetectorRef.detectChanges();
    const component: UIFilterBase<UIQueryFilterBase> = componentRef.instance as unknown as UIFilterBase<UIQueryFilterBase>;
    this.filters.push(component);
    this.filterTypes.push(componentType);
  }

  private createComponent<T>(environmentInjector: EnvironmentInjector, vCref: ViewContainerRef, type: Type<unknown>): ComponentRef<T> {

    // create component without adding it directly to the DOM
    const comp = createComponent(type, { environmentInjector, elementInjector: vCref.injector });

    return comp as ComponentRef<T>;
  }

  private hasComponent<T>(componentType: Type<T>): boolean {
    const componentRef: ComponentRef<T> = this.createComponent(this.environmentInjector, this.filtersContainerElement, componentType);
    const component = this.filtersComponentRef.find(ref => ref.componentType === componentRef.componentType);

    if (component) {
      return true;
    }

    return false;
  }

  private removeComponent<T>(componentType: Type<T>) {
    const componentRef: ComponentRef<T> = this.createComponent(this.environmentInjector, this.filtersContainerElement, componentType);
    const component = this.filtersComponentRef.find(ref => ref.componentType === componentRef.componentType);

    if (component) {
      const componentRefIndex = this.filtersComponentRef.indexOf(component);
      if (componentRefIndex > 0) {
        this.filtersComponentRef.splice(componentRefIndex, 1);
      }

      const containerElementIndex = this.filtersContainerElement.indexOf(component.hostView);
      if (containerElementIndex >= 0) {
        this.filtersContainerElement.remove(containerElementIndex);
      }

      const filterIndex = this.filters.indexOf(component.instance as UIFilterBase<UIQueryFilterBase>);
      if (filterIndex >= 0) {
        this.filters.splice(filterIndex, 1);
      }

      component.changeDetectorRef.detach();
      component.destroy();
    }
  }

  private open<T>(type: Type<T>, origin: HTMLElement) {

    if (this.isOpen) {
      return;
    }

    const componentRef = this.createComponent(this.environmentInjector, this.vcr, type);
    this.appRef.attachView(componentRef.hostView);

    this.view = componentRef.hostView;
    const domElement: HTMLElement = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;

    const component: UIFilterBase<UIQueryFilterBase> = componentRef.instance as UIFilterBase<UIQueryFilterBase>;
    component.isOpened = true;

    document.body.appendChild(domElement);

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, domElement, {
        placement: 'right-start',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport'
            }
          },
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ],
        onFirstUpdate: (state: State) => {
          state.elements.popper.classList.add('top-most');
        }
      });
    });
  }

  private close() {
    this.destroyResources();
  }

  private destroyResources() {
    if (this.popperRef) {
      this.popperRef.destroy();
    }

    if (this.view) {
      this.view.destroy();
    }

    this.view = null;
    this.popperRef = null;
  }
}
