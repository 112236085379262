<div class="orphan-detail-container">

    <div class="orphan-list">

      <mat-table #table class="mat-elevation-z8" [dataSource]="orphanResults?.results" matSort
        (matSortChange)="doSort($event)" matSortDisableClear="true" [matSortActive]="sortColumn"
        [matSortDirection]="sortMatDirection">

        <ng-container matColumnDef="instrumentResultOrphanId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.InstrumentResultOrphanId' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let orphan">{{orphan.instrumentResultOrphanId}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="storageDateUtc">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.StorageDate' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let orphan">{{orphan.storageDateUtc | date: storageDateFormat }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="serialNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.SerialNumber' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let orphan">{{orphan.instrumentSerialNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="errorCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.ErrorCode' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let orphan">{{orphan.errorCode}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="orphanReason">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ translationBaseKey + '.OrphanReason' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let orphan">{{orphan.orphanReason}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>

        <mat-row #matrow *matRowDef="let row; columns: displayColumns" [ngClass]="{hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="onRowSelect(row)"></mat-row>

      </mat-table>
    </div>
    <mat-paginator class="orphan-paginator" [length]="orphanResults?.totalResults" [pageSize]="pageSize"
      [pageSizeOptions]="[10,20,50,100]" [pageIndex]="pageNumber" (page)="doPage($event)" showFirstLastButtons="true">
    </mat-paginator>

  </div>
