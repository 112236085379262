import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, QueryService, SearchService, TitleService,
  TestResultsService, SearchSummaryTest, SearchSummaryInstrument, ThemeService, SearchConnectedInstrumentsResultsPage
} from '../core';
import { SortOrder, ColumnSort } from '../utils/sorting';
import { PageEvent } from '@angular/material/paginator';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  standalone: false
})
export class SummaryComponent extends BaseComponent implements OnInit, OnDestroy {

  selectedTab = -1;
  testsSummary: TestsSummary = { Total: 0, Invalid: 0, Tests: [] };
  expandedRows: number[] = [];
  instrumentsSummary: InstrumentsSummary = { Total: 0, Reporting: 0, PercentageReporting: 0, Instruments: [] };
  news = { header: '', body: '' };

  testsSort: Record<string, ColumnSort> = {};
  testsSortOrder: SortOrder[] = [];
  instrumentsSort: Record<string, unknown> = {};
  instrumentsSortOrder: SortOrder[] = [];

  public pageSizeOptions: number[] = [10, 20, 50, 100];
  public currentInstrumentsPage = 1;
  public rowsPerPage = 50;

  private instrumentSortMap = {
    isReporting: 'Instrument.IsReporting',
    serialNumber: 'Instrument.SerialNumber',
    facilityName: 'Facility.Name',
    streetAddress1: 'Facility.StreetAddress1',
    stateCode: 'Facility.State.Abbreviation',
    zipCode: 'Facility.ZipCode'
  };

  constructor(
    private queryService: QueryService,
    private searchService: SearchService,
    private testResultsService: TestResultsService,
    private titleService: TitleService,
    private themeService: ThemeService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.themeService.enableSummaryTheme();
    this.titleService.updateTitleTranslateKey('Summary.Title');

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.onSearch();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onSearch(): void {
    this.googleAnalyticsService.logWithLabels('Search', 'Summary', 'Search', 'filters', this.queryService.getCurrentQuery(true));
    this.onSearchSummaries();
    this.onSearchInstruments();

    this.subscription.add(this.testResultsService.getNews().subscribe(result => {
      this.news = result;
    }));
  }

  onSearchSummaries(): void {
    this.subscription.add(this.searchService.getSearchSummaries(this.queryService.getCurrentQuery(true)).subscribe(
      (result: SearchSummaryTest[]) => {
        const summary: TestsSummary = {
          Tests: result,
          Total: result.map(t => t.totalTests).reduce((a, b) => a + b, 0),
          Invalid: result.map(t => t.totalInvalid).reduce((a, b) => a + b, 0)
        };

        this.testsSummary = summary;
        this.searchService.searchResultsCompletedSubject.next(summary.Total);
      }));
  }

  onSearchInstruments(): void {

    let sortColumn: string = null;
    let sortDirection = 0;

    if (this.instrumentsSortOrder && this.instrumentsSortOrder.length > 0) {
      sortColumn = this.instrumentSortMap[this.instrumentsSortOrder[0].property] as string;
      sortDirection = this.instrumentsSortOrder[0].direction === ColumnSort.Ascending ? 0 : 1;
    }

    this.subscription.add(this.searchService.getSearchConnectedInstruments(this.queryService.getCurrentQuery(true), this.currentInstrumentsPage, this.rowsPerPage, sortColumn, sortDirection).subscribe(
      (result: SearchConnectedInstrumentsResultsPage) => {
        const summary: InstrumentsSummary = {
          Total: result.totalResults,
          Reporting: result.totalReportingInstruments || 0,
          PercentageReporting: 0,
          Instruments: result.results
        };

        if (isNaN(summary.Total)) {
          summary.Total = 0;
        }

        if (isNaN(summary.Reporting)) {
          summary.Reporting = 0;
        }

        summary.PercentageReporting = ((summary.Reporting / summary.Total) * 100);

        if (isNaN(summary.PercentageReporting)) {
          summary.PercentageReporting = 0;
        }

        summary.Instruments.forEach(i => {
          if (!i.isReporting) {
            i.isReporting = false;
          }
        });

        this.instrumentsSummary = summary;
      }));
  }

  onTabSelected(index: number): void {
    this.expandedRows = [];

    if (this.selectedTab === index) {
      this.selectedTab = -1;
    } else {
      this.selectedTab = index;
    }

    if (this.selectedTab === 0) {
      this.googleAnalyticsService.log('Test Results Panel', 'Summary', 'Expand Test Results Panel');
    }
    else if (this.selectedTab === 1) {
      this.googleAnalyticsService.log('Instruments Panel', 'Summary', 'Expand Instruments Panel');
    }
    else if (this.selectedTab === 2) {
      this.googleAnalyticsService.log('News Panel', 'Summary', 'Expand News Panel');
    }
  }

  onRowSelected(row: number): void {
    if (this.expandedRows.includes(row)) {
      const index = this.expandedRows.indexOf(row);
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows.push(row);
    }
  }

  onTestsColumnSort(property: string, event: MouseEvent): void {
    this.expandedRows = [];

    let sortDirection: ColumnSort;

    switch (this.testsSort[property]) {
      case ColumnSort.None:
        sortDirection = ColumnSort.Ascending;
        break;
      case ColumnSort.Ascending:
        sortDirection = ColumnSort.Descending;
        break;
      case ColumnSort.Descending:
        sortDirection = ColumnSort.None;
        break;
      default:
        sortDirection = ColumnSort.Ascending;
        break;
    }

    if (!event.ctrlKey) {
      this.testsSort = {};
      this.testsSortOrder = [];
    }

    this.testsSort[property] = sortDirection;

    const sort = this.testsSortOrder.find(s => s.property === property);

    if (sort) {
      if (sortDirection === ColumnSort.None) {
        this.testsSortOrder.splice(this.testsSortOrder.indexOf(sort), 1);
      } else {
        this.testsSortOrder[this.testsSortOrder.indexOf(sort)].direction = sortDirection;
      }
    } else if (sortDirection !== ColumnSort.None) {
      this.testsSortOrder.push({ property, direction: sortDirection });
    }

    this.applyTestsSort();
  }

  private applyTestsSort() {

    const sort: string[] = [];

    if (this.testsSortOrder.length > 0) {
      this.testsSortOrder.forEach((item) => sort.push((item.direction === ColumnSort.Descending ? '-' : '') + item.property));
    } else {
      sort.push('testName');
    }

    // Sort child analytes
    this.testsSummary.Tests.forEach(t => t.analytes.sort(this.dynamicSortMultiple(...sort)));

    // Sort parent tests
    this.testsSummary.Tests = this.testsSummary.Tests.sort(this.dynamicSortMultiple(...sort));
  }

  onInstrumentsColumnSort(property: string, event: MouseEvent): void {
    this.expandedRows = [];

    let sortDirection: ColumnSort;

    switch (this.instrumentsSort[property]) {
      case ColumnSort.None:
        sortDirection = ColumnSort.Ascending;
        break;
      case ColumnSort.Ascending:
        sortDirection = ColumnSort.Descending;
        break;
      case ColumnSort.Descending:
        sortDirection = ColumnSort.None;
        break;
      default:
        sortDirection = ColumnSort.Ascending;
        break;
    }

    this.instrumentsSort = {};
    this.instrumentsSortOrder = [];
    this.instrumentsSort[property] = sortDirection;

    const sort = this.instrumentsSortOrder.find(s => s.property === property);

    if (sort) {
      if (sortDirection === ColumnSort.None) {
        this.instrumentsSortOrder.splice(this.instrumentsSortOrder.indexOf(sort), 1);
      } else {
        this.instrumentsSortOrder[this.instrumentsSortOrder.indexOf(sort)].direction = sortDirection;
      }
    } else if (sortDirection !== ColumnSort.None) {
      this.instrumentsSortOrder.push({ property, direction: sortDirection });
    }

    this.onSearchInstruments();
  }

  private dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substring(1);
    }
    return (a: object, b: object) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  private dynamicSortMultiple(...args: string[]) {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    const props = args;
    return (obj1: object, obj2: object) => {
      let i = 0;
      let result = 0;
      const numberOfProperties = props.length;

      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i++;
      }

      return result;
    };
  }

  loadInstrumentsLazy(event: PageEvent): void {
    console.debug('loadInstrumentsLazy');
    console.debug(event);

    this.currentInstrumentsPage = event.pageIndex + 1;

    this.onSearchInstruments();
  }
}

interface TestsSummary {
  Total: number;
  Invalid: number;
  Tests: SearchSummaryTest[];
}

interface InstrumentsSummary {
  Total: number;
  Reporting: number;
  PercentageReporting: number;
  Instruments: SearchSummaryInstrument[];
}
