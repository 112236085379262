import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationModalInput {
  titleResourceKey: string;
  parameter?: string;
}

@Component({
  selector: 'core-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  standalone: false
})
export class ConfirmationModalComponent {

  public static ModalConfirmationDialogKey = 'ModalConfirmationDialog';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalInput,
  ) { }

  onYesClick(): void {
    console.debug('ConfirmationModalComponent: Yes');
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    console.debug('ConfirmationModalComponent: No');
    this.dialogRef.close(false);
  }

}
