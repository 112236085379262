import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FavoriteModalTypes, FavoriteModalData, EmailSubscriptionScheduleTypes, EmailSubscriptionScheduleTypeDescriptions, EmailSubscription } from './add-edit-modal.component.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FilterService } from '../../../../../../services/filter.service';
import { MetadataService } from '../../../../../../services/metadata.service';
import { UserFavorite, User, UserFavoriteSchedule } from '../../../../../../services/metadata.service.models';
import { FavoritesUIQuery } from '../../../filter-custom-favorites.model';
import { ReportKeys } from '../../../../../..//utils/report.keys';
import { RoleAccessService } from 'src/app/core/services/role-access.service';
import { UserGroup } from 'src/app/core/utils/user-group.enum';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'core-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
  standalone: false
})
export class AddEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<AddEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FavoriteModalData,
    private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    private metadataService: MetadataService,
    private roleAccessService: RoleAccessService
  ) {
    /* eslint-disable @typescript-eslint/no-base-to-string */
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    console.debug(`AddEditModalComponent: ${data}`);
    /* eslint-enable @typescript-eslint/no-base-to-string */
    /* eslint-enable @typescript-eslint/restrict-template-expressions */

    this.modalData = data;
    this.isReadOnlyMode = false;
    if (this.modalData.mode === FavoriteModalTypes.Readonly) {
      this.isReadOnlyMode = true;
    }

    this.showEmailSubscription = this.roleAccessService.getRole() !== UserGroup.TechSupportAdmin && this.roleAccessService.getRole() !== UserGroup.SalesAdmin;
    this.doNotShareWithOrganization = this.roleAccessService.getRole() == UserGroup.NetworkOrganizationAdmin;

    /* eslint-disable @typescript-eslint/unbound-method */
    this.favoritesFormGroup = this.formBuilder.group({
      name: new UntypedFormControl({ value: '', disabled: this.isReadOnlyMode }, [Validators.required, Validators.maxLength(50)]),
      export: new UntypedFormControl({ value: false, disabled: this.isReadOnlyMode || this.doNotShareWithOrganization }),
      email: new UntypedFormControl({ value: '', disabled: false }, [Validators.maxLength(this.emailMaxLength), Validators.email, this.isAtLeastOneEmailAndReportPresent.bind(this)]),
    });
    /* eslint-enable @typescript-eslint/unbound-method */

    this.favoriteUIQuery = this.filterService.getFavoritesQuery();

    this.cancelCloseTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.CancelTitle`;
    this.removeEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.RemoveTitle`;
    this.addUpdateEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.AddTitle`;
    if (this.modalData.mode === FavoriteModalTypes.Add) {
      this.title = `${AddEditModalComponent.FavortiesAddEditDialogKey}.AddTitle`;
      this.confirmationTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.AddTitle`;

      this.selectedEmailScheduleDescription = this.getEmailScheduleDescription(EmailSubscriptionScheduleTypes.None);

    } else if (this.modalData.mode === FavoriteModalTypes.Edit) {

      this.setView(this.modalData);

      this.title = `${AddEditModalComponent.FavortiesAddEditDialogKey}.EditTitle`;
      this.confirmationTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.SaveTitle`;
    } else if (this.modalData.mode === FavoriteModalTypes.Readonly) {
      this.confirmationTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.UpdateTitle`;
      this.cancelCloseTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.CloseTitle`;

      this.setView(this.modalData);
    }
    this.updateReportPermissions();

  }
  get selectedEmailScheduleDescription(): string {
    return this._selectedEmailScheduleDescription;
  }
  set selectedEmailScheduleDescription(value: string) {
    const previousValue = this._selectedEmailScheduleDescription;
    this._selectedEmailScheduleDescription = value;

    for (const kv of EmailSubscriptionScheduleTypeDescriptions) {
      const key = kv[0];
      const description = kv[1];

      if (description === value) {
        this.selectedEmailSchedule = EmailSubscriptionScheduleTypes[key] as EmailSubscriptionScheduleTypes;
        this.forceEmailValidation();
        this.favoritesFormGroup.updateValueAndValidity();
        if (previousValue) {
          this.isEmailsSubscriptionListDirty = true;
        }
        console.debug(this.selectedEmailSchedule);
        break;
      }
    }
  }

  public static FavortiesAddEditDialogKey = 'FavortiesAddEditDialog';

  private _selectedEmailScheduleDescription: string;
  private readonly emailMaxLength = 320;

  modalData: FavoriteModalData;
  isReadOnlyMode = false;

  title: string;
  confirmationTitle: string;
  cancelCloseTitle: string;

  favoritesFormGroup: UntypedFormGroup;

  emailSubscriptionScheduleTypesMap = EmailSubscriptionScheduleTypeDescriptions;
  selectedEmailSchedule: EmailSubscriptionScheduleTypes;
  selectedEmail: EmailSubscription;
  addUpdateEmailTitle: string;
  removeEmailTitle: string;

  public showEmailSubscription: boolean;
  public doNotShareWithOrganization: boolean;

  private _isCheckedCsvReport = false;
  get isCheckedCsvReport(): boolean {
    return this._isCheckedCsvReport;
  }
  set isCheckedCsvReport(value: boolean) {
    this._isCheckedCsvReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedCsvByStorageDateReport = false;
  get isCheckedCsvByStorageDateReport(): boolean {
    return this._isCheckedCsvByStorageDateReport;
  }
  set isCheckedCsvByStorageDateReport(value: boolean) {
    this._isCheckedCsvByStorageDateReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPdfReport = false;
  get isCheckedPdfReport(): boolean {
    return this._isCheckedPdfReport;
  }
  set isCheckedPdfReport(value: boolean) {
    this._isCheckedPdfReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientsByRunDateReport = false;
  get isCheckedPatientsByRunDateReport(): boolean {
    return this._isCheckedPatientsByRunDateReport;
  }
  set isCheckedPatientsByRunDateReport(value: boolean) {
    this._isCheckedPatientsByRunDateReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientsByAssayReport = false;
  get isCheckedPatientsByAssayReport(): boolean {
    return this._isCheckedPatientsByAssayReport;
  }
  set isCheckedPatientsByAssayReport(value: boolean) {
    this._isCheckedPatientsByAssayReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientsByFacilityAssayReport = false;
  get isCheckedPatientsByFacilityAssayReport(): boolean {
    return this._isCheckedPatientsByFacilityAssayReport;
  }
  set isCheckedPatientsByFacilityAssayReport(value: boolean) {
    this._isCheckedPatientsByFacilityAssayReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientsByFacilityResultReport = false;
  get isCheckedPatientsByFacilityResultReport(): boolean {
    return this._isCheckedPatientsByFacilityResultReport;
  }
  set isCheckedPatientsByFacilityResultReport(value: boolean) {
    this._isCheckedPatientsByFacilityResultReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientsByResultReport = false;
  get isCheckedPatientsByResultReport(): boolean {
    return this._isCheckedPatientsByResultReport;
  }
  set isCheckedPatientsByResultReport(value: boolean) {
    this._isCheckedPatientsByResultReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientResultTrendsReport = false;
  get isCheckedPatientResultTrendsReport(): boolean {
    return this._isCheckedPatientResultTrendsReport;
  }
  set isCheckedPatientResultTrendsReport(value: boolean) {
    this._isCheckedPatientResultTrendsReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPercentPositiveResultsReport = false;
  get isCheckedPercentPositiveResultsReport(): boolean {
    return this._isCheckedPercentPositiveResultsReport;
  }
  set isCheckedPercentPositiveResultsReport(value: boolean) {
    this._isCheckedPercentPositiveResultsReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedPatientCoInfectionsReport = false;
  get isCheckedPatientCoInfectionsReport(): boolean {
    return this._isCheckedPatientCoInfectionsReport;
  }
  set isCheckedPatientCoInfectionsReport(value: boolean) {
    this._isCheckedPatientCoInfectionsReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedQualityControlReportReport = false;
  get isCheckedQualityControlReportReport(): boolean {
    return this._isCheckedQualityControlReportReport;
  }
  set isCheckedQualityControlReportReport(value: boolean) {
    this._isCheckedQualityControlReportReport = value;
    this.forceEmailValidation();
  }

  private _isCheckedTestVolumeByTypeReport = false;
  get isCheckedTestVolumeByTypeReport(): boolean {
    return this._isCheckedTestVolumeByTypeReport;
  }
  set isCheckedTestVolumeByTypeReport(value: boolean) {
    this._isCheckedTestVolumeByTypeReport = value;
    this.forceEmailValidation();
  }

  isPatientsByRunDateEnabled: boolean;
  isPatientsByAssayEnabled: boolean;
  isPatientsByFacilityAssayEnabled: boolean;
  isPatientsByFacilityResultEnabled: boolean;
  isPatientsByResultEnabled: boolean;
  isPatientResultTrendsEnabled: boolean;
  isPercentPositiveResultEnabled: boolean;
  isPatientCoInfectionsEnabled: boolean;
  isQualityControlReportEnabled: boolean;
  isCsvEnabled: boolean;
  isCsvByStorageDateEnabled: boolean;
  isTestVolumeByTypeEnabled: boolean;

  emailsSubscriptionList: EmailSubscription[] = [];
  isEmailsSubscriptionListDirty: boolean;
  displayedColumns = ['emailColumn'];
  selectedRowIndex = -1;

  private favoriteUIQuery: FavoritesUIQuery;

  async onAddEditClick(): Promise<void> {
    console.debug('AddEditModalComponent: Add/Save');

    if (this.modalData.mode === FavoriteModalTypes.Add) {
      const userFavorite = await this.getModel();
      const added = await this.favoriteUIQuery.addFavorite(userFavorite);

      console.debug(`added:${added}`);
    } else if (this.modalData.mode === FavoriteModalTypes.Edit || this.modalData.mode === FavoriteModalTypes.Readonly) {

      const fav = this.modalData.favorite;

      const user = await firstValueFrom(this.metadataService.getMe());
      const updatedSchedule = this.getScheduleModel(user);
      if (updatedSchedule && this.modalData.schedule) {
        updatedSchedule.userFavorites_UserFavoriteId = this.modalData.schedule.userFavorites_UserFavoriteId;
        updatedSchedule.userFavoriteScheduleId = this.modalData.schedule.userFavoriteScheduleId;
        updatedSchedule.userDetail_UserDetailId = this.modalData.schedule.userDetail_UserDetailId;
      }

      const name = this.getName();
      const shareWithOrgSelected = this.getShareWithOrgSelected();

      fav.setName(name);
      fav.setShareWithOrg(shareWithOrgSelected);
      fav.setSchedule(updatedSchedule);
    }

    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    console.debug('AddEditModalComponent: Cancel');
    this.dialogRef.close(false);
  }

  private getEmailScheduleDescription(schedule: EmailSubscriptionScheduleTypes): string {
    for (const kv of EmailSubscriptionScheduleTypeDescriptions) {
      const key = kv[0];
      const description = kv[1];
      if (key === schedule.valueOf()) {
        return description;
      }
    }

    return undefined;
  }

  selectEmailForEdit(row: EmailSubscription): void {
    if (this.selectedEmail && row && this.selectedEmail.id === row.id) {
      this.selectedRowIndex = -1;
      this.selectedEmail = undefined;
      this.addUpdateEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.AddTitle`;
    } else {
      this.selectedRowIndex = row.id;
      this.selectedEmail = row;
      this.addUpdateEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.UpdateTitle`;
      console.debug(row);
    }

    if (this.selectedEmail) {
      this.favoritesFormGroup.setValue({
        email: this.selectedEmail.email,
        name: this.getName(),
        export: this.getShareWithOrgSelected()
      });
    } else {
      this.favoritesFormGroup.setValue({
        email: '',
        name: this.getName(),
        export: this.getShareWithOrgSelected()
      });
    }

  }

  addUpdateEmail(): void {
    const emailValue = this.getEmail();
    const isValid = true;

    if (emailValue && isValid) {

      // the simplest way to support IsDirty when it's needed for ReadOnly mode when an user doesn't own the favorite but could edit email list
      this.isEmailsSubscriptionListDirty = true;
      if (!this.selectedEmail) {

        const email: EmailSubscription = { id: -1, email: emailValue, hovered: false };
        this.emailsSubscriptionList.push(email);
        this.emailsSubscriptionList = [...this.emailsSubscriptionList];
        this.updateEmailsSubscriptionListIds();

        console.debug(this.emailsSubscriptionList);
      } else { // update email
        const updateEmail = this.emailsSubscriptionList.find(i => i.id === this.selectedEmail.id);
        updateEmail.email = emailValue;
      }

    }

    this.forceEmailValidation();

  }

  removeEmail(): void {
    if (!this.selectedEmail) {
      return;
    }

    this.isEmailsSubscriptionListDirty = true;
    this.emailsSubscriptionList = [...this.emailsSubscriptionList.filter(i => i.id !== this.selectedEmail.id)];
    this.selectedEmail = undefined;
    this.favoritesFormGroup.patchValue({
      email: ''
    });

    this.updateEmailsSubscriptionListIds();
    this.forceEmailValidation();
  }

  private updateEmailsSubscriptionListIds() {
    for (let i = 0; i < this.emailsSubscriptionList.length; i++) {
      this.emailsSubscriptionList[i].id = i;
    }
  }

  private forceEmailValidation() {

    const value = this.getEmail();

    this.favoritesFormGroup.setValue({
      name: this.getName(),
      export: this.getShareWithOrgSelected(),
      email: ' '
    });

    this.favoritesFormGroup.setValue({
      name: this.getName(),
      export: this.getShareWithOrgSelected(),
      email: value
    });

    if (this.selectedEmail && this.selectedEmail.id === this.selectedRowIndex) {
      this.addUpdateEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.UpdateTitle`;
    } else {
      this.addUpdateEmailTitle = `${AddEditModalComponent.FavortiesAddEditDialogKey}.AddTitle`;
    }
  }

  private isAtLeastOneReportSelected(): boolean {
    return this.isCheckedCsvReport ||
      this.isCheckedPdfReport ||
      this.isCheckedPatientsByRunDateReport ||
      this.isCheckedPatientsByAssayReport ||
      this.isCheckedPatientsByFacilityAssayReport ||
      this.isCheckedPatientsByFacilityResultReport ||
      this.isCheckedPatientsByResultReport ||
      this.isCheckedPatientResultTrendsReport ||
      this.isCheckedPercentPositiveResultsReport ||
      this.isCheckedPatientCoInfectionsReport ||
      this.isCheckedQualityControlReportReport ||
      this.isCheckedTestVolumeByTypeReport ||
      this.isCheckedCsvByStorageDateReport;

  }

  private isAtLeastOneEmailAndReportPresent(c: UntypedFormControl) {

    let hasAtLeastOneEmailIsValid = false;
    let hasAtLeastOneReportIsValid = false;
    if (this && this.favoritesFormGroup && this.emailsSubscriptionList) {
      console.debug(this.selectedEmailSchedule);
      if (this.selectedEmailSchedule !== EmailSubscriptionScheduleTypes.None) {
        if (this.emailsSubscriptionList.length > 0) {
          hasAtLeastOneEmailIsValid = true;
        } else {
          hasAtLeastOneEmailIsValid = false;
        }

        if (this.isAtLeastOneReportSelected()) {
          hasAtLeastOneReportIsValid = true;
        }
      } else { // None schedule selected
        hasAtLeastOneEmailIsValid = true;
        hasAtLeastOneReportIsValid = true;
      }
    }

    if (hasAtLeastOneEmailIsValid && hasAtLeastOneReportIsValid) {
      return true;
    } else if (!hasAtLeastOneEmailIsValid && hasAtLeastOneReportIsValid) {
      return {
        isAtLeastOneEmailPresent: true
      };
    } else if (hasAtLeastOneEmailIsValid && !hasAtLeastOneReportIsValid) {
      return {
        isAtLeastOneReportSelected: true
      };
    } else if (!hasAtLeastOneEmailIsValid && !hasAtLeastOneReportIsValid) {
      return {
        isAtLeastOneReportSelected: true,
        isAtLeastOneEmailPresent: true
      };

    }
  }

  public hasErrorEmail = (errorName: string): boolean => {
    // console.debug(this.favoritesFormGroup.controls.email);
    return this.favoritesFormGroup.controls.email.hasError(errorName);
  };

  private async getModel(): Promise<UserFavorite> {
    const user = await firstValueFrom(this.metadataService.getMe());

    const filterState = await this.filterService.serializeState();

    const userFavorite: UserFavorite = {
      name: this.getName(),
      filterState,
      userDetail_UserDetailId: user.userDetailId,
      shareWithOrg: this.getShareWithOrgSelected()
    };

    const schedule = this.getScheduleModel(user);
    userFavorite.schedule = schedule;

    return userFavorite;
  }

  private getScheduleModel(user: User): UserFavoriteSchedule {
    const scheduleEmails = this.emailsSubscriptionList.map(i => i.email);
    const outputTypes = this.getScheduleReports();



    const result: UserFavoriteSchedule = {
      userDetail_UserDetailId: user.userDetailId,
      scheduleFrequency: this.selectedEmailSchedule,
      emails: JSON.stringify(scheduleEmails),
      outputTypes: JSON.stringify(outputTypes),
    };

    if (this.modalData.schedule && this.modalData.schedule.userFavoriteScheduleId) {
      result.userFavoriteScheduleId = this.modalData.schedule.userFavoriteScheduleId;
    }

    return result;
  }

  private setEmailSubscriptions(emails: string[]) {
    this.emailsSubscriptionList = [];
    emails.forEach(e => {
      this.emailsSubscriptionList.push({ email: e, id: 0, hovered: false });
    });

    this.updateEmailsSubscriptionListIds();

    console.debug(this.emailsSubscriptionList);
  }

  private setScheduleReports(reportsIds: number[]) {
    console.debug(reportsIds);

    reportsIds.forEach(id => {
      this.selectReport(id.toString());

    });
  }

  private selectReport(reportId: string) {
    switch (reportId) {
      case ReportKeys.Csv:
        this.isCheckedCsvReport = true;
        break;
      case ReportKeys.PatientsByRunDate:
        this.isCheckedPatientsByRunDateReport = true;
        break;
      case ReportKeys.PatientsByAssay:
        this.isCheckedPatientsByAssayReport = true;
        break;
      case ReportKeys.PatientsByFacilityAssay:
        this.isCheckedPatientsByFacilityAssayReport = true;
        break;
      case ReportKeys.PatientsByFacilityResult:
        this.isCheckedPatientsByFacilityResultReport = true;
        break;
      case ReportKeys.PatientsByResult:
        this.isCheckedPatientsByResultReport = true;
        break;
      case ReportKeys.PatientResultTrends:
        this.isCheckedPatientResultTrendsReport = true;
        break;
      case ReportKeys.PercentPositiveResults:
        this.isCheckedPercentPositiveResultsReport = true;
        break;
      case ReportKeys.PatientCoInfections:
        this.isCheckedPatientCoInfectionsReport = true;
        break;
      case ReportKeys.QualityControlReport:
        this.isCheckedQualityControlReportReport = true;
        break;
      case ReportKeys.TestVolumeByType:
        this.isCheckedTestVolumeByTypeReport = true;
        break;
      case ReportKeys.CsvByStorageDate:
        this.isCheckedCsvByStorageDateReport = true;
        break;
    }

  }

  private getScheduleReports(): number[] {
    const outputTypes: number[] = [];

    if (this.isCheckedCsvReport) {
      outputTypes.push(+ReportKeys.Csv);
    }
    if (this.isCheckedCsvByStorageDateReport) {
      outputTypes.push(+ReportKeys.CsvByStorageDate);
    }
    if (this.isCheckedPatientsByRunDateReport) {
      outputTypes.push(+ReportKeys.PatientsByRunDate);
    }
    if (this.isCheckedPatientsByAssayReport) {
      outputTypes.push(+ReportKeys.PatientsByAssay);
    }
    if (this.isCheckedPatientsByFacilityAssayReport) {
      outputTypes.push(+ReportKeys.PatientsByFacilityAssay);
    }
    if (this.isCheckedPatientsByFacilityResultReport) {
      outputTypes.push(+ReportKeys.PatientsByFacilityResult);
    }
    if (this.isCheckedPatientsByResultReport) {
      outputTypes.push(+ReportKeys.PatientsByResult);
    }
    if (this.isCheckedPatientResultTrendsReport) {
      outputTypes.push(+ReportKeys.PatientResultTrends);
    }
    if (this.isCheckedPercentPositiveResultsReport) {
      outputTypes.push(+ReportKeys.PercentPositiveResults);
    }
    if (this.isCheckedPatientCoInfectionsReport) {
      outputTypes.push(+ReportKeys.PatientCoInfections);
    }
    if (this.isCheckedQualityControlReportReport) {
      outputTypes.push(+ReportKeys.QualityControlReport);
    }
    if (this.isCheckedTestVolumeByTypeReport) {
      outputTypes.push(+ReportKeys.TestVolumeByType);
    }

    return outputTypes;
  }

  private getName(): string {
    return this.favoritesFormGroup.get('name').value as string;
  }

  private getShareWithOrgSelected(): boolean {
    return this.favoritesFormGroup.get('export').value as boolean;
  }

  private getEmail(): string {
    return this.favoritesFormGroup.get('email').value as string;
  }

  private setView(data: FavoriteModalData) {

    console.debug('setView');
    console.debug(data.favorite);

    this.favoritesFormGroup.setValue({
      name: data.favorite.getLabel(),
      export: data.favorite.isShareWithOrgEnabled(),
      email: ''
    });

    if (this.modalData.schedule) {
      const schedule = EmailSubscriptionScheduleTypes[this.modalData.schedule.scheduleFrequency] as EmailSubscriptionScheduleTypes;
      this.selectedEmailScheduleDescription = this.getEmailScheduleDescription(schedule);

      if (this.modalData.schedule && this.modalData.schedule.outputTypes) {
        const reportIds: number[] = JSON.parse(this.modalData.schedule.outputTypes) as number[];

        this.setScheduleReports(reportIds);
      }

      if (this.modalData.schedule.emails) {
        const emails: string[] = JSON.parse(this.modalData.schedule.emails) as string[];

        this.setEmailSubscriptions(emails);
      }
    } else {
      this.selectedEmailScheduleDescription = this.getEmailScheduleDescription(EmailSubscriptionScheduleTypes.None);
    }
  }

  updateReportPermissions(): void {
    this.isPatientResultTrendsEnabled = this.filterService.isReportEnabled(ReportKeys.PatientResultTrends);
    this.isPatientsByAssayEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByAssay);
    this.isPatientsByFacilityAssayEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByFacilityAssay);
    this.isPatientsByFacilityResultEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByFacilityResult);
    this.isPatientsByResultEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByResult);
    this.isPatientsByRunDateEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByRunDate);
    this.isPercentPositiveResultEnabled = this.filterService.isReportEnabled(ReportKeys.PercentPositiveResults);
    this.isPatientCoInfectionsEnabled = this.filterService.isReportEnabled(ReportKeys.PatientCoInfections);
    this.isQualityControlReportEnabled = this.filterService.isReportEnabled(ReportKeys.QualityControlReport);
    this.isCsvEnabled = true;
    this.isCsvByStorageDateEnabled = true;
    this.isTestVolumeByTypeEnabled = true;
  }
}
