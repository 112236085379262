import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BaseComponent, TitleService, MetadataService, User, PatientSampleTypesResponse,
  LoincCode, AssayType, PatientSampleType, SnomedCode, Analyte, TranslateNotificationKeys, HttpCachingClient
} from '../../../core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, shareReplay } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/core/utils/translateServiceHelper';

export enum AnalyteValidation {
  None,
  InvalidCount,
  DataInvalid
}

export enum ResultCode {
  Positive = 1,
  Negative = 2,
  Passed = 3,
  Failed = 4,
  Invalid = 5
}

@Component({
  selector: 'myvirena2-assays-edit',
  templateUrl: './assays-edit.component.html',
  styleUrls: ['./assays-edit.component.scss'],
  standalone: false
})
export class AssaysEditComponent extends BaseComponent implements OnInit, OnDestroy {
  private paramId = 'id';
  private assay: PatientSampleTypesResponse;

  public translationBaseKey = 'AssayEdit';
  public translationAnalyteTableKey = 'AnalyteTable';
  public newAssay = true;
  public isAssayAdmin: boolean;
  public assayId: number;
  public assayFormGroup: UntypedFormGroup;
  public analytes: Analyte[] = [];
  public snomeds: SnomedCode[] = [];

  public codes: LoincCode[] = [];
  public filteredCodes: Observable<LoincCode[]>;

  public types: AssayType[] = [];
  public filteredTypes: Observable<AssayType[]>;

  // This is required to use enums in Angular templates
  public AnalyteValidation = AnalyteValidation;
  public analyteError: AnalyteValidation = AnalyteValidation.None;

  // TODO: Add 'delete' when support for removing analytes is needed
  displayColumns = ['analyteName', 'analytePositive', 'analyteNegative', 'analyteInvalid'];

  constructor(
    private metadataService: MetadataService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cachingService: HttpCachingClient
  ) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      this.assayId = params[this.paramId] as number;
      this.newAssay = Number(this.assayId) === 0;

      if (!this.newAssay) {
        if (history && history.state) {
          const historyStateTyped = history.state as { data?: PatientSampleTypesResponse };
          if (historyStateTyped.data) {
            this.loadAssay(historyStateTyped.data);
          }
        } else {
          this.subscription.add(this.metadataService.getPatientSampleTypes().subscribe((assays: PatientSampleTypesResponse[]) => {
            this.loadAssay(assays.find(a => Number(a.testTypeId) === Number(this.assayId)));
          }));
        }
      } else {
        this.loadNew();
      }
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);

  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  initForm(): void {
    /* eslint-disable @typescript-eslint/unbound-method */
    this.assayFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      code: ['', Validators.required],
      export: false,
    });
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  onSave(): void {
    this.analyteError = AnalyteValidation.None;

    const assay: PatientSampleType = {
      testTypeId: 0,
      testName: this.assayFormGroup.get('name').value as string,
      assayTypeId: 0,
      assayTypeCode: this.assayFormGroup.get('type').value as string,
      loincCodeId: 0,
      loincCode: this.assayFormGroup.get('code').value as string,
      exportToPublicHealth: this.assayFormGroup.get('export').value as boolean,
      analytes: []
    };

    const existingLoinc = this.codes.find(c => c.code === assay.loincCode);
    if (existingLoinc) {
      assay.loincCodeId = existingLoinc.loincCodeId;
    }

    const existingType = this.types.find(t => t.code === assay.assayTypeCode);
    if (existingType) {
      assay.assayTypeId = existingType.assayTypeId;
    }

    if (!this.analytes || this.analytes.length === 0) {
      this.analyteError = AnalyteValidation.InvalidCount;
      return;
    }

    this.analytes.forEach(analyte => {
      assay.analytes.push({
        analyteId: analyte.analyteId,
        analyteName: analyte.analyteName,
        positiveSnomed: analyte.positiveSnomed,
        negativeSnomed: analyte.negativeSnomed,
        invalidSnomed: analyte.invalidSnomed
      });
    });

    if (!assay.analytes.every(a => a.analyteName !== '')) {
      this.analyteError = AnalyteValidation.DataInvalid;
      return;
    }

    let saveObservable: Observable<unknown>;
    if (this.newAssay) {
      saveObservable = this.metadataService.postInsertTestType(assay);
    } else {
      assay.testTypeId = this.assay.testTypeId;
      saveObservable = this.metadataService.putUpdateTestType(assay);
    }

    this.subscription.add(saveObservable
      .subscribe({
        next: () => {
          this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveSuccess}`));
          this.cachingService.invalidate();
          this.location.back();
        },
        error: (err) => {
          let errMsg = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`);
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (err.error && err.error.TestName && err.error.TestName.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            if (err.error.TestName[0].indexOf('already in use') > 0) {
              errMsg = translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailureAlreadyInUse}`);
            }
          }
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
          this.toastr.error(undefined, errMsg);
        }
      }));
  }

  onCancel(): void {
    this.location.back();
  }

  public loadInitData(): void {
    this.subscription.add(this.metadataService.getLoincCodes().subscribe((codes: LoincCode[]) => {
      this.codes = codes;

      this.filteredCodes = this.assayFormGroup.get('code').valueChanges
        .pipe(
          startWith(this.newAssay ? '' : this.assay.loincCode.code),
          map((code: string) => code ? this.filterCodes(code) : this.codes.slice()),
          shareReplay(1)
        );
    }));

    this.subscription.add(this.metadataService.getAssayTypes().subscribe((types: AssayType[]) => {
      this.types = types;

      this.filteredTypes = this.assayFormGroup.get('type').valueChanges
        .pipe(
          startWith(this.newAssay ? '' : this.assay.assayType.code),
          map((type: string) => type ? this.filterTypes(type) : this.types.slice()),
          shareReplay(1)
        );
    }));

    this.subscription.add(this.metadataService.getSnomedCodes().subscribe((snomeds: SnomedCode[]) => {
      this.snomeds = snomeds;
    }));
  }

  public loadNew(): void {
    this.subscription.add(this.metadataService.getMe().subscribe((me: User) => {

      this.assayFormGroup.setValue({
        name: '',
        type: '',
        code: '',
        export: false,
      });

      this.loadInitData();
    }));
  }

  public loadAssay(assay: PatientSampleTypesResponse): void {
    if (!assay) {
      return;
    }

    this.assay = assay;

    const analytes: Analyte[] = [];

    this.assay.analytes.forEach(analyte => {

      const positive = analyte.analyteResultCodes.find(c => c.resultCodeId === ResultCode.Positive.valueOf());
      const negative = analyte.analyteResultCodes.find(c => c.resultCodeId === ResultCode.Negative.valueOf());
      const invalid = analyte.analyteResultCodes.find(c => c.resultCodeId === ResultCode.Invalid.valueOf());

      analytes.push({
        analyteId: analyte.analyteId,
        analyteName: analyte.name,
        positiveSnomed: positive ? positive.snomedCode.code : '',
        negativeSnomed: negative ? negative.snomedCode.code : '',
        invalidSnomed: invalid ? invalid.snomedCode.code : '',
      });
    });

    this.analytes = analytes;

    this.assayFormGroup.setValue({
      name: this.assay.testName,
      type: this.assay.assayType.code,
      code: this.assay.loincCode.code,
      export: this.assay.exportToPublicHealth || false
    });

    this.loadInitData();
  }

  public addAnalyte(): void {
    this.analytes = [...this.analytes, {
      analyteId: 0,
      analyteName: '',
      positiveSnomed: '',
      negativeSnomed: '',
      invalidSnomed: ''
    }];
  }

  public deleteAnalyte(analyte: Analyte): void {
    const index = this.analytes.indexOf(analyte);
    const analytes = [...this.analytes];

    if (index !== -1) {
      analytes.splice(index, 1);
      this.analytes = analytes;
    }
  }

  private filterCodes(value: string): LoincCode[] {
    const filterValue = value.toLowerCase();
    return this.codes.filter(code => code.code.toLowerCase().indexOf(filterValue) === 0);
  }

  private filterTypes(value: string): AssayType[] {
    const filterValue = value.toLowerCase();
    return this.types.filter(type => type.code.toLowerCase().indexOf(filterValue) === 0);
  }
}
