import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChildren, QueryList } from '@angular/core';
import { MetadataService, User, BaseComponent, SortDirection, TitleService, UsersNavigationKeys, TranslateNotificationKeys, DateConstants } from '../../../core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { scrollElementIntoView } from 'src/app/core/utils/scroll';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'myvirena2-user-unapproved',
  templateUrl: './user-unapproved.component.html',
  styleUrls: ['./user-unapproved.component.scss'],
  standalone: false
})
export class UserUnapprovedComponent extends BaseComponent implements OnInit, OnDestroy {
  runDateFormat = DateConstants.YearMonthDayWithoutTimeFormat;
  private paramPageNumber = 'pageNumber';
  private paramPageSize = 'pageSize';
  private paramSortColumn = 'sortColumn';
  private paramSortDirection = 'sortDirection';

  public translationBaseKey = 'UserUnapproved';

  displayColumns = ['displayname', 'username', 'organizationname', 'facilityname', 'createddate', 'notes'];
  sortColumn = 'userName';
  sortDirection: SortDirection = SortDirection.Ascending;
  sortMatDirection: string;

  pageSize = 20;
  pageNumber = 0;

  users: User[];
  sortedUsers: User[];

  @ViewChildren('matrow', { read: ViewContainerRef }) tableRows: QueryList<ViewContainerRef>;

  constructor(private metadataService: MetadataService, private router: Router, private route: ActivatedRoute,
    private titleService: TitleService, private toastr: ToastrService, private translate: TranslateService) {
    super();
  }

  onRowSelect(user: User): void {
    void this.router.navigate([UsersNavigationKeys.EditFullPath, user.externalObjectId]);
  }

  public loadData(): void {
    this.subscription.add(this.metadataService.getUnapprovedUsers().subscribe({
      next: (users: User[]) => {
        this.users = users;
        this.updateData();
      },
      error: () => {
        this.toastr.error(translate(this.translate, `${this.translationBaseKey}.LoadFailure`),
          translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.LoadFailure}`));
      }
    }));
  }

  doSort(sortEvent: Sort): void {
    this.sortColumn = sortEvent.active;
    this.sortDirection = sortEvent.direction === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.pageNumber = 0;
    this.updateData();
  }

  doPage(pageEvent: PageEvent): void {

    const isPageUp: boolean = pageEvent.pageIndex >= this.pageNumber;

    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.updateData(true, isPageUp);
  }

  scrollToTop(): void {
    scrollElementIntoView(this.tableRows.first?.element);
  }

  scrollToBottom(): void {
    scrollElementIntoView(this.tableRows.last?.element);
  }

  updateData(doScroll: boolean = false, isPageUp: boolean = true): void {

    const searchParams: Params = {
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber
    };

    // update navigation
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchParams,
        replaceUrl: true
      }
    );

    const isAsc = this.sortDirection === SortDirection.Ascending;

    const sliceStart = this.pageNumber * this.pageSize;

    this.sortedUsers = this.users.sort((a, b) => {
      switch (this.sortColumn) {
        case 'displayname': return this.compare(a.displayName.toLowerCase(), b.displayName.toLowerCase(), isAsc);
        case 'username': return this.compare(a.userName.toLowerCase(), b.userName.toLowerCase(), isAsc);
        case 'organizationname': return this.compare(a.requestedOrganization.toLowerCase(), b.requestedOrganization.toLowerCase(), isAsc);
        case 'facilityname': return this.compare(a.requestedFacility.toLowerCase(), b.requestedFacility.toLowerCase(), isAsc);
        case 'createddate': return this.compare(a.b2cCreatedDate, b.b2cCreatedDate, isAsc);
        case 'notes': return this.compare(a.notes ? a.notes.toLowerCase() : '', b.notes ? b.notes.toLowerCase() : '', isAsc);
        default: return 0;
      }
    }).slice(sliceStart, sliceStart + this.pageSize);

    if (doScroll) {
      if (isPageUp) {
        this.scrollToTop();
      } else {
        this.scrollToBottom();
      }
    }

  }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.pipe(first()).subscribe(params => {
      this.pageNumber = params[this.paramPageNumber] as number || this.pageNumber;
      this.pageSize = params[this.paramPageSize] as number || this.pageSize;
      this.sortColumn = (params[this.paramSortColumn] as string || this.sortColumn).toLowerCase();
      this.sortDirection = params[this.paramSortDirection] as SortDirection || this.sortDirection;
      this.sortMatDirection = +this.sortDirection === SortDirection.Descending.valueOf() ? 'desc' : 'asc';
      this.loadData();
    }));

    this.titleService.updateTitleTranslateKey(`${this.translationBaseKey}.Title`);
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
