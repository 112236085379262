import { ViewContainerRef, Directive } from '@angular/core';

@Directive({
  selector: '[formControlErrorContainer]',
  standalone: false
})
export class FormControlErrorContainerDirective {
  constructor(public vcr: ViewContainerRef) {
  }
}
