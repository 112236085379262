<h1 mat-dialog-title>{{ 'ExportOrphansDialog.Title' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'ExportOrphansDialog.ContentPart1' | translate:{'resultCount': resultCount} }}</p>
  <p>{{ 'ExportOrphansDialog.ContentPart2' | translate }}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Dismiss</button>
  <button mat-button (click)="onExport()" cdkFocusInitial>Export</button>
</div>

