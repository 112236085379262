import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateNotificationKeys, MetadataService, BaseComponent, FilterService, FilterState, QueryService, TestResultsQuery } from '../../../core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { AssayItem, AssaysUIQuery } from '../../../core/components/filters/filter-custom-assays/filter-custom-assays.model';
import { FilterElement } from 'src/app/core/components/filters/FilterElement';
import { translate } from 'src/app/core/utils/translateServiceHelper';

export enum ExportFileType {
  PDF = 'PDF',
  CSV = 'CSV',
  QC = 'QC'
}

export class ModalPayload {
  constructor(
    public fileType: ExportFileType,
    public value: string,
    public isTestResultExport: boolean
  ) { }
}

@Component({
  selector: 'app-export-file-modal',
  templateUrl: './export-file-modal.component.html',
  styleUrls: ['./export-file-modal.component.scss'],
  standalone: false
})
export class ExportFileModalComponent extends BaseComponent implements OnDestroy {
  urlEndpoint: string;
  searchText: string;
  fileType: ExportFileType;
  isTestResultExport: boolean;
  assaysUIQuery: AssaysUIQuery;
  assays: FilterElement<AssayItem>[];
  assayId: number;
  constructor(
    public dialogRef: MatDialogRef<ExportFileModalComponent>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private filterService: FilterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private queryService: QueryService,
    @Inject(MAT_DIALOG_DATA) public data: ModalPayload,
  ) {
    super();
    this.fileType = data.fileType;
    this.searchText = data.value;
    this.isTestResultExport = data.isTestResultExport;
    this.urlEndpoint = this.router.url;
    this.assayId = this.getAssayId();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getAssayId(): number {
    if (this.urlEndpoint.includes('mapping')) {
      const currentQuery = { ...this.queryService.getCurrentQuery() } as TestResultsQuery;
      this.assaysUIQuery = this.filterService.getAssaysUIQuery();
      if (!currentQuery.testTypes || currentQuery.testTypes.length > 1) {
        return this.assaysUIQuery.items[0].item.getId();
      } else if (currentQuery.testTypes.length === 1) {
        const item = this.assaysUIQuery.items.find(a => a.isSelected);
        return item.item.getId();
      }
    } else {
      return null;
    }
  }

  logAnalytics(filterState: FilterState): void {
    if (this.urlEndpoint.includes('results')) {
      this.googleAnalyticsService.logWithLabels('Export to CSV', 'Test Results', 'Export to CSV', 'filters', filterState);
    }
    else if (this.urlEndpoint.includes('mapping')) {
      this.googleAnalyticsService.logWithLabels('Export to CSV', 'Map', 'Export to CSV', 'filters', filterState);
    }
  }

  public async onExport(): Promise<void> {
    if (this.fileType === ExportFileType.CSV) {
      console.debug('Exporting CSV ...');

      let filterState: FilterState = null;
      if (this.isTestResultExport) {
        filterState = await this.filterService.serializeState();
      }
      this.logAnalytics(filterState);
      this.subscription.add(
        this.metadataService.exportReport(filterState, this.urlEndpoint, this.searchText, this.assayId).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportSuccess}`));
            this.dialogRef.close();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportFailure}`));
            this.dialogRef.close();
          }
        }));

    } else if (this.fileType === ExportFileType.QC) {
      console.debug('Exporting QC ...');

      const filterState = await this.filterService.serializeState();
      this.googleAnalyticsService.logWithLabels('Export to QC', 'Test Results', 'Export to QC', 'filters', filterState);

      this.subscription.add(
        this.metadataService.exportQCReport(filterState).subscribe({
          next: () => {
            this.toastr.success(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportSuccess}`));
            this.dialogRef.close();
          },
          error: () => {
            this.toastr.error(undefined, translate(this.translate, `${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.ExportFailure}`));
            this.dialogRef.close();
          }
        }));

    } else if (this.fileType === ExportFileType.PDF) {
      // TODO: API call to export PDF file
      console.debug('Exporting PDF ...');
      this.dialogRef.close();
    }

  }
}
