import { Component } from '@angular/core';

@Component({
  selector: 'app-user-blocking-cookies',
  templateUrl: './user-blocking-cookies.component.html',
  styleUrls: ['./user-blocking-cookies.component.scss'],
  standalone: false
})
export class UserBlockingCookiesComponent {
}
