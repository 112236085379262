import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BusyService } from '../services/busy.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  preventBusyIndicatorString = 'preventBusyIndicator';

  constructor(private busyService: BusyService) { }
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const preventBusy = req.headers.get(this.preventBusyIndicatorString);
    if (!preventBusy) {
      this.busyService.busy();
    }
    const updatedReq = req.clone({headers: req.headers.delete(this.preventBusyIndicatorString)});
    return next.handle(updatedReq).pipe(
      finalize(() => {
        if (!preventBusy) {
          this.busyService.idle();
        }
      })
    );
  }
}
