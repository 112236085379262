import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsApiListComponent } from './results-api-list/results-api-list.component';
import { ResultsApiEditComponent } from './results-api-edit/results-api-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuidelCoreModule, AngularMaterialModule } from '../../core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [ResultsApiListComponent, ResultsApiEditComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    QuidelCoreModule,
    TranslateModule,
    ToastrModule,
  ],
  exports: [
    ResultsApiListComponent,
    ResultsApiEditComponent
  ]
})
export class ResultsApiModule { }
