// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, OrphansNavigationKeys } from '../../core';
import { OrphansSearchComponent } from './orphans-search/orphans-search.component';
import { OrphansAssignComponent } from './orphans-assign/orphans-assign.component';
import { OrphansResultListComponent } from './orphans-result-list/orphans-result-list.component';

export const routes: Routes = [
  {
    path: OrphansNavigationKeys.Search, component: OrphansSearchComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: OrphansNavigationKeys.EditWithIdArg, component: OrphansAssignComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: OrphansNavigationKeys.ResultsListWithId, component: OrphansResultListComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class OrphansRoutingModule { }
