import { TranslateService } from '@ngx-translate/core';
import { OnDestroy } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { translate } from '../utils/translateServiceHelper';

export abstract class TranslateBaseComponent extends BaseComponent implements OnDestroy {

  constructor(protected translate: TranslateService, private componentTranslationKey: string) {
    super();

    translate.setDefaultLang('en'); // TODO: better way?

    this.subscription.add(this.translate.get(this.componentTranslationKey).subscribe(s => {
      this.onTranslateResourcesLoaded();
    }));

    this.subscription.add(this.translate.onLangChange.subscribe(s => {
      this.onTranslateLanguageChanged();
    }));
  }

  /**
   * Executed when the language resources are loaded for the first time. Localization specific functionality can now be executed.
   *
   * **ONLY for localization specific functionality. Anything utilizing injected services should occur in ngOnInit or other Angular lifecycle functions**
   */
  abstract onTranslateResourcesLoaded(): void;

  /**
   * Executed when the current language has changed. Localization specific functionality can now be executed to refresh for the new language.
   *
   * **ONLY for localization specific functionality. Anything utilizing injected services should occur in ngOnInit or other Angular lifecycle functions**
   */
  abstract onTranslateLanguageChanged(): void;

  protected getText(key: string): string {
    return translate(this.translate, `${this.componentTranslationKey}.${key}`);
  }
}
