import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import {
  InitDataSelectionLists, User, Facility, CountiesSelection, State, IdbRecord,
  Features, Role, Organization, FacilityDescription, OrganizationDistributor, Instrument, PatientSampleTypesResponse, UserFavorite, PairedDevice, PairedDeviceType, InstrumentResultOrphan,
  ResultsApiIdentifier, InsertApplicationIdentifierRequest, UpdateApplicationIdentifierRequest, InstrumentType
} from './metadata.service.models';
import { SearchResultsPage, SortDirection } from './common.service.models';
import { Observable, of } from 'rxjs';
import { HttpCachingClient } from '../utils/http-caching-client';

@Injectable({
  providedIn: 'root'
})
export class MetadataServiceStub {

  constructor(private httpClient: HttpCachingClient, private configurationService: ConfigurationService) {
  }

  // Application

  public getMe(): Observable<User> {
    return of({
      displayName: '',
      givenName: '',
      surname: '',
      email: '',
      userDetailId: 0,
      externalObjectId: '',
      isActive: false,
      lastModifiedDate: '',
      createdDate: new Date(),
      b2cCreatedDate: new Date(),
      group: {
        objectId: '',
        displayName: ''
      },
      additionalOrganizationIds: []
    });
  }

  public fetchInitDataSelectionLists(): Observable<InitDataSelectionLists> {
    return of({
      Analytes: [],
      TestTypes: [],
      SampleTypes: [],
      InstrumentTypes: [],
      Organizations: [],
      Countries: []
    });
  }

  public getFacSelectionListByOrg(id: number): Observable<Facility[]> {
    return of<Facility[]>([]);
  }

  public getStateSelectionListByCountry(id: number): Observable<State[]> {
    return of<State[]>([]);
  }

  public getInstrumentTypeList(): Observable<InstrumentType[]> {
    return of<InstrumentType[]>([]);
  }

  public getCountySelectionListByStates(ids: number[]): Observable<CountiesSelection> {
    return of({});
  }

  public getPatientSampleTypes(): Observable<PatientSampleTypesResponse[]> {
    return of<PatientSampleTypesResponse[]>([]);
  }

  // Settings
  public getFeatures(): Observable<Features> {
    return of({ WriteEnabled: false, FunctionImportEnabled: false, B2CModificationsEnabled: false, TestResultsDeleteEnabled: false });
  }

  public getSettings(): void {
    return;
  }

  // Users
  public getUser(id: string): Observable<User> {
    return of({
      displayName: '',
      givenName: '',
      surname: '',
      email: '',
      userDetailId: 0,
      externalObjectId: '',
      isActive: false,
      lastModifiedDate: '',
      createdDate: new Date(),
      b2cCreatedDate: new Date()
    });
  }

  public getUnapprovedUsers(): Observable<User[]> {
    return of<User[]>([]);
  }

  public searchUsers(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<User>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public deleteUser(user: User): void {
    return;
  }

  public saveUser(user: User): void {
    return;
  }

  public getRoles(): Observable<Role[]> {
    return of<Role[]>([]);
  }

  // Organizations
  public getOrganizations(): Observable<Organization[]> {
    return of<Organization[]>([]);
  }

  public searchOrganizations(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Organization>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getOrganizationDistributors(): Observable<OrganizationDistributor[]> {
    return of<OrganizationDistributor[]>([]);
  }

  public getOrganization(id: number): Observable<Organization> {
    return of({
      organizationId: 0,
      name: '',
      description: '',
      phone: '',
      contactName: '',
      mobilePhone: '',
      email: '',
      address_AddressId: 0,
      rejectPatientId: false,
      rejectStudyCodes: false,
      nationalMapSubscription: false,
      rejectOrderNumbers: false,
      organizationDistributor_OrganizationDistributorId: 0,
      lastModifiedDate: new Date(),
      lastModifiedBy: '',
      shareNational: false,
      shareState: false
    });
  }


  public deleteOrganization(org: Organization): void {
    return;
  }

  public postOrganization(org: Organization): void {
    return;
  }

  public putOrganization(org: Organization): void {
    return;
  }

  // Facilities
  public getFacilitiesByOrgId(id: number): Observable<Facility[]> {
    return of<Facility[]>([]);
  }

  public searchFacilities(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Facility>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getFacility(id: number): Observable<Facility> {
    return of({
      facilityId: 0,
      name: '',
      organization_OrganizationId: 0,
      facilityDescription_FacilityDescriptionId: 0,
      contactName: '',
      contactEmail: '',
      contactWorkPhone: '',
      contactMobilePhone: '',
      lastModifiedBy: '',
      lastModifiedDate: new Date()
    });
  }

  public getFacilityDescriptions(): Observable<FacilityDescription[]> {
    return of<FacilityDescription[]>([]);
  }

  public deleteFacility(facility: Facility): void {
    return;
  }

  public postFacility(facility: Facility): void {
    return;
  }

  public putFacility(facility: Facility): void {
    return;
  }

  // Instruments

  public getInstrument(id: number): Observable<Instrument> {
    return of({
      instrumentId: 0,
      serialNumber: '',
      active: false
    });
  }

  public deleteInstrument(instrument: Instrument): void {
    return;
  }

  public postInstrument(instrument: Instrument): void {
    return;
  }

  public putInstrument(instrument: Instrument): void {
    return;
  }

  public searchInstruments(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<Instrument>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getInstrumentsByPairedDeviceId(id: number): Observable<SearchResultsPage<Instrument>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getInstrumentResultOrphanBySummary(serialNumber: string, deviceAddress: string, zipCode: string): Observable<InstrumentResultOrphan> {
    return of(
      {
        instrumentSerialNumber: '',
        instrumentTypeCode: '',
        originatingDeviceAddress: '',
        zipCode: '',
        resultCount: 0,
        instrumentResultOrphanId: 0,
        resultOrigin: '',
        storageDateUtc: new Date(),
        resultBlobUri: '',
        errorCode: '',
        orphanReason: ''
      }
    );
  }

  public getInstrumentResultOrphansPageBySummary(serialNumber: string, deviceAddress: string, zipCode: string, sortColumn: string, sortDirection: SortDirection,
    pageSize: number, pageNumber: number): Observable<SearchResultsPage<InstrumentResultOrphan>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getInstrumentResultOrphanBlobById(instrumentResultOrphanId: number): void {
    return;
  }

  // IDB Records

  public getIdbRecordBySerialNumber(serialNumber: string): Observable<IdbRecord> {
    return of(
      {
        loadDate: new Date(),
        instrumentItemNumber: '',
        instrumentDescription: '',
        instrumentProductClass: '',
        serialNumber: '',
        installationGroup: '',
        status: '',
        hardwareItemNumber: '',
        hardwareDescription: '',
        softwareItemNumber: '',
        softwareDescription: '',
        addressCode: '',
        shippingSalutation: '',
        shippingStreetNumber: '',
        shippingStreetName: '',
        shippingCity: '',
        shippingState: '',
        shippingZipCode: '',
        shippingCountry: '',
        shippingDate: new Date(),
        imei: '',
        iccId: '',
        devId: '',
        wmId: '',
        emId: '',
        slsOrd: '',
        slsPos: 0,
        crmId: '',
        optIn: 0,
        actSts: 0,
        contId: '',
        shippingBuilding: '',
        shippingUnit: ''
      }
    );
  }

  // Paired Devices

  public searchPairedDevices(query: string, sortColumn: string, sortDirection: SortDirection, pageSize: number, pageNumber: number): Observable<SearchResultsPage<PairedDevice>> {
    return of(
      {
        pagingInformation: {
          pageNumber: 0,
          pageSize: 0,
          sortColumn: '',
          sortDirection: 0
        },
        totalResults: 0,
        results: []
      });
  }

  public getPairedDevice(id: number): Observable<PairedDevice> {
    return of({
      pairedDeviceId: 0,
      deviceAddress: ''
    });
  }

  public getPairedDeviceTypes(): Observable<PairedDeviceType[]> {
    return of<PairedDeviceType[]>([]);
  }

  public deletePairedDevice(pairedDevice: PairedDevice): void {
    return;
  }

  public postPairedDevice(pairedDevice: PairedDevice): void {
    return;
  }

  public putPairedDevice(pairedDevice: PairedDevice): void {
    return;
  }

  // Results APIs

  public getResultsApiIdentifier(id: string): Observable<ResultsApiIdentifier> {
    return of({
      resultsApiIdentifierId: '',
      name: '',
      facility: null,
      myVirenaEnabled: false,
      comment: ''
    });
  }

  public getResultsApiIdentifiers(): Observable<ResultsApiIdentifier[]> {
    return of<ResultsApiIdentifier[]>([]);
  }

  public postResultsApiIdentifier(request: InsertApplicationIdentifierRequest): void {
    return;
  }

  public updateResultsApiIdentifier(request: UpdateApplicationIdentifierRequest): void {
    return;
  }

  public deleteResultsApiIdentifier(resultsApiIdentifier: ResultsApiIdentifier): void {
    return;
  }

  // Locations
  public getStates(id: number): Observable<State[]> {
    return of<State[]>([]);
  }

  public postInsertUserFavorite(userFavorite: UserFavorite): void {
    return;
  }

  public getFavoriteForCurrentUser(): Observable<UserFavorite[]> {
    return of<UserFavorite[]>([]);
  }

  // userDetail_UserDetailId, shareWithOrg, filterState
  public getUserFavorite(userFavoriteId: number): Observable<UserFavorite> {
    return of({ name: 'test', userDetail_UserDetailId: 0, shareWithOrg: false, filterState: undefined });
  }

  public updateUserFavorite(userFavorite: UserFavorite): void {
    return;
  }

  public deleteUserFavorite(userFavoriteId: number): void {
    return;
  }

}
