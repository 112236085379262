import {
  Component, Input, OnDestroy, ElementRef,
  HostBinding, ViewContainerRef, NgZone, TemplateRef, EmbeddedViewRef, Output, EventEmitter, ChangeDetectorRef, HostListener, ViewChild, Optional, Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { createPopper, Instance } from '@popperjs/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-virtual-select',
  templateUrl: './virtual-select.component.html',
  styleUrls: ['./virtual-select.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: VirtualSelectComponent
  }],
  standalone: false
})
export class VirtualSelectComponent implements MatFormFieldControl<unknown>, OnDestroy, ControlValueAccessor {

  static nextId = 0;

  @Output() closed = new EventEmitter();

  @HostBinding('tabindex') tabindex = 0;
  @ViewChild('origin') originElement: ElementRef<HTMLDivElement>;
  @ViewChild('dropdown') dropdownTemplate: TemplateRef<unknown>;

  @HostBinding() id = `core-virtual-select-${VirtualSelectComponent.nextId++}`;
  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return (this.focused || !this.empty || this.isOpen);
  }
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input() itemSize: number;
  @Input() valueField: string;
  @Input() displayField: string;
  @Input() showSearch = false;

  @Input() get dataSource(): [] | null {
    return this.dataSourceInternal;
  }
  set dataSource(source: [] | null) {
    this.dataSourceInternal = source;
    this.updateFilteredItems();
  }
  private dataSourceInternal: [];

  @Input()
  get placeholder(): string {
    return this.placeholderInternal;
  }
  set placeholder(plh: string) {
    this.placeholderInternal = plh;
    this.stateChanges.next();
  }
  private placeholderInternal: string;

  @Input()
  get value(): unknown {
    return this.valueInteral;
  }
  set value(val: unknown) {
    this.valueInteral = val;
    this.stateChanges.next();
  }
  private valueInteral: unknown;

  @Input()
  get required(): boolean {
    return this.requiredInternal;
  }
  set required(req: boolean) {
    this.requiredInternal = req;
    this.stateChanges.next();
  }
  private requiredInternal = false;

  get empty(): boolean {
    return this.value ? false : true;
  }

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  @Input()
  get disabled(): boolean {
    return this.isDisabled;
  }

  controlType = 'core-virtual-select';
  errorState = false;
  stateChanges = new Subject<void>();
  onChange: (value: unknown) => unknown;
  focused = false;
  isDisabled = false;
  searchText: string | null = null;
  filteredItems = [];

  private focusSub: Subscription;
  private view: EmbeddedViewRef<unknown>;
  private popperRef: Instance;

  constructor(
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.focusSub = this.fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin || this.isOpen;
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }

    this.destroyResources();
    this.stateChanges.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeydown($event: KeyboardEvent): void {
    if ($event.key === ' ' || $event.key === 'Enter') {
      if (!this.isOpen) {
        this.open(this.dropdownTemplate, this.originElement.nativeElement);
      }
    } else if ($event.key === 'Tab' && this.isOpen) {
      this.close();
    }
  }

  getLabel(item: Record<string, string>): string {
    return item[this.displayField];
  }

  getValue(item: Record<string, unknown>): unknown {
    return item[this.valueField];
  }

  getDisplayValue(): string {
    if (this.value) {
      if (this.dataSource) {
        const item = this.dataSource.find(i => this.getValue(i) === this.value);
        return item ? this.getLabel(item) : this.placeholder;
      }
    } else {
      return this.placeholder;
    }
  }

  open(dropdownTpl: TemplateRef<unknown>, origin: HTMLElement): void {

    if (this.isOpen) {
      return;
    }

    this.clearSearch();
    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0] as HTMLDivElement;

    document.body.appendChild(dropdown);
    dropdown.style.width = `${origin.offsetWidth}px`;
    if (this.showSearch) {
      setTimeout(() => {
        (<HTMLInputElement>dropdown.getElementsByClassName('search')[0]).focus();
      });
    }

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown);
    });

    this.handleClickOutside();
    this.focused = true;
    this.stateChanges.next();
  }

  listKeyDown($event: KeyboardEvent): void {
    if ($event.code === 'Escape') {
      this.close();
      setTimeout(() => {
        this.elRef.nativeElement.focus();
      });
    }
  }

  itemKeyDown($event: KeyboardEvent, item: Record<string, unknown>): void {
    if ($event.code === 'Enter') {
      this.itemSelected(item);
      setTimeout(() => {
        this.elRef.nativeElement.focus();
      });
    }
  }

  close(): void {
    this.closed.emit();

    this.destroyResources();

    this.focused = false;
    this.stateChanges.next();
  }

  destroyResources(): void {
    if (this.popperRef) {
      this.popperRef.destroy();
    }

    if (this.view) {
      this.view.destroy();
    }

    this.view = null;
    this.popperRef = null;
  }

  itemSelected(item: Record<string, unknown>): void {
    const val = this.getValue(item);
    this.value = val;
    this.onChange(val);
    this.close();
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const origin = this.popperRef.state.elements.reference as HTMLElement;
          const popper = this.popperRef.state.elements.popper;
          const contains = origin.contains(target as HTMLElement) || popper.contains(target as HTMLElement);
          return contains === false;
        }),
        takeUntil(this.closed)
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  writeValue(obj: unknown): void {
    this.value = obj;
  }

  registerOnChange(fn: (value: unknown) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: unknown) => unknown): void {
    // intentionally blank
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    // intentionally blank
  }

  clearSearch(): void {
    this.searchText = null;
    this.updateFilteredItems();
  }

  onSearchChanged(val: string | null): void {
    this.searchText = val;
    this.updateFilteredItems();
  }

  private updateFilteredItems() {
    if (!this.searchText || this.searchText === '' || !this.dataSource) {
      this.filteredItems = this.dataSource;
      return;
    }

    this.filteredItems = this.dataSource.filter(i => this.getLabel(i).toLocaleLowerCase().startsWith(this.searchText.toLocaleLowerCase()));
  }
}
