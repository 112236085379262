<div class="report-action-container">
  <div class="left-side">
    <div *ngIf="this.patientsCoInfection.showChart">
      Total number of positives: {{ this.patientsCoInfection.totalPositivesCount | number }}
    </div>
    <div *ngIf="this.patientsCoInfection.showChart">
      Total number of tests: {{ this.patientsCoInfection.totalTestCount | number }}
    </div>
  </div>
  <div class="right-side">
    <button mat-flat-button [matMenuTriggerFor]="saveAsMenu">
      <mat-icon class="icon-button-override">save</mat-icon>
      Save As
    </button>
    <mat-menu #saveAsMenu>
      <button mat-menu-item (click)="saveImage()">
        <span>Image</span>
      </button>
      <button mat-menu-item (click)="savePDF()">
        <span>PDF</span>
      </button>
    </mat-menu>
  </div>
</div>
<core-patients-coinfection #chart></core-patients-coinfection>
