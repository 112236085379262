<div class="report-action-container">
  <div class="left-side">
    Select date range :
    <mat-radio-group aria-label="Select an option" (change)="onChange($event)">
      <mat-radio-button value="{{DateGroupType.Year}}">Years</mat-radio-button>
      <mat-radio-button value="{{DateGroupType.Month}}" checked>Months</mat-radio-button>
      <mat-radio-button value="{{DateGroupType.Week}}">Weeks</mat-radio-button>
      <mat-radio-button value="{{DateGroupType.Day}}">Days</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="right-side">
    <button mat-flat-button [matMenuTriggerFor]="saveAsMenu">
      <mat-icon class="icon-button-override">save</mat-icon>
      Save As
    </button>
    <mat-menu #saveAsMenu>
      <button mat-menu-item (click)="saveImage()">
        <span>Image</span>
      </button>
      <button mat-menu-item (click)="savePDF()">
        <span>PDF</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="report-action-container">
  <div class="left-side-2">
    <mat-form-field>
      <mat-label>Select Assay</mat-label>
      <mat-select (selectionChange)="onTestSelected($event)">
        <mat-option *ngFor="let test of testNames" [value]="test">
          {{test}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<core-patients-result-trends #chart></core-patients-result-trends>

<core-patients-result-trends-detail #secondarychart></core-patients-result-trends-detail>
