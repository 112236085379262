import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  BaseComponent, QueryService, SearchService, TitleService, TestResult_PatientsByAssayRow,
  PatientsAssayComponent, FilterService, ReportKeys
} from '../../core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';
import { translate } from 'src/app/core/utils/translateServiceHelper';

@Component({
  selector: 'app-report-patient-assay',
  templateUrl: './report-patient-assay.component.html',
  styleUrls: ['./report-patient-assay.component.scss'],
  standalone: false
})
export class ReportPatientAssayComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) private patientsByAssay: PatientsAssayComponent;

  constructor(private queryService: QueryService,
    private searchService: SearchService,
    private titleService: TitleService,
    private filterService: FilterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toastr: ToastrService,
    private translate: TranslateService) {
    super();
  }


  ngOnInit(): void {

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      console.debug(`Filters applied: ${filtersApplied}`);
    }));

    this.subscription.add(this.queryService.searchRequested.subscribe(() => {
      this.onSearch();
    }));

    this.onSearch();

    this.titleService.updateTitleTranslateKey('ReportMenu.PatientsByAssay');
  }

  onSearch(): void {

    if (this.filterService.isReportEnabled(ReportKeys.PatientsByAssay)) {
      this.googleAnalyticsService.logWithLabels('View Report', 'Report', 'View Patient By Assay Report', 'filters', this.queryService.getCurrentQuery(true));
      this.subscription.add(this.searchService.getReportPatientsByAssay(this.queryService.getCurrentQuery(true)).subscribe(
        (result: TestResult_PatientsByAssayRow[]) => {
          if (!result || result.length === 0) {
            const title = translate(this.translate, 'ReportMenu.NoDataToShow');
            this.toastr.info(undefined, title);
            return;
          }

          this.patientsByAssay.setData(result);
        }));
    } else {
      const message = translate(this.translate, 'ReportMenu.RequirePatient');
      const title = translate(this.translate, 'ReportMenu.ReportLoadError');
      this.toastr.error(message, title);
    }
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  savePDF(): void {
    this.patientsByAssay.saveAsPdf();
  }

  saveImage(): void {
    this.patientsByAssay.saveAsImage();
  }

}
