// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { NavigationGuard, FacilitiesNavigationKeys } from '../../core';
import { FacilityListComponent } from './facility-list/facility-list.component';
import { FacilityEditComponent } from './facility-edit/facility-edit.component';

export const routes: Routes = [
  {
    path: FacilitiesNavigationKeys.Search, component: FacilityListComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: FacilitiesNavigationKeys.EditWithIdArg, component: FacilityEditComponent,
    canActivate: [NavigationGuard]
  },
  {
    path: FacilitiesNavigationKeys.EditWithIdAndOrgId, component: FacilityEditComponent,
    canActivate: [NavigationGuard]
  },
];

// Currently NOT lazy loading the admin modules because of weird DOM issues during Angular 19 upgrade
// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class FacilityRoutingModule { }
